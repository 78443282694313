export default `
test_info {
    id
    name
    test_date
    classroom_test_type {
      name
    }
  }

  test_subject {
    subject_id
    subject_name
    avg_marks
    avg_percentage
    highest_marks
    highest_percentage
    my_marks
    my_percentage
    max_marks
    my_rank
    percentile
    total_ques
    correct
    incorrect
    partial_correct
  }
  total_info {
    max_marks
    avg_marks
    avg_percentage
    highest_marks
    highest_percentage
    
    my_marks
    my_percentage
    my_rank
    percentile
    total_ques
    correct
    incorrect
    partial_correct
  }
    `;
