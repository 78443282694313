import React, { useContext, useState } from "react";
import { useLocation} from "react-router-dom";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutsession from "../../../../../../utils/form-layout-admin";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {  
  handleFormChange,  
  resetForm, 
  error_beautify,    
} from "../../../../../../utils/functions";
import Typography from "@material-ui/core/Typography";
  
import Contacts from './contacts';
import {api_url} from '../config.js'
const axios = require('axios');

export default () => {
   const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
       snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar }) => {
	 const location = useLocation()
   
  
    
  const [id, setId] = useState('');
  
   React.useEffect(() => {
 let state=location.state;
 let idd=''
 if(state && state.id){
	 idd=state.id
  setId(idd);
  handleSubmit(idd);
	  
 	
 }
   
	
	
  }, [location]);
  
  
  const [show,setShow]=useState(false);
    const [res, setRes] = useState(null);
  const handleInputChange = (e) => {

   setId(e.target.value);
		 
  };
 
  
  const handleSubmit = async (in_id) => {
	
 var lead_id=id;
 if(!id)
 lead_id=in_id;
if(lead_id.length<1)
{
	snackBar.show('Enter valid Lead Id');
	return false;
}


  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/view-interaction.php',
        method: 'post',
		responseType: 'json',
       data: 'auth_token=##reliabletoken##&lead_id='+lead_id,
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
 
 setRes( data.message.res)

      
     // resetForm({ values, setValues });
    } catch (e) {
		 
      //snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 
 console.log(res)
   
  return (
 
      <>
        
          <Grid spacing={3} container>
            <Grid item xs={12}> 
              <TextField
                label="Enter Lead Id" 
                variant="outlined"
                name="mobile"
				 inputProps={{
					maxLength: 10,
				  }}
                value={id}
                onChange={handleInputChange}
			 
              />
            </Grid>

             
            <Grid item xs={12}>
              <Button type="button" onClick={handleSubmit} variant="contained" color="primary">
                Check
              </Button>
            </Grid>
          </Grid>
		  
		  
		  <br /><br />
		  
		  {res && 
		  <>
		  <Contacts array={res}      setShow={setShow}/>
		  
		  
		 
		  </>  
		  }
		  
		
         
       
   </>
  );
};



