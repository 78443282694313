import React, { memo } from "react";
import { Input } from "../../../../../utils/input";
import { Button } from "../../../../../utils/button";
import { error_beautify } from "../../../../../utils/functions";
import { useMutation } from "@apollo/client";
import login_mutation_gql from "./login-mutation-gql";
import MButton from "@material-ui/core/Button";
import { Navigate } from "react-router-dom";
import get_device_id from "../../../../../utils/unique-device-id";
import get_model_name from "../../../../../utils/model-name";
import forgotPasswordHandler from "./forgot-password-handler";
export default ({ _handleSubmit, snackBar, loader, setLogin }) => {
  const [admin_login_mutation] = useMutation(login_mutation_gql);

  return (
    <Comp
      admin_login_mutation={admin_login_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};

class Comp extends React.Component {
  state = {
    username: "",
    password: "",
    login: false,
  };
  _handleChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  _handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const { snackBar, loader, admin_login_mutation } = this.props;

    if (username === "") {
      snackBar.show("Enter Username");
      return false;
    }

    if (password === "") {
      snackBar.show("Enter Password");
      return false;
    }
    if (password.length < 6 || password.length > 15) {
      snackBar.show("password length must be between 6 to 15");
      return;
    }
    try {
      loader.show();

      const device_id = get_device_id();
      const model_name = get_model_name();
      const info = {
        device_id: device_id,
        model_name: model_name,
      };
      let {
        data: {
          admin_login: { token },
        },
      } = await admin_login_mutation({
        variables: { username, password, info },
      });

      localStorage.setItem("admin_token", token);
      this.setState({ login: true });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };

  render() {
    const { state } = this;
    return (
      <div style={{ width: "100%" }}>
        <form onSubmit={this._handleSubmit} method="post" noValidate>
          <br />
          <Input
            label={"Username"}
            value={state["username"]}
            maxLength={20}
            onChange={(e) =>
              this._handleChange({ name: "username", value: e.target.value })
            }
          />
          <br /> <br />
          <Input
            type="password"
            label={"Password"}
            value={state["password"]}
            maxLength={15}
            onChange={(e) =>
              this._handleChange({ name: "password", value: e.target.value })
            }
          />
          <br /> <br /> <Button type="submit" title="Login" color="primary" />
          <br /> <br />
          {/* <MButton onClick={() => forgotPasswordHandler()} color="secondary">
            Forgot Password?
          </MButton> */}
        </form>
        {state.login && <Navigate to="/admin" />}
      </div>
    );
  }
}
