import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Sales = ({ data: input, className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { test_subject, total_info } = input;
  let data = [];
  for (let i = 0; i < test_subject.length; i++) {
    data.push({
      name: test_subject[i].subject_name,
      "Average Score": test_subject[i].avg_percentage,
      "Your Score": test_subject[i].my_percentage,
    });
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        // action={
        //   <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
        //     Last 7 days
        //   </Button>
        // }
        title="Relative Comparison of Your Score vs. Average Score (in %)"
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <ResponsiveContainer>
            <BarChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ fontFamily: "Roboto, sans-serif;" }} />
              <Bar dataKey="Average Score" fill={"#8884d8"} />
              <Bar dataKey="Your Score" fill={"#82ca9d"} />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string,
};

export default Sales;
