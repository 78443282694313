import React, { Fragment } from "react";

import login_mutation_gql from "./login-mutation-gql";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { useMutation } from "@apollo/client";

import { theme } from "../../../../../utils/theme";

import LoginForm from "./login-form";

const roundness = 30;

export default () => {
  const [login_mutation] = useMutation(login_mutation_gql);
  const snackBar = React.useContext(SnackBarContext);
  const loader = React.useContext(LoaderContext);
  return (
    <Comp snackBar={snackBar} loader={loader} login_mutation={login_mutation} />
  );
};

class Comp extends React.Component {
  render() {
    const { snackBar, loader } = this.props;
    const props = {
      roundness: roundness,

      theme: theme,
      loader: loader,
      snackBar: snackBar,
    };

    return <LoginForm {...props} />;
  }
}
