import React from "react";
import { faculty_time_table_query_gql } from "../../../gql/query/faculty-time-table-query-gql";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import Divider from "@material-ui/core/Divider";
import Comp from "./comp";
export default () => {
  let navigate = useNavigate();
  
  React.useEffect(() => {
    const intervalId = setInterval(()=> {
      refetch();
    }, 30 * 1000)

    return () => {
      clearInterval(intervalId);

    }
  },[navigate])

  const { loading, error, data, refetch } = useQuery(faculty_time_table_query_gql,{ fetchPolicy: "no-cache" });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { faculty_time_table } = data;

  return (
    <>
      {faculty_time_table.map((n, i) => {
        return (
          <>
            <Typography component="h3">{n.date}</Typography>
            <br />
            <Comp {...n} />
            <br />
            <Divider />
            <br />
            <br />
          </>
        );
      })}
    </>
  );
};
