import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { get_jwt } from "../../../gql/get_jwt_gql"
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import { error_beautify } from '../../../../../utils/functions';
import external_url_handler from "../../../../../utils/external_url_handler";

export default () => {
  const loader = useContext(LoaderContext);
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(get_jwt, {
    variables: { path: "test" },
    fetchPolicy: "no-cache"
  });

  React.useEffect(() => {
    refetch();
    return () => {
    }
  }, [navigate]);

  React.useEffect(() => {
    if (data && data.get_jwttoken && data.get_jwttoken.token) {
      let token = "https://reliablekota.prepseed.com/"
      external_url_handler(token)
      navigate('/student')
    }
    return () => {
    }
  }, [data])

  if (loading) {
    return <span>Loading...</span>
  }

  if (error) {
    if (error_beautify(error) === "Response not successful: Received status code 500") {
      localStorage.removeItem("student_token");
      navigate("/student");
    }
    return <GqlErrorComp error={error} />;
  }

  return <span>Redirecting to Testing Platform</span>;
};
