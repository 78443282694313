import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import SelectComp from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    width: 222,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const Select = ({ array, value, name, label, onChange }) => {
  const classes = useStyles();

  if (value === "") value = "select";

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <SelectComp
        value={value}
        inputProps={{
          name: name,
        }}
        onChange={onChange}
      >
        <MenuItem disabled value="">
          <em>Select</em>
        </MenuItem>
        {array &&
          array.map(({ key, value }, i) => {
            return (
              <MenuItem key={i} value={key}>
                {value}
              </MenuItem>
            );
          })}
      </SelectComp>
    </FormControl>
  );
};
