import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtomComp from './button';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { api_endpoint } from '../../../../../admin/app/srceens/result-app/config';
import { STUDENT_TOKEN_NAME } from "../../../../../../config"

export default function Result(props) {

    let navigate = useNavigate();
    const admin = props.admin;
    const rollno = props.rollno;
    const [result, setResult] = useState([]);
    const [test, setTest] = useState();
    const [arr, setArr] = useState([]);
    const [subjectdata, setSubjectData] = useState([]);
    const [list, setList] = useState([]);

    useEffect(async () => {
        const obj = await axios({
            url: api_endpoint + '/get-test-data',
            method: 'post',
            responseType: 'json',
            data: {
                admin: admin,
                rollno: rollno
            },
            headers: {
                APIKEY: '##reliable_rest_api_token##',
                STUDENTRELIABLETOKEN: localStorage.getItem(STUDENT_TOKEN_NAME)
            }
        });
        console.log(obj.data);
        setSubjectData(obj.data.Subject);
        setResult(obj.data.Result);
        setArr(obj.data.Array);
        setList(obj.data.list);
    }, []);

    const row = result;
    const data = arr;
    console.log(data)

    // console.log(result);

    const handleMaster = async (obj) => {
        try {
            console.log(obj);
            if (!admin && !rollno) {
                navigate(`/student/app/result-portal/result-page`, { state: { id: obj.test_id } });
            }
            if (admin && rollno) {
                navigate('/admin/app/result-app/get-student-result', { state: { id: obj.test_id, rollno: rollno } });
            }
        }
        catch (e) {
            alert("Error: " + e);
        }
    }


    return (
        <>
            <Typography>Scroll Down to View your Tests</Typography>
            <div style={{ overflowX: 'auto' }}>
                <table border="1" align="center" style={{ fontSize: '0.73rem', fontFamily: 'arial', borderCollapse: 'collapse', width: '100%' }}>
                    <thead style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>

                        <tr>
                            <th style={{ fontSize: '0.8rem', border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Test Name</th>
                            <th style={{ fontSize: '0.8rem', border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Test Date</th>
                            {list.map((element, index) => (
                                <th colspan="5" scope="col" style={{ fontSize: '0.8rem', border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{element.name}</th>
                            ))}
                            <th colspan="7" scope="col" style={{ fontSize: '0.8rem', border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>Total</th>
                        </tr>



                        <tr>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}></td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}></td>
                            {list.map((element, index) => (
                                <>
                                    <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Total</b></td>
                                    {/* <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Max</b></td> */}
                                    <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Avg</b></td>
                                    <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>High</b></td>
                                    <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>%age</b></td>
                                    <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Rank</b></td>
                                </>
                            ))}
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Total</b></td>
                            {/* <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Max</b></td> */}
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Avg</b></td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>High</b></td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>%age</b></td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Rank</b></td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Cummulative Rank</b></td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}><b>Cummulative %age</b></td>
                        </tr>

                    </thead>
                    <tbody>
                        {result.map((element, index) => (
                            <tr>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{element.name}</td>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{moment(element.date).format("DD-MM-YYYY")}</td>
                                {subjectdata.map((elements, index) => (
                                    (element.name === elements.test_name)
                                        ? <>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{elements.subject_marks}/{elements.max_marks}</td>
                                            {/* <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{elements.max_marks}</td> */}
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{elements.sub_avg_marks}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{elements.sub_high_marks}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{elements.subjectwise_percentage}%</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{elements.subjectwise_rank}</td>
                                        </>
                                        : <></>
                                ))}
                                {arr.map((row, index) => (
                                    (element.name === row.name)
                                        ? <>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{row.overall_marks}/{row.test_maximum_marks}</td>
                                            {/* <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{row.test_maximum_marks}</td> */}
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{row.avg_marks}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{row.highest_marks}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{row.overall_percentage}%</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{row.overall_rank}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{row.c_rank}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{row.c_per}%</td>

                                        </>
                                        : <></>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <br /><br /><br /><br />
            <Typography>View Full Analysis Here</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ textAlign: 'center' }}>Sr. No.</TableCell>
                        {/* <TableCell style={{ textAlign: 'center' }}>Test Id</TableCell> */}
                        <TableCell style={{ textAlign: 'center' }}>Test Name</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Test Date</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>View Analysis</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {row.map((element, index) => (
                        <TableRow>
                            <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                            {/* <TableCell style={{ textAlign: 'center' }}>{element.id}</TableCell> */}
                            <TableCell style={{ textAlign: 'center' }}>{(element.name)}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>{moment(element.date).format("DD-MM-YYYY")}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <ButtomComp id={element.id} index={index + 1} test_id={element.id} name={element.name} date={element.date} handleMaster={handleMaster} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}