import React, { useContext, useState } from "react";
import axios from 'axios';
import { Container, TextField, Grid, Button } from "@material-ui/core";
import { courseware_upload_url } from "../../../../../config";
import FormLayoutcourseware from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_courseware_mutation_gql } from "../../../gql/mutation/create-courseware-mutation";
import Typography from "@material-ui/core/Typography";
import SelectChildsubjectClassTopic from "../../components/select-childsubject-class-topic";
import SelectCoursewareType from "../../components/select-courseware-type";
export default () => {
  const [create_courseware_mutation] = useMutation(
    create_courseware_mutation_gql
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_courseware_mutation={create_courseware_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_courseware_mutation }) => {
  const [values, setValues] = useState({
    title: "",
    type: "",
    pdf_url: "",
    topic_id: "",
  });
  const handleInputChange = async (e) => {
    const { name } = e.target;
    if(name === "pdf_url")
    {
      const file = e.target.files[0];
      if(!file) return;
      
      if(file.type !== "application/pdf")
      alert("Upload only PDF file")


      const formData = new FormData();
    
      // Update the formData object
      formData.append(
        "myFile",
        file,
        file.name
      );
      try {
        loader.show();
        const res = await axios.post(courseware_upload_url, formData);
        if(res.data.url){
          loader.hide();
          setValues({ ...values, [name]: res.data.url });
        }
        else {
          loader.hide();
          snackBar.show("Some error in file upload")
        }  
      }  
      catch (err) {
        loader.hide();
        snackBar.show(err)
      }
     
    }
    else handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { title, type, topic_id } = values;

      let pdf_url;
      
      if(type === "VIDEO"){
        const { video_url } = values;
        pdf_url = video_url;
      }
      else pdf_url = values.pdf_url ; 

      loader.show();

      let {
        data: {
          create_courseware: { success },
        },
      } = await create_courseware_mutation({
        variables: {
          title,
          type,
          pdf_url,
          topic_id,
        },
      });

      if (success) snackBar.show("courseware created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutcourseware title="Create Courseware">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                name="title"
                value={values.title}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectCoursewareType
                label="Type"
                name="type"
                value={values.type}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              { values.type === "VIDEO" ? <TextField
                label="Video URL"
                fullWidth
                variant="outlined"
                name="video_url"
                onChange={handleInputChange}
              /> :
              <TextField
                variant="outlined"
                name="pdf_url"
                onChange={handleInputChange}
                type="file"
                inputProps= {{ accept : "application/pdf" }}
              /> } 
            </Grid>

            <SelectChildsubjectClassTopic
              onChange={handleInputChange}
              name="topic_id"
            />

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutcourseware>
      </Container>
    </form>
  );
};
