import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SelectSession from "./select-session";
import SelectCourse from ".//select-course";
import SelectPhase from "./select-phase";
import { handleFormChange } from "../../../../utils/functions";
export default ({ onChange }) => {
  const [values, setValues] = useState({
    session_id: "",
    course_id: "",
    phase_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
    // if (e.target.name === "phase_id") {
    onChange(e);
    // }
  };
  return (
    <>
      <Grid item xs={12}>
        <SelectSession
          label="Session"
          name="session_id"
          value={values.session_id}
          onChange={handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectCourse
          label="Course"
          name="course_id"
          value={values.course_id}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectPhase
          course_id={values.course_id}
          session_id={values.session_id}
          label="Phase"
          name="phase_id"
          value={values.phase_id}
          onChange={handleInputChange}
        />
      </Grid>
    </>
  );
};
