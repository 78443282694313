import React from "react";
import { Select } from "../../select";
export const SubQuestionType = ({ onChange }) => {
  const array = [
    { key: "scq", value: "scq" },

    { key: "mcq", value: "mcq" },
    { key: "fib", value: "fib" },
    { key: "tf", value: "tf" },
    { key: "single_digit", value: "single_digit" },
  ];
  return <Select array={array} label="Type" onChange={onChange} />;
};
