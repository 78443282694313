import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange,stream_class_course_list,stream,classs }) => {
 
   let i=0,len=stream_class_course_list.length,course_list=[],tmp_course;
   for(i=0;i<len;i++){
	   if(stream_class_course_list[i].stream===stream && stream_class_course_list[i].classs===classs ){
		   tmp_course=stream_class_course_list[i].course;
	 
		   course_list.push({key:tmp_course,value:tmp_course})
	   }
   }
   
  
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={course_list}
    />
  );
};
