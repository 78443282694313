import axios from 'axios';
import React from 'react';
import { LoaderContext } from '../../../../../context/LoaderContext';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import { Button } from '@material-ui/core';

export default function ButtonComp({ admin_id, state, refresh, loader, roll_no, mobile1, hide, mobile2 }) {

    const handleClick = async () => {
        try {
            loader.show();
            const { data: response } = await axios({
                url: api_url + '/lead-data-calling/transfer-lead',
                method: 'post',
                responseType: 'json',
                data: {
                    id: admin_id,
                    roll: roll_no,
                    mobile1: mobile1,
                    mobile2: mobile2
                },
                ...rest_headers
            })
            if (response.Success === 'ok') {
                hide(true);
                refresh(state);
                alert('Lead Transfered Successfully');
            }
        }
        catch (e) {
            alert(e.message)
        }
        finally {
            loader.hide();
        }
    }

    return (
        <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleClick}>Transfer</Button>
    )
}