import { gql } from "@apollo/client";

export const change_student_batch_mutation_gql = gql`
  mutation change_student_batch(
    $roll_no: String!
    $batch_id: ID!
    $remark: String
  ) {
    change_student_batch(
      roll_no: $roll_no
      batch_id: $batch_id
      remark: $remark
    ) {
      success
    }
  }
`;
