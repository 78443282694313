import React from "react";
import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import TableComp from './table-row';
import { Typography } from "@material-ui/core";
import csvDownload from 'json-to-csv-export';
import { api_endpoint } from "../config";
import { LoaderContext } from "../../../../../../context/LoaderContext";


export default function ViewTest() {

    const loader = useContext(LoaderContext);

    const [list, setList] = useState([]);
    const [result, setResult] = useState([]);
    const [listed, setListed] = useState([]);
    const [array, setArray] = useState([]);
    const [val, setVal] = useState([]);
    const [error, setError] = useState(false);
    const [width, setWidth] = useState();
    const [value, setValue] = useState();
    const [testfile, setTestFile] = useState(false);
    const [urllength, setUrlLength] = useState();
    const [urlarray, setUrlArray] = useState([]);
    const [urlissue, setUrlIssue] = useState([]);
    const [urlerror, setUrlError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState([]);
    const [ques, setQues] = useState();
    const [error_roll, setErrorRoll] = useState(false);
    const [roll_value, setRollValue] = useState([]);


    // const fetch = async () => {
    //     try {
    //         loader.show();
    //         const obj = await axios({
    //             url: api_endpoint + '/view-test',
    //             method: 'post',
    //             responseType: 'json'
    //         })
    //         setResult(obj.data.Result);
    //     }
    //     catch (e) {
    //         alert(e.message);
    //     }
    //     finally {
    //         loader.hide();
    //     }
    // }

    // // console.log(result);

    // // fetch();
    // // console.log(result)
    // useEffect(() => {
    //     fetch();
    // }, [])

    useEffect(async () => {
        try {
            loader.show();
            const test = localStorage.getItem('test');
            const { data: obj } = await axios({
                url: api_endpoint + '/view-test',
                method: 'post',
                responseType: 'json',
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            });
            console.log(obj.Result);
            setResult(obj.Result);
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }, []);

    const handleMasterTestDrop = (obj) => {
        setValue(obj.width);
        setListed(obj.testdata);
        setVal(obj.testissue);
        setQues(obj.quesno);
        if (obj.testissue.length !== 0) {
            setError(true);
        }
        else {
            setError(false);
            setTestFile(true);
        }
    }

    const handleMasterStudentDrop = (obj) => {
        // console.log(obj);
        setWidth(obj.width);
        setList(obj.responsedata)
        setArray(obj.responseissue);
        setRollValue(obj.error);
        if (obj.responseissue.length !== 0) {
            setTestFile(true);
            setError(true);
        }
        if (obj.error.length !== 0) {
            setErrorRoll(true);
            setError(true);
        }
        else {
            setError(false);
        }
    }

    const handleUrlMaster = (obj) => {
        setUrlLength(obj.width);
        setUrlIssue(obj.urlissue);
        setUrlArray(obj.urldata);
        if (obj.urlissue.length !== 0) {
            setUrlError(true);
        }
        else {
            setUrlError(false);
        }
    }

    const handleUrlClick = async (obj) => {
        // loader.show();
        try {
            loader.show();
            console.log(obj);
            if (urllength === 3) {
                const { data: response } = await axios({
                    url: api_endpoint + '/upload-url',
                    method: 'post',
                    responseType: 'json',
                    data: {
                        test: obj.test,
                        value: obj.urlarray
                    },
                    headers: {
                        APIKEY: '##reliable_rest_api_token##',
                        ADMINTOKEN: localStorage.getItem('admin_token')
                    }
                })
                if (response.Success === 'ok') {
                    window.location.reload(false);
                }
                if (response.Success === 'false') {
                    alert("Url Not Uploaded");
                }
            }
            else {
                alert("Please Upload Correct Url File");
                return false;
            }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    const handleTestClick = async (obj) => {

        try {
            loader.show();
            setLoading(true);
            if (value === 8) {
                console.log(obj);
                const { data: response } = await axios({
                    url: api_endpoint + '/upload-test',
                    method: 'post',
                    responseType: 'json',
                    data: {
                        value: obj.listed,
                        test: obj.id
                    },
                    headers: {
                        APIKEY: '##reliable_rest_api_token##',
                        ADMINTOKEN: localStorage.getItem('admin_token')
                    }
                })
                console.log(response);
                if (response.Success === 'ok') {
                    obj.setVariable(true);
                    window.location.reload(false);
                }
                if (response.Success === 'false') {
                    alert("Data Not Uploaded");
                    return false;
                }
            }
            else {
                alert("Please Upload Correct Test Data File");
                return false;
            }
        }
        catch (e) {
            alert(e.message)
        }
        finally {
            loader.hide();
            // setLoading(false)
        }
    }

    const handleClick = async (obj) => {

        try {
            loader.show();
            console.log(obj.test);
            if (width === 8) {
                const { data: data2 } = await axios({
                    url: api_endpoint + '/upload-response',
                    method: "post",
                    responseType: "json",
                    data: {
                        value: obj.list,
                        test: obj.id
                    },
                    headers: {
                        APIKEY: '##reliable_rest_api_token##',
                        ADMINTOKEN: localStorage.getItem('admin_token')
                    }
                })
                console.log(data2);
                if (data2.Success === 'ok') {
                    window.location.reload(false);
                }
                if (data2.Success === 'false') {
                    alert("Data not Uploaded");
                    return false;
                }
            }
            else {
                alert("Please Upload Correct Response File");
                return false;
            }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    const handleTestDownload = async (obj) => {
        var file = [];
        try {
            const { data: response } = await axios({
                url: api_endpoint + '/download-test-csv',
                method: 'post',
                responseType: 'json',
                data: {
                    test: obj.id
                }
            })
            console.log(response);
            for (var i = 0; i < response.Result.length; i++) {
                file.push({ QuestionNo: response.Result[i].question_no, CorrectAnswer: response.Result[i].correct_ans, CorrectMarks: response.Result[i].correct_marks, IncorrectMarks: response.Result[i].incorrect_marks, Subject: response.Result[i].subject, Topic: response.Result[i].topic, PaperCode: response.Result[i].paper_code })
            }
            if (response.Success === 'ok') {

                csvDownload(file, 'test-schema.csv');
                // alert("Test Downloaded");
            }
            if (response.Success === 'false') {
                alert("Test Cannot be Downloaded");
            }
        }
        catch (e) {
            alert(e.message);
        }
    }

    const handleResponseDownload = async (obj) => {
        var file = [];
        try {
            const { data: response } = await axios({
                url: api_endpoint + '/download-response-csv',
                method: 'post',
                responseType: 'json',
                data: {
                    test: obj.id
                }
            })
            console.log(response);
            for (var i = 0; i < response.Result.length; i++) {
                file.push({ RollNo: response.Result[i].roll_no, QuestionNo: response.Result[i].question_no, Response: response.Result[i].response, PaperCode: response.Result[i].paper_code })
            }
            if (response.Success === 'ok') {
                csvDownload(file, 'student-response.csv');
                // alert("Response CSV Downloaded");
            }
            if (response.Success === 'false') {
                alert("Response File not Downloaded");
            }
        }
        catch (e) {
            alert(e.message);
        }
    }

    const handleUrlDownload = async (obj) => {
        var file = [];
        try {
            const { data: response } = await axios({
                url: api_endpoint + '/download-url-csv',
                method: 'post',
                responseType: 'json',
                data: {
                    test: obj.test
                }
            })
            console.log(response);
            for (var i = 0; i < response.Result.length; i++) {
                file.push({ QuestionNo: response.Result[i].question_no, QuestionUrl: response.Result[i].question_url, PaperCode: response.Result[i].paper_code })
            }
            if (response.Success === 'ok') {
                csvDownload(file, 'test-images.csv');
                // alert("Images CSV is Downloaded");
            }
            if (response.Success === 'false') {
                alert("Images CSV is not downloaded");
            }
        }
        catch (e) {
            alert(e.message);
        }
    }


    const handleCalculate = async (obj) => {
        try {
            loader.show();
            console.log(obj);
            if (obj.test.length !== 0) {
                const { data: data3 } = await axios({
                    url: api_endpoint + '/start-calculation',
                    method: "post",
                    responseType: "json",
                    data: {
                        test: obj.id
                    }
                })
                console.log(data3);
                if (data3.Success === 'ok') {
                    console.log("Success");
                    window.location.reload(false);
                }
                if (data3.Success === 'false') {
                    alert("Issue in the Server, the test has not been uploaded to the Server");
                }
            }
            else {
                alert('Test Not Found');
            }
        }
        catch (e) {
            alert('Error: ' + e);
        }
        finally {
            loader.hide();
        }
    }

    const handleDelete = async (obj) => {
        try {
            loader.show();
            console.log(obj);
            const { data: Response } = await axios({
                url: api_endpoint + '/update-test',
                method: 'post',
                responseType: 'json',
                data: {
                    test: obj.id
                }
            })
            // console.log(Response);
            if (Response.Success === 'ok') {
                alert('Test Deleted Successfully');
                window.location.reload(false);
            }
            else {
                alert('Test Aready Deleted');
            }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    // console.log(result)

    return (
        <>
            <h2>Upload CSV Files</h2>
            <table width="100%" style={{ border: '1px solid #ddd', textAlign: 'left', borderCollapse: 'collapse' }}>
                <thead style={{ fontSize: '0.8rem', border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                    <tr>
                        <td width={"7%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Sr. No.</Typography></td>
                        <td width={"10%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Test Name</Typography></td>
                        <td width={"10%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Test Date</Typography></td>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Test Schema</Typography></td>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Upload Pdf</Typography></td>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Student Response</Typography></td>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Test Images</Typography></td>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Start Analysis</Typography></td>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Delete Test</Typography></td>
                        {/* <td style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>Action</td> */}
                        {/* <td style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>Calculation</td> */}
                    </tr>
                </thead>
                <tbody>
                    {result.map((row, index) => (
                        <tr>
                            <td width={"7%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>{index + 1}</td>
                            <td width={"10%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>{row.name}</td>
                            <td width={"10%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>{moment(row.date).format("DD-MM-YYYY")}</td>
                            {/* <TableComp handleCalculate={handleCalculate} handleTestDownload={handleTestDownload} handleUrlDownload={handleUrlDownload} handleResponseDownload={handleResponseDownload} analysis_completed={row.is_analysis_completed} uploadurl={row.is_url_uploaded} urlerror={urlerror} urlarray={urlarray} handleUrlMaster={handleUrlMaster} handleUrlClick={handleUrlClick} uploadtest={row.is_test_uploaded} val={val} value={value} handleTestClick={handleTestClick} error={error} testfile={testfile} handleMasterStudentDrop={handleMasterStudentDrop} handleMasterTestDrop={handleMasterTestDrop} listed={listed} index={index + 1} width={width} uploads={row.is_response_uploaded} test={row.name} list={list} handleClick={handleClick} /> */}
                            <TableComp ques={ques} url={row.url} pdfupload={row.is_pdf_uploaded} urlarray={urlarray} handleUrlClick={handleUrlClick} handleUrlDownload={handleUrlDownload} handleUrlMaster={handleUrlMaster} id={row.id} handleDelete={handleDelete} uploadurl={row.is_url_uploaded} handleCalculate={handleCalculate} handleTestDownload={handleTestDownload} handleResponseDownload={handleResponseDownload} analysis_completed={row.is_analysis_completed} uploadtest={row.is_test_uploaded} val={val} value={value} handleTestClick={handleTestClick} error={error} testfile={testfile} handleMasterStudentDrop={handleMasterStudentDrop} handleMasterTestDrop={handleMasterTestDrop} listed={listed} index={index + 1} width={width} uploads={row.is_response_uploaded} test={row.name} list={list} handleClick={handleClick} />
                            {/* <td style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                <Button onClick={handleCalculate} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Calculate</Button>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            <br /><br />
            {
                (testfile)
                    ? (!error)
                        ? <></>
                        : <>
                            <h2>Issues In Student Response CSV File</h2>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }}>Sr. No.</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>Row</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>Column</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {array.map((element, index) => (
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                            <TableCell style={{ color: 'red', textAlign: 'center' }}>Error</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{element.row}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{element.column}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    : <></>
            }<br /><br />
            {error_roll && <>

                <h2>Duplicacy of Student's Answer</h2>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: 'center' }}>Sr. No.</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>Roll No.</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roll_value.map((element, index) => (
                            <TableRow>
                                <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                <TableCell style={{ color: 'red', textAlign: 'center' }}>Error</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>{element.roll_no}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </>}
            {
                (testfile)
                    ? <></>
                    : (!error)
                        ? <></>
                        : <>
                            {val.length !== 0 && <>
                                <h2>List of Issues in Test Info CSV File</h2>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center' }}>Sr. No.</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Row</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Column</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {val.map((element, index) => (
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                                <TableCell style={{ color: 'red', textAlign: 'center' }}>Error</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>{element.row}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>{element.column}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>}
                        </>
            }
            <br /><br />
            {/* {
                (!urlerror)
                    ? <></>
                    : <>
                        <h2>List of Issues in Question Url CSV File</h2>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center' }}>Sr. No.</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Row</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Column</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {urlissue.map((element, index) => (
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                        <TableCell style={{ color: 'red', textAlign: 'center' }}>Error</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{element.row}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{element.column}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
            } */}
        </>
    );
}


//<TableComp handleCalculate={handleCalculate} handleTestDownload={handleTestDownload} handleUrlDownload={handleUrlDownload} handleResponseDownload={handleResponseDownload} analysis_completed={row.is_analysis_completed} uploadurl={row.is_url_uploaded} urlerror={urlerror} urlarray={urlarray} handleUrlMaster={handleUrlMaster} handleUrlClick={handleUrlClick} uploadtest={row.is_test_uploaded} val={val} value={value} handleTestClick={handleTestClick} error={error} testfile={testfile} handleMasterStudentDrop={handleMasterStudentDrop} handleMasterTestDrop={handleMasterTestDrop} listed={listed} index={index + 1} width={width} uploads={row.is_response_uploaded} test={row.name} list={list} handleClick={handleClick} />