import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import JoinMeetingButton from "./join-meeting-button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default ({
  r,

  is_home_component,
}) => {
  const classes = useStyles();
  const props = {
    classes,
  };
  return (
    <>
      {r &&
        r.length > 0 &&
        r.map((t, i) => {
          if (is_home_component && i > 2) return <></>;
          return <JoinMeetingButton r={t} key={i} />;
        })}
    </>
  );
};
