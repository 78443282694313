import React,{useState} from 'react'
import { CSVReader } from 'react-papaparse'
import {validate_roll_no} from '../utils.js'
import './table.css' 
import { Container, TextField, Grid, Button } from "@material-ui/core";
import SubmitButton from './submit' 
export default({dynamic})=>{
	const [array,setArray]=useState([]); 
	const [name,setName]=useState('');
	const [sms,setSMS]=useState('');
	
	const clear=()=>{
		setArray([])
		setName('')
		setSMS('')
	}
	const handleOnDrop=(data)=>{
	try{
	const arr=convert_to_json({data,dynamic})
	setArray(arr) 
	}
	catch(e){
		alert(e.message)
	}
		} 
return(
<Container maxWidth="sm">
<div style={{fontFamily:'Arial'}}>
 <TextField
 fullWidth

                label="Enter name and description"
                variant="outlined"
                name="name"
                value={name}
				
               onChange={(e)=>setName(e.target.value)}
              /><br/><br/>{!dynamic && 
<TextField

fullWidth
multiline
maxRows={10}
                label="Paste sms language"
                variant="outlined"
                name="sms"
                value={sms}
               onChange={(e)=>setSMS(e.target.value)}
              />
			  }
			  <br/><br/>
			  <div style={{maxWidth:400,fontSize:12}}>
	<CSVReader
        onDrop={handleOnDrop}
        onError={(e)=>alert(e)}
        >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
	  </div>
	  <br/>
	  
	    
	  {
		  array.length>0 && (dynamic || sms.length>100) && name.length>3 && (<>
	  <table id="table" border="1" style={{borderCollapse:'collapse'}}><tr><th>Roll No.</th>
		  {dynamic && 
	  <th>Message</th>
		  }
	  </tr>
	  
	  {
		 array.map((n,i)=>{
		  return(
		 <tr><td>{n.roll_no}</td>{
			 
			 dynamic 
			 &&
			 
			 <td>{n.message}</td>
			 }</tr>
		  )
	  })
	  }
	  
	  </table> 
	   <br/>
<SubmitButton array={array} name={name} sms={sms} clear={clear}/>
	  </>)

			 
	  }
	 

</div>
</Container>
)
}

const convert_to_json=({data,dynamic})=>{
	try{
	let i=0,tmp,len=data.length,tmp_res,array=[];
	for(i=0;i<len;i++){
		tmp=data[i].data;
	 
		if(i==(len))
		continue;
		if(i==0){
			if(tmp[0]!=='rollno')
				throw Error('first column of first row must be "rollno"');
			if(dynamic)
			{
				if(tmp[1]!=='message')
				throw Error('second column of first row must be "message"');
			}
		continue;
		}
		
		tmp_res=validate_roll_no(tmp[0]);
		if(!tmp_res.status) 
			throw Error('error in Roll No. column of row-'+(i+1)+'--'+tmp_res.error);
		
		let obj={roll_no:tmp[0]}
		if(dynamic)
			obj={...obj,...{message:tmp[1]}}
		array.push(obj);
	}
	return array;
	}
	catch(e){
		throw e
	}
}