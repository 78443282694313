import React, { useState, useContext } from 'react'
import { LoaderContext } from "../../../../../../context/LoaderContext";
import styles from '../../reusable-calling-code-project/table.module.css';
import { Grid, Paper, InputLabel, Select, MenuItem, Container, TextField, Button } from '@material-ui/core';
import axios from 'axios';
import FormLayoutAdmin from "../../../../../../utils/form-layout-admin";

const CourseCodeMIS = () => {

    const loader = useContext(LoaderContext);

    const [show, setShow] = useState(false);
    const [res, setRes] = useState([]);
    const [arr, setArr] = useState([]);
    const [leads, setLeads] = useState([])
    const [code, setCode] = useState('');

    const handleClick = async () => {

        try {
            loader.show();

            const { data: obj } = await axios({
                url: 'https://api-dot-reformexam.el.r.appspot.com/mis/course-code-data',
                method: 'post',
                responseType: 'json',
                data: {
                    code: code,
                }
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setRes(obj.res);
                setArr(obj.arr)
                setLeads(obj.leads);
                setShow(true);
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }

    }

    return (

        <>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Container>
                        <FormLayoutAdmin title={"Course Code Data"}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="tel"
                                        label="Course-Code / Mobile No."
                                        value={code}
                                        placeholder="Course Code / Mobile No."
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullwidth onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
                                </Grid>
                            </Grid>
                        </FormLayoutAdmin>
                    </Container>
                </Grid>

                {show && <><Grid item xs={12}>
                    <Container>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={6}>RNET Data</th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Course Code</th>
                                        <th>Form No.</th>
                                        <th>Name</th>
                                        <th>Class</th>
                                        <th>Test Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {res.map((ele, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.course}</td>
                                                <td>{ele.fno}</td>
                                                <td>{ele.name}</td>
                                                <td>{ele.SCLASS}</td>
                                                <td>{ele.testdate}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Container>
                </Grid>

                    <Grid item xs={12}>
                        <Container>
                            <Paper component={Paper} style={{ padding: '16px' }}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th colSpan={5}>Admission Data</th>
                                        </tr>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Course Code</th>
                                            <th>Form No.</th>
                                            <th>Name</th>
                                            <th>Class</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arr.map((ele, i) => (
                                            <>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{ele.course}</td>
                                                    <td>{ele.fno}</td>
                                                    <td>{ele.name}</td>
                                                    <td>{ele.classs}</td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </Paper>
                        </Container>
                    </Grid>

                    <Container>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={5}>Online leads / Fee Not Paid Data</th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Form No.</th>
                                        <th>Course Code</th>
                                        <th>Name</th>
                                        <th>Class</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leads.map((ele, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.fno}</td>
                                                <td>{ele.course}</td>
                                                <td>{ele.name}</td>
                                                <td>{ele.SCLASS}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Container>

                </>}

            </Grid>

        </>
    )
}

export default CourseCodeMIS
