import React from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Button } from '../../../button'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SendIcon from '@material-ui/icons/Send';
import { Paper } from '../../../../utils/paper'
import Grid from '@material-ui/core/Grid';
export default withWidth()(({ preview, width, handlePrev, handleNext, prevDisabled, nextDisabled, onSubmit }) => {
    if (isWidthUp('md', width))
        return (

            <Paper style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                alignContent: 'center',
                maxHeight: '10vh',
                height: '10vh',
                overflowY: 'auto',
                paddingLeft: 10,
                paddingRight: 10,
                
				backgroundColor:'#e7e7e7'
            }} elevation={0}>
                <Grid container  >
                    <Grid item xs={2}>
                        <Button
                            fullWidth={false}
                            variant="outlined"
                            color="primary"
                            onClick={handlePrev}
                            //disabled={prevDisabled}
                            title="PREV"
                            startIcon={<ChevronLeftIcon />}
                        />
                    </Grid>

                    <Grid item xs={2}>    <Button
                        variant="outlined"
                        fullWidth={false}
                        onClick={handleNext}
                       // disabled={nextDisabled}
                        title="NEXT"
                        color="primary"
                        endIcon={<ChevronRightIcon />}
                    />
                    </Grid><Grid item xs={8} >
                        <div style={{ float: 'right' }}>
                            { !preview && <Button
                                mode="contained"
                                fullWidth={false}
                                onClick={onSubmit}
                                color="secondary"
                                title="SUBMIT"
                                endIcon={<SendIcon />}
                            /> }
                        </div>
                    </Grid>
                </Grid>


            </Paper>

        )


    return (
        <div style={{ marginTop: 10, padding: 10,bottom:0,width:'100%' }}>
            <Grid container  >
                <Grid item xs={6}>
                    <Button
                        fullWidth={false}
                        variant="outlined"
                        color="primary"
                        onClick={handlePrev}
                        disabled={prevDisabled}
                        title="PREV"
                        startIcon={<ChevronLeftIcon />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="outlined"
                            fullWidth={false}
                            onClick={handleNext}
                            disabled={nextDisabled}
                            title="NEXT"
                            color="primary"
                            endIcon={<ChevronRightIcon />}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>     <Button
                    mode="contained"
                    fullWidth={true}
                    onClick={() => onSubmit(false)}
                    color="secondary"
                    title="SUBMIT"
                    endIcon={<SendIcon />}
                />

                </Grid>


            </Grid>
        </div>
    )
})