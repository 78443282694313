import { gql } from "@apollo/client";

export const create_session_mutation_gql = gql`
  mutation create_session(
    $name: String!
    $start_date: String!
    $end_date: String!
  ) {
    create_session(name: $name, start_date: $start_date, end_date: $end_date) {
      success
    }
  }
`;
