import React from "react";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutstudent from "../../../../../utils/form-layout-admin";
import Comp from "./comp";
import SelectSessionBatch from "../../components/select-batch-sessionwise";
import SelectAllChildSubject from "../../components/select-childsubject-all";

import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";

export default () => {
  const [values, setValues] = React.useState({
    batch_id: '',
    childsubject_id: '',
    date_from: '',
    date_to: ''
  });

  const handleInputChange = (e) => {
    e.preventDefault();
    handleFormChange({ e, values, setValues });
  };
  
  return (
    // <>
    //   {view_student.map((n, i) => {
    //     return (
          <>
          <form>
      <Container>
        <FormLayoutstudent title="View Attendence">
          <Grid spacing={3} container>
            <Grid item xs={12}>
            <SelectSessionBatch
              label="Select Batch"
              onChange={handleInputChange}
              name="batch_id"
              value={values.batch_id}
            /></Grid>
            { values.batch_id ? <Grid item xs={12}>
            <SelectAllChildSubject
              label="Select ChildSubject"
              onChange={handleInputChange}
              name="childsubject_id"
              value={values.childsubject_id}
            /></Grid> : null }
            { values.childsubject_id ? <Grid item xs={12}>
            <TextField
                type="date"
                variant="outlined"
                name="date_from"
                label="Date From"
                value={values.date_from}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              /></Grid> : null }
              { values.date_from ? <Grid item xs={12}>
            <TextField
                type="date"
                variant="outlined"
                name="date_to"
                label="Date To"
                value={values.date_to}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              /></Grid> : null }
            </Grid>
        </FormLayoutstudent>
      </Container>
    </form> <br />
    {values.date_to ? <Comp values={values} /> : null}
          </>
        );
      }
//       )}
//      </>
//   );
// };
