import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SelectBatch from "./select-batch";
import { handleFormChange } from "../../../../utils/functions";
import SelectChildsubject from "./select-childsubject-all";
import SelectTopic from "./select-topic";
import SelectClass from "./select-class";
export default ({ onChange, name }) => {
  const [values, setValues] = useState({
    childsubject_id: "",
    class_id: "",
    [name]: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
    if (e.target.name === name) {
      onChange(e);
    }
  };

  return (
    <>
      <Grid item xs={12}>  
      <SelectChildsubject 
        label="Child Subject"
        name="childsubject_id"
        value={values.childsubject_id} 
        onChange={handleInputChange} 
        />
        </Grid>
      <Grid item xs={12}>
        <SelectClass
          label="Class"
          name="class_id"
          value={values.class_id}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectTopic
          childsubject_id={values.childsubject_id}
          class_id={values.class_id}
          label="Topic"
          name={name}
          value={values[name]}
          onChange={handleInputChange}
        />
      </Grid>
    </>
  );
};
