import * as React from 'react';
// import Link from '@mui/material/Link';
import { Typography } from '@material-ui/core';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            maxWidth: theme.spacing(48),
            height: theme.spacing(16),
            padding: theme.spacing(4),

        },
    },

}));


function preventDefault(event) {
    event.preventDefault();
}

export default function Deposits(props) {

    const classes = useStyles();

    return (
        <>
            <Card style={{ backgroundColor: 'wheat', padding: 20 }}>

                <CardContent style={{ padding: 0 }}>

                    <Typography type="subheading">
                        <b>Roll No.:</b>  {props.roll}
                    </Typography>
                    <br />
                    <Typography type="subheading">
                        <b>Test Name:</b>  {props.test.name}
                    </Typography>
                    <br />

                    <Typography type="subtitle">
                        <b> Test Date:</b>  {moment(props.test.date).format('DD-MMM,YYYY')}
                    </Typography>
                    <br />
                    <Typography type="subtitle">
                        <b>Maximum Marks:</b>  {props.test.test_maximum_marks}
                    </Typography>
                </CardContent>


            </Card>
            {/* <Typography component="p" variant="h6" style={{ fontSize: '1.1rem' }} gutterBottom>
                <b>Roll No.</b> : {props.roll} <br /><br />
                <b>Test Name</b> : {props.test.name}  <br /><br />
                <b>Test Date</b> : {moment(props.test.date).format('DD-MMM,YYYY')}  <br /><br />
                <b>Maximum Marks</b> : {props.test.test_maximum_marks} <br /><br />
            </Typography> */}
        </>
    );
}