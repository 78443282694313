import React, { memo } from "react";
import { Input } from "../../../../../utils/input";
import { Button } from "../../../../../utils/button";
import { error_beautify } from "../../../../../utils/functions";
 import {api_url,rest_headers} from '../../../app/srceens/digital-classroom/config'
import MButton from "@material-ui/core/Button";
import { Navigate } from "react-router-dom";
import get_device_id from "../../../../../utils/unique-device-id";
import get_model_name from "../../../../../utils/model-name";
import external_url_handler from "../../../../../utils/external_url_handler";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
const axios = require('axios');
export default ({ _handleSubmit, snackBar, loader, setLogin,force }) => {
  
  return (
    <Comp
 
      snackBar={snackBar}
      loader={loader}
	  force={force}
    />
  );
};

class Comp extends React.Component {
  state = {
    roll: "",
	list:[]	,
	showList:false,
	mobile:'',
	showOTP:false,
	otp:'',
	showPassword:false,
	password1:'',
	password2:'',
	
  };
  _handleChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  _handleSubmit=async(e)=>{
	  e.preventDefault()
  const {loader,snackBar,force}=this.props
  const {list,roll,mobile,otp,showOTP,showPassword,password1,password2}=this.state
	  try {
		  loader.show();
if(list.length<1){
if(!roll || roll.length!=8){
	snackBar.show('Enter valid roll number');
	return false
}
   const  {data}=await axios({
        url: api_url+'/forgot-password',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##'	 
},data:{
			roll:this.state.roll 
		},
      }) 
	    
	 if(data.success=='false'){
		 snackBar.show(data.message);
		 return
	 }
	 this.setState({list:data.list,showList:true});
	 
}
else if(list.length>0 && !((showOTP || showPassword))){
	if(!mobile){
	snackBar.show('Please choose mobile');
	return false
}


 const  {data}=await axios({
        url: api_url+'/forgot-password',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##'	 
},data:{
			roll:this.state.roll,
			mobile:this.state.mobile
		},
      }) 
	    
	 if(data.success=='false'){
		 snackBar.show(data.message);
		 return
	 }
	 else{
	 snackBar.show(data.message);
	 this.setState({showOTP:true});
	  return
	 }



}
 
else if(showOTP  && !showPassword){
	if(!otp){
	snackBar.show('Please enter otp');
	return false
}
if(otp.length!=4){
	snackBar.show('otp is not correct');
	return false
}

 const  {data}=await axios({
        url: api_url+'/forgot-password',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##'	 
},data:{
			roll:this.state.roll,
			mobile:this.state.mobile,
			otp:this.state.otp,
			force:force
		},
      }) 
	    
	 if(data.success=='false'){
		 snackBar.show(data.message);
		 return
	 }
	 else{
	 
	 if(!force){
		this.setState({showOTP:false,showPassword:true});
	 }
	 else{
		 snackBar.show(data.message);
		setTimeout(()=>{
			
			window.history.back()
		
		},2000)
		 this.setState({showOTP:false,list:[],roll:'',password1:'',
	 password2:'',showList:false,showPassword:false,});
	 }
	 return
	 }



}
 
else if(showPassword){
	if(!password1){
	snackBar.show('Please enter new password');
	return false
}
if(password1.length<6){
	snackBar.show('please provide at least 6 characters for Password');
	return false
}
if(!password2){
	snackBar.show('Please confirm new password');
	return false
}
if(password1!=password2){
	snackBar.show('passwords are not matching');
	return false
}

 const  {data}=await axios({
        url: api_url+'/forgot-password',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##'	 
},data:{
			roll:this.state.roll,
			mobile:this.state.mobile,
			otp:this.state.otp,
			password1:this.state.password1,
			password2:this.state.password2
			
		},
      }) 
	    
	 if(data.success=='false'){
		 snackBar.show(data.message);
		 return
	 }
	 else{
	 snackBar.show(data.message);
	 setTimeout(()=>{
			
			window.history.back()
		
		},2000)
	 this.setState({showOTP:false,list:[],roll:'',password1:'',
	 password2:'',showList:false,showPassword:false,});
	  return
	 }



}

      
   
    } catch (e) {
		 
       snackBar.show(e.message);
    } finally {
      loader.hide();
    }
  
    
  }

  render() {
    const { state,props } = this;
    return (
      <div style={{ width: "100%" }}>
        <form onSubmit={this._handleSubmit} method="post" noValidate>
            <br /><center><Typography component="h1" variant="h5">
		  {props.force?'Sign Out of All Devices':'Generate Password'}
          </Typography></center>
		  <br />
		  <TextField fullWidth  type="tel" name="roll"   label="Roll No" variant="outlined" value={state["roll"]} disabled={state.list.length>0}
			InputProps={{ maxLength: 8}}
			onChange={(e) =>
              this._handleChange({ name: "roll", value: e.target.value })
            }
		  />
           
		  
		  {this.state.showList && <>   
		  <FormControl component="fieldset" style={{marginTop:20}}>
      <FormLabel component="legend">Choose Mobile for OTP</FormLabel>
      <RadioGroup aria-label="mobile" name="mobile" value={state['mobile']} onChange={(e)=>{
		  this._handleChange({name:'mobile',value:e.target.value})
	  }}>
        
		{this.state.list.map((n,i)=>{
			return(
			<FormControlLabel value={n} 	disabled={state.showOTP ||state.showPassword} control={<Radio />} label={mask(n)} />
			)
		})
		}
        
        
      </RadioGroup>
    </FormControl>
	</>
		  }
		  
		  {this.state.showOTP && !this.state.showPassword && <>
		   <TextField fullWidth  type="tel" name="otp"   label="OTP" variant="outlined" value={state["otp"]} 
			InputProps={{ maxLength: 4}}
			onChange={(e) =>
              this._handleChange({ name: "otp", value: e.target.value })
            }
		  />
		  
		  </>   }
		   {!props.force && this.state.showPassword && <>
		   <TextField fullWidth  type="password" name="password1"   label="New Password" variant="outlined" value={state["password1"]} 
			InputProps={{ maxLength: 30}}
			onChange={(e) =>
              this._handleChange({ name: "password1", value: e.target.value })
            }
		  />
		  <br/> <br/>
		  <TextField fullWidth  type="password" name="password2"   label="Confirm New Password" variant="outlined" value={state["password2"]} 
			InputProps={{ maxLength: 30}}
			onChange={(e) =>
              this._handleChange({ name: "password2", value: e.target.value })
            }
		  />
		  
		  </>   }
          
          <br /> <br /> <Button type="submit" title="Submit" color="primary" />
          
        </form>
        {state.login && <Navigate to="/student" />}
      </div>
    );
  }
}

const mask=(n)=>{
 
	 
	 return `******${n[6]}${n[7]}${n[8]}${n[9]}`
}