import { gql } from "@apollo/client";

export const create_subject_mutation_gql = gql`
  mutation create_subject(
    $name: String!
    $alias: String!
    $description: String!
  ) {
    create_subject(name: $name, alias: $alias, description: $description) {
      success
    }
  }
`;
