import React, { useContext, useState } from "react";
import axios from 'axios';
import { Container, TextField, Grid, Button } from "@material-ui/core";
import { courseware_upload_url } from "../../../../../config";
import FormLayoutcourseware from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_batch_courseware_mutation_gql } from "../../../gql/mutation/create-batch-courseware-mutation";
import Typography from "@material-ui/core/Typography";
import SelectChildsubjectClassTopic from "../../components/select-childsubject-class-topic";
import SelectCoursewareType from "../../components/select-courseware-type";
import SelectTopicCourseware from "../../components/select-topic-courseware";
import SelectSessionBatch from "../../components/select-multiple-batch-sessionwise";

export default () => {
  const [create_batch_courseware_mutation] = useMutation(
    create_batch_courseware_mutation_gql
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
    create_batch_courseware_mutation={create_batch_courseware_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_batch_courseware_mutation }) => {
  const [values, setValues] = useState({
    courseware_id: "",
    batch_id:[],
  });
  const handleInputChange = (e) => {
     handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { courseware_id, batch_id } = values;
      console.log(batch_id)
      loader.show();

      let {
        data: {
            create_batch_courseware : { success },
        },
      } = await create_batch_courseware_mutation({
        variables: {
            courseware_id,
            batch_id
        },
      });

      if (success) snackBar.show("batch courseware created successfully");
      //resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutcourseware title="Create Batch Courseware">
          <Grid spacing={3} container>

            <SelectChildsubjectClassTopic
              onChange={handleInputChange}
              name="topic_id"
            />
            <Grid item xs={12}>
              <SelectCoursewareType
                label="Type"
                name="type"
                value={values.type}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectTopicCourseware
                topic_id={values.topic_id}
                type={values.type}
                label="CourseWare"
                name="courseware_id"
                value={values.courseware}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
            <SelectSessionBatch
              label="Select Batch"
              onChange={handleInputChange}
              name="batch_id"
              value={values.batch_id}
            />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutcourseware>
      </Container>
    </form>
  );
};
