import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        marginTop: 15,
        width: '100%'
    },
    table: {
        width: '100%'
    },
    title: {
        // margin: 10
        padding: '13px'
    }
});


function Title(props) {
    const classes = useStyles();
    return (
        <Typography className={classes.title} id="tableTitle" component="div" variant="h5">
            <b>{props.children}</b>
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};

export default Title;