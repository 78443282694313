import {api_url,rest_headers} from '../config'
import {handle_error} from '../../call-center/handle_error'
import {useState} from 'react'
import { Container, TextField, Grid, Button } from "@material-ui/core";
const axios = require('axios');
export default({array,name,sms,clear})=>{ 
 const [loading,setLoading]=useState(false);
	const handleSubmit=async()=>{
		try{
			setLoading(true)
			const  {data}=await axios({
        url: api_url+'/send-sms',
        method: 'post',
		responseType: 'json',
		data:{
			name:name,
			sms:sms,
			array:array
		},
         headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
			}
      }) 
	  alert(data.message);
	  clear()
		}
		catch(e){
			alert(e.message)
		}
		finally{
			setLoading(false)
		}
	}
	return(
	 <Button disabled={loading?true:false}type="submit" onClick={handleSubmit} variant="contained" color="primary">
                SEND
      </Button>
	)
}