let isElectron = require("is-electron");

export default () => {
  try {
    const url = "https://www.reliablekota.com/liveclasses/forgot-password.php";
    if (!isElectron()) {
      window.open(url);
      return false;
    } else {
      var shell = require("electron").shell;
      shell.openExternal(url);
    }
  } catch (e) {
    alert(e);
  }
};
