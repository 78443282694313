import React from 'react';
import readXlsxFile from 'read-excel-file'
import moment from 'moment'
import axios from 'axios';
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { useState, useContext } from 'react';


const FBLeadsUpload = () => {

    const loader = useContext(LoaderContext);

    const [rows, setRows] = useState([]);

    const showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            console.log(text)
        };

        readXlsxFile(e.target.files[0], { dateFormat: 'yyyy-mm-dd' }).then((row) => {
            console.log(row);
            setRows(row);
            const a = row[0];
            if (a[0] != 'id')
                alert('index 0 should be "id"');
            if (a[1] != 'platform')
                alert('index 1 should be "platform"');
            if (a[2] != 'select_class')
                alert('index 2 should be "select_class"');
            if (a[3] != 'full_name')
                alert('index 3 should be "full_name"');
            if (a[4] != 'email')
                alert('index 4 should be "email"');
            if (a[5] != 'phone_number')
                alert('index 5 should be "phone_number"');
            if (a[6] != 'city')
                alert('index 6 should be "city"');
            if (a[7] != 'event')
                alert('index 7 should be "event"');
            else {
                // setRows(row);
                console.log(row.length);

                let i, j, array = [], b;

                for (i = 0; i < row.length; i++) {

                    if (i === 0) {
                        continue;
                    }

                    b = row[i];

                    array.push({ id: parseInt(b[0]), source: b[1], class: b[2], name: b[3], email: b[4], mobile: b[5], city: b[6], event: b[7] });

                }

                console.log(array);

                setRows(array);

            }
        })
    }

    const handleClick = async () => {
        try {
            loader.show();

            const { data: response } = await axios({
                url: 'https://api-dot-reformexam.el.r.appspot.com/c-zentrix/fb-leads-upload',
                method: 'post',
                responseType: 'json',
                data: {
                    rows: rows
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            console.log(response);

            if (response.success === 'ok') {
                alert("Leads Uploaded Successfully");
            }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }


    return (
        <>

            <input type="file" id="input" onChange={(e) => showFile(e)} />

            <button onClick={handleClick}>Submit</button>

        </>
    )
}

export default FBLeadsUpload
