import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, TextField, Grid, Button } from "@material-ui/core";
  
const Comp = ({ id }) => {
 
  let navigate = useNavigate();
  
  

  
  return (
   
	      <Button type="submit" variant="outlined" color="secondary"
		  onClick={()=>{
				   navigate("../view-interaction", { 
				 state: { id:id }
			});
					}} 
		  >
             View
              </Button>
       
		  
		   
  );
};

export default Comp



