import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import styles from '../reusable-calling-code-project/table.module.css';
import Callback from '../reusable-calling-code-project/reuable-calling-code/callback'
import Conversation from '../reusable-calling-code-project/reuable-calling-code/conversation'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CallIcon from '@material-ui/icons/Call';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TransferComp from './tranfer-comp';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import { Button, Card, Collapse, Grid, ServerStyleSheets, Typography, useMediaQuery } from '@material-ui/core';
import _ from 'lodash'
import axios from 'axios';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import BulkTransferComp from './bulk-transfer-comp';
import moment from 'moment';
import PaperComp from './card-comp';
import TemplateComp from './template-comp';
import ModalComp from './modal-comp';
import LeadOwnerComp from './lead-owner-comp';
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        background: '#fce4ec'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },

}));



export default function RecipeReviewCard({ handleRSort, handleClass, handleCallbackFilter, handleCategoryFilter, filter_name, handleTestDate, handleFees, handleAdmin, adminf, handleSource, handleUtmSource, handleDateSort, datesorted, lead_total, touched, classf, utmf, untouched, handleF, array, cityf, sourcef, tested, sorted, handleSort, snackBar, loader, state, refresh, manager, project }) {

    const isDesktop = useMediaQuery('(min-width: 960px)');

    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [checkboxlist, setCheckBoxList] = useState([]);
    const [expanded, setExpanded] = useState(false);
    let avatar = {};

    if (array.length < 1)
        return (
            <></>
        )

    let count = array.length;

    const handleClick = () => {
        handleSort();
        if (sorted == null) {
            document.getElementById('toggle_sort').style.transform = `rotate(0.5turn)`
        }
        if (sorted == 'asc') {
            document.getElementById('toggle_sort').style.transform = `rotate(0)`
        }
    }



    const getColor = (fee) => {
        if (fee == null) {
            fee = 'N'
            return '#FFF';
        }
        if (fee == 'Y') {
            return '#E9FCE9'
        }
        else {
            return '#FFF';
        }
    }

    const getDarkBorder = (list, i) => {
        if (i == list.length - 1) {
            return '2px solid #353839';
        }
        else {
            return '0.7px solid #eee'
        }
    }

    const handleCheckbox = ({ orderid, value }) => {

        const state = checkboxlist;
        const indexToRemove = checkboxlist.indexOf(orderid);
        let result;
        if (!value) {
            result = state.filter((_, i) => i !== indexToRemove);
            setCheckBoxList(result)
        }
        else
            setCheckBoxList([...checkboxlist, orderid])

    };

    const handleDateClick = () => {
        handleDateSort();
        if (datesorted == null) {
            document.getElementById('toggle_date_sort').style.transform = `rotate(0.5turn)`
        }
        if (datesorted == 'asc') {
            document.getElementById('toggle_date_sort').style.transform = `rotate(0)`
        }
    }

    const Unchecked = () => {
        var x = window.document.getElementsByClassName("Mycheckbox");
        let i;
        for (i = 0; i < x.length; i++) {
            x[i].checked = false;
        }
    }





    return (
        <>


            {
                (manager)
                    ? <>

                        {/* <Button onClick={Unchecked}>Unchecked</Button> */}

                        <BulkTransferComp Unchecked={Unchecked} setCheckBoxList={setCheckBoxList} checkboxlist={checkboxlist} loader={loader} refresh={refresh} state={state} />

                        <TableContainer component={Paper}>
                            <div style={{ overflowX: 'auto', maxHeight: '650px' }}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th colSpan={24} className={styles.typogrpahy_header}>Calling Panel<br />
                                                ({filter_name})
                                            </th>
                                        </tr>

                                        <tr>
                                            <th rowspan="2" width="50px">Name

                                            </th>
                                            <th rowspan="2" width="50px">Admission

                                            </th>
                                            <th rowspan="2" width="50">Count
                                                <br /><br /> <ArrowDropDownIcon style={{ height: '40px', width: '40px' }} id='toggle_sort' onClick={handleClick} />
                                            </th>
                                            <th rowspan="2" width="50">Date
                                                <br /><br /> <ArrowDropDownIcon style={{ height: '40px', width: '40px' }} id='toggle_date_sort' onClick={handleDateClick} />
                                            </th>
                                            <th rowspan="2" width="50px">
                                                Admin Name
                                            </th>
                                            <th colspan={19}>Lead
                                                <br /><br />
                                                <b>Total Count: </b>{count}
                                            </th>
                                        </tr>
                                        <tr>
                                            {/* <th>
                                                Lead Owner
                                                <br />
                                                <ModalComp handleAdmin={handleAdmin} component={'admin'} adminf={adminf} />
                                            </th> */}
                                            <th>Name</th>
                                            <th>FNo.

                                            </th>
                                            <th>Scholarship</th>
                                            <th>Class

                                            </th>
                                            <th>Course</th>
                                            <th>Fees
                                                {/* <br />
                                                <ModalComp title={'Filter'} handleFees={handleFees} component={'fees'} /> */}

                                            </th>
                                            <th>Form Date</th>
                                            <th>Reg. Date</th>
                                            <th>Test Date</th>
                                            <th>Dept. Date</th>
                                            <th>
                                                Source
                                                <br />
                                                <ModalComp title={'Filter'} handleSource={handleSource} component={'source'} sourcef={sourcef} />
                                            </th>
                                            <th>
                                                Utm Source
                                                <br />
                                                <ModalComp title={'Filter'} handleUtmSource={handleUtmSource} component={'utm_source'} utmf={utmf} />
                                            </th>
                                            <th>
                                                Remark
                                                <br />
                                                <ModalComp handleCategoryFilter={handleCategoryFilter} component={'category'} title={'Filter'} />
                                            </th>
                                            <th>
                                                Callback
                                                <br />
                                                <ModalComp handleCallbackFilter={handleCallbackFilter} component={"followup"} title={"Filter"} />
                                            </th>
                                            <th>
                                                Transfer
                                            </th>
                                            <th>
                                                Lead Owner
                                                <br />
                                                <ModalComp title={'Filter'} handleAdmin={handleAdmin} component={'admin'} adminf={adminf} />
                                            </th>
                                            <th style={{ minWidth: '80px' }}>Transfer Date</th>
                                            <th>
                                                Call Center Category
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {array.map(({ list, ct, td }) => {


                                            return (
                                                <>
                                                    <ManagerRowComp isDesktop={isDesktop} Checkbox={Checkbox} handleCheckbox={handleCheckbox} state={state} IndeterminateCheckBoxIcon={IndeterminateCheckBoxIcon} ControlPointIcon={ControlPointIcon} list={list} ct={ct} td={td} getColor={getColor} getDarkBorder={getDarkBorder} show={show} setShow={setShow} array={array} project={project} refresh={refresh} loader={loader} snackBar={snackBar} />
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </TableContainer>
                    </>
                    : <>
                        {
                            (isDesktop)
                                ? <>
                                    <Card style={{ width: '30%' }}>
                                        <table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3}>Score Card</th>
                                                </tr>
                                                <tr>
                                                    <th>Leads Allocated</th>
                                                    <th>Leads Touched</th>
                                                    <th>Leads Untouched</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{lead_total}</td>
                                                    <td>{touched}</td>
                                                    <td>{untouched}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Card>
                                    <br />
                                    <TableContainer component={Paper}>
                                        <div style={{ overflowX: 'auto', maxHeight: '650px' }}>
                                            <table className={styles.table}>
                                                <thead style={{ position: 'sticky', top: 0 }}>
                                                    <tr>
                                                        <th colSpan={23} className={styles.typogrpahy_header}>Calling Panel</th>
                                                    </tr>
                                                    <tr>
                                                        <th rowspan="2" width="50px">Name

                                                        </th>
                                                        <th rowspan="2" width="50px">Admission

                                                        </th>
                                                        <th rowspan="2" width="50">Count
                                                            <br /><br /> <ArrowDropDownIcon style={{ height: '40px', width: '40px' }} id='toggle_sort' onClick={handleClick} />
                                                        </th>
                                                        <th rowspan="2" style={{ minWidth: '80px' }}>Date
                                                            <br /><br /> <ArrowDropDownIcon style={{ height: '40px', width: '40px' }} id='toggle_date_sort' onClick={handleDateClick} />
                                                        </th>
                                                        <th colspan={19}>Lead
                                                            <br /><br />
                                                            <b>Total Count: </b>{count}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th >Mobile</th>
                                                        <th>FNo.

                                                        </th>
                                                        <th>Sch.</th>
                                                        <th>Class<br />
                                                            <ModalComp title={'Filter'} handleClass={handleClass} component={'class'} /></th>
                                                        <th>Course</th>
                                                        <th>Fees
                                                            <ModalComp title={'Filter'} handleFees={handleFees} component={'fees'} />
                                                        </th>
                                                        <th>Form Date</th>
                                                        <th style={{ minWidth: '80px' }}>Reg. Date</th>
                                                        <th style={{ minWidth: '80px' }}>Test Date
                                                            <br />
                                                            <ModalComp title={'Filter'} handleTestDate={handleTestDate} component={'testDate'} tested={tested} />
                                                        </th>
                                                        <th style={{ minWidth: '80px' }}>Dept. Date</th>
                                                        <th>
                                                            Source
                                                            <br />
                                                            <ModalComp title={'Filter'} handleSource={handleSource} component={'source'} sourcef={sourcef} />
                                                        </th>
                                                        <th>
                                                            Utm Source
                                                            <br />
                                                            <ModalComp title={'Filter'} handleUtmSource={handleUtmSource} component={'utm_source'} utmf={utmf} />
                                                        </th>
                                                        <th>
                                                            Conversation<br />
                                                            <ModalComp handleCategoryFilter={handleCategoryFilter} component={'category'} title={'Filter'} />
                                                        </th>
                                                        <th>Callback
                                                            <br />
                                                            <ModalComp handleCallbackFilter={handleCallbackFilter} component={"followup"} title={"Filter"} />
                                                        </th>
                                                        <th>Lead Owner</th>
                                                        <th style={{ minWidth: '80px' }}>Transfer Date</th>
                                                        {/* <th>SMS</th>
                                                        <th>WhatsApp</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        array.map(({ list, ct, td }) => {

                                                            return (
                                                                <>
                                                                    <TRowComp isDesktop={isDesktop} IndeterminateCheckBoxIcon={IndeterminateCheckBoxIcon} ControlPointIcon={ControlPointIcon} list={list} ct={ct} td={td} getColor={getColor} getDarkBorder={getDarkBorder} show={show} setShow={setShow} array={array} project={project} refresh={refresh} loader={loader} snackBar={snackBar} />
                                                                </>
                                                            )


                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </TableContainer>
                                </>
                                : <>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Card style={{ width: '60%' }}>
                                            <table className={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={3}>Score Card</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Leads Allocated</th>
                                                        <th>Leads Touched</th>
                                                        <th>Leads Untouched</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{lead_total}</td>
                                                        <td>{touched}</td>
                                                        <td>{untouched}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card>
                                    </div>
                                    <br />
                                    <TableContainer component={Paper}>
                                        <table className={styles.table}>
                                            <thead style={{ position: 'sticky', top: 0 }}>
                                                <tr>
                                                    <th colSpan={2} className={styles.typogrpahy_header}>Calling Panel
                                                        <br /><br /> <b>Count: &nbsp;</b>{count}
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </TableContainer>
                                    {
                                        array.map(({ list, ct }) => {

                                            return (
                                                <>
                                                    <PaperComp handleClass={handleClass} handleCallbackFilter={handleCallbackFilter} handleCategoryFilter={handleCategoryFilter} utmf={utmf} tested={tested} handleTestDate={handleTestDate} sourcef={sourcef} handleUtmSource={handleUtmSource} handleSource={handleSource} ct={ct} list={list} loader={loader} isDesktop={isDesktop} snackBar={snackBar} project={project} />
                                                    <br /><br />
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }
                    </>
            }
        </>
    )
}



const ManagerRowComp = ({ isDesktop, Checkbox, handleCheckbox, state, IndeterminateCheckBoxIcon, ControlPointIcon, list, array, ct, td, getDarkBorder, getColor, refresh, loader, snackBar, project }) => {
    const [show, setShow] = React.useState(false);

    return (

        <>

            {list.map((n, i) => {
                return (
                    <>

                        {
                            (i < 1)
                                ? <>
                                    <tr style={{ backgroundColor: getColor(n.fee), borderBottom: getDarkBorder(list, i) }}>
                                        <td {...(show && { rowSpan: list.length })}>
                                            <table>
                                                <tr>
                                                    <td style={{ border: 'none' }}>
                                                        <Checkbox list={list} handleCheckbox={handleCheckbox} />
                                                    </td>
                                                    <td style={{ border: 'none' }}>
                                                        {
                                                            (list[0].name != null)
                                                                ? <>{list[0].name}</>
                                                                : <>{getLeadName(list)}</>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        {
                                                            (list.length > 1)
                                                                ? <>
                                                                    {
                                                                        (show)
                                                                            ? <IndeterminateCheckBoxIcon style={{ cursor: 'pointer' }} onClick={() => setShow(!show)} />
                                                                            : <ControlPointIcon style={{ cursor: 'pointer' }} onClick={() => setShow(!show)} />
                                                                    }
                                                                </>
                                                                : <></>
                                                        }
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td {...(show && { rowSpan: list.length })} style={{ backgroundColor: getAdmissionColor(list) }}>{Admission(list)}</td>
                                        <td {...(show && { rowSpan: list.length })}>{ct}</td>
                                        <td {...(show && { rowSpan: list.length })} style={{ minWidth: '80px' }}>
                                            {
                                                (td != null && td != 'null' && td != '')
                                                    ? <>
                                                        {moment(td).format('DD-MMM,YYYY')}
                                                    </>
                                                    : <></>
                                            }
                                        </td>
                                        <td {...(show && { rowSpan: list.length })}>{getAdminName(list)}</td>
                                        {show && <MComp2 isDesktop={isDesktop} array={array} project={project} list={list} refresh={refresh} loader={loader} snackBar={snackBar} state={state} handleCheckbox={handleCheckbox} />}
                                        {!show && <MComp4 isDesktop={isDesktop} array={array} project={project} list={list} refresh={refresh} loader={loader} snackBar={snackBar} state={state} handleCheckbox={handleCheckbox} />}
                                    </tr>
                                </>
                                : <>

                                    {show && <tr style={{ borderBottom: getDarkBorder(list, i), backgroundColor: getColor(n.fee) }}>
                                        <MComp3 isDesktop={isDesktop} n={n} array={array} project={project} list={list} refresh={refresh} loader={loader} snackBar={snackBar} state={state} handleCheckbox={handleCheckbox} />

                                    </tr>}

                                </>
                        }

                    </>
                )
            })}

        </>


    )
}


const MComp2 = ({ isDesktop, list, array, snackBar, loader, refresh, project, state, handleCheckbox }) => {

    return (

        <>
            {/* <td>{list[0].admin_name}
                <br />
                <LeadOwnerComp ORDERID={list[0].ORDERID} loader={loader} />
            </td> */}

            <td>
                {list[0].name}
                {/* {list[0].ORDERID} */}
            </td>

            <td>{list[0].fno}</td>
            <td>
                {
                    (list[0].schper)
                        ? <>
                            {list[0].schper}%
                        </>
                        : <></>
                }
            </td>
            <td>{list[0].SCLASS}</td>
            <td>{list[0].coursetitle}</td>
            <td>{list[0].fee}</td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0].formdate != null && list[0].formdate != 'null')
                        ? <>
                            {moment(list[0].formdate).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0].REGDATE != null && list[0].REGDATE != 'null')
                        ? <>
                            {moment(list[0].REGDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0][`TEST DATE`] != null && list[0][`TEST DATE`] != 'null')
                        ? <>
                            {moment(list[0]['TEST DATE']).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0].DEPTDATE != null && list[0].DEPTDATE != 'null')
                        ? <>
                            {moment(list[0].DEPTDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td>{list[0].source}
                {
                    (list[0].allotment != null && list[0].allotment != 'null')
                        ? <>
                            <br />
                            ({list[0].allotment})
                        </>
                        : <></>
                }
            </td>
            <td>
                {list[0].utm_source}
            </td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_time={list[0]['last_time']} last_category={list[0]['last_category']} last_remark={list[0]['last_remark']} roll_no={list[0]['ORDERID']} snackBar={snackBar} loader={loader} component={'conversation'} />
                <br /><Typography variant="h6">Category:&nbsp;&nbsp;{list[0]['last_category']}</Typography>
            </td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_callback={list[0]['last_callback']} roll_no={list[0]['ORDERID']} snackBar={snackBar} loader={loader} component={'callback'} />
            </td>
            <td>
                <TransferComp state={state} refresh={refresh} loader={loader} roll_no={list[0].ORDERID} mobile1={list[0].MOBILE1} mobile2={list[0].MOBILE2} />
            </td>
            <td>{list[0].admin_name}
                <br />
                <LeadOwnerComp ORDERID={list[0].ORDERID} loader={loader} />
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0].transfer_date != null && list[0].transfer_date != 'null')
                        ? <>{moment(list[0].transfer_date).format('DD-MMM,YYYY')}</>
                        : <></>
                }
            </td>
            <td>{list[0].category}</td>

        </>


    )
}


const MComp4 = ({ isDesktop, list, array, snackBar, loader, refresh, project, state, handleCheckbox }) => {

    let len = list.length - 1;

    return (

        <>
            {/* <td>{list[len].admin_name}
                <br />
                <LeadOwnerComp ORDERID={list[len].ORDERID} loader={loader} /></td> */}
            <td>
                {list[len].name}
                {/* {list[len].ORDERID} */}
            </td>

            <td>{list[len].fno}</td>
            <td>
                {
                    (list[len].schper)
                        ? <>
                            {list[len].schper}%
                        </>
                        : <></>
                }
            </td>
            <td>{list[len].SCLASS}</td>
            <td>{list[len].coursetitle}</td>
            <td>{list[len].fee}</td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len].formdate != null && list[len].formdate != 'null')
                        ? <>
                            {moment(list[len].formdate).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len].REGDATE != null && list[len].REGDATE != 'null')
                        ? <>
                            {moment(list[len].REGDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len][`TEST DATE`] != null && list[len][`TEST DATE`] != 'null')
                        ? <>
                            {moment(list[len]['TEST DATE']).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len].DEPTDATE != null && list[len].DEPTDATE != 'null')
                        ? <>
                            {moment(list[len].DEPTDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td>{list[len].source}
                {
                    (list[len].allotment != null && list[len].allotment != 'null')
                        ? <>
                            <br />
                            ({list[len].allotment})
                        </>
                        : <></>
                }
            </td>
            <td>
                {list[len].utm_source}
            </td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_time={list[len]['last_time']} last_category={list[len]['last_category']} last_remark={list[len]['last_remark']} roll_no={list[len]['ORDERID']} snackBar={snackBar} loader={loader} component={'conversation'} />
                <br /><Typography variant="h6">Category:&nbsp;&nbsp;{list[len]['last_category']}</Typography>
            </td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_callback={list[len]['last_callback']} roll_no={list[len]['ORDERID']} snackBar={snackBar} loader={loader} component={'callback'} />
            </td>
            <td>
                <TransferComp state={state} refresh={refresh} loader={loader} roll_no={list[len].ORDERID} mobile1={list[len].MOBILE1} mobile2={list[len].MOBILE2} />
            </td>
            <td>{list[len].admin_name}
                <br />
                <LeadOwnerComp ORDERID={list[len].ORDERID} loader={loader} /></td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len].transfer_date != null && list[len].transfer_date != 'null')
                        ? <>{moment(list[len].transfer_date).format('DD-MMM,YYYY')}</>
                        : <></>
                }
            </td>
            <td>{list[len].category}</td>

        </>

    )

}


const MComp3 = ({ isDesktop, list, array, n, snackBar, loader, refresh, project, state, handleCheckbox }) => {

    return (

        <>
            {/* <td>{n.admin_name}
                <br />
                <LeadOwnerComp ORDERID={n.ORDERID} loader={loader} /></td> */}
            <td>

                {n.name}
                {/* {n.ORDERID} */}
            </td>

            <td>{n.fno}</td>
            <td>
                {
                    (n.schper)
                        ? <>
                            {n.schper}%
                        </>
                        : <></>
                }
            </td>
            <td>{n.SCLASS}</td>
            <td>{n.coursetitle}</td>
            <td>{n.fee}</td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n.formdate != null && n.formdate != 'null')
                        ? <>
                            {moment(n.formdate).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n.REGDATE != null && n.REGDATE != 'null')
                        ? <>
                            {moment(n.REGDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n[`TEST DATE`] != null && n[`TEST DATE`] != 'null')
                        ? <>
                            {moment(n['TEST DATE']).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n.DEPTDATE != null && n.DEPTDATE != 'null')
                        ? <>
                            {moment(n.DEPTDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td>{n.source}
                {
                    (n.allotment != null && n.allotment != 'null')
                        ? <>
                            <br />
                            ({n.allotment})
                        </>
                        : <></>
                }
            </td>
            <td>{n.utm_source}</td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_time={n['last_time']} last_category={n['last_category']} last_remark={n['last_remark']} roll_no={n['ORDERID']} snackBar={snackBar} loader={loader} component={'conversation'} />
                <br /><Typography variant="h6">Ctaegory:&nbsp;&nbsp;{n['last_category']}</Typography>
            </td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_callback={n['last_callback']} roll_no={n['ORDERID']} snackBar={snackBar} loader={loader} component={'callback'} />
            </td>
            <td>
                <TransferComp state={state} refresh={refresh} loader={loader} roll_no={n.ORDERID} mobile1={n.MOBILE1} mobile2={n.MOBILE2} />
            </td>
            <td>{n.admin_name}
                <br />
                <LeadOwnerComp ORDERID={n.ORDERID} loader={loader} /></td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n.transfer_date != null && n.transfer_date != 'null')
                        ? <>{moment(n.transfer_date).format('DD-MMM,YYYY')}</>
                        : <></>
                }
            </td>
            <td>{n.category}</td>

        </>


    )
}




const TRowComp = ({ isDesktop, IndeterminateCheckBoxIcon, ControlPointIcon, list, array, ct, td, getDarkBorder, getColor, refresh, loader, snackBar, project }) => {

    const [show, setShow] = React.useState(false);

    // const handleCreateForm = () => {
    //     window.open('https://reliablekota.web.app/admin/app/call-center/create-ee-lead', '_blank')
    // }

    return (
        <>
            {list.map((n, i) => {

                return (
                    <>

                        {
                            (i < 1)
                                ? <>
                                    <tr style={{ backgroundColor: getColor(n.fee), borderBottom: getDarkBorder(list, i) }}>
                                        <td {...(show && { rowSpan: list.length })}>
                                            <table>
                                                <tr>
                                                    <td style={{ border: 'none' }}>
                                                        {
                                                            (list[0].name != null)
                                                                ? <>{list[0].name}</>
                                                                : <>{getLeadName(list)}</>
                                                        }
                                                    </td>
                                                    <td style={{ border: 'none' }}>
                                                        <a href='https://reliablekota.web.app/admin/app/call-center/create-ee-lead' target='_blank' style={{ border: '1px solid', fontSize: '15px' }}>Create</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: 'none' }}>
                                                        {
                                                            (list.length > 1)
                                                                ? <>
                                                                    {
                                                                        (show)
                                                                            ? <IndeterminateCheckBoxIcon style={{ cursor: 'pointer' }} onClick={() => setShow(!show)} />
                                                                            : <ControlPointIcon style={{ cursor: 'pointer' }} onClick={() => setShow(!show)} />
                                                                    }
                                                                </>
                                                                : <></>
                                                        }
                                                    </td>
                                                </tr>
                                            </table>

                                        </td>
                                        <td {...(show && { rowSpan: list.length })} style={{ backgroundColor: getAdmissionColor(list) }}>{Admission(list)}</td>
                                        <td {...(show && { rowSpan: list.length })}>{ct}</td>
                                        <td {...(show && { rowSpan: list.length })} style={{ minWidth: '80px' }}>
                                            {
                                                (td != null && td != 'null' && td != '')
                                                    ? <>
                                                        {moment(td).format('DD-MMM,YYYY')}
                                                    </>
                                                    : <></>
                                            }
                                        </td>
                                        {show && <Comp2 isDesktop={isDesktop} array={array} project={project} list={list} refresh={refresh} loader={loader} snackBar={snackBar} />}

                                        {!show && <Comp4 isDesktop={isDesktop} array={array} project={project} list={list} refresh={refresh} loader={loader} snackBar={snackBar} />}

                                    </tr>
                                </>
                                : <>
                                    {show && <tr style={{ borderBottom: getDarkBorder(list, i), backgroundColor: getColor(n.fee) }}>
                                        <Comp3 isDesktop={isDesktop} array={array} project={project} n={n} refresh={refresh} loader={loader} snackBar={snackBar} />

                                    </tr>}
                                </>
                        }

                    </>
                )

            })}
        </>
    )
}

const Comp4 = ({ isDesktop, list, array, snackBar, loader, refresh, project }) => {
    let len = list.length - 1;
    return (
        <>
            <td>
                {list[len].name}
                {/* <br />
                {list[len].ORDERID} */}
            </td>
            <td style={{ fontSize: '9px' }}>


                <table>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (list[len].MOBILE1 && list[len].MOBILE1 != 'null')
                                    ? <>

                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={list[len].MOBILE1} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (list[len].MOBILE2 && list[len].MOBILE2 != 'null')
                                    ? <>

                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={list[len].MOBILE2} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (list[len].MOBILE3 && list[len].MOBILE3 != 'null')
                                    ? <>

                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={list[len].MOBILE3} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                </table>
            </td>
            <td>{list[len].fno}</td>
            <td>
                {
                    (list[len].schper)
                        ? <>
                            {list[len].schper}%
                        </>
                        : <></>
                }
            </td>
            <td>{list[len].SCLASS}</td>
            <td>{list[len].coursetitle}</td>
            <td>{list[len].fee}</td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len].formdate != null && list[len].formdate != 'null')
                        ? <>
                            {moment(list[len].formdate).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len].REGDATE != null && list[len].REGDATE != 'null')
                        ? <>
                            {moment(list[len].REGDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len][`TEST DATE`] != null && list[len][`TEST DATE`] != 'null')
                        ? <>
                            {moment(list[len]['TEST DATE']).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len].DEPTDATE != null && list[len].DEPTDATE != 'null')
                        ? <>
                            {moment(list[len].DEPTDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td>{list[len].source}</td>
            <td>{list[len].utm_source}</td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_time={list[len]['last_time']} last_category={list[len]['last_category']} last_remark={list[len]['last_remark']} roll_no={list[len]['ORDERID']} snackBar={snackBar} loader={loader} component={'conversation'} />
                <br /><Typography variant="h6">Category:&nbsp;&nbsp;{list[len]['last_category']}</Typography>
            </td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_callback={list[len]['last_callback']} roll_no={list[len]['ORDERID']} snackBar={snackBar} loader={loader} component={'callback'} />
            </td>

            <td>{list[len].admin_name}
                <br />
                <LeadOwnerComp ORDERID={list[len].ORDERID} loader={loader} /></td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[len].transfer_date != null && list[len].transfer_date != 'null')
                        ? <>{moment(list[len].transfer_date).format('DD-MMM,YYYY')}</>
                        : <></>
                }
            </td>
            {/* <td>
                <TemplateComp ORDERID={list[len].ORDERID} loader={loader} sms={'sms'} mobile1={checkmobile(list[len].MOBILE1)} mobile2={checkmobile(list[len].MOBILE2)} mobile3={checkmobile(list[len].MOBILE3)} />
            </td>
            <td>
                <TemplateComp ORDERID={list[len].ORDERID} loader={loader} sms={'whatsapp'} mobile1={checkmobile(list[len].MOBILE1)} mobile2={checkmobile(list[len].MOBILE2)} mobile3={checkmobile(list[len].MOBILE3)} />
            </td> */}
        </>
    )
}


const Comp2 = ({ isDesktop, list, array, snackBar, loader, refresh, project }) => {
    return (
        <>
            <td>
                {list[0].name}
                {/* <br />
                {list[0].ORDERID} */}
            </td>
            <td style={{ fontSize: '9px' }}>

                <table>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (list[0].MOBILE1 && list[0].MOBILE1 != 'null')
                                    ? <>
                                        {/* <b>Mobile1:</b>&nbsp; */}
                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={list[0].MOBILE1} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (list[0].MOBILE2 && list[0].MOBILE2 != 'null')
                                    ? <>

                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={list[0].MOBILE2} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (list[0].MOBILE3 && list[0].MOBILE3 != 'null')
                                    ? <>

                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={list[0].MOBILE3} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                </table>
            </td>
            <td>{list[0].fno}</td>
            <td>
                {
                    (list[0].schper)
                        ? <>
                            {list[0].schper}%
                        </>
                        : <></>
                }
            </td>
            <td>{list[0].SCLASS}</td>
            <td>{list[0].coursetitle}</td>
            <td>{list[0].fee}</td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0].formdate != null && list[0].formdate != 'null')
                        ? <>
                            {moment(list[0].formdate).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0].REGDATE != null && list[0].REGDATE != 'null')
                        ? <>
                            {moment(list[0].REGDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0][`TEST DATE`] != null && list[0][`TEST DATE`] != 'null')
                        ? <>
                            {moment(list[0]['TEST DATE']).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0].DEPTDATE != null && list[0].DEPTDATE != 'null')
                        ? <>
                            {moment(list[0].DEPTDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td>{list[0].source}</td>
            <td>{list[0].utm_source}</td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_time={list[0]['last_time']} last_category={list[0]['last_category']} last_remark={list[0]['last_remark']} roll_no={list[0]['ORDERID']} snackBar={snackBar} loader={loader} component={'conversation'} />
                <br /><Typography variant="h6">Category:&nbsp;&nbsp;{list[0]['last_category']}</Typography>
            </td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_callback={list[0]['last_callback']} roll_no={list[0]['ORDERID']} snackBar={snackBar} loader={loader} component={'callback'} />
            </td>

            <td>{list[0].admin_name}
                <br />
                <LeadOwnerComp ORDERID={list[0].ORDERID} loader={loader} /></td>
            <td style={{ minWidth: '80px' }}>
                {
                    (list[0].transfer_date != null && list[0].transfer_date != 'null')
                        ? <>{moment(list[0].transfer_date).format('DD-MMM,YYYY')}</>
                        : <></>
                }
            </td>
            {/* <td>
                <TemplateComp ORDERID={list[0].ORDERID} loader={loader} sms={'sms'} mobile1={checkmobile(list[0].MOBILE1)} mobile2={checkmobile(list[0].MOBILE2)} mobile3={checkmobile(list[0].MOBILE3)} />
            </td>
            <td>
                <TemplateComp ORDERID={list[0].ORDERID} loader={loader} sms={'whatsapp'} mobile1={checkmobile(list[0].MOBILE1)} mobile2={checkmobile(list[0].MOBILE2)} mobile3={checkmobile(list[0].MOBILE3)} />
            </td> */}
        </>
    )
}

const Comp3 = ({ isDesktop, n, array, snackBar, loader, refresh, project }) => {
    return (
        <>
            <td>

                {n.name}
                {/* <br />
                {n.ORDERID} */}
            </td>
            <td style={{ fontSize: '9px' }}>
                <table>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (n.MOBILE1 && n.MOBILE1 != 'null')
                                    ? <>

                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={n.MOBILE1} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (n.MOBILE2 && n.MOBILE2 != 'null')
                                    ? <>

                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={n.MOBILE2} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: 'none' }}>
                            {
                                (n.MOBILE3 && n.MOBILE3 != 'null')
                                    ? <>

                                        <CopyIcon isDesktop={isDesktop} snackBar={snackBar} mobile={n.MOBILE3} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                </table>

            </td>
            <td>{n.fno}</td>
            <td>
                {
                    (n.schper)
                        ? <>
                            {n.schper}%
                        </>
                        : <></>
                }
            </td>
            <td>{n.SCLASS}</td>
            <td>{n.coursetitle}</td>
            <td>{n.fee}</td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n.formdate != null && n.formdate != 'null')
                        ? <>
                            {moment(n.formdate).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n.REGDATE != null && n.REGDATE != 'null')
                        ? <>
                            {moment(n.REGDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n[`TEST DATE`] != null && n[`TEST DATE`] != 'null')
                        ? <>
                            {moment(n['TEST DATE']).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n.DEPTDATE != null && n.DEPTDATE != 'null')
                        ? <>
                            {moment(n.DEPTDATE).format('DD-MMM,YYYY')}
                        </>
                        : <></>
                }
            </td>
            <td>{n.source}</td>
            <td>{n.utm_source}</td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_time={n['last_time']} last_category={n['last_category']} last_remark={n['last_remark']} roll_no={n['ORDERID']} snackBar={snackBar} loader={loader} component={'conversation'} />
                <br /><Typography variant="h6">Ctaegory:&nbsp;&nbsp;{n['last_category']}</Typography>
            </td>
            <td>
                <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_callback={n['last_callback']} roll_no={n['ORDERID']} snackBar={snackBar} loader={loader} component={'callback'} />
            </td>
            <td>{n.admin_name}
                <br />
                <LeadOwnerComp ORDERID={n.ORDERID} loader={loader} /></td>
            <td style={{ minWidth: '80px' }}>
                {
                    (n.transfer_date != null && n.transfer_date != 'null')
                        ? <>{moment(n.transfer_date).format('DD-MMM,YYYY')}</>
                        : <></>
                }
            </td>
            {/* <td>
                <TemplateComp ORDERID={n.ORDERID} loader={loader} sms={'sms'} mobile1={checkmobile(n.MOBILE1)} mobile2={checkmobile(n.MOBILE2)} mobile3={checkmobile(n.MOBILE3)} />
            </td>
            <td>
                <TemplateComp ORDERID={n.ORDERID} loader={loader} sms={'whatsapp'} mobile1={checkmobile(n.MOBILE1)} mobile2={checkmobile(n.MOBILE2)} mobile3={checkmobile(n.MOBILE3)} />
            </td> */}
        </>
    )
}


const CopyIcon = ({ mobile, snackBar, isDesktop }) => {
    const mask = (mobile) => {
        try {
            return mobile.replace(/\d(?=\d{4})/g, "*");
        } catch (e) {

        }
    }
    return (
        <>
            {mask(mobile)}
            <FileCopyIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" onClick={() => {
                navigator.clipboard.writeText(mobile);
                snackBar.show('Copied!');
            }} />
            {
                (isDesktop)
                    ? <></>
                    : <>
                        <a href={'tel:' + mobile} target='blank'>
                            <CallIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" />
                        </a>
                    </>
            }

        </>
    )
}

const getLeadName = (list) => {
    let status;

    let i, tmp, l_len = list.length;

    for (i = 0; i < l_len; i++) {
        tmp = list[i].name;
        if (tmp != null && tmp != 'null' && tmp != '') {
            status = tmp;
            break;
        }
    }


    return status;
}





const Checkbox = ({ list, handleCheckbox }) => {

    const onChange = (event) => {

        handleCheckbox({ orderid: list[(list.length - 1)].ORDERID, value: event.target.checked })
    }

    return (
        <>&nbsp;&nbsp;
            <input className="Mycheckbox" type="checkbox" onChange={onChange} />
        </>
    )
}


const checkmobile = (mobile) => {
    if (mobile === null || mobile === 'null')
        return false;
    return mobile;
}


const Admission = (list) => {
    let status = 'No';

    let i, tmp, l_len = list.length;

    for (i = 0; i < l_len; i++) {
        tmp = list[i].fee;
        if (tmp == 'Y') {
            status = 'Done';
            break;
        }
    }


    return status;
}


const getAdmissionColor = (list) => {


    let i, tmp, l_len = list.length;

    for (i = 0; i < l_len; i++) {
        tmp = list[i].fee;
        if (tmp == 'Y') {
            return '#90EE90';
        }
    }

}

const getAdminName = (list) => {

    let status = null, i, l_len = list.length, tmp, count = 0;

    for (i = 0; i < l_len; i++) {
        tmp = list[i].admin_name;

        if (tmp != null) {
            status = tmp;

        }

    }

    return status;
}