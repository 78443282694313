import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors,
} from "@material-ui/core";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable({ data }) {
  const classes = useStyles();
  const { test_subject, total_info } = data;

  return (
    <Card>
      <CardHeader
        // action={
        //   <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
        //     Last 7 days
        //   </Button>
        // }
        title="Subject Wise Analysis"
      />
      <Divider />
      <CardContent>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {test_subject.map((n, i) => {
                  return (
                    <TableCell key={i}>
                      <b>{n.subject_name}</b>
                    </TableCell>
                  );
                })}
                <TableCell>
                  <b>Total</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Total Questions</TableCell>
                {test_subject.map((n, i) => {
                  return <TableCell key={i}>{n.total_ques}</TableCell>;
                })}

                <TableCell>{total_info.total_ques}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Correct</TableCell>
                {test_subject.map((n, i) => {
                  return <TableCell key={i}>{n.correct}</TableCell>;
                })}

                <TableCell>{total_info.correct}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Incorrect</TableCell>
                {test_subject.map((n, i) => {
                  return <TableCell key={i}>{n.incorrect}</TableCell>;
                })}

                <TableCell>{total_info.incorrect}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Partial Correct</TableCell>
                {test_subject.map((n, i) => {
                  return <TableCell key={i}>{n.partial_correct}</TableCell>;
                })}

                <TableCell>{total_info.partial_correct}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
