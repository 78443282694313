import * as React from 'react';
// import Link from '@mui/material/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from './title';

function preventDefault(event) {
    event.preventDefault();
}
const useStyles = makeStyles({
    container: {
        marginTop: 15,
        width: '100%'
    },
    table: {
        width: '100%'
    },
    title: {
        margin: 20
    }
});
export default function ResponseTable(props) {
    const classes = useStyles();
    const rows = props.data;
    console.log(rows)
    // console.log("rows:" + rows);
    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <div style={{ borderBottom: '1px solid #d6d6d6' }}>
                    <Title>Your Response</Title>
                </div>
                <br />
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell style={{ textAlign: 'center' }}>Sr. No.</TableCell> */}
                            <TableCell style={{ textAlign: 'center' }}>Question No.</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>Question Image</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>Your Response</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>Is Correct</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={row.id}>
                                {/* <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell> */}
                                <TableCell style={{ textAlign: 'center' }}>{row.question_no}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}><a href={row.question_url} target='_blank'>Image{index + 1}</a></TableCell>
                                <TableCell style={{ textAlign: 'center' }}>{row.student_ans}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>{row.is_correct}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        </React.Fragment>
    );
}