import { Grid, Button, InputLabel, TextField, MenuItem, Paper, Select, Card, Typography } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { api_url } from "./config";
import { url } from "./config";
import { url2 } from "./config";

export default function GetStudentResult() {
    const [batch, setBatch] = useState('');
    const [value, setValue] = useState([]);
    const [show, setShow] = useState(true);
    const [rollno, setRollNo] = useState('');
    const loader = useContext(LoaderContext);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [batch1, setBatch1] = useState('');
    const [rollno1, setRollno1] = useState('');
    const [batch2, setBatch2] = useState('');
    const [rollno2, setRollno2] = useState('');

    useEffect(() => {
        fetch();
        fetch2();
        fetch3();
    }, [])
    const fetch = async () => {

        try {
            const { data: obj } = await axios({
                url: api_url + "select-batch",
                method: "post",
                responseType: "json"
            })
            setValue(obj.test);
            console.log(obj.test);
        }
        catch (e) {
            alert(e.message);
        }
    }
    const fetch2 = async () => {

        try {
            const { data: obj } = await axios({
                url: url + "select-batch",
                method: "post",
                responseType: "json"
            })
            setData(obj.test);
            console.log(obj.test);
        }
        catch (e) {
            alert(e.message);
        }
    }
    const fetch3 = async () => {

        try {
            const { data: obj } = await axios({
                url: url2 + "select-batch",
                method: "post",
                responseType: "json"
            })
            setData2(obj.test);
            console.log(obj.test);
        }
        catch (e) {
            alert(e.message);
        }
    }

    const handleClick = async (e) => {
        try {
            loader.show();
            const val = e.target.value;
            setBatch(e.target.value);

            // window.location.href = `http://localhost:8060/saper/get-data?input_batch=${val}`;

            window.open(api_url + `get-data?input_batch=${val}&type=batch`, '_blank');

            // setShow(false);
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    const hClick = async () => {
        try {
            loader.show();

            let val = rollno;
            // let session = session;

            window.open(api_url + `get-data?rollno=${val}&type=rollno&mode=print`, '_blank')

        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }
    const handleClick2023 = async (e) => {
        try {
            loader.show();
            const val = e.target.value;
            setBatch1(e.target.value);

            // window.location.href = `http://localhost:8060/saper/get-data?input_batch=${val}`;

            window.open(url + `get-data?input_batch=${val}&type=batch`, '_blank');

            // setShow(false);
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }
    const hClick2023 = () => {
        try {
            loader.show();

            let val = rollno1;
            // let session = session;

            window.open(url + `get-data?rollno=${val}&type=rollno&mode=print`, '_blank')

        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }
    const handleClick2024 = async (e) => {
        try {
            loader.show();
            const val = e.target.value;
            setBatch2(e.target.value);

            // window.location.href = `http://localhost:8060/saper/get-data?input_batch=${val}`;

            window.open(url2 + `get-data?input_batch=${val}&type=batch&mode=print`, '_blank');

            // setShow(false);
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }
    const hClick2024 = () => {
        try {
            loader.show();

            let val = rollno2;
            // let session = session;

            window.open(url2 + `get-data?rollno=${val}&type=rollno&mode=print`, '_blank')

        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }


    return (
        <>
            <Grid container spacing={2} >
                <div style={{ padding: '40px 0px', width: '100%', height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>

                    <Grid item >
                        <Card style={{ width: '400px', height: '400px', paddingTop: '30px' }}>
                            <Typography style={{ textAlign: 'center' }}> Get Result 2022-23</Typography>
                            <br></br>
                            <br></br>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "200px", }}>

                                    <InputLabel>Select Batch</InputLabel>
                                    <Select value={batch} onChange={handleClick} fullWidth>
                                        {value.map((ele) => {
                                            return (
                                                <MenuItem value={ele.batch}>{ele.batch}</MenuItem>
                                            )

                                        })}
                                    </Select>
                                </Paper>

                            </Grid>
                            <br></br>
                            <br></br>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "200px", }}>
                                    <TextField
                                        type="text"
                                        label="Roll No"
                                        value={rollno}
                                        placeholder="Enter Roll No"
                                        onChange={(e) => setRollNo(e.target.value)}
                                    />
                                    <br></br>
                                    <br></br>

                                    <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={hClick}>Submit</Button>
                                </Paper>

                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item >
                        <Card style={{ width: '400px', height: '400px', paddingTop: '30px' }}>
                            <Typography style={{ textAlign: 'center' }}> Get Result 2023-24</Typography>
                            <br></br>
                            <br></br>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "200px", }}>

                                    <InputLabel>Select Batch</InputLabel>
                                    <Select value={batch1} onChange={handleClick2023} fullWidth>
                                        {data.map((ele) => {
                                            return (
                                                <MenuItem value={ele.batch}>{ele.batch}</MenuItem>
                                            )

                                        })}
                                    </Select>
                                </Paper>

                            </Grid>
                            <br></br>
                            <br></br>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "200px", }}>
                                    <TextField
                                        type="text"
                                        label="Roll No"
                                        value={rollno1}
                                        placeholder="Enter Roll No"
                                        onChange={(e) => setRollno1(e.target.value)}
                                    />
                                    <br></br>
                                    <br></br>

                                    <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={hClick2023}>Submit</Button>
                                </Paper>

                            </Grid>
                        </Card>

                    </Grid>
                    <Grid item >
                        <Card style={{ width: '400px', height: '400px', paddingTop: '30px' }}>
                            <Typography style={{ textAlign: 'center' }}> Get Result 2024-25</Typography>
                            <br></br>
                            <br></br>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "200px", }}>

                                    <InputLabel>Select Batch</InputLabel>
                                    <Select value={batch2} onChange={handleClick2024} fullWidth>
                                        {data2.map((ele) => {
                                            return (
                                                <MenuItem value={ele.batch}>{ele.batch}</MenuItem>
                                            )

                                        })}
                                    </Select>
                                </Paper>

                            </Grid>
                            <br></br>
                            <br></br>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "200px", }}>
                                    <TextField
                                        type="text"
                                        label="Roll No"
                                        value={rollno2}
                                        placeholder="Enter Roll No"
                                        onChange={(e) => setRollno2(e.target.value)}
                                    />
                                    <br></br>
                                    <br></br>

                                    <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={hClick2024}>Submit</Button>
                                </Paper>

                            </Grid>
                        </Card>

                    </Grid>
                </div>
            </Grid>


        </>
    )
}
