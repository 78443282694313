import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SelectSubject from "./select-subject";
import SelectChildSubject from ".//select-childsubject";
import { handleFormChange } from "../../../../utils/functions";
export default ({ onChange }) => {
  const [values, setValues] = useState({
    subject_id: "",
    childsubject_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
    if (e.target.name === "childsubject_id") {
      onChange(e);
    }
  };
  return (
    <>
      <Grid item xs={12}>
        <SelectSubject
          label="Subject"
          name="subject_id"
          value={values.subject_id}
          onChange={handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectChildSubject
          subject_id={values.subject_id}
          label="Child Subject"
          name="childsubject_id"
          value={values.childsubject_id}
          onChange={handleInputChange}
        />
      </Grid>
    </>
  );
};
