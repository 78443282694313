import React from "react";
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import { CSVReader } from 'react-papaparse';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Routes, Route, useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useLocation } from 'react-router-dom';
// import Alert from "@material-ui/core/Alert";
import { api_url } from '../reusable-calling-code-project/config';
import { LoaderContext } from "../../../../../context/LoaderContext";

export default function CreateResult(props) {

    const loader = useContext(LoaderContext);

    let navigate = useNavigate();

    var today = new Date();
    var day = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    var str = `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}`;
    // console.log(str);

    const [roll_no, setRollNo] = useState();
    const [array, setArray] = useState([]);
    const [name, setName] = useState();
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(async () => {
        if (props.roll_no != null) {
            fetch(props.roll_no);
            setLoad(true);
        }
        else {
            setLoading(true);
        }
    }, [props.roll_no])



    const fetch = async (roll) => {
        try {
            loader.show();
            const { data: data2 } = await axios({
                url: api_url + '/attendance-data-calling/view-student-attendance',
                method: "post",
                responseType: "json",
                data: {
                    roll: roll,
                },
            })
            console.log(data2.array)
            if (data2.Success === 'ok') {
                setArray(data2.array);
                setName(data2.name);
            }
        }
        catch (e) {
            alert('No Record of the Student');
            navigate('../attendance-data-calling');
        }
        finally {
            loader.hide();
        }
    }
    const handleClick = () => {
        fetch(roll_no);
        setLoad(true);
    }

    const handleReturn = () => {
        props.hide(true)
    }

    const handlePrint = async () => {
        window.open(`https://api-dot-reformexam.el.r.appspot.com/attendance-data-calling/print-student-attendance?rollno=${roll_no}`, '_blank');
    }


    return (
        <div>

            {
                (loading)
                    ? <>
                        <FormLayoutAdmin title="View Attendance">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        type='text'
                                        label="Roll No"
                                        value={roll_no}
                                        placeholder="Enter Roll No"
                                        onChange={(e) => setRollNo(e.target.value)}
                                    />
                                    {/* <br /><br /> */}

                                </Grid>
                                <Grid item xs={3}>
                                    <Button fullwidth onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button fullwidth onClick={handlePrint} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Print</Button>
                                </Grid>
                            </Grid>
                        </FormLayoutAdmin>
                    </>
                    : <></>
            }

            {
                (load)
                    ? <>
                        <b>Name: </b>{name}<br /><br />
                        <button onClick={handleReturn}>Close</button>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {array.map((ele, index) => (
                                    <TableRow>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{ele.date}</TableCell>
                                        <TableCell>{ele.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                    : <></>
            }

        </div>
    )
}