import React, { useState, useContext } from 'react'
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { Container, Grid, TextField, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';

const UploadPhoto = () => {

    const loader = useContext(LoaderContext);

    const [roll, setRoll] = useState('');
    const [url, setUrl] = useState('');
    const [show, setShow] = useState(false);
    const [image, setImage] = useState('');

    // const api_endpoint = 'http://localhost:8060/upload-photo';
    const api_endpoint = 'https://api-dot-reformexam.el.r.appspot.com/upload-photo';


    const handleUpload = async (event) => {
        try {
            const { name } = event.target.files[0];

            loader.show();
            const data = new FormData();
            data.append('file', event.target.files[0]);
            console.log(data);
            const { data: obj } = await axios.post(`${api_endpoint}/uploadFileApi`, data)


            //=======================================

            console.log(obj);

            if (obj.success === 'ok') {
                setUrl(obj.url);
                setImage(obj.name);
                setShow(true);
            }

            if (obj.success === 'false') {

                alert(obj.message);

            }


        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    const handleSubmit = async () => {
        try {
            loader.show();
            const { data: obj } = await axios({
                url: api_endpoint + '/upload',
                method: 'post',
                responseType: 'json',
                data: {
                    roll: roll,
                    url: url
                },
            })

            if (obj.success === 'ok') {
                alert("Photo Uploaded Successfully");
            }
            if (obj.success === 'false') {
                alert("Photo Already Uploaded");
            }
        }

        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }


    return (

        <>

            <Container>
                <FormLayoutAdmin title={"Upload Photo"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                type="tel"
                                label="Roll Number"
                                value={roll}
                                placeholder="Enter Roll No."
                                onChange={(e) => setRoll(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input type="file" id="contained-button-file" style={{ display: 'none' }} onChange={handleUpload} />
                            <label htmlFor="contained-button-file">
                                <Button variant="outined"
                                    startIcon={<CloudUploadIcon />}
                                    component="span">
                                    Upload
                                </Button>
                            </label>
                            {show && <label>{image}</label>}
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullwidth style={{ border: '1px solid purple', borderStyle: 'dashed' }} onClick={handleSubmit}>Submit Data</Button>
                        </Grid>
                    </Grid>
                </FormLayoutAdmin>
            </Container>

        </>

    )
}

export default UploadPhoto
