import React, { useContext, useEffect, useState } from "react";
import { Container, TextField, Grid, Button, Select, InputLabel, MenuItem } from "@material-ui/core";
import FormLayoutstudent from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
// import { create_student_mutation_gql } from "../../../gql/mutation/create-student-mutation";
import Typography from "@material-ui/core/Typography";
import SelectSessionCoursePhaseBatch from "../../components/select-session-course-phase-batch";
import SelectGender from "../../components/select-gender";
import { api_url, rest_headers } from '../../../../student/app/srceens/digital-classroom/config'
const axios = require('axios');

const url = 'https://api-dot-reformexam.el.r.appspot.com/create-student';


export default () => {
  // const [create_student_mutation] = useMutation(create_student_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      // create_student_mutation={create_student_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_student_mutation }) => {
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    roll_no: "",
    mobile: "",
    email: "",
    gender: "",
    current_batch_id: "",
    coursename: "",
    coursetitle: "",
    fee_status: "",
    fee: ""
  });
  const [session, setSession] = useState('');
  const [value, setValue] = useState([]);
  const [course, setCourse] = useState('');
  const [value1, setValue1] = useState([]);
  const [batch, setBatch] = useState('');
  const [value2, setValue2] = useState([]);

  useEffect(() => {
    fetch2();

  }, [])
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };


  const fetch = async () => {
    try {
      const roll_no = values.roll_no;
      loader.show();
      if (!roll_no) {
        snackBar.show('Please enter the roll no');
        return;
      }
      const { data } = await axios({
        url: api_url + '/admin-get-student-details',
        method: 'post',
        responseType: 'json',
        data: {
          roll_no: roll_no

        },
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })
      // console.log(data);
      const stu_data = data.message.stu_data;
      // console.log(stu_data)

      if (data.error === 'true') {
        snackBar.show(data.message);
        return;
      }

      let name;
      if (stu_data.length > 0) {
        name = stu_data[0]['name'].split(' ');
        setValues(prevState => ({
          ...prevState,

          fname: "",
          lname: "",

          mobile: "",
          email: "",
          gender: ""

        }));
        setValues(prevState => ({
          ...prevState, fname: name[0], lname: name[1], mobile: stu_data[0]['mobile1'], gender: stu_data[0]['gender'], coursetitle: stu_data[0]['coursetitle'], coursename: stu_data[0]['coursename'], fee_status: stu_data[0]['fee_status']
          , fee: stu_data[0]['fee']
        }));
      }
      // snackBar.show(data.message);

    } catch (e) {
      snackBar.show(e.message);
    } finally {
      loader.hide();
    }


  }
  const fetch2 = async () => {
    try {
      const { data: obj } = await axios({
        url: url + "/select-data",
        method: "post",
        responseType: "json",

      })
      setValue(obj.session);
      setValue1(obj.course);


    }
    catch (e) {
      alert(e.message)
    }
  }

  const handleClick = async (e) => {
    const val = e.target.value;
    setSession(e.target.value)
    console.log(e.target.value);
    const { data: obj } = await axios({
      url: url + "/select-data",
      method: "post",
      responseType: "json",
      data: {
        session: val
      }
    })
    setValue2(obj.batch);
    console.log(obj.batch);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const {
        fname,
        lname,
        roll_no,
        mobile,
        email,
        gender,
        current_batch_id,
        coursetitle
      } = values;

      loader.show();
      const { data: obj } = await axios({
        url: url + "/student-data",
        method: "post",
        responseType: "json",
        data: {
          roll_no: values.roll_no,
          fname: values.fname,
          lname: values.lname,
          mobile: values.mobile,
          email: values.email,
          gender: values.gender,
          current_batch_id: batch,
          coursetitle: values.coursetitle
        }

      })
      // let {
      //   data: {
      //     create_student: { success },
      //   },
      // } = await create_student_mutation({
      //   variables: {
      //     fname,
      //     lname,
      //     roll_no,
      //     mobile,
      //     email,
      //     gender,
      //     current_batch_id,
      //   },
      // });
      console.log(obj);
      if (obj.Success == 'ok') {
        snackBar.show("Student Created Successfully");
        // resetForm({ values, setValues });
        setValues(prevState => ({
          ...prevState,

          fname: "",
          lname: "",
          roll_no: "",
          mobile: "",
          email: "",
          gender: ""

        }));
      }
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutstudent title="Create student">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                label="Enter Roll No"
                variant="outlined"
                name="roll_no"
                value={values.roll_no}
                onChange={handleInputChange}
              /><br />
              Courstitle :{values.coursetitle}<br />
              CourseName :{values.coursename}<br />
              Fee Status :{values.fee_status}<br />
              Amount Deposited :{values.fee}<br />
              &nbsp;<button type="button" onClick={() => fetch()}>Fetch</button>

            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Firstname"
                variant="outlined"
                name="fname"
                value={values.fname}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Lastname"
                variant="outlined"
                name="lname"
                value={values.lname}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Enter Mobile"
                variant="outlined"
                name="mobile"
                value={values.mobile}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Email"
                variant="outlined"
                name="email"
                value={values.email}
                onChange={handleInputChange}
              />
              <Typography variant="overline"> optional</Typography>
            </Grid>
            <Grid item xs={12}>
              <SelectGender
                label="Gender"
                name="gender"
                value={values.gender}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel style={{ fontSize: "12px" }}>Select session</InputLabel>
              <Select value={session} onChange={handleClick} label='Session' style={{ width: "100%", maxWidth: "223px" }}>
                {value.map((ele, i) => {
                  return (
                    <MenuItem value={ele.id}>{ele.name}</MenuItem>
                  )
                })}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <InputLabel style={{ fontSize: "12px" }}>Select course</InputLabel>
              <Select value={course} onChange={(e) => setCourse(e.target.value)} label='Course' style={{ width: "100%", maxWidth: "223px" }}>
                {value1.map((ele, i) => {
                  return (
                    <MenuItem value={ele.id}>{ele.name}</MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel style={{ fontSize: "12px" }}>Select Batch</InputLabel>
              <Select value={batch} onChange={(e) => setBatch(e.target.value)} label='Batch' style={{ width: "100%", maxWidth: "223px" }}>
                {value2.map((ele) => {
                  return (
                    <MenuItem value={ele.id}>{ele.name}</MenuItem>
                  )
                })}
              </Select>
            </Grid>
            {/* <SelectSessionCoursePhaseBatch
              onChange={handleInputChange}
              name="current_batch_id"
            /> */}

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutstudent>
      </Container>
    </form>
  );
};
