import { gql } from "@apollo/client";

export const batch_list_sessionwise_query_gql = gql`
  query batch_list_sessionwise {
    batch_list_sessionwise {
      key: id
      value: name
      dept
    }
  }
`;
