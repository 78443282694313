import _ from "lodash";
import CryptoJS from "crypto-js";
import { array } from "yup";

export const get_decrypt_string = (str) => {
  const secretKey = 'b52b4f45b6e9337b57869d7cb718c693';
  var bytes  = CryptoJS.AES.decrypt(str, secretKey);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const rand = (min = 0, max = 10) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const error_beautify = (str) => {
  if (typeof str === "object") str = str.message;
  return str.replace(/graphql error:/gi, "").trim();
};

export const title_case = (string) => {
  var sentence = string.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(" ");
};

export const is_answered = ({ subquestion }) => {
  let i,
    j,
    tmp1,
    tmp2,
    len1 = subquestion.length,
    len2,
    answered = false;
  for (i = 0; i < len1; i++) {
    tmp1 = subquestion[i];
    len2 = tmp1.subquestion.option.length;
    for (j = 0; j < len2; j++) {
      tmp2 = tmp1.subquestion.option[j];
      if (tmp2.selected) {
        answered = true;
        break;
      }
    }
  }
  return answered;
};

export const map_attempt_to_quiz = ({ quiz, attempt }) => {
  let i = 0,
    ret_obj,
    len = quiz.quiz_question.length;
  for (i = 0; i < len; i++) {
    ret_obj = mapper(quiz.quiz_question[i], attempt.attempt_quiz_question);
    quiz.quiz_question[i] = ret_obj;
  }

  return quiz;
};

const mapper = (quiz_question, attempt_quiz_question=false) => {
  let i = 0;
  if (attempt_quiz_question !== false) {
    let len = attempt_quiz_question.length;
    for (i = 0; i < len; i++) {
      if (attempt_quiz_question[i].quiz_question.id === quiz_question.id) {
        quiz_question = map_deep(quiz_question, attempt_quiz_question[i]);
  
        return {
          ...quiz_question,
          answered: attempt_quiz_question[i].answered,
          flag: attempt_quiz_question[i].flag,
          visited: attempt_quiz_question[i].visited,
        };
      }
    }
  }
  else return {
    ...quiz_question,
    answered: [],
    flag: [],
    visited: [],
  };
  }
    
  

const map_deep = (quiz_question, attempt_quiz_question) => {
  let i = 0,
    j = 0,
    tmp1,
    tmp2,
    len1 = quiz_question.quiz_subquestion.length,
    len2;
  for (i = 0; i < len1; i++) {
    tmp1 = quiz_question.quiz_subquestion[i].subquestion.option;
    len2 = tmp1.length;
    for (j = 0; j < len2; j++) {
      quiz_question.quiz_subquestion[i].subquestion.option[
        j
      ].selected = map_deep_helper(
        quiz_question.quiz_subquestion[i].subquestion.option[j],
        quiz_question.quiz_subquestion[i],
        attempt_quiz_question
      );
    }
  }

  return quiz_question;
};

const map_deep_helper = (
  in_option,
  quiz_subquestion,
  { attempt_quiz_subquestion }
) => {
  let i = 0,
    j = 0,
    k,
    selected_option_array = [],
    len1 = quiz_subquestion.length,
    len2 = attempt_quiz_subquestion.length,
    tmp1,
    tmp2,
    len3,
    tmp3,
    tmp4;

  tmp1 = quiz_subquestion;

  for (j = 0; j < len2; j++) {
    tmp2 = attempt_quiz_subquestion[j];
    selected_option_array = pluck_options(tmp2.attempt_quiz_subquestion_option);

    if (tmp2.quiz_subquestion.id === tmp1.id) {
      return selected_option_array.indexOf(in_option.id) > -1;
      tmp3 = tmp1.subquestion.option;
      len3 = tmp3.length;
    }
  }
};

const pluck_options = (obj) => {
  let i = 0,
    len = obj.length,
    array = [];
  for (i = 0; i < len; i++) {
    array.push(obj[i].option.id);
  }
  return array;
};

export const handleFormChange = ({ e, values, setValues }) => {
  const { name, value } = e.target;
  setValues({ ...values, [name]: value });
};
export const resetForm = ({ values, setValues }) => {
  let key;
  for (key in values) {
    if (values.hasOwnProperty(key)) {
      values[key] = "";
    }
  }
  setValues({ ...values });
};
