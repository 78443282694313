import { gql } from "@apollo/client";

export const batch_list_query_gql = gql`
  query batch_list($phase_id: Int!) {
    batch_list(phase_id: $phase_id) {
      key: id
      value: name
    }
  }
`;
