import { gql } from "@apollo/client";

export const classroom_test_list_query_gql = gql`
  query classroom_test_list($classroom_test_type_id: Int!) {
    classroom_test_list(classroom_test_type_id: $classroom_test_type_id) {
      key: id
      value: name
      test_date: test_date
    }
  }
`;
