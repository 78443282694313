import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";
import { useQuery } from "@apollo/client";
import {viewer_gql} from '../../../gql/viewer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import {error_beautify} from '../../../../../utils/functions';

import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";
import theme from "../../../../../theme";

const common = {
  height: "8px",
  borderRadius: "8px",
};
const StyleSheet = {
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  item: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  circle: {
    height: 64,
    width: 64,
    borderRadius: "50%",
  },
  line1: {
    ...common,
    width: 200,
  },
  line2: {
    ...common,
    width: 140,
  },
  header: {
    ...common,
    width: 96,
  },
  divider: {
    height: 20,
  },
  divider2: {
    height: 10,
  },
  left: {
    borderRadius: 64,
  },
  table: {
    width: '100%'
  },
  card: {
   width: 650,
   align: 'center'
  }
};

export default ({ is_home_component }) => {
//   const [mark_attendance_mutation] = useMutation(
//     batch_courseware_mark_attendance
//   );

  return (
    <StyledApp
      //mark_attendance_mutation={mark_attendance_mutation}
      //is_home_component={is_home_component}
    />
  );
};

const App = ({ classes }) => {
    let navigate = useNavigate();
    

  useEffect(() => {
    refetch();
  }, []);

  const res = useQuery(viewer_gql);

  const { loading, error, data, refetch } = res;

  if (loading)
    return (
      <>
        <ShimmerHeader />
        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />
      
      </>
    );

    if (error){
      if(error_beautify(error) === "Response not successful: Received status code 500"){
        localStorage.removeItem("student_token");
        navigate("/student");  
      }
      return <GqlErrorComp error={error} />;
    }

  const {
    viewer
  } = data;

  let style = {};
style = { color: theme.palette.primary.main };

  return (
    <center><Card className={classes.card} align='center'>
    <CardContent>
      <Typography variant="h4" align='center'>
        Student Profile
        </Typography><br /><br />
      
     <Table className={classes.table} aria-label="simple table">
       
        <TableHead>
            <TableRow >
              <TableCell align='center'>
              Name
              </TableCell>
              <TableCell align='center'>-</TableCell>
              <TableCell align='center'>{viewer.profile.name}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell align='center'>
              Rollno
              </TableCell>
              <TableCell align='center'>-</TableCell>
              <TableCell align='center'>{viewer.profile.fno}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell align='center'>
              Medium
              </TableCell>
              <TableCell align='center'>-</TableCell>
              <TableCell align='center'>{viewer.profile.medium === "E" ? "English" : viewer.profile.medium === "H" ? "Hindi" : "No Record Found"}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell align='center'>
              Course
              </TableCell>
              <TableCell align='center'>-</TableCell>
              <TableCell align='center'>{viewer.profile.coursetitle}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell align='center'>
              Fee Deposited
              </TableCell>
              <TableCell align='center'>-</TableCell>
              <TableCell align='center'>{viewer.profile.fee} /-</TableCell>
            </TableRow>
            <TableRow >
              <TableCell align='center'>
              Less Amount
              </TableCell>
              <TableCell align='center'>-</TableCell>
              <TableCell align='center'>{viewer.profile.less_amount} /-</TableCell>
            </TableRow>
            </TableHead>
        
      </Table>
    </CardContent>
   
  </Card></center>
            
        
  );
};



const ShimmerHeader = () => {
  return (
    <Shimmer>
      <div
        style={{ marginTop: 30, width: 100, borderRadius: 64, height: 10 }}
      ></div>
    </Shimmer>
  );
};
const ShimmerRow = ({ classes }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
      <Shimmer>
        <div
          style={{
            marginTop: 30,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            width: 24,
            height: 16,
          }}
        ></div>
      </Shimmer>

      <div style={{ justifyContent: "space-between", marginLeft: 30 }}>
        <div
          style={{
            marginTop: 10,
            width: 200,
            borderRadius: 64,
          }}
        >
          <Shimmer>
            <div className={classes.line1}></div>
          </Shimmer>
        </div>

        <div
          style={{
            marginTop: 10,
            width: 140,
            borderRadius: 64,
            height: 10,
          }}
        >
          <Shimmer>
            <div className={classes.line2}></div>
          </Shimmer>
        </div>
      </div>
    </div>
  );
};

const StyledApp = injectSheet(StyleSheet)(App);
