import React from "react";
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import Question from "./question";
import { theme } from "../../theme";
import View from "../design/view";
import QuestionContainer from "../design/question-container";
import Container from "@material-ui/core/Container";
import Control from "../design/control";
import Header from "../design/header";
import Timer from "../design/header/timer";
import SubjectTab from "../design/subject-tabs";
import Grid from "@material-ui/core/Grid";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import QuizAnchorList from "../design/drawer/list";
import { map_attempt_to_quiz } from '../../functions'
import { StylesContext } from "@material-ui/styles";
import quiz from "..";

class ExportedClass extends React.Component {
  constructor(props) {
    super(props);
    let quiz = JSON.parse(JSON.stringify(props.quiz));
    if (props.preview) {
      quiz = map_attempt_to_quiz({ quiz: quiz, attempt: props.attempt })
  }
    this.state = {
      current_question_index: 0,
      quiz: quiz,
      subject_id: quiz.quiz_question.length > 0 ? quiz.quiz_question[0].question.subtopic.topic.childsubject.subject.id : 0
    };
  }

  
  onChange = ({ id, value }) => {
    if (this.props.preview)
    return false

    this.setAnswered(this.state.current_question_index)
    value = parseInt(value, 10);

    let { quiz } = this.state;
    let i,
      j,
      k,
      l,
      len1 = quiz.quiz_question.length,
      len2,
      len3,
      tmp_subquestion,
      tmp_option;
     

    for (i = 0; i < len1; i++) {
      len2 = quiz.quiz_question[i].quiz_subquestion.length;

      for (j = 0; j < len2; j++) {
        tmp_subquestion = quiz.quiz_question[i].quiz_subquestion[j].subquestion;
        if (tmp_subquestion.id === id) {
          len3 = quiz.quiz_question[i].quiz_subquestion[j].subquestion.option.length;
          for (k = 0; k < len3; k++) {
            tmp_option = tmp_subquestion.option[k];

            if (tmp_subquestion.type !== "mcq") {
              if (tmp_option.id === value) {
                if (
                  quiz.quiz_question[i].quiz_subquestion[j].subquestion.option[k]
                    .selected
                )
                  quiz.quiz_question[i].quiz_subquestion[j].subquestion.option[
                    k
                  ].selected = false;
                else
                  quiz.quiz_question[i].quiz_subquestion[j].subquestion.option[
                    k
                  ].selected = true;
              } else {
                quiz.quiz_question[i].quiz_subquestion[j].subquestion.option[
                  k
                ].selected = false;
              }
            } else {
              if (tmp_option.id === value) {
                if (
                  quiz.quiz_question[i].quiz_subquestion[j].subquestion.option[k]
                    .selected
                )
                  quiz.quiz_question[i].quiz_subquestion[j].subquestion.option[
                    k
                  ].selected = false;
                else
                  quiz.quiz_question[i].quiz_subquestion[j].subquestion.option[
                    k
                  ].selected = true;
              }
            }
          }
        }
      }
    }
   
    this.setState({ quiz });
    this.props.sendQuizData({ quiz });
  };
  noOfQues = () => {
    return this.state.quiz.quiz_question.length;
  };
  maxCondition = () => {
    return this.state.current_question_index === this.noOfQues() - 1;
  };
  minCondition = () => {
    return this.state.current_question_index === 0;
  };
  onFlagToggle = (index) => {
    if (this.props.preview)
    return false

    const questionsNew = this.state.quiz.quiz_question.slice();
    questionsNew[index].flag = !questionsNew[index].flag;
    const { quiz_question, ...other } = this.state.quiz;
    this.setState({ quiz: { ...other, quiz_question: questionsNew } });
  };
  setVisited = (index) => {
    const questionsNew = this.state.quiz.quiz_question.slice();
    questionsNew[index].visited = true;
    
    if(!questionsNew[index].answered)
    questionsNew[index].answered = false;

    const { quiz_question, ...other } = this.state.quiz;
    this.setState({ quiz: { ...other, quiz_question: questionsNew } });
  };
  setAnswered = (index) => {
    const questionsNew = this.state.quiz.quiz_question.slice();
    questionsNew[index].answered = true;
    const { quiz_question, ...other } = this.state.quiz;
    this.setState({ quiz: { ...other, quiz_question: questionsNew } });
  };

  handleNext = () => {
    const { quiz_question } = this.state.quiz;
    let t = this.state.current_question_index;
    if (!this.maxCondition()) this.setState({ current_question_index: t + 1, subject_id : quiz_question[t + 1].question.subtopic.topic.childsubject.subject.id });
    else this.setState({ current_question_index: 0, subject_id : quiz_question[0].question.subtopic.topic.childsubject.subject.id });
  };
  handlePrev = () => {
    const { quiz_question } = this.state.quiz;
    let t = this.state.current_question_index;
    if (!this.minCondition()) this.setState({ current_question_index: t - 1, subject_id : quiz_question[t - 1].question.subtopic.topic.childsubject.subject.id });
    else this.setState({ current_question_index: this.noOfQues() - 1, subject_id : quiz_question[this.noOfQues() - 1].question.subtopic.topic.childsubject.subject.id });
  };
  goToQues = (no) => {
    const { quiz_question } = this.state.quiz;
    this.setState({ current_question_index: no, subject_id : quiz_question[no].question.subtopic.topic.childsubject.subject.id });
  };

  onMount = (id) => {
    const questionsNew = this.state.quiz.quiz_question.slice();
    questionsNew[id].lastTime = this.currentTime();
    questionsNew[id].total_time = questionsNew[id].total_time || 0;
    const { quiz_question, ...other } = this.state.quiz;
    this.setState({ quiz: { ...other, quiz_question: questionsNew } });
  }

  onUnmount = (id) => {
    let now = this.currentTime();
    const questionsNew = this.state.quiz.quiz_question.slice();
    questionsNew[id].total_time = questionsNew[id].total_time + (now - questionsNew[id].lastTime);
      const { quiz_question, ...other } = this.state.quiz;
      this.setState({ quiz: { ...other, quiz_question: questionsNew } });  
  }

  currentTime() {
    return Math.floor(Date.now() / 1000);
  }

  handleCounter = () => {
    this.props.onSubmit(true)
  }

  handleSubjectTab = (id) => {
    this.setState({ subject_id : id})
    this.findQuestionIndex(id)
  }

  findQuestionIndex = (id) => {
    let index = this.state.quiz.quiz_question.findIndex(x => x.question.subtopic.topic.childsubject.subject.id === id);
    console.log(index)
    this.setState({ current_question_index : parseInt(index,10) })
  }
  
  render() {
    console.log(this.state.quiz)
    console.log(this.state.subject_id)
    const {
      current_question_index,
      quiz: { quiz_question },
      subject_id,
    } = this.state;
    const { width, previewQuestion, show_on_small_screen, preview } = this.props;

    const new_props = {
      current_question_index: current_question_index,
      quiz_question: quiz_question,
      onFlagToggle: this.onFlagToggle,
      onChange: this.onChange,
      setVisited: this.setVisited,
      onMount: this.onMount,
      onUnmount: this.onUnmount
    };

    let show_on_small_screen_style = {};
    if (show_on_small_screen) show_on_small_screen_style = { width: 360 };
    
    let arr = []
    for(let i in quiz_question){
      arr.push(quiz_question[i].question.subtopic.topic.childsubject.subject)
    }
  
    const subjects = Array.from(new Set(arr.map(item => item.id )))
    .map(id => {
      return {
        id : id,
        name : arr.find(s => s.id === id).name
      }
    })
    
    return (
      <div style={show_on_small_screen_style}>
        <div
          id="header"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: 50,
            right: 0,
            overflow: "hidden",
          }}
        >
          <HeaderComp
            quiz={this.state.quiz}
            goToQues={this.goToQues}
            current_question_index={current_question_index}
            preview={preview}
            time={this.state.quiz.total_time} 
            handleCounter={this.handleCounter} 
          />
        </div>
        <div style={{ marginTop: 20 }}>
          {quiz_question && (
            <Container fixed disableGutters>
              {!isWidthUp("md", width) ? (
                <React.Fragment>
                  <SubjectTab subject_id={subject_id} subjects={subjects} handleSubjectTab={this.handleSubjectTab}  />
                  <Comp {...new_props} />
                  <ControlComp {...this} />
                </React.Fragment>
              ) : (
                <Grid container spacing={3}>
                  <Grid item md={8} lg={9}>
                  { !preview && <div style={{ display: "flex", alignItems:'center', justifyContent: 'center'}}>
                  <Grid item md={3} lg={3}>
                  <Avatar style={{width:"100%", backgroundColor: theme.palette.primary.main}} variant="square">
                  <Timer time={this.state.quiz.total_time} handleCounter={this.handleCounter} /> 
                  </Avatar></Grid>
                    </div> }
                  <SubjectTab subject_id={subject_id} subjects={subjects} handleSubjectTab={this.handleSubjectTab}  />
                    <Comp {...new_props} />
                    <ControlComp {...this} preview={preview} />
                  </Grid>

                  {!previewQuestion && (
                    <Grid item md={4} lg={3}>
                      <QuizAnchorList
                        quiz_question={quiz_question}
                        goToQues={this.goToQues}
                        current_question_index={current_question_index}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Container>
          )}
        </div>
      </div>
    );
  }
}

const ControlComp = ({
  preview,
  handleNext,
  props: { onSubmit, previewQuestion },
  handlePrev,
  maxCondition,
  minCondition,
}) => {
  if (!previewQuestion)
    return (
      <Control
        preview={preview}
        handlePrev={handlePrev}
        prevDisabled={minCondition()}
        handleNext={handleNext}
        nextDisabled={maxCondition()}
        onSubmit={onSubmit}
      />
    );
  return <></>;
};

const Comp = ({
  current_question_index,
  quiz_question,
  onFlagToggle,
  onChange,
  setVisited,
  onMount,
  onUnmount
}) => {
 
  return (
    <QuestionContainer
      question_no={current_question_index + 1}
      quiz_question={quiz_question[current_question_index]}
      onFlagToggle={onFlagToggle}
    >
      <Question
        onChange={onChange}
        question={quiz_question[current_question_index].question}
        subquestions={quiz_question[current_question_index].quiz_subquestion}
        current_question_index={current_question_index}
        setVisited={setVisited}
        onMount={onMount}
        onUnmount={onUnmount}
      />
    </QuestionContainer>
  );
};

const HeaderComp = ({ quiz, goToQues, current_question_index, preview, time, handleCounter }) => {
  return (
    <React.Fragment>
      <Header
        title={quiz.title}
        quiz={quiz}
        goToQues={goToQues}
        current_question_index={current_question_index}
        preview={preview}
        time={time} 
        handleCounter={handleCounter}
      />
    </React.Fragment>
  );
};

export default withWidth()(ExportedClass);
