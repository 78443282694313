import { gql } from "@apollo/client";

export const create_classroom_test_batch = gql`
  mutation create_classroom_test_batch(
    $classroom_test_id: Int!
    $batch_id: Int!
  ) {
    create_classroom_test_batch(
      classroom_test_id: $classroom_test_id
      batch_id: $batch_id
    ) {
      success
    }
  }
`;
