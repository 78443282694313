import React, { useContext, useState } from "react";
import axios from 'axios';
import { Container, TextField, Grid, Button } from "@material-ui/core";
import { courseware_upload_url } from "../../../../../config";
import FormLayoutcourseware from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_notification_mutation_gql } from "../../../gql/mutation/create-notification-mutation";
import Typography from "@material-ui/core/Typography";
import SelectNotificationType from "../../components/select-notification-type";
export default () => {
  const [create_notification_mutation] = useMutation(
    create_notification_mutation_gql
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_notification_mutation={create_notification_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_notification_mutation }) => {
  const [values, setValues] = useState({
    title: "",
    type: "",
    description: ""
  });
  const handleInputChange = async (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { title, type, description } = values;

      loader.show();

      let {
        data: {
            create_notification: { success },
        },
      } = await create_notification_mutation({
        variables: {
          title,
          type,
          description
        },
      });

      if (success) snackBar.show("notification created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutcourseware title="Create Notifications">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                name="title"
                fullWidth
                value={values.title}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                multiline
                maxRows={4}
                name="description"
                fullWidth
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>
            
            <Grid item xs={12}>
              <SelectNotificationType
                label="Type"
                name="type"
                value={values.type}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutcourseware>
      </Container>
    </form>
  );
};
