import axios from 'axios'
let isElectron = require("is-electron");
let BrowserWindow;

//115.178.96.190
export default async({ url, title }) => {
  if (!isElectron()) {
    //alert("app is not running in native environmet");
	const res=await axios.get('https://geolocation-db.com/json/')
	const ip=res.data.IPv4;
	if(ip!=='115.178.96.190'){
		alert('app is not running in native environment');
	}else{
    window.open(
  url,
  '_blank' // <- This is what makes it open in a new window.
);
	}
	return false;
  }
  try {
    BrowserWindow = require("electron").remote.BrowserWindow;

    url = `${url}#toolbar=0&zoom=100`;
    const win2 = new BrowserWindow({
      width: 600,
      height: 600,
      title: `${title || ""}`,
    });
    win2.setMenuBarVisibility(false);
    win2.webContents.on("devtools-opened", () => {
      win2.webContents.closeDevTools();
    });
    win2.loadURL(url);
  } catch (e) {
    alert(e);
  }
};
