import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import theme from "../../../../../theme";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../../utils/loader";

export default (props) => {
  let navigate = useNavigate();
  return <Comp {...props} navigate={navigate} />;
};
class Comp extends React.Component {
  state = {
    loading: false,
  };
  setLoader = (loading) => {
    this.setState({ loading });
  };

  join = async () => {
    let { r, navigate } = this.props;

    navigate(`../classroom-test-syllabus-subject/${r.test_batch_id[0].id}`);
  };
  render() {
    const { r } = this.props;

    let style = {};
    style = { color: theme.palette.primary.main };

    return (
      <>
        <ListItem button onClick={this.join}>
          <ListItemIcon style={style}>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText primary={r.name} secondary={r.test_date} />
        </ListItem>
        {this.state.loading && <Loader />}
      </>
    );
  }
}
