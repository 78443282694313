import { gql } from "@apollo/client";

export const view_student_query_gql = gql`
  query view_student($roll_no: String!) {
    view_student(roll_no: $roll_no) {
        mobile
        fname
        lname
        email
        gender
        is_deleted
        is_added
        current_batch {
            name
        }
        profile {
          fee
          fno
          fee_status
          course
          fdate
          name
          gender
          mobile1
          mobile2
          email
          coursetitle
          medium
          less_amount
        }
    }
  }
`;
