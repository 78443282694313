import React from "react";

import TextField from "@material-ui/core/TextField";

export const Input = ({
  label,
  onChange,
  value,
  type = "text",
  maxLength = 255,
}) => {
  return (
    <TextField
      inputProps={{ maxLength: maxLength }}
      type={type}
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
    />
  );
};
