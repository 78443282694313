import React, { useContext, useState,useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange, 
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
 import { Select } from "../../../../../../utils/select";
import Typography from "@material-ui/core/Typography";
 import {api_url,rest_headers} from '../../../../../student/app/srceens/digital-classroom/config'
import {handle_error} from '../../call-center/handle_error'
 
const axios = require('axios');

export default () => {
	 
    const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
       snackBar={snackBar}
      loader={loader}
	  
    />
  );
};
const Comp = ({ loader, snackBar, adminname }) => {
  const [class_list,setList1]=useState([])
  const [childsubject_list,setList2]=useState([])
  const [class_id,setClassId]=useState('')
  const [childsubject_id,setChildSubjectId]=useState('')
  const [name,setName]=useState('')
  
   
  
   useEffect(async()=>{
	  
  fetch();
  },[])
  
  const fetch=async()=>{
	  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/class-childsubject-list',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	    
	 
 
 setList2( data.childsubject_list)
 setList1( data.class_list)

      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 
  
  
  
   

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

if(!class_id)
{
	alert('Please enter class ');
	return;
}
if(!childsubject_id)
{
	alert('Please enter childsubject ');
	return;
}
   
if(!name)
{
	alert('Please enter name');
	return;
}
      loader.show();
const  {data}=await axios({
        url: api_url+'/create-video-lecture-topic',
        method: 'post',
		responseType: 'json',
		data:{
			name:name,
			class_id:class_id,
			childsubject_id:childsubject_id
			 
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	  alert(data.message);
       
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayouttopic title="Create Video Lecture">
          <Grid spacing={3} container>
		     <Grid item xs={12}>
				<ClassList list={class_list} class_id={class_id} setClassId={setClassId}/>
			 </Grid>
			 <Grid item xs={12}>
				<ChildSubjectList list={childsubject_list} 
				childsubject_id={childsubject_id}
				setChildSubjectId={setChildSubjectId}
				/>
			 </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Topic Name"
                variant="outlined"
                name="name"
                value={name}
                onChange={(e)=>setName(e.target.value)}
              />
            </Grid>
           
           

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    </form>
  );
};



const ClassList=({list,class_id,setClassId})=>{
	return(
	<Select
      label={"Select Class"}
      value={class_id}
      name={'class'}
      onChange={(e)=>setClassId(e.target.value)}
      array={list}
    />
	)
}
const ChildSubjectList=({list,childsubject_id,setChildSubjectId})=>{
	return(
	<Select
      label={"Select Subject"}
      value={childsubject_id}
      name={'childsubject_id'}
      onChange={(e)=>setChildSubjectId(e.target.value)}
      array={list}
    />
	)
}