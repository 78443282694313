import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { items } from "./menu-list";
import { useQuery } from "@apollo/client";
import { viewer_gql } from "../../gql/viewer";

import GqlErrorComp from "../../../../utils/gql-error-comp";
import NotAllowed from "../not-allowed";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
   /* [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
	*/
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    padding: 16,
  },
}));

const DashboardLayout = () => {
  const { pathname } = useLocation();

  const current_path = pathname.replace("/admin/app/", "");

  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { loading, error, data } = useQuery(viewer_gql, { fetchPolicy: "no-cache" });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  
  const {
    viewer: { allowed_role,name:adminname },
  } = data;

  const permission_array = get_permission_list(allowed_role, items);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        permission_array={permission_array}
        items={items}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {permission_array.indexOf(current_path) > -1 ? (
              <Outlet />
            ) : (
              <NotAllowed />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;

const get_permission_list = (allowed_role, items) => {
  let i,
    permission_array = [],
    tmp_role,
    j;
  for (let i = 0; i < allowed_role.length; i++) {
    tmp_role = allowed_role[i].role;
    for (j = 0; j < tmp_role.role_permission.length; j++) {
      permission_array.push(tmp_role.role_permission[j].permission.route);
    }
  }

  for (i = 0; i < items.length; i++) {
    if (items[i].required) permission_array.push(items[i].path);
  }

  return permission_array;
};
