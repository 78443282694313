import React,{useState,useEffect,useContext} from 'react';
 import {api_url,rest_headers} from '../config'
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { Select } from "../../../../../../utils/select";
import { Select as SelectMultiple } from "../../../../../../utils/select/select-multiple";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
import moment from 'moment';
 import styles from '../../call-center/table.module.css'; 
const axios = require('axios');


export default (props) => {
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
		  {...props}
    />
  );
};
const Comp = ({ loader, snackBar,...props })=>{
	 
	 const [result,setResult]=useState(null)
  useEffect(async()=>{
	   document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
  fetch1();  
  },[])
  
  const fetch1=async()=>{
	  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/view-mis',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	    
	 
 setResult(data.data)
  

      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 
   if(!result)
   return <></>
 
	return(
	 <Container>
	 <FormLayouttopic title="Reliable MIS">
           

	 <Result9 {...result}/>
      		 <br/>
	<Result4 {...result}/>
	 <br/>
	 <Result5 {...result}/>
	 <br/>
	 <Result6 {...result}/>
	 <br/>
	 <br/>
	 <Result1 {...result}/>
	 <br/>
	 <Result2 {...result}/>
	 <br/>
	 <Result3 {...result}/>
     <br/>
	 <Result7 {...result}/>
    <br/>
	 <Result8 {...result}/>
   
	 
	</FormLayouttopic>
	</Container>
 
	)
}
 
 
 
 
 
 const Result1=({result1})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Application Form (Overall)</th></tr>
          <tr>
            <th>ct</th>
      
			<th  >ISPAID</th>
			
            
          </tr>
        </thead>
		  <tbody>
  
  {
	  result1.map(({ct,ISPAID})=>{
		  
		  return(
		 <tr>
		   <td>{ct}</td> <td>{ISPAID}</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
 
 
 
 
 const Result2=({result2})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Application Form (Class wise)</th></tr>
          <tr>
		  <th>Class</th>
        
            <th>Count</th>
      
			<th>ISPAID</th>
		     
          </tr>
        </thead>
		  <tbody>
  
  {
	  result2.map(({ct,SCLASS,ISPAID})=>{
		  
		  return(
		 <tr>
		  <td>{SCLASS}</td> <td>{ct}</td> <td>{ISPAID}</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
 
 const Result3=({result3})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Application Form (Course wise)</th></tr>
          <tr>
		  <th>Course</th>
        
            <th>Count</th>
      
			<th>ISPAID</th>
		     
          </tr>
        </thead>
		  <tbody>
  
  {
	  result3.map(({ct,course,ISPAID})=>{
		  
		  return(
		 <tr>
		  <td>{course}</td> <td>{ct}</td> <td>{ISPAID}</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
 
    
 const Result4=({result4})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Enrolments (Overall)</th></tr>
          <tr>
            <th>ct</th>
      
			<th  >ISPAID</th>
			
            
          </tr>
        </thead>
		  <tbody>
  
  {
	  result4.map(({ct,fee})=>{
		  
		  return(
		 <tr>
		   <td>{ct}</td> <td>{fee}</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
 
 
  
 const Result5=({result5})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Enrolments (Class wise)</th></tr>
          <tr>
		  <th>Class</th>
        
            <th>Count</th>
      
			<th>ISPAID</th>
		     
          </tr>
        </thead>
		  <tbody>
  
  {
	  result5.map(({ct,classs,fee})=>{
		  
		  return(
		 <tr>
		  <td>{classs}</td> <td>{ct}</td> <td>{fee}</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
 
 
  const Result6=({result6})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Enrolments (Course wise)</th></tr>
          <tr>
		  <th>Course</th>
        
            <th>Count</th>
      
			<th>ISPAID</th>
		     
          </tr>
        </thead>
		  <tbody>
  
  {
	  result6.map(({ct,coursetitle,fee})=>{
		  
		  return(
		 <tr>
		  <td>{coursetitle}</td> <td>{ct}</td> <td>{fee}</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
 
  const Result7=({result7})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > RNET Registrations</th></tr>
          <tr>
		  <th>RNET</th>
        
            <th>Count</th>
      
	 
		     
          </tr>
        </thead>
		  <tbody>
  
  {
	  result7.map(({ct,dt})=>{
		  
		  return(
		 <tr>
		  <td>{dt}</td> <td>{ct}</td>  
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
 
  const Result8=({result8})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > RNET Conversion</th></tr>
          <tr>
		  <th>RNET</th>
        
            <th>Appeared</th>
			<th>Converted</th>
			<th>%</th>
		     
          </tr>
        </thead>
		  <tbody>
  
  {
	  result8.map(({appeared,converted,dt,per})=>{
		  
		  return(
		 <tr>
		  <td>{dt}</td> <td>{appeared}</td> <td>{converted}</td><td>{per}%</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
  const Result9=({result9})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Average Fee</th></tr>
          <tr>
		  <th>Class</th>
        
            <th>Fee</th>
			 
		     
          </tr>
        </thead>
		  <tbody>
  
  {
	  result9.map(({classs,avg})=>{
		  
		  return(
		 <tr>
		  <td>{classs}</td> <td>{avg} /-</td>    
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 