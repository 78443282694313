import React from 'react';
import { useState } from 'react';
import { Button } from '@material-ui/core';

export default function ButtonComp(props) {

    const handleClick = () => {
        props.handleDelete({ id: props.id, test: props.test, index: props.index });
    }

    return (
        <>
            <Button onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Delete</Button>
        </>
    )
}