// export const api_url = 'http://localhost:8060/saper/';
export const api_url = 'https://api-dot-reformexam.el.r.appspot.com/saper/';
// export const url = 'http://localhost:8060/saper-2023/';
export const url = 'https://api-dot-reformexam.el.r.appspot.com/saper-2023/';
// export const url2 = 'http://localhost:8060/saper-2024/';
export const url2 = 'https://api-dot-reformexam.el.r.appspot.com/saper-2024/';

export const rest_headers = {
    headers: {
        APIKEY: '##reliable_rest_api_token##',
        ADMINTOKEN: localStorage.getItem('admin_token')
    }
}