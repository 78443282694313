import React from 'react';

import PaperComp from '@material-ui/core/Paper';

export const Paper = ({ children, style, className }) => {


    return (

        <PaperComp elevation={3} style={style} className={className}>
            {children}
        </PaperComp>


    );
}