import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../reusable-calling-code-project/config'
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { Button, Grid, MenuItem, Select, TextField, Paper, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Profile from './profile'
import { filter } from 'lodash';
const axios = require('axios');
const _ = require("lodash");
export default (props) => {
    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);
    return (
        <Comp
            snackBar={snackBar}
            loader={loader}
            {...props}
        />
    );
};
const Comp = ({ loader, snackBar, ...props }) => {

    const [show, setShow] = useState(false);
    const [res, setRes] = useState(null);
    const [sorted, setSorted] = useState(null);
    const [datesorted, setDateSorted] = useState(null);
    const [display, setDisplay] = useState('ASC');
    const [test, setTest] = useState([]);
    const [init, setInit] = useState(null);
    const [sourcef, setSourceF] = useState([]);
    const [cityf, setCityF] = useState([]);
    const [untouch, setUntouch] = useState();
    const [total, setTotal] = useState();
    const [utmf, setUtm] = useState([]);
    const [touch, setTouched] = useState();
    const [classf, setClassF] = useState([]);
    const [source_filter, setSourceFilter] = useState('null');
    const [fee_filter, setFeeFilter] = useState('null');
    const [test_date_filter, setTestDateFilter] = useState('null');
    const [conversation_filter, setConversationFilter] = useState('null');
    const [callback_filter, setCallbackFilter] = useState('null');
    const [city_filter, setCityFilter] = useState('null');
    const [ispaid_filter, setIsPaid] = useState('null');
    const [utm_filter, setUtmFilter] = useState('null');
    const [class_filter, setClassFilter] = useState('null');
    const [mobile_number, setMobileNo] = useState(null);
    const [follow_ups, setFollowUps] = useState();
    const refresh = () => {
        fetch()
    }

    const manager = false;

    const handleFollowUp = (e) => {
        const day = e.target.value;
        setFollowUps(day);
        const followup = 'true';
        fetch(followup, day);
    }

    const handleLeads = () => {
        const followup = 'false';
        fetch(followup);
    }

    const handleUntouched = async () => {
        const followup = 'untouched';
        fetch(followup);
    }

    const handleSearchMobile = async () => {
        if (mobile_number.length != 10) {
            alert('Enter 10 digit mobile number');
        }
        else {
            const followup = 'leadfinder';
            fetch(followup, mobile_number);
        }
    }



    const fetch = async (followup, val) => {
        try {

            loader.show();
            const { data } = await axios({
                url: api_url + '/lead-data-calling/calling-data',
                method: 'post',
                responseType: 'json',
                data: {
                    manager: manager,
                    followup: followup,
                    val: val,
                },
                ...rest_headers
            })
            console.log(data);
            if (data.Success === 'ok') {

                setTouched(data.leads_count.touched);
                setUntouch(data.leads_count.untouched);
                setTotal(data.leads_count.total);

                let i, j, l_len, array, tmp, len = data.arr.length, touched = 0, untouched = 0, a_name, total;

                let fee_count = 0;

                for (i = 0; i < len; i++) {
                    l_len = data.arr[i].list.length;
                    for (j = 0; j < l_len; j++) {
                        total = data.arr[i].list[j];
                        if (total.SCLASS == 'VI') {
                            data.arr[i].list[j].SCLASS = 6;
                        }
                        if (total.SCLASS == 'VII') {
                            data.arr[i].list[j].SCLASS = 7;
                        }
                        if (total.SCLASS == 'VIII') {
                            data.arr[i].list[j].SCLASS = 8;
                        }
                        if (total.SCLASS == 'IX') {
                            data.arr[i].list[j].SCLASS = 9;
                        }
                        if (total.SCLASS == 'X') {
                            data.arr[i].list[j].SCLASS = 10;
                        }
                        if (total.SCLASS == 'XI') {
                            data.arr[i].list[j].SCLASS = 11;
                        }
                        if (total.SCLASS == 'XII') {
                            data.arr[i].list[j].SCLASS = 12;
                        }
                        if (total.SCLASS == 'XIII' || total.SCLASS == 'XII PASSED') {
                            data.arr[i].list[j].SCLASS = 13;
                        }
                    }
                }

                setRes(data.arr);
                setInit(data.arr);


                for (i = 0; i < len; i++) {
                    l_len = data.arr[i].list.length
                    for (j = 0; j < l_len; j++) {
                        array = data.arr[i].list[j];
                        if (array.last_category == null) {
                            array.last_category = 'null';
                        }
                        if (array.last_remark == null) {
                            array.last_remark = '';
                        }
                        if (array.last_callback == null) {
                            array.last_callback = 'null';
                        }
                        if (array.fee == null) {
                            array.fee = 'N';
                        }
                        if (array.ISPAID == null) {
                            array.ISPAID = 'N';
                        }
                        // if (array.fee == 'Y') {
                        //     fee_count++
                        // }
                    }
                }

                // console.log(fee_count);



                let a = [], b, c = [], d = [], e = [], f = [];
                for (i = 0; i < len; i++) {
                    l_len = data.arr[i].list.length
                    for (j = 0; j < l_len; j++) {
                        b = data.arr[i].list[j];
                        a.push({ test: b[`TEST DATE`] });
                        c.push({ source: b['source'] })
                        d.push({ city: b['CITY'] })
                        e.push({ utm: b['utm_source'] })
                        f.push({ classs: b['SCLASS'] })
                    }
                }

                let tests = _.uniqBy(a, 'test');
                tests = _.sortBy(tests, 'test');
                let sources = _.uniqBy(c, 'source')
                sources = _.sortBy(sources, 'source');
                let citys = _.uniqBy(d, 'city')
                citys = _.sortBy(citys, 'city');
                let utms = _.uniqBy(e, 'utm')
                utms = _.sortBy(utms, 'utm');
                let classes = _.uniqBy(f, 'classs');
                classes = _.sortBy(classes, 'classs')
                console.log(tests);
                setTest(tests);
                setSourceF(sources);
                setCityF(citys);
                setUtm(utms);
                setClassF(classes);
            }
            if (data.Success === 'false') {
                alert(data.message);
            }

        } catch (e) {
            snackBar.show(e);
        } finally {
            loader.hide();
        }
    }

    const handleSort = () => {
        const tmp = res;
        if (sorted === null) {
            const value = [...tmp].sort((a, b) => b.ct - a.ct);
            setRes(value);
            setDisplay('ASC');
            setSorted('asc');
        }
        if (sorted == 'asc') {
            const value = [...tmp].sort((a, b) => a.ct - b.ct);
            setRes(value);
            setDisplay('DESC')
            setSorted(null);
        }
    }

    const handleDateSort = () => {
        const tmp = res;
        if (datesorted === null) {
            const value = [...tmp].sort((a, b) => new Date(b.td) - new Date(a.td));
            setRes(value);
            setDisplay('ASC');
            setDateSorted('asc');
        }
        if (datesorted == 'asc') {
            const value = [...tmp].sort((a, b) => new Date(a.td) - new Date(b.td));
            setRes(value);
            setDisplay('DESC')
            setDateSorted(null);
        }
    }

    const handleUtmSource = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, j, new_group = [], total;
        len = filter_result.length;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                for (j = 0; j < filter_result[i].list.length; j++) {
                    total = filter_result[i].list[j].utm_source;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
            new_group = []
        }
        setRes(filter_result);
        obj.hide();
    }

    const handleSource = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, l_len, j, new_group = [], total;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                l_len = filter_result[i].list.length;
                for (j = 0; j < l_len; j++) {
                    total = filter_result[i].list[j].source;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
        }
        setRes(filter_result);
        obj.hide();
    }

    const handleClass = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, l_len, j, new_group = [], total;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                l_len = filter_result[i].list.length;
                for (j = 0; j < l_len; j++) {
                    total = filter_result[i].list[j].SCLASS;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
        }
        if (obj.filter === "null") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }


    const handleTestDate = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, l_len, j, new_group = [], total;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                l_len = filter_result[i].list.length;
                for (j = 0; j < l_len; j++) {
                    total = filter_result[i].list[j]['TEST DATE'];
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
        }
        if (obj.filter === "null") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }

    const handleCategoryFilter = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, j, new_group = [], total;
        len = filter_result.length;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                for (j = 0; j < filter_result[i].list.length; j++) {
                    total = filter_result[i].list[j].last_category;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            if (new_group.length === 0) {
                alert("No Data Found")
            }
            else {
                filter_result = new_group;
                new_group = []
            }
        }
        if (obj.filter === "null") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }

    const handleCallbackFilter = (obj) => {
        console.log(obj.filter);
        let filter_result = init;
        let len = filter_result.length, i, j, new_group = [], total;
        len = filter_result.length;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                for (j = 0; j < filter_result[i].list.length; j++) {
                    total = filter_result[i].list[j].last_callback;
                    total = moment(total).format('YYYY-MM-DD');
                    console.log(total);
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
        }
        if (obj.filter === "null") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }


    return (
        <div>

            <>

                <InputLabel>Select Follow-Ups</InputLabel>
                <Select style={{ width: '200px' }} value={follow_ups} onChange={handleFollowUp}>
                    <MenuItem value="0">Today</MenuItem>
                    <MenuItem value="1">Yesterday</MenuItem>
                    <MenuItem value="2">Day Before Yes.</MenuItem>
                    <MenuItem value="3">2 Day Before Yes.</MenuItem>
                    <MenuItem value="4">3 Day Before Yes.</MenuItem>
                    <MenuItem value="5">4 Day Before Yes.</MenuItem>
                    <MenuItem value="6">5 Day Before Yes.</MenuItem>
                    <MenuItem value="7">6 Day Before Yes.</MenuItem>
                </Select>
                &nbsp;&nbsp;&nbsp;&nbsp;

                {/* <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleFollowUp}>Follow Up</Button>&nbsp;&nbsp;&nbsp;&nbsp; */}

                <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleLeads}>Show Leads</Button>&nbsp;&nbsp;&nbsp;&nbsp;

                <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleUntouched}>Show Untouched Leads</Button>
                <br /><br />

                <br />
                <Paper style={{ padding: '16px', maxWidth: '200px' }} component={Paper}>
                    <TextField
                        fullWidth
                        onChange={(e) => setMobileNo(e.target.value)}
                        value={mobile_number}
                        label="Search Mobile"
                    />
                    <br /><br />
                    <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleSearchMobile}>Find Lead</Button>
                </Paper>
                <br /><br />
            </>

            {res && <Profile handleClass={handleClass} handleCallbackFilter={handleCallbackFilter} handleCategoryFilter={handleCategoryFilter} handleTestDate={handleTestDate} handleSource={handleSource} handleUtmSource={handleUtmSource} utmf={utmf} classf={classf} lead_total={total} touched={touch} untouched={untouch} handleDateSort={handleDateSort} datesorted={datesorted} handleSort={handleSort} cityf={cityf} sourcef={sourcef} tested={test} sorted={sorted} project='project4' array={res} refresh={refresh} snackBar={snackBar} loader={loader} {...props} />}
        </div>
    )
}
