import * as React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from './title';

function preventDefault(event) {
    event.preventDefault();
}
const useStyles = makeStyles({
    container: {
        marginTop: 15,
        width: '100%'
    },
    table: {
        width: '100%'
    },
    title: {
        margin: 20
    }
});

export default function Orders(props) {
    const rows = props.data;
    const testarr = props.testarray;
    const classes = useStyles();
    // console.log("rows:" + rows);
    return (
        <>
            <TableContainer component={Paper}>
                <div style={{ borderBottom: '1px solid #d6d6d6', height: "auto" }}>
                    <Title>Score Card</Title>
                </div>
                <div style={{ overflowX: 'auto' }}>
                    <Table sx={{ minWidth: 650 }} size="small" className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center' }}></TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Marks Obtained</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Maximum Marks</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Average Marks</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Highest Marks</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Rank Obtained</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Percentage</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Percentile Scored</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((data) => (
                                <TableRow>
                                    <TableCell ><b>{data.name}</b></TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{data.yourmarks}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{data.maxmarks}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{data.avgscore}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{data.highscore}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{data.subject_rank}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{data.subject_percentage}%</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{data.subject_percentile}</TableCell>
                                </TableRow>
                            ))}
                            {testarr.map((arr) => (
                                <TableRow>
                                    <TableCell><b>Total</b></TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{arr.overall_marks}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{arr.test_maximum_marks}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{arr.avg_marks}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{arr.highest_marks}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{arr.overall_rank}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{arr.overall_percentage}%</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{arr.overall_percentile}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </TableContainer>
        </>
    );
}


/*
    <TableBody>
                            <TableRow>
                                <TableCell></TableCell>
                                {rows.map((data) => (
                                    <TableCell><b>{data.name}</b></TableCell>
                                ))}
                                <TableCell style={{ textAlign: 'left' }}><b>Total</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >Rank Obtained</TableCell>
                                {rows.map((data) => (
                                    <TableCell >{data.subject_rank}</TableCell>
                                ))}
                                {testarr.map((arr) => (
                                    <TableCell style={{ textAlign: 'left' }}>{arr.overall_rank}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell >Percentage Scored</TableCell>
                                {rows.map((data) => (
                                    <TableCell >{data.subject_percentage}%</TableCell>
                                ))}
                                {testarr.map((arr) => (
                                    <TableCell style={{ textAlign: 'left' }}>{arr.overall_percentage}%</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell >Percentile Obtained</TableCell>
                                {rows.map((data) => (
                                    <TableCell >{data.subject_percentile}</TableCell>
                                ))}
                                {testarr.map((arr) => (
                                    <TableCell style={{ textAlign: 'left' }}>{arr.overall_percentile}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell >Marks Obtained</TableCell>
                                {rows.map((data) => (
                                    <TableCell >{data.yourmarks}</TableCell>
                                ))}
                                {testarr.map((arr) => (
                                    <TableCell style={{ textAlign: 'left' }}>{arr.overall_marks}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell >Highest Score</TableCell>
                                {rows.map((data) => (
                                    <TableCell >{data.highscore}</TableCell>
                                ))}
                                {testarr.map((arr) => (
                                    <TableCell style={{ textAlign: 'left' }}>{arr.highest_marks}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell >Average Score</TableCell>
                                {rows.map((data) => (
                                    <TableCell >{data.avgscore}</TableCell>
                                ))}
                                {testarr.map((arr) => (
                                    <TableCell style={{ textAlign: 'left' }}>{arr.avg_marks}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell>Maximum Marks</TableCell>
                                {rows.map((data) => (
                                    <TableCell >{data.maxmarks}</TableCell>
                                ))}
                                {testarr.map((arr) => (
                                    <TableCell>{arr.test_maximum_marks}</TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
*/