import React from 'react'
import ResultPage from "../../../../../student/app/srceens/result-portal/result-page/index.js";
import { useLocation } from 'react-router-dom';

export default function GetStudentResult() {

    const { state } = useLocation();


    return (
        <>
            <ResultPage admin id={state.id} rollno={state.rollno} />
        </>
    )
}