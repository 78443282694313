import React from "react";
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
} from "react-feather";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import CreateAdmin from "../srceens/create-admin";
import CreateStudent from "../srceens/create-student";
import CreateBatch from "../srceens/create-batch";
import CreateBatchCourseware from "../srceens/create-batch-courseware";
import CreateBatchNotification from "../srceens/create-batch-notification";
import Dashboard from "../srceens/dashboard";
import CreateSession from "../srceens/create-session";
import CreateDivision from "../srceens/create-division";
import CreateSubject from "../srceens/create-subject";
import CreateChildSubject from "../srceens/create-childsubject";
import CreateCourse from "../srceens/create-course";
import CreateClass from "../srceens/create-class";
import CreatePhase from "../srceens/create-phase";
import CreateTopic from "../srceens/create-topic";
import CreateClassTopic from "../srceens/create-class-topic";
import CreateLiveClass from "../srceens/create-liveclass";
import CreateLiveClassJr from "../srceens/create-liveclass-jr";
import CreateLatestNews from "../srceens/create-latest-news";
import CreateCourseware from "../srceens/create-courseware";
import CreateNotifications from "../srceens/create-notifications";
import CreateClassroomTestType from "../srceens/create-classroom-test-type";
import CreateClassroomTest from "../srceens/create-classroom-test";
import CreateClassroomTestSubject from "../srceens/create-classroom-test-subject";
import CreateClassroomTestBatch from "../srceens/create-classroom-test-batch";
//import CreateClassroomTestChildSubject from "../srceens/create-classroom-test-childsubject";
import CreateQuestion from "../srceens/question-bank/create-question";

import CreateClassroomTestTopic from "../srceens/create-classroom-test-topic";
import ViewFacultyTimeTable from "../srceens/view-faculty-time-table";
import CreateTestAnalysis from "../srceens/create-test-analysis";
import ChangeBatch from "../srceens/change-batch";
import ViewStudent from "../srceens/view-student";
import DeleteStudent from "../srceens/delete-student";
import ViewLiveClass from '../srceens/view-live-classes';
import ViewAttendence from "../srceens/view-attendence";
import Logout from "../logout";

//-- created-by-anurag
import CallCenterViewLead from "../srceens/call-center/view-lead";
import CallCenterCreateLead from "../srceens/call-center/create-lead";
import CallCenterManager from "../srceens/call-center/manager";
import CallCenterCreateCallback from "../srceens/call-center/create-callback";
import CallCenterViewInteraction from "../srceens/call-center/view-interaction";
import CallCenterReformCallingReport1 from "../srceens/call-center/reform-calling/view-lead";
import CallCenterReformCallingAllot from "../srceens/call-center/reform-calling/allot-lead";


import SMSPortalSendSMS from "../srceens/sms-portal/send-sms";
import ReformBulkEntry from "../srceens/reform/bulk-entry";
import ReformCreateSchool from "../srceens/reform/create-school";

import ReliableKotaAppCreateVideo from "../srceens/reliablekotaapp/create-video";
import ReliableKotaAppShareVideo from "../srceens/reliablekotaapp/share-video";
import ReliableKotaAppCreateVideoLectureTopic from "../srceens/reliablekotaapp/create-video-lecture-topic";

import AttendencePortalMarkAttendance from "../srceens/attendance-portal/mark-attendance";
import AttendencePortalUploadTT from "../srceens/attendance-portal/upload-tt";

import ChangeMobileNo from "../srceens/reliablekotaapp/change-mobile-no";
import RaiseTicketAdmin from "../srceens/reliablekotaapp/raise-ticket-admin";
import ManageTicketAdmin from "../srceens/reliablekotaapp/manage-ticket-admin";

import FeeCollection from "../srceens/reliablekotaapp/fee-collection";

import CreateEELead from "../srceens/call-center/create-ee-lead";
import MISMain from "../srceens/mis/main";
import MISBW from "../srceens/mis/batch-wise-count";
import AttendanceDataCalling from "../srceens/attendance-data-calling";
import FeeDataCalling from "../srceens/fee-data-calling";

//-- created-by-anurag


// Created by Sakshi

import SMSPortalSMS from "../srceens/sms-portal/sms";
import AttendanceFeeMis from "../srceens/attendance-fee-mis";
import GetResult from "../srceens/saper/get-student-result";
import SMSPortalSMSDetail from "../srceens/sms-portal/student-sms-details";
import AttendancePortalMarkAttendance2023 from "../srceens/attendance-portal/mark-attendance/mark-attendance-2023";
import Uploadtt2023 from "../srceens/attendance-portal/upload-tt/upload-tt-2023";
import Saper2023 from "../srceens/saper/create-saper-2023";
import AdmissionPunch from "../srceens/admission-punch/admission-data";
import FrontOfficeMis from "../srceens/front-office-mis/mis";
import AbsentData from "../srceens/attendance-data-calling/absent-data";
import JnvDetails from '../srceens/jnv-attendance/jnv-details';

// Created by Sakshi



//created by Manan
import CreateResult from "../srceens/result-app/create-result";
import ViewTest from "../srceens/result-app/view-test";
import ViewStudentResult from "../srceens/result-app/view-student-result";
import GetStudentResult from "../srceens/result-app/get-student-result";
import JeeMainDataCalling from "../srceens/jee-main-data-calling";
import ViewStudentAttendance from "../srceens/view-student-attendance";
import LeadDataCalling from "../srceens/lead-data-calling";
import AssigningLeads from "../srceens/assigning-leads";
import CZentrixFileUpload from "../srceens/c-zentrix-file-upload";
import ViewCallingReports from "../srceens/view-calling-reports";
import ViewAttendanceSystem from "../srceens/view-attendace-system";
import ReliableKotaAppShareVideoJunior from "../srceens/reliablekotaapp/share-video-junior";
import ReliableKotaAppCreateVideoJunior from "../srceens/reliablekotaapp/create-video-junior";
import Saper from "../srceens/saper";
import ViewUploadedTest from "../srceens/view-uploaded-test";
import CourseWiseCount from "../srceens/mis/course-wise-count";
import CallingPanel from "../srceens/calling-panel";
import CallingPanelReport from "../srceens/calling-panel/calling-panel-report";
import AttendanceDataReport from "../srceens/attendance-data-calling/attendance-data-report";
import PTMAttendance from "../srceens/view-student-attendance/ptm-attendance";
import FormPunch from "../srceens/admission-punch/form-punch";
import SamvaadCallingMIS from "../srceens/mis/samvaad-calling-mis";
import ConversionMis from "../srceens/mis/conversion-mis";
import DepartmentMIS from "../srceens/mis/department-mis";
import ViewLeadDetails from "../srceens/lead-data-calling/view-lead-details";
import ShowUploadedVideo from "../srceens/reliablekotaapp/show-uploaded-video";
import EnquiryDataMIS from "../srceens/mis/enquiry-data-mis";
import EnquirySourceMIS from "../srceens/mis/enquiry-source-mis";
import FBLeadsUpload from "../srceens/c-zentrix-file-upload/fb-leads-upload"
import MarkYogaAttendance from "../srceens/view-student-attendance/mark-yoga-attendance";
import UploadPhoto from "../srceens/upload-photo";
import CreateICard from "../srceens/upload-photo/create-i-card";
import VyaapakCalling from "../srceens/vyaapak-calling";
import VyaapakTransferLeads from "../srceens/vyaapak-calling/vyaapak-transfer-leads";
import VyaapakCallingReviews from "../srceens/vyaapak-calling/vyaapak-calling-reviews";
import VijetaCalling from "../srceens/vijeta-calling";
import VijetaTransferLeads from "../srceens/vijeta-calling/vijeta-leads-transfer";
import VijetaCallingReviews from "../srceens/vijeta-calling/vijeta-calling-reviews";
import RnetMis from "../srceens/mis/rnet-mis";
import JuniorDivisionCalling from "../srceens/junior-division-calling";
import Saper2024 from "../srceens/saper/create-saper-2024";
import ViewStudentScholarship from "../srceens/view-student-scholarship/indes";
import ReviewConversation from "../srceens/review-conversation";
import CourseCodeMIS from "../srceens/mis/course-code-mis";
import MergeBatch from "../srceens/change-batch/merge-batch";
import AgentData from "../srceens/mis/agent-data";
//created by Manan 





const array = [
  {
    path: "create-admin",
    title: "Create Admin",
    icon: ChevronRightIcon,
    element: <CreateAdmin />,
  },
  {
    path: "create-student",
    title: "Create Student",
    icon: ChevronRightIcon,
    element: <CreateStudent />,
  },
  {
    path: "create-session",
    title: "Create Session",
    icon: ChevronRightIcon,
    element: <CreateSession />,
  },
  {
    path: "create-division",
    title: "Create Division",
    icon: ChevronRightIcon,
    element: <CreateDivision />,
  },
  {
    path: "create-course",
    title: "Create Course",
    icon: ChevronRightIcon,
    element: <CreateCourse />,
  },
  {
    path: "create-phase",
    title: "Create Phase",
    icon: ChevronRightIcon,
    element: <CreatePhase />,
  },
  {
    path: "create-batch",
    title: "Create Batch",
    icon: ChevronRightIcon,
    element: <CreateBatch />,
  },
  {
    path: "create-batch-courseware",
    title: "Create Batch Courseware",
    icon: ChevronRightIcon,
    element: <CreateBatchCourseware />,
  },
  {
    path: "create-batch-notification",
    title: "Create Batch Notifications",
    icon: ChevronRightIcon,
    element: <CreateBatchNotification />,
  },
  {
    path: "create-subject",
    title: "Create Subject",
    icon: ChevronRightIcon,
    element: <CreateSubject />,
  },
  {
    path: "create-childsubject",
    title: "Create Child Subject",
    icon: ChevronRightIcon,
    element: <CreateChildSubject />,
  },
  {
    path: "create-class",
    title: "Create Class",
    icon: ChevronRightIcon,
    element: <CreateClass />,
  },
  {
    path: "create-topic",
    title: "Create Topic",
    icon: ChevronRightIcon,
    element: <CreateTopic />,
  },
  // {
  //   path: "create-subtopic",
  //   title: "Create Subtopic",
  //   icon: ChevronRightIcon,
  //   element: <CreateSubtopic />,
  // },
  {
    path: "create-classtopic",
    title: "Create Class Topic",
    icon: ChevronRightIcon,
    element: <CreateClassTopic />,
  },
  {
    path: "create-liveclass",
    title: "Create Live Class",
    icon: ChevronRightIcon,
    element: <CreateLiveClass />,
  },
  {
    path: "create-liveclass-jr",
    title: "Create Live Class Jr",
    icon: ChevronRightIcon,
    element: <CreateLiveClassJr />,
  },
  {
    path: "create-latest-news",
    title: "Create Latest News",
    icon: ChevronRightIcon,
    element: <CreateLatestNews />,
  },
  {
    path: "create-courseware",
    title: "Create Courseware",
    icon: ChevronRightIcon,
    element: <CreateCourseware />,
  },
  {
    path: "create-notifications",
    title: "Create Notifications",
    icon: ChevronRightIcon,
    element: <CreateNotifications />,
  },
  {
    path: "create-test-analysis",
    title: "Create Test Analysis",
    icon: ChevronRightIcon,
    element: <CreateTestAnalysis />,
  },
  {
    path: "create-classroom-test-type",
    title: "Create Classroom Test Type",
    icon: ChevronRightIcon,
    element: <CreateClassroomTestType />,
  },
  {
    path: "create-classroom-test",
    title: "Create Classroom Test",
    icon: ChevronRightIcon,
    element: <CreateClassroomTest />,
  },
  {
    path: "create-classroom-test-batch",
    title: "Create Classroom Test Batch",
    icon: ChevronRightIcon,
    element: <CreateClassroomTestBatch />,
  },
  {
    path: "create-classroom-test-subject",
    title: "Create Classroom Test Subject",
    icon: ChevronRightIcon,
    element: <CreateClassroomTestSubject />,
  },
  // {
  //   path: "create-classroom-test-childsubject",
  //   title: "Create Classroom Test ChildSubject",
  //   icon: ChevronRightIcon,
  //   element: <CreateClassroomTestChildSubject />,
  // },

  {
    path: "create-classroom-test-topic",
    title: "Create Classroom Test Topic",
    icon: ChevronRightIcon,
    element: <CreateClassroomTestTopic />,
  },

  {
    path: "create-question",
    title: "Create Question",
    icon: ChevronRightIcon,
    element: <CreateQuestion />,
  },

  {
    path: "view-faculty-time-table",
    title: "View Faculty Time Table",
    icon: ChevronRightIcon,
    element: <ViewFacultyTimeTable />,
  },
  {
    path: "change-batch",
    title: "Change Student Batch",
    icon: ChevronRightIcon,
    element: <ChangeBatch />,
  },
  {
    path: "view-student",
    title: "View Student",
    icon: ChevronRightIcon,
    element: <ViewStudent />,
  },
  {
    path: "delete-student",
    title: "Delete Student",
    icon: ChevronRightIcon,
    element: <DeleteStudent />,
  },
  {
    path: "view-live-class",
    title: "View Live Classes",
    icon: ChevronRightIcon,
    element: <ViewLiveClass />
  },
  {
    path: "view-attendence",
    title: "View Attendence",
    icon: ChevronRightIcon,
    element: <ViewAttendence />
  },
  // ==created-by-anurag

  {
    path: "call-center/view-lead",
    title: "Create/View Lead",
    icon: ChevronRightIcon,
    element: <CallCenterViewLead />,
  },
  {
    path: "call-center/view-interaction",
    title: "View Interaction",
    icon: ChevronRightIcon,
    element: <CallCenterViewInteraction />,
  },
  {
    path: "call-center/reform-calling/view-lead",
    title: "View Reform Leads",
    icon: ChevronRightIcon,
    element: <CallCenterReformCallingReport1 />,
  },
  {
    path: "call-center/reform-calling/allot-lead",
    title: "Lead Allocation",
    icon: ChevronRightIcon,
    element: <CallCenterReformCallingAllot />,
  },

  /* {	
     path: "call-center/create-lead",	
     title: "Create Lead",	
     icon: ChevronRightIcon,	
     element: <CallCenterCreateLead />,	
   },*/
  {
    path: "call-center/manager",
    title: "Manager",
    icon: ChevronRightIcon,
    element: <CallCenterManager />,
  },
  {
    path: "reform/bulk-entry",
    title: "Reform-Bulk Entry",
    icon: ChevronRightIcon,
    element: <ReformBulkEntry />,
  },
  {
    path: "reform/create-school",
    title: "Reform-Create School",
    icon: ChevronRightIcon,
    element: <ReformCreateSchool />,
  },
  {
    path: "reliablekotapp/create-video",
    title: "Create Video",
    icon: ChevronRightIcon,
    element: <ReliableKotaAppCreateVideo />,
  },
  {
    path: "reliablekotapp/share-video",
    title: "Share Video",
    icon: ChevronRightIcon,
    element: <ReliableKotaAppShareVideo />,
  },
  {
    path: "reliablekotapp/create-video-lecture-topic",
    title: "Create Video Lecture Topic",
    icon: ChevronRightIcon,
    element: <ReliableKotaAppCreateVideoLectureTopic />,
  },
  {
    path: "reliablekotaapp/change-mobile-no",
    title: "Change Mobile No.",
    icon: ChevronRightIcon,
    element: <ChangeMobileNo />,
  },
  {
    path: "reliablekotaapp/raise-ticket-admin",
    title: "Raise Ticket",
    icon: ChevronRightIcon,
    element: <RaiseTicketAdmin />,
  },
  {
    path: "reliablekotaapp/manage-ticket-admin",
    title: "Manage Tickets",
    icon: ChevronRightIcon,
    element: <ManageTicketAdmin />,
  },
  {
    path: "sms-portal/send-sms",
    title: "SMS Portal",
    icon: ChevronRightIcon,
    element: <SMSPortalSendSMS />,
  },
  {
    path: "attendance-portal/mark-attendance",
    title: "Attendence Portal- Mark Attendance",
    icon: ChevronRightIcon,
    element: <AttendencePortalMarkAttendance />,
  },
  {
    path: "attendance-portal/upload-tt",
    title: "Attendence Portal- Upload Time Table",
    icon: ChevronRightIcon,
    element: <AttendencePortalUploadTT />,
  },
  {
    path: "call-center/create-ee-lead",
    title: "Create Lead",
    icon: ChevronRightIcon,
    element: <CreateEELead />,
  }, {
    path: "mis/main",
    title: "View MIS",
    icon: ChevronRightIcon,
    element: <MISMain />,
  },
  {
    path: "mis/batch-wise-count",
    title: "View Batch Wise Count",
    icon: ChevronRightIcon,
    element: <MISBW />,
  },
  {
    path: "reliablekotaapp/fee-collection",
    title: "Fee Collection",
    icon: ChevronRightIcon,
    element: <FeeCollection />,
  },
  {
    path: "result-app/create-result",
    title: "Create Test",
    icon: ChevronRightIcon,
    element: <CreateResult />,

  },
  {
    path: "result-app/view-test",
    title: "View Test",
    icon: ChevronRightIcon,
    element: <ViewTest />,

  }, {
    path: "result-app/view-student-result",
    title: "View Student Result",
    icon: ChevronRightIcon,
    element: <ViewStudentResult />,

  },
  {
    path: "result-app/get-student-result",
    // title: "View Student Result",
    // icon: ChevronRightIcon,
    element: <GetStudentResult />,

  },
  {
    path: "jee-main-data-calling/jee-main-calling",
    title: "Jee Main Data Calling",
    icon: ChevronRightIcon,
    element: <JeeMainDataCalling />,
    // required: true,
  },
  {
    path: "attendance-data-calling",
    title: "Attendance Data Calling",
    icon: ChevronRightIcon,
    element: <AttendanceDataCalling />,

  },
  {
    path: "fee-data-calling",
    title: "Fee Data Calling",
    icon: ChevronRightIcon,
    element: <FeeDataCalling />,

  },
  {
    path: "view-student-attendance",
    title: "View Student Attendance",
    icon: ChevronRightIcon,
    element: <ViewStudentAttendance />,
  },
  {
    path: "lead-data-calling",
    title: "Lead Data Calling",
    icon: ChevronRightIcon,
    element: <LeadDataCalling />
  },
  {
    path: "assigning-leads",
    title: "Assigning Leads",
    icon: ChevronRightIcon,
    element: <AssigningLeads />,
  },
  {
    path: "c-zentrix-file-upload/upload-file",
    title: "C-Zentrix File Upload",
    icon: ChevronRightIcon,
    element: <CZentrixFileUpload />,
  },
  {
    path: "view-calling-reports/calling-reports",
    title: "View Calling Report",
    icon: ChevronRightIcon,
    element: <ViewCallingReports />,
  },
  {
    path: "view-attendance-system",
    title: "Attendance System",
    icon: ChevronRightIcon,
    element: <ViewAttendanceSystem />,
    // required: true,
  },
  {
    path: "reliablekotapp/share-video-junior",
    title: "Share Video Junior",
    icon: ChevronRightIcon,
    element: <ReliableKotaAppShareVideoJunior />,
    // required: true,
  },
  {
    path: "reliablekotapp/create-video-junior",
    title: "Create Video Junior",
    icon: ChevronRightIcon,
    element: <ReliableKotaAppCreateVideoJunior />,
  },
  {
    path: "sms-portal/sms",
    title: "SMS Portal",
    icon: ChevronRightIcon,
    element: <SMSPortalSMS />,
    // required: true
  },
  {
    path: "sms-portal/student-sms-detail",
    title: "Student SMS Detail",
    icon: ChevronRightIcon,
    element: <SMSPortalSMSDetail />,
    // required: true
  },
  {
    path: "attendance-fee-mis",
    title: "Attendance Fee mis",
    icon: ChevronRightIcon,
    element: <AttendanceFeeMis />,
    // required: true,
  },
  {
    path: "create-saper",
    title: "Create Saper",
    icon: ChevronRightIcon,
    element: <Saper />,
    // required: true,
  },
  {
    path: "get-student-result",
    title: "Get Student Result",
    icon: ChevronRightIcon,
    element: <GetResult />,
    // required: true,
  },
  {
    path: "view-uploaded-test",
    title: "View Uploaded Test",
    icon: ChevronRightIcon,
    element: <ViewUploadedTest />,
    // required: true,
  },
  {
    path: "sms-portal/student-sms-detail",
    title: "Student SMS Detail",
    icon: ChevronRightIcon,
    element: <SMSPortalSMSDetail />,
    // required: true
  },
  {
    path: "mark-attendance-2023",
    title: "Mark Attendance-2023",
    icon: ChevronRightIcon,
    element: <AttendancePortalMarkAttendance2023 />,
    // required: true,
  },
  {
    path: "upload-tt-2023",
    title: "Upload Time Table-2023",
    icon: ChevronRightIcon,
    element: <Uploadtt2023 />,
    // required: true,
  },
  {
    path: "course-wise-mis",
    title: "Course Wise MIS",
    icon: ChevronRightIcon,
    element: <CourseWiseCount />,
    // required: true,
  },
  {
    path: "calling-panel",
    title: "Calling Panel",
    icon: ChevronRightIcon,
    element: <CallingPanel />,
    // required: true,
  },
  {
    path: "calling-panel-report",
    title: "Calling Panel Report",
    icon: ChevronRightIcon,
    element: <CallingPanelReport />,
    // required: true,
  },
  {
    path: "create-saper-2023-24",
    title: "Create Saper 2023",
    icon: ChevronRightIcon,
    element: <Saper2023 />,
    // required: true,
  },
  {
    path: "attendance-data-calling/attendance-data-report",
    title: "Attendance Data Report",
    icon: ChevronRightIcon,
    element: <AttendanceDataReport />,
    // required: true,
  },
  {
    path: "view-student-attendance/ptm-attendance",
    title: "Mark PTM Attendance",
    icon: ChevronRightIcon,
    element: <PTMAttendance />,
    // required: true,
  },
  {
    path: "mis/conversion-mis",
    title: "MIS Dashboard",
    icon: ChevronRightIcon,
    element: <ConversionMis />,
    // required: true,
  },
  {
    path: "admission-punch",
    title: "Admission Punch",
    icon: ChevronRightIcon,
    element: <AdmissionPunch />,
    // required: true,
  },
  {
    path: "admission-punch/form-punch",
    title: "Form Punch",
    icon: ChevronRightIcon,
    element: <FormPunch />,
    // required: true,
  },
  {
    path: "mis/samvaad-calling-mis",
    title: "Samvaad Calling MIS",
    icon: ChevronRightIcon,
    element: <SamvaadCallingMIS />,
    // required: true,
  },
  {
    path: "front-office-mis",
    title: "Front-Office-Mis",
    icon: ChevronRightIcon,
    element: <FrontOfficeMis />,
    // required: true,
  },
  {
    path: "department-wise-mis",
    title: "Department Wise Mis",
    icon: ChevronRightIcon,
    element: <DepartmentMIS />,
    // required: true,
  },
  {
    path: "lead-data-calling/view-lead-details",
    title: "View Lead Details",
    icon: ChevronRightIcon,
    element: <ViewLeadDetails />,
    // required: true,
  },
  {
    path: "reliablekotaapp/show-uploaded-video",
    title: "View Uploaded Video",
    icon: ChevronRightIcon,
    element: <ShowUploadedVideo />,
    // required: true,
  },
  {
    path: "absent-data",
    title: "Download Absentees Excel",
    icon: ChevronRightIcon,
    element: <AbsentData />,
    // required: true,
  },
  {
    path: "jnv-details",
    title: "JNV Details",
    icon: ChevronRightIcon,
    element: <JnvDetails />,
    // required: true,
  },
  {
    path: "mis/enquiry-data-mis",
    title: "Enquiry Data MIS",
    icon: ChevronRightIcon,
    element: <EnquiryDataMIS />,
    // required: true,
  },
  {
    path: "mis/enquiry-source-mis",
    title: "Enquiry Source MIS",
    icon: ChevronRightIcon,
    element: <EnquirySourceMIS />,
    // required: true,
  },
  {
    path: "fb-leads-upload",
    title: "Upload FB Leads",
    icon: ChevronRightIcon,
    element: <FBLeadsUpload />,
    // required: true,
  },
  {
    path: "mark-yoga-attendance",
    title: "Mark Yoga Attendance",
    icon: ChevronRightIcon,
    element: <MarkYogaAttendance />,
    // required: true,
  },
  {
    path: "upload-photo",
    title: "Upload Photo",
    icon: ChevronRightIcon,
    element: <UploadPhoto />,
    // required: true,
  },
  {
    path: "create-icard",
    title: "Create ICard",
    icon: ChevronRightIcon,
    element: <CreateICard />,
    // required: true,
  }, {
    path: "vyaapak-calling",
    title: "Vyaapak 2024",
    icon: ChevronRightIcon,
    element: <VyaapakCalling />,
    // required: true,
  },
  {
    path: "vyaapak-transfer-leads",
    title: "Vyaapak Leads Transfer",
    icon: ChevronRightIcon,
    element: <VyaapakTransferLeads />,
    // required: true,
  },
  {
    path: "vyaapak-calling-reviews",
    title: "Vyaapak Calling Reviews",
    icon: ChevronRightIcon,
    element: <VyaapakCallingReviews />,
    // required: true,
  },
  {
    path: "vijeta-calling",
    title: "Vijeta 2024",
    icon: ChevronRightIcon,
    element: <VijetaCalling />,
    // required: true,
  },
  {
    path: "vijeta-transfer-leads",
    title: "Vijeta Leads Transfer",
    icon: ChevronRightIcon,
    element: <VijetaTransferLeads />,
    // required: true,
  },
  {
    path: "vijeta-calling-reviews",
    title: "Vijeta Calling Reviews",
    icon: ChevronRightIcon,
    element: <VijetaCallingReviews />,
    // required: true,
  },
  {
    path: "mis/rnet-mis",
    title: "RNet MIS",
    icon: ChevronRightIcon,
    element: <RnetMis />,
    // required: true,
  },
  {
    path: "junior-division-calling",
    title: "Junior Division Calling",
    icon: ChevronRightIcon,
    element: <JuniorDivisionCalling />,
    // required: true,
  },
  {
    path: "create-saper-2024-25",
    title: "Create Saper 2024",
    icon: ChevronRightIcon,
    element: <Saper2024 />,
    // required: true,
  },
  {
    path: "view-student-scholarship",
    title: "View Student Scholarship",
    icon: ChevronRightIcon,
    element: <ViewStudentScholarship />,
    // required: true,
  },
  {
    path: "review-conversation",
    title: "Review Conversation",
    icon: ChevronRightIcon,
    element: <ReviewConversation />,
    // required: true,
  },
  {
    path: "course-code-data",
    title: "Course Code MIS",
    icon: ChevronRightIcon,
    element: <CourseCodeMIS />,
    // required: true,
  },
  {
    path: "merge-batch",
    title: "Merge Batch",
    icon: ChevronRightIcon,
    element: <MergeBatch />,
    // required: true,
  },
  {
    path: "agent-data",
    title: "Agent Data",
    icon: ChevronRightIcon,
    element: <AgentData />,
    // required: true,
  },
  /*{	
    path: "call-center/create-callback",	
    title: "CallBack",	
    icon: ChevronRightIcon,	
    element: <CallCenterCreateCallback />,	
  },
  */
  //===
];

export const items = [
  {
    path: "dashboard",
    title: "Dashboard",
    icon: BarChartIcon,
    element: <Dashboard />,
    required: true,
  },
  ...array,
  {
    path: "logout",
    icon: PowerSettingsNewIcon,
    title: "Logout",
    element: <Logout />,
    required: true,
  },
];
