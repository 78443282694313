import React from "react";
import FormLayoutAdmin from "../../../../../../utils/form-layout-admin";
import { Container, TextField, Grid, Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import { CSVReader } from 'react-papaparse';
import axios from 'axios';
import ViewTest from "../view-test";
import { useNavigate } from "react-router-dom";
import { Routes, Route, useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Alert from "@material-ui/core/Alert";
import { api_endpoint } from '../config';
import { LoaderContext } from "../../../../../../context/LoaderContext";

export default function CreateResult() {

    let navigate = useNavigate();
    const loader = useContext(LoaderContext);

    var today = new Date();
    var day = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    var str = `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}`;
    // console.log(str);

    const [test, setTest] = useState('');
    const [date, setDate] = useState('');
    const [array, setArray] = useState([]);
    const [list, setList] = useState([]);
    const [error, setError] = useState(false);
    const [width, setWidth] = useState();
    const [paper_type, setPaperType] = useState('');
    const [quesno, setQuesNo] = useState();


    var arr = [];
    var issue = [];

    const handleClick = async () => {
        console.log("Test Name: " + test);
        console.log("Test Date: " + date);
        try {
            loader.show();
            if (test.length === 0) {
                alert("Enter Test Name");
                return false;
            }
            if (date.length === 0) {
                alert("Enter Date of the above mentioned Test");
                return false;
            }
            // if (paper_type.length === 0) {
            //     alert("Enter Type of Paper");
            //     return false;
            // }
            // if (quesno > 90) {
            //     alert("Number of questions exceeded, should not more than 90 in special case");
            //     return false;
            // }

            /*if (list.length === 0) {
                alert("Upload the test_info CSV File to proceed further");
                return false;
            }
            if (test && date) {
                if (width === 7) {*/
            const { data: data2 } = await axios({
                url: api_endpoint + '/create-test',
                method: "post",
                responseType: "json",
                data: {
                    // value: list,
                    test: test,
                    date: date,
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            if (data2.Success === 'ok') {
                localStorage.setItem('test', test);
                navigate(`/admin/app/result-app/view-test`);
            }
            /*}
            else {
                alert("Enter Correct CSV File");
                return false;
            }
        }
        else {
            alert('Enter Test and Date Correctly');
        }*/
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    return (
        <div>
            <form>
                <Container>
                    <FormLayoutAdmin title="Create Result">
                        <TextField
                            type="text"
                            label="Test Name"
                            value={test}
                            placeholder="Enter Test Name"
                            onChange={(e) => setTest(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            type="date"
                            value={date}
                            inputProps={{ max: str }}
                            onChange={(e) => setDate(e.target.value)}
                        /><br />
                        <br />
                        {/* <br />
                        <br />
                        <CSVReader onDrop={handleOnDrop}>
                            <span>Drop Test-Info CSV file here or click to upload.</span>
                        </CSVReader> */}
                        {/* <InputLabel>Select Paper Type</InputLabel>
                        <Select
                            value={paper_type}
                            onChange={(e) => setPaperType(e.target.value)}
                            style={{ minWidth: '150px' }}
                            label={"Select Paper Type"}
                        >
                            <em>Select</em>
                            <MenuItem value="0">Not Special</MenuItem>
                            <MenuItem value="1">Special</MenuItem>
                        </Select>
                        <br />
                        <br />
                        {
                            (paper_type == '1')
                                ? <>
                                    <TextField
                                        label="Enter Question Number"
                                        style={{ minWidth: '150px' }}
                                        type="tel"
                                        value={quesno}
                                        onChange={(e) => setQuesNo(e.target.value)}
                                    /><br />
                                    <br />
                                </>
                                : <></>
                        } */}
                        <Button fullwidth onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>

                    </FormLayoutAdmin>
                </Container>
            </form>
            {/* <br /><br />
            {
                (!error)
                    ? <></>
                    : <>
                        <h2>List of Issues in Test Info CSV File</h2>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center' }}>Sr. No.</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Row</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Column</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {array.map((element, index) => (
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                        <TableCell style={{ color: 'red', textAlign: 'center' }}>Error</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{element.row}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{element.column}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
            } */}
        </div>
    )
}
