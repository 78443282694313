import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutstudent from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { change_student_batch_mutation_gql } from "../../../gql/mutation/change-student-batch-mutation";
import SelectSessionBatch from "../../components/select-batch-sessionwise";
import Typography from "@material-ui/core/Typography";
import SelectSessionCoursePhaseBatch from "../../components/select-session-course-phase-batch";
import SelectGender from "../../components/select-gender";

export default () => {
  const [change_student_batch] = useMutation(change_student_batch_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
    change_student_batch={change_student_batch}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, change_student_batch }) => {
  const [values, setValues] = useState({
    roll_no: "",
    batch_id: "",
    remark: ""
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const {
        roll_no,
        batch_id,
        remark
      } = values;

      loader.show();

      let {
        data: {
            change_student_batch: { success },
        },
      } = await change_student_batch({
        variables: {
          roll_no,
          batch_id,
          remark
        },
      });

      if (success) snackBar.show("student batch changed successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutstudent title="Change Student Batch">
          <Grid spacing={3} container>
            
            <Grid item xs={12}>
              <TextField
                label="Enter Roll No"
                variant="outlined"
                name="roll_no"
                value={values.roll_no}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
            <SelectSessionBatch
              label="Select Batch"
              onChange={handleInputChange}
              name="batch_id"
              value={values.batch_id}
            />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Remark"
                name="remark"
                fullWidth
                multiline
                maxRows={3}
                value={values.remark}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Change Batch
              </Button>
            </Grid>
          </Grid>
        </FormLayoutstudent>
      </Container>
    </form>
  );
};
