import { gql } from "@apollo/client";

export const childsubject_list_all_query_gql = gql`
  query childsubject_list_all {
    childsubject_list_all {
      key: id
      value: name
    }
  }
`;
