import React from "react";
import { Select } from "../../../../utils/select";
import { view_courseware_list_query_gql } from "../../gql/query/view-courseware-list-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({ topic_id, type, label, value, name, onChange }) => {
  if (!topic_id && !type) return <span></span>;
  const { loading, error, data } = useQuery(view_courseware_list_query_gql, {
    variables: {
        topic_id,
        type
    },
  });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { view_courseware_list } = data;

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={view_courseware_list}
    />
  );
};
