import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { api_url } from '../reusable-calling-code-project/config';
import styles from '../reusable-calling-code-project/table.module.css';
import moment from 'moment';
import { Paper, Typography } from '@material-ui/core';


const ViewAttendanceSystem = () => {
    const loader = useContext(LoaderContext);
    const [result, setResult] = useState([]);
    const [cache, setCache] = useState([]);
    const [count, setCount] = useState();
    const [count2, setCount2] = useState();
    const [punch, setPunch] = useState([]);

    useEffect(async () => {
        try {
            loader.show();
            const { data: obj } = await axios({
                url: api_url + '/view-attendance-system/get-data',
                method: 'post',
                responseType: 'json',
            });
            // console.log(obj);
            setResult(obj.array);
            setCache(obj.sql);
            // setCount(obj.count);
            setCount2(obj.count2);
            setPunch(obj.punch);
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide()
        }
    }, [])


    if (result.length === 0)
        return <></>


    return (
        <div>
            <Paper component={Paper} style={{ padding: '16px' }}>
                <table className={styles.table} style={{ fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Attendance Record</th>

                        </tr>
                        {/* <tr>
                            <th colSpan={2}></th>
                        </tr> */}
                        <tr>
                            <th>Sr. No.</th>
                            {/* <th>Last 10 Records (2022-23)<br /> <br /><b>Count: </b>{count}</th> */}
                            <th>Last 10 Records (2023-24) <br /><br /><b>Count: </b>{count2}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {result.map((ele, i) => (
                            <tr>
                                <td>{i + 1}</td>
                                {/* <td>{moment(ele.updatedate).format('Do MMMM YYYY, h:mm:ss a')}</td> */}
                                <td>{moment(ele.updatedate2).format('Do MMMM YYYY, h:mm:ss a')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Paper>
            <br />
            <Paper component={Paper} style={{ padding: '16px' }}>
                <table className={styles.table} style={{ fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Student Punch Record</th>
                        </tr>
                        <tr>
                            <th>Sr. No.</th>
                            {/* <th>Last 10 Records (2022-23)</th> */}
                            <th>Last 10 Records (2023-24)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {punch.map((ele, i) => (
                            <tr>
                                <td>{i + 1}</td>
                                {/* <td>{moment(ele.last_updated).format('Do MMMM YYYY, h:mm:ss a')}</td> */}
                                <td>{moment(ele.last_updated2).format('Do MMMM YYYY, h:mm:ss a')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Paper>
            <br />
            <Paper component={Paper} style={{ padding: '16px' }}>
                <table className={styles.table} style={{ fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Cache Record</th>
                        </tr>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Last 10 Records</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cache.map((ele, i) => (
                            <tr>
                                <td>{i + 1}</td>
                                <td>{moment(ele.last_updated).format('Do MMMM YYYY, h:mm:ss a')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Paper>
        </div>
    )
}

export default ViewAttendanceSystem;
