import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { view_live_class_query_gql } from "../../../gql/query/view-live-class-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../../utils/gql-error-comp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default ({ batch_id }) => {
  if (!batch_id) return <span></span>;
  const classes = useStyles();

const { loading, error, data  } = useQuery(view_live_class_query_gql, {
  fetchPolicy: "no-cache",
  variables: {
    batch_id
  },
});

if (loading) return <span>Loading...</span>;
if (error) return <GqlErrorComp error={error} />;
const { view_live_class } = data;

  return (
    <List className={classes.root}>
      {view_live_class.map((n, i) => {
        console.log(n);
        return (
          <>
          <Typography component="h3">{n.date}</Typography>
          <ListItem key={i}>
            <ListItemText
              primary={n.childsubject.name}
              secondary={<>
                {n.start_time} - {n.end_time} <br />
                ZoomID - {n.zoom_account.email} <br />
                MeetingID - {n.meeting_no} <br />
                Password - {n.meeting_pwd} <br />
                Faculty - {n.faculty.name} <br />
                Status - {n.is_cancelled ? "Cancelled" : "Ok"}
                </>}
            />
          </ListItem>
          <br />
            <Divider />
            <br />
            <br />
          </>
        );
      })}
    </List>
  );
}
