import React, { useContext, useEffect, useState } from 'react'
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { Container, TextField, Grid, Button, Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import axios from 'axios';
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const MergeBatch = () => {

    const classes = useStyles();

    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);

    const [old, setOld] = useState('');
    const [batch, setBatch] = useState('');
    const [list, setList] = useState([]);

    useEffect(async () => {
        try {

            loader.show();

            const { data: obj } = await axios({
                url: 'https://api-dot-reformexam.el.r.appspot.com/reliablekotaapp/batch-list',
                method: 'post',
                responseType: 'json',
                data: {
                    year: 2024,
                }
            })

            if (obj.success === 'ok') {
                setList(obj.list);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide()
        }
    }, [])


    const handleClick = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: 'https://api-dot-reformexam.el.r.appspot.com/reliablekotaapp/merge-batch',
                method: 'post',
                responseType: 'json',
                data: {
                    old: old,
                    batch: batch,
                }
            })

            if (obj.success === 'ok') {
                snackBar.show("Batch Merged Successfully");
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide()
        }
    }


    return (
        <>

            <Container>
                <FormLayoutAdmin title="Merge Batch">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Current Batch</InputLabel>
                                <Select
                                    value={old}
                                    onChange={(e) => setOld(e.target.value)}
                                    style={{ minWidth: '150px' }}
                                    label="Select Old Batch"
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                >
                                    <em>Select Current Batch</em>
                                    {list.map((ele, i) => {

                                        return (

                                            <MenuItem value={ele.key}>{ele.value}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">New Batch</InputLabel>
                                <Select
                                    value={batch}
                                    onChange={(e) => setBatch(e.target.value)}
                                    style={{ minWidth: '150px' }}
                                    label={"Select New Batch"}
                                >
                                    <em>Select New Batch</em>
                                    {list.map((ele, i) => {

                                        return (

                                            <MenuItem value={ele.key}>{ele.value}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
                        </Grid>
                    </Grid>
                </FormLayoutAdmin>
            </Container>


        </>
    )
}

export default MergeBatch
