export const attendance_fn = async ({
  mark_attendance_mutation,
  status_type,
  meeting_id,
}) => {
  try {
    let {
      data: {
        live_class_mark_attendance: {success},
      },
    } = await mark_attendance_mutation({
      variables: {
        id: meeting_id,
        status_type: status_type,
      },
    });
  } catch (e) {}
};
