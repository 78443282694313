import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutclassroom_test from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_classroom_test_mutation_gql } from "../../../gql/mutation/create-classroom_test-mutation";
import Typography from "@material-ui/core/Typography";
import SelectClassroomTestType from "../../components/select-classroom-test-type";
import SelectSessionCoursePhaseBatch from "../../components/select-session-course-phase-batch";

export default () => {
  const [create_classroom_test_mutation] = useMutation(
    create_classroom_test_mutation_gql
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_classroom_test_mutation={create_classroom_test_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_classroom_test_mutation }) => {
  const [values, setValues] = useState({
    name: "",
    test_date: "",
    classroom_test_type_id: "",
    // batch_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { name, test_date, classroom_test_type_id } = values;

      loader.show();

      let {
        data: {
          create_classroom_test: { success },
        },
      } = await create_classroom_test_mutation({
        variables: {
          name,
          test_date,
          classroom_test_type_id
        },
      });

      if (success) snackBar.show("classroom_test created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutclassroom_test title="Create classroom_test">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                label="Enter Name"
                variant="outlined"
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Test Date"
                variant="outlined"
                name="test_date"
                value={values.test_date}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectClassroomTestType
                label="Classroom Test Type"
                name="classroom_test_type_id"
                value={values.classroom_test_type_id}
                onChange={handleInputChange}
              />
            </Grid>
            {/* <SelectSessionCoursePhaseBatch
              name="batch_id"
              onChange={handleInputChange}
            /> */}

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutclassroom_test>
      </Container>
    </form>
  );
};
