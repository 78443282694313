import React from "react";
import { Container, Typography } from "@material-ui/core";

export default () => {
  return (
    <Typography align="center" color="textPrimary" variant="h1">
      401: Unauthorized: Access is denied due to invalid credentials
    </Typography>
  );
};
