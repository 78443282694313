import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SelectBatch from "./select-batch";
import { handleFormChange } from "../../../../utils/functions";
import SelectSessionCoursePhaseBatch from "./select-session-course-phase-batch";
import SelectClassroomTestType from "./select-classroom-test-type";
export default ({ onChange, name }) => {
  const [values, setValues] = useState({
    classroom_test_id: "",
    [name]: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
    // if (e.target.name === name) {
    onChange(e);
    // }
  };

  return (
    <>
      <SelectSessionCoursePhaseBatch
        name="batch_id"
        onChange={handleInputChange}
      />

      <Grid item xs={12}>
        <SelectClassroomTestType
          batch_id={values.batch_id}
          label="Test Type"
          name={name}
          value={values[name]}
          onChange={handleInputChange}
        />
      </Grid>
    </>
  );
};
