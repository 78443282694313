import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { VideoViewer } from '../video-viewer'




export default function FormDialog({ toggleModal, url }) {
console.log(url)

    const handleClose = () => {
        toggleModal(false)
    };

    return (


        <Dialog fullWidth={true}
            maxWidth={'md'} open onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent  >
                <center>  <VideoViewer url={url} />
                </center>
            </DialogContent>

        </Dialog>

    );
}