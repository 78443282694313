import { gql } from "@apollo/client";

export const create_classroom_test_mutation_gql = gql`
  mutation create_classroom_test(
    $name: String!
    $test_date: String!
    $classroom_test_type_id: Int!
  ) {
    create_classroom_test(
      name: $name
      test_date: $test_date
      classroom_test_type_id: $classroom_test_type_id
    ) {
      success
    }
  }
`;
