import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange }) => {
	const array=[{key:'E',value:'E'},{key:'H',value:'H'}]
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={array}
    />
  );
};
