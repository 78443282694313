import React, { useState } from "react";
import { useParams, Navigate as Redirect } from 'react-router-dom'; 
import { useQuery, useMutation } from "@apollo/client";
import { quiz_gql, submit_gql, preview_quiz_gql } from "./gql";
import { Spinner } from "../../../../../utils/spinner";
import { error_beautify } from "../../../../../utils/functions";
import Quiz from "../../../../../utils/quiz";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
// let str = require("./response.json");

export default ({preview=false}) => {
  const { id } = useParams();
  const query = preview ? preview_quiz_gql : quiz_gql;
  let common = { id: parseInt(id, 10) };
  const variables = preview ? { ...common, preview: true } : common;
  const { loading, error, data } = useQuery(query, {
    variables: variables,
  });
  if (loading) return <Spinner />;

  if (error) return <span>{error_beautify(error.message)}</span>;
  
  return (
    <Comp
      quiz={data.quiz}
      attempt={data.attempt}
      id={id}
      preview={preview}
    />
  );
};

const Comp = ({ quiz: in_quiz, attempt, id, preview }) => {
  const [redirect, setRedirect] = useState(false)
  const [loaded, setLoaded] = useState(false);
  const [quiz, setQuiz] = useState(in_quiz);

  const [submit_mutation] = useMutation(submit_gql);

  const snackBar = React.useContext(SnackBarContext);
  const loader = React.useContext(LoaderContext);
  const sendQuizData = (data) => {
    setQuiz(data);
  };

  const submit_function = async () => {
    try {
      loader.show();
      let {
        data: {
          quiz_submit: { success },
        },
      } = await submit_mutation({ variables: { quiz: JSON.stringify(quiz) } });

      snackBar.show(`Submitted Successfully`);
      setTimeout(() => {
        snackBar.hide();
      if(success)
      setRedirect(true)
      else setRedirect(false)
      }, 1000);
    } catch (e) {
      snackBar.show(error_beautify(e));
    } finally {
      loader.hide();
    }
  };
  const onSubmit = (count) => {
    if(count){
      snackBar.show("Time Over");
      submit_function();
    }
    else { 
    var r = window.confirm("Do you really want to submit?");
    if (r == true) {
      submit_function();
    }
  }
  };
 if(redirect) 
 return <Redirect to={`/student/app/preview-quiz/${id}`} /> 
console.log(quiz)
  return (
    <Quiz
      preview={preview}
      quiz={quiz}
      attempt={attempt}
      sendQuizData={sendQuizData}
      onSubmit={onSubmit}
    />
  );
};
