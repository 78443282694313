import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutsession from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_session_mutation_gql } from "../../../gql/mutation/create-session-mutation";
import Typography from "@material-ui/core/Typography";
export default () => {
  const [create_session_mutation] = useMutation(create_session_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_session_mutation={create_session_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_session_mutation }) => {
  const [values, setValues] = useState({
    name: "",
    username: "",
    password: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { name, start_date, end_date } = values;

      loader.show();

      let {
        data: {
          create_session: { success },
        },
      } = await create_session_mutation({
        variables: { name, start_date, end_date },
      });

      if (success) snackBar.show("Session created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutsession title="Create Session">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                label="Start Name"
                variant="outlined"
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Start Date"
                variant="outlined"
                name="start_date"
                value={values.start_date}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter End Date"
                variant="outlined"
                name="end_date"
                value={values.end_date}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutsession>
      </Container>
    </form>
  );
};
