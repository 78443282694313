import { gql } from "@apollo/client";

export const course_list_query_gql = gql`
  {
    course_list {
      key: id
      value: name
    }
  }
`;
