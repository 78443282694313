import React from "react";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutstudent from "../../../../../utils/form-layout-admin";
import Comp from "./comp";
import SelectSessionBatch from "../../components/select-batch-sessionwise";
  
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";

export default () => {
  const [values, setValues] = React.useState({
    batch_id: 0
  });
  const [batchid, setBatchID] = React.useState("");
  const snackBar = React.useContext(SnackBarContext);
  const handleInputChange = (e) => {
    e.preventDefault();
    console.log(e.target.value)
    let batch = e.target.value;
    handleFormChange({ e, values, setValues });
    setBatchID(batch)
  };
  
  return (
    // <>
    //   {view_student.map((n, i) => {
    //     return (
          <>
          <form>
      <Container>
        <FormLayoutstudent title="View Live Classes">
          <Grid spacing={3} container>
            <Grid item xs={12}>
            <SelectSessionBatch
              label="Select Batch"
              onChange={handleInputChange}
              name="batch_id"
              value={values.batch_id}
            /></Grid>
            </Grid>
        </FormLayoutstudent>
      </Container>
    </form> <br />
    <Comp batch_id={batchid} />
          </>
        );
      }
//       )}
//      </>
//   );
// };
