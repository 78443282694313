import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from '../../table.module.css'; 
import Delete from './delete'
import Allot from './allot'
import Callback from './callback'
import Conversation from './conversation'
import FileCopyIcon from '@material-ui/icons/FileCopy';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400, 
	background:'#fce4ec'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
   
}));

export default function RecipeReviewCard({array,snackBar,loader,refresh,manager}) {
 
  const classes = useStyles();
  const [cb_date,setCBDate]=useState('');
  const [utm_source,setUTMSource]=useState('');
  
 let avatar={};
 
if(array.length<1)
return(
<></>
)

 
return(
<> 
  <TableContainer component={Paper}>
     <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan={manager?10:9} className={styles.typogrpahy_header} > Not Paid</th></tr>
            <tr>
              <th rowspan="2" width="10">#</th>
			  <th rowspan="2" width="120">Mobile</th>
              <th colspan={manager?8:7}>Registrations</th>
			   
			  
          </tr>
		  <tr><th>Name</th><th>Class</th><th>Close Lead</th><th>Conversations</th><th>Callback<br/><input type="date" value={cb_date} onChange={(e)=>setCBDate(e.target.value)}/>&nbsp;<button style={{padding:4,color:'red'}} onClick={()=>{setCBDate('')}}> X </button></th><th>Created At</th><th>Source</th>{manager && <th>Allot</th>}
		  
		 
		  </tr>
        </thead>
		  <tbody>
  
  {
	  array.map(({id,mobile,registrations})=>{
		 
		  if(registrations.length==1){
			 
			  
			  if(cb_date && cb_date!=left_date(registrations[0]['last_callback']))
			  return <></>
		  return(
		 <tr>
		   <td>{id}</td><td><CopyIcon snackBar={snackBar} mobile={mobile}/></td>
			<Comp registrations={registrations} refresh={refresh} loader={loader} snackBar={snackBar} manager={manager} />
			 </tr>
		  )
		  }
		  else if(registrations.length>1){
			  
		  return(
		  <>
		  <tr>
		  {(cb_date && all_none(registrations,cb_date) ) ?<></>:<>
			   
		  <td rowSpan={registrations.length}>{id}</td> 
		   <td rowSpan={registrations.length}><CopyIcon snackBar={snackBar} mobile={mobile}/></td>
		   </>
		   
		  }
	    {(cb_date && cb_date!=left_date(registrations[0]['last_callback'])  ) ?<></>:
			   
		  <Comp2 registrations={registrations} refresh={refresh} loader={loader} snackBar={snackBar} manager={manager}/>
		} 
		  </tr>
		  
		  {registrations.map((n,i)=>{
			  if(i>0)
			 return(
			 <tr>
			 
			  {(cb_date && cb_date!=left_date(n['last_callback'])  ) ?<></>:
			   
					<Comp3 n={n} refresh={refresh} loader={loader} snackBar={snackBar} manager={manager}/>
					}
			
		  </tr>
			 ) 
		  })
		  } 
		  </>
		  )
		  }
	  })
  }
    </tbody>
  </table>
  </TableContainer >
  
 </>
)
 
  
  
} 

const left_date=(dt)=>{
	if(!dt)
	return dt;

	return dt.split("T")[0];
}

const all_none=(registrations,cb_date)=>{
	let i=0,len=registrations.length,j=0;
	for(i=0;i<len;i++){
	if(cb_date!=left_date(registrations[i]['last_callback']))
		j++;
	}
 
	if(j===len)
	return true;
	return false
	 
}

const Comp=({registrations,snackBar,loader,refresh,manager})=>{
	return(
	<>
	<td>{registrations[0]['name']} <b>({registrations[0]['gender']})</b></td>  <td>{registrations[0]['class']}</td> <td><Delete refresh={refresh} id={registrations[0]['id']} snackBar={snackBar} loader={loader}/></td>
		    <td><Conversation  last_remark={registrations[0]['last_remark']} id={registrations[0]['id']} snackBar={snackBar} loader={loader}/></td>
			<td><Callback last_callback={registrations[0]['last_callback']} id={registrations[0]['id']} snackBar={snackBar} loader={loader}/></td>
			<td>{registrations[0]['created_at']}</td><td>{registrations[0]['utm_source']}</td>{manager && <td><Allot refresh={refresh} n={registrations[0]} snackBar={snackBar} loader={loader}/></td>}
	</>
	)
}

const Comp2=({registrations,refresh,loader,snackBar,manager})=>{
	return(
	<>
	 <td>{registrations[0]['name']} <b>({registrations[0]['gender']})</b></td>  <td>{registrations[0]['class']}</td> 
		   <td><Delete refresh={refresh}  id={registrations[0]['id']}  snackBar={snackBar} loader={ loader}/></td>
		    <td><Conversation  last_remark={registrations[0]['last_remark']} id={registrations[0]['id']} snackBar={snackBar} loader={loader}/></td>
			<td><Callback last_callback={registrations[0]['last_callback']} id={registrations[0]['id']} snackBar={snackBar} loader={loader}/></td>
			<td>{registrations[0]['created_at']}</td><td>{registrations[0]['utm_source']}</td>{manager && <td><Allot refresh={refresh}  n={registrations[0]}  snackBar={snackBar} loader={ loader}/></td>}
	</>
	)
}

const Comp3=({n,refresh,loader,snackBar,manager})=>{
	return(
	<>
	<td>{ n.name } <b>({n.gender})</b></td>  <td>{ n['class'] }</td> 
			 <td><Delete refresh={refresh}  id={n['id']}  snackBar={snackBar} loader={loader}/></td>
			  <td><Conversation id={n['id']} last_remark={n['last_remark']} snackBar={snackBar} loader={loader}/></td>
			 <td><Callback last_callback={n['last_callback']} id={n['id']} snackBar={snackBar} loader={loader}/></td>
			 <td>{n['created_at']}</td> <td>{n['utm_source']}</td>
			 {manager && <td><Allot refresh={refresh}  n={n}  snackBar={snackBar} loader={loader}/></td>}
	</>
	)
}

const CopyIcon=({mobile,snackBar})=>{
	return (
	<>
	{mobile}&nbsp;&nbsp;
	 <FileCopyIcon style={{fontSize:12, cursor:'pointer'}} color="primary" onClick={()=>{
		 navigator.clipboard.writeText(mobile);
		 snackBar.show('Copied!');
	 }}/>
	 </>
	)
}

