import { graphql_endpoint as uri, STUDENT_TOKEN_NAME,zoom_web_app_url,zoomLeaveUrl } from "../../../../../config.js";

export default({
	r
})=>{
	 let token = localStorage.getItem(STUDENT_TOKEN_NAME);
	   let live_class_id = r['eid'];
	   create_form({token,live_class_id});
	// window.open(`${zoom_web_app_url}?uri=${encodeURIComponent(uri)}&token=${encodeURIComponent(token)}&meetid=${encodeURIComponent(live_class_id)}`, "_blank")

}




function create_form({token,live_class_id}){
	// Create a form synamically
	const random=Math.floor(Math.random() * 1000000) + 1;
    var form = window.document.createElement("form");
	form.setAttribute("id","secure__form"+random);
	form.setAttribute("target", "_blank");
    form.setAttribute("method", "post");

    form.setAttribute("action", zoom_web_app_url);
	
	 var FN = window.document.createElement("input");
    FN.setAttribute("type", "hidden");
    FN.setAttribute("name", "uri");
	FN.setAttribute("value", encodeURIComponent(uri));
	    
		form.appendChild(FN); 
		
	 FN = window.document.createElement("input");
    FN.setAttribute("type", "hidden");
    FN.setAttribute("name", "token");
	FN.setAttribute("value", encodeURIComponent(token));	
		
		form.appendChild(FN); 
		
	
	FN = window.document.createElement("input");
    FN.setAttribute("type", "hidden");
    FN.setAttribute("name", "meetid");
	FN.setAttribute("value", encodeURIComponent(live_class_id));
	
		form.appendChild(FN); 
		
	FN = window.document.createElement("input");
    FN.setAttribute("type", "hidden");
    FN.setAttribute("name", "leaveUrl");
	FN.setAttribute("value", encodeURIComponent(zoomLeaveUrl));
	
		form.appendChild(FN); 
		
		
		
		
	
	
	  var s = document.createElement("input");
                s.setAttribute("type", "submit");
                s.setAttribute("value", "Submit");
                  
              form.appendChild(s);      
               document.getElementsByTagName("body")[0]
               .appendChild(form);
  
  form.submit();
}