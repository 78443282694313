import React,{useState} from 'react';
import {Grid,Container} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormLayoutchildsubject from "../../../../../../utils/form-layout-admin";
import CreateLead from "../create-lead" 
import CreateContactButton from "./create-contact-button"
import ContactTable from './contact-table'
import Leads from './leads';
export default({array,mobile,setShow,done})=>{
 
const [contact_id,setContactId]=useState(0);
 
	return(
	    <Container style={{maxWidth:700}}>
        <>
		{array.length>0 ?<><ContactTable setContactId={setContactId} array={array}/><br/>
		 <CreateContactButton setShow={()=>setShow(true)}/><br/></>
		:<CreateLead mobile={mobile} done={done} contact_id={contact_id}/>}
 <br/>
 {contact_id>0 ?<Leads contact_id={contact_id}/>:null}
 

		
		</>
		
			<br/>
		 
	
		</Container>
		
	)
}

 