import React from "react";

import QuestionArea from "./question-area";
export default ({
  preview,
  quiz,
  onSubmit,
  sendQuizData,
  attempt,
  previewQuestion,
  show_on_small_screen,
}) => {
  // const { quiz_question, ...rest } = quiz
  // const arr2 = quiz_question.map((item, i) => {

  //     return { index: i, ...item };
  // });
  // quiz = { ...rest, quiz_question: arr2 }
console.log(attempt)

  return (
    <React.Fragment>
      <QuestionArea
        preview={preview}
        quiz={quiz}
        onSubmit={onSubmit}
        sendQuizData={sendQuizData}
        attempt={attempt}
        previewQuestion={previewQuestion}
        show_on_small_screen={show_on_small_screen}
      />
    </React.Fragment>
  );
};
