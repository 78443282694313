import { gql } from "@apollo/client";

export const classroom_test_type_list_query_gql = gql`
  {
    classroom_test_type_list {
      key: id
      value: name
    }
  }
`;
