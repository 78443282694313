import React from "react";
import { CSVReader } from 'react-papaparse';

export default function UrlCsvComp(props) {

    var arry = [];
    var issued = [];

    const handleUrlDrop = (data) => {

        var len = data.length;
        try {
            if (data[0].data.length !== 3) {
                alert("Please Upload correct CSV File");
                return false;
            }
            else {
                if (data[0].data[0] !== 'QuestionNo') {
                    alert("First Row and First Column should be of name: QuestionNo");
                    return false;
                }
                if (data[0].data[1] !== 'QuestionUrl') {
                    alert("First Row and Second Column should be of name: QuestionUrl");
                    return false;
                }
                if (data[0].data[2] !== 'PaperCode') {
                    alert("First Row and Third Column should be of name: PaperCode");
                    return false;
                }
                else {
                    var a, c = 2;
                    for (var i = 1; i < len; i++) {
                        arry.push({ Question_no: data[i].data[0], Question_url: data[i].data[1], Paper_code: data[i].data[2] });
                    }
                    for (a = 0; a < arry.length; a++) {
                        if (arry[a].Question_no < 1 || arry[a].Question_no > 200 || arry[a].Question_no.match(/^[a-zA-z]+$/)) {
                            issued.push({ row: c, column: 1 });
                        }
                        if (arry[a].Paper_code.match(/^[k-zK-Z]+$/)) {
                            issued.push({ row: c, column: 3 });
                        }
                        c++
                    }
                }
            }
            props.handleUrlMaster({ id: props.id, width: data[0].data.length, urlissue: issued, urldata: arry })
        }
        catch (e) {
            alert(e.message);
        }
    }

    return (
        <>
            <CSVReader onDrop={handleUrlDrop}>
                <span>Drop Image File</span>
            </CSVReader>
        </>
    )
}