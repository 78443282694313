import React from "react";
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import { Container, TextField, Grid, Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { api_url } from '../reusable-calling-code-project/config';
import { LoaderContext } from "../../../../../context/LoaderContext";

const MarkYogaAttendance = () => {


    const [sname, setName] = useState('');
    const [classs, setClass] = useState('');
    const [mobile, setMobile] = useState('');
    const [show, setShow] = useState(false);

    const loader = useContext(LoaderContext);


    const handleVerify = async () => {

        try {
            loader.show();

            if (mobile.length !== 10) {
                alert("Entered Mobile Number is wrong");
                return false;
            }

            const { data: obj } = await axios({
                url: api_url + '/view-attendance-system/mark-yoga-attendance',
                method: 'post',
                responseType: 'json',
                data: {
                    sname: sname,
                    classs: classs,
                    mobile: mobile,
                }
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setName('');
                setClass('');
                setMobile('');
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }

    }


    return (

        <>

            <FormLayoutAdmin title="Yoga Day Registration">

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Enter Name"
                            value={sname}
                            placeholder="Enter Participant Name"
                            onChange={(e) => setName(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <Select
                            value={classs}
                            onChange={(e) => setClass(e.target.value)}
                            style={{ minWidth: '150px' }}
                            label={"Select Class / Occupation"}
                        >
                            <em>Select</em>
                            <MenuItem value="6">Class 6th</MenuItem>
                            <MenuItem value="7">Class 7th</MenuItem>
                            <MenuItem value="8">Class 8th</MenuItem>
                            <MenuItem value="9">Class 9th</MenuItem>
                            <MenuItem value="10">Class 10th</MenuItem>
                            <MenuItem value="11">Class 11th</MenuItem>
                            <MenuItem value="12">Class 12th</MenuItem>
                            <MenuItem value="13">Class 12th Passed</MenuItem>
                            <MenuItem value="Job">Job</MenuItem>
                            <MenuItem value="Business">Business</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="tel"
                            label="Enter Mobile Number"
                            value={mobile}
                            placeholder="Enter Mobile Number"
                            onChange={(e) => setMobile(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <Button fullwidth onClick={handleVerify} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
                    </Grid>
                </Grid>

            </FormLayoutAdmin>

        </>


    )
}

export default MarkYogaAttendance
