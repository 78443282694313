import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { api_url } from '../config';
import { LoaderContext } from "../../../../../../context/LoaderContext";
import styles from '../../reusable-calling-code-project/table.module.css';
import { Grid, Paper, InputLabel, Select, MenuItem } from '@material-ui/core';

const EnquirySourceMIS = () => {
    const loader = useContext(LoaderContext);

    const [online, setOnline] = useState([]);
    const [front, setFront] = useState([]);
    const [bd, setBd] = useState([]);
    const [grp, setGrp] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(async () => {

        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/enquiry-source-mis',
                method: 'post',
                responseType: 'json',
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setOnline(obj.online);
                setBd(obj.bd);
                setFront(obj.front);
                setGrp(obj.grp);
                setShow(true);
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }

    }, [])


    return (
        <>

            {show &&

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>Online Leads</th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Count</th>
                                        <th>Class</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {online.map((ele, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.count}</td>
                                                <td>{ele.class}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>Front Office Leads</th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Count</th>
                                        <th>Class</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {front.map((ele, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.count}</td>
                                                <td>{ele.class}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>BD Leads</th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Count</th>
                                        <th>Class</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bd.map((ele, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.count}</td>
                                                <td>{ele.class}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table} style={{ fontSize: '13px' }}>
                                <thead>
                                    <tr>
                                        <th colSpan={7}>YTD (Admission) MIS</th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Class</th>
                                        <th>Session 2020-21</th>
                                        <th>Session 2021-22</th>
                                        <th>Session 2022-23</th>
                                        <th>Session 2023-24</th>
                                        <th>Session 2024-25</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {grp.map((ele, i) => (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.grp}</td>
                                            <td>{ele.ct4}</td>
                                            <td>{ele.ct3}</td>
                                            <td>{ele.ct}</td>
                                            <td>{ele.ct2}</td>
                                            <td>{ele.ct5}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                </Grid>


            }

        </>
    )
}

export default EnquirySourceMIS
