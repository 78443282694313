import React, { useContext, useState, useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
    handleFormChange,
    resetForm,
    error_beautify,
} from "../../../../../../utils/functions";
import { Select } from "../../../../../../utils/select";
import Typography from "@material-ui/core/Typography";
import { api_url, rest_headers } from '../config'
import { handle_error } from '../../call-center/handle_error'

import List from './list'
const axios = require('axios');

export default () => {
    const year = 2023
    // console.log(year);
    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);
    return (
        <Comp
            snackBar={snackBar}
            loader={loader}
            year={year}

        />
    );
};
const Comp = ({ loader, snackBar, adminname, year }) => {
    console.log(year);
    const [list, setList] = useState([])
    const [batch_list, setBL] = useState([]);

    const [batch_id, setBatchId] = useState('')

    const [values, setValues] = useState({
        title: "",
        url: "",

    });
    const handleInputChange = (e) => {
        handleFormChange({ e, values, setValues });
    };



    useEffect(async () => {

        fetch();
    }, [])

    const fetch = async () => {
        try {

            loader.show();
            const { data } = await axios({
                url: api_url + '/batch-list',
                method: 'post',
                responseType: 'json',
                data: {
                    year: year
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })


            setList(data.list)




        } catch (e) {

            snackBar.show(e);
        } finally {
            loader.hide();
        }
    }


    return (

        <Container>
            <FormLayouttopic title="Attendance Processing">
                <Grid spacing={3} container>

                    <Grid item xs={12}>
                        <Select
                            label={"Select Batch"}
                            value={batch_id}
                            name={'batch_id'}
                            onChange={(e) => setBatchId(e.target.value)}
                            array={list}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <List batch_id={batch_id} loader={loader} year={year} />
                    </Grid>




                </Grid>
            </FormLayouttopic>
        </Container>

    );
};
