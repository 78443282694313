import { Container, Paper, Grid, MenuItem, TextField, Typography, Button, Dialog, DialogContent, Snackbar } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import styles from '../reusable-calling-code-project/table.module.css';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { handle_error } from '../reusable-calling-code-project/handle_error'


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

// const api_url = 'http://localhost:8060/vyaapak-calling/'
const api_url = 'https://api-dot-reformexam.el.r.appspot.com/vyaapak-calling/'

const VyaapakCallingReviews = () => {


    const loader = useContext(LoaderContext);
    const snackBar = useContext(SnackBarContext);


    const [array, setArray] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        fetch();
    }, [])

    const fetch = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + 'data-reviews',
                method: 'post',
                responseType: 'json'
            })

            if (obj.Success === 'ok') {
                setArray(obj.array);
                setShow(true);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }
    }

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {show && <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={6}>Vyaapak Calling Reviwes</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Reg. Date</th>
                                    <th>View Conversation</th>
                                    <th>Admin Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {array.map((ele, i) => (

                                    <>

                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.type}</td>
                                            <td>{moment(ele.date).format('Do MMMM YYYY')}</td>
                                            <td>
                                                <ViewComp orderid={ele.orderid} loader={loader} snackBar={snackBar} />
                                                <br /><br />
                                                <Typography variant='h6'><b>Latest Remark:- </b>{ele.last_remark}</Typography>
                                            </td>
                                            <td>{ele.admin}</td>
                                        </tr>

                                    </>

                                ))}
                            </tbody>
                        </table>
                    </Paper>}
                </Grid>
            </Grid>
        </Container>
    )
}

export default VyaapakCallingReviews


const ViewComp = ({ orderid, loader, snackBar }) => {

    const [array, setArray] = useState([]);
    const hide = () => {
        setArray([]);
    }

    const handleClick = async (orderid) => {
        try {
            loader.show();

            const { data } = await axios({
                url: api_url + 'view-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    orderid: orderid
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            console.log(data);
            setArray(data.array)

            if (data.array.length < 1) {
                snackBar.show("No Conversation Found");
            }


        } catch (e) {
            snackBar.show(handle_error(e));
        } finally {
            loader.hide();
        }

    }


    return (<>

        <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(orderid)}>All Remarks</Button>

        {array && array.length > 0 && <ModalComp hide={hide}>
            <div style={{ fontFamily: 'Arial' }}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={3}>Conversation</th>
                        </tr>
                        <tr>
                            <th>Remark</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {array.map((n, i) => {
                            return (
                                <tr style={{ fontSize: '13px' }}>
                                    <td>{n.text}</td>
                                    <td>{n.date}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>


            </div>


        </ModalComp>

        }

    </>)
}

const ModalComp = ({ children, hide }) => {

    return (
        <div>
            <Dialog open
                onClose={() => { hide() }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>

        </div>
    )
}