import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
 
export default function FullScreenDialog({title,topic,vimeo_link,onClose}) {
  const classes = useStyles();
 
  

  return (
    <div>
    
      <Dialog fullScreen open onClose={onClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
             
         
          </Toolbar>
        </AppBar>
		<br/>
		 <center><div style={{width:'100%',maxWidth:400}}>
        <div dangerouslySetInnerHTML={{__html: `<div style="padding:75% 0 0 0;position:relative;"><iframe src="${vimeo_link}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&portrait=0&byline=0&title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="sample-mp4-file.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`}}></div>
		</div>
		<br/>
		  <Typography variant="h3">{topic}</Typography>
		<br/><Typography variant="h4">{title}</Typography>
		</center>
	   
      </Dialog>
    </div>
  );
}
