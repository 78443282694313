import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange }) => {
  const list = [
    { key: "07:00:00", value: "07:00 AM" },
    { key: "07:15:00", value: "07:15 AM" },
    { key: "07:30:00", value: "07:30 AM" },
    { key: "07:45:00", value: "07:45 AM" },
    { key: "08:00:00", value: "08:00 AM" },
    { key: "08:15:00", value: "08:15 AM" },
    { key: "08:30:00", value: "08:30 AM" },
    { key: "08:45:00", value: "08:45 AM" },
    { key: "09:00:00", value: "09:00 AM" },
    { key: "09:15:00", value: "09:15 AM" },
    { key: "09:30:00", value: "09:30 AM" },
    { key: "09:45:00", value: "09:45 AM" },
    { key: "10:00:00", value: "10:00 AM" },
    { key: "10:15:00", value: "10:15 AM" },
    { key: "10:30:00", value: "10:30 AM" },
    { key: "10:45:00", value: "10:45 AM" },
    { key: "11:00:00", value: "11:00 AM" },
    { key: "11:15:00", value: "11:15 AM" },
    { key: "11:30:00", value: "11:30 AM" },
    { key: "11:45:00", value: "11:45 AM" },
    { key: "12:00:00", value: "12:00 NOON" },
    { key: "12:15:00", value: "12:15 PM" },
    { key: "12:30:00", value: "12:30 PM" },
    { key: "12:45:00", value: "12:45 PM" },
    { key: "13:00:00", value: "13:00 PM" },
    { key: "13:15:00", value: "13:15 PM" },
    { key: "13:30:00", value: "13:30 PM" },
    { key: "13:45:00", value: "13:45 PM" },
    { key: "14:00:00", value: "14:00 PM" },
    { key: "14:15:00", value: "14:15 PM" },
    { key: "14:30:00", value: "14:30 PM" },
    { key: "14:45:00", value: "14:45 PM" },
    { key: "15:00:00", value: "15:00 PM" },
    { key: "15:15:00", value: "15:15 PM" },
    { key: "15:30:00", value: "15:30 PM" },
    { key: "15:45:00", value: "15:45 PM" },
    { key: "16:00:00", value: "16:00 PM" },
    { key: "16:15:00", value: "16:15 PM" },
    { key: "16:30:00", value: "16:30 PM" },
    { key: "16:45:00", value: "16:45 PM" },
    { key: "17:00:00", value: "17:00 PM" },
    { key: "17:15:00", value: "17:15 PM" },
    { key: "17:30:00", value: "17:30 PM" },
    { key: "17:45:00", value: "17:45 PM" },
    { key: "18:00:00", value: "18:00 PM" },
    { key: "18:15:00", value: "18:15 PM" },
    { key: "18:30:00", value: "18:30 PM" },
    { key: "18:45:00", value: "18:45 PM" },
    { key: "19:00:00", value: "19:00 PM" },
    { key: "19:15:00", value: "19:15 PM" },
    { key: "19:30:00", value: "19:30 PM" },
    { key: "19:45:00", value: "19:45 PM" },
    { key: "20:00:00", value: "20:00 PM" },
    { key: "20:30:00", value: "20:30 PM" },
    { key: "21:00:00", value: "21:00 PM" },
    { key: "21:30:00", value: "21:30 PM" },
    { key: "22:00:00", value: "22:00 PM" },
    { key: "22:30:00", value: "22:30 PM" },
    { key: "23:00:00", value: "23:00 PM" },
    { key: "23:30:00", value: "23:30 PM" },
  ];

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={list}
    />
  );
};
