import { gql } from "@apollo/client";

export const viewer_gql = gql`
  {
    viewer: viewer_admin {
      name
      profile_pic
      allowed_role: admin_allowed_role {
        role: admin_role {
          name
          role_permission: admin_role_permission {
            permission: admin_permission {
              route
            }
          }
        }
      }
    }
  }
`;
