import React, { useContext, useEffect, useState } from 'react'
import { LoaderContext } from "../../../../../context/LoaderContext";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { handle_error } from '../reusable-calling-code-project/handle_error'
import axios from 'axios';
import styles from '../reusable-calling-code-project/table.module.css';
import { Container, Paper, Grid, MenuItem, TextField, Typography, Button, Dialog, DialogContent } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

// const api_url = 'http://localhost:8060/vijeta-calling/'
const api_url = 'https://api-dot-reformexam.el.r.appspot.com/vijeta-calling/'

const VijetaTransferLeads = () => {

    const loader = useContext(LoaderContext);
    const snackBar = useContext(SnackBarContext);

    const [array, setArray] = useState([]);
    const [show, setShow] = useState(false);
    const [admin_id, setAdminId] = useState([]);
    const [bulk, setBulk] = useState([]);
    const [check, setCheck] = useState();
    const [count, setCount] = useState();

    // let len = bulk.length;

    useEffect(() => {

        fetch();

    }, [])

    const fetch = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + 'transfer-data',
                method: 'post',
                responseType: 'json',
            })

            if (obj.Success === 'ok') {
                setArray(obj.array);
                setAdminId(obj.admin_id);
                setCount(obj.count);
            }
        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }
    }

    // console.log(len)


    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>

                    <Paper component={Paper} style={{ padding: '16px' }}>

                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={6}>Transfer Vijeta Leads &nbsp;&nbsp;&nbsp;<b>Count:</b>&nbsp;{count}</th>
                                </tr>
                                <tr>
                                    <th colSpan={5}>
                                        <TransferComp admin_id={admin_id} loader={loader} snackBar={snackBar} orderid={bulk} fetch={fetch} />
                                    </th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Checkbox</th>
                                    <th>Reg. Id.</th>
                                    <th>Name</th>
                                    <th>Scholarship</th>
                                    {/* <th>Action</th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {array.map((ele, i) => (
                                    <>

                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                                <CheckboxComp bulk={bulk} orderid={ele.orderid} />
                                            </td>
                                            <td>{ele.orderid}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.scholarship}%</td>
                                            {/* <td>
                                                <TransferComp admin_id={admin_id} loader={loader} snackBar={snackBar} orderid={ele.orderid} fetch={fetch} />
                                            </td> */}

                                        </tr>

                                    </>
                                ))}
                            </tbody>
                        </table>

                    </Paper>

                </Grid>
            </Grid>
        </Container>
    )
}

export default VijetaTransferLeads



const CheckboxComp = ({ orderid, bulk }) => {

    const [checked, setChecked] = useState(false);

    const handleChange = (e) => {

        setChecked(true);


        let val = e.target.value;
        let ch = e.target.checked;

        if (ch == true) {
            bulk.push(val);
        }

        else {
            let index = bulk.indexOf(val);
            bulk.splice(index, 1)
            setChecked(false);
        }

        // console.log(bulk)


    }


    return (
        <>

            <Checkbox checked={checked} value={orderid} onChange={handleChange} />

        </>
    )
}




const TransferComp = ({ admin_id, loader, snackBar, orderid, fetch }) => {

    const [show, setShow] = useState(false);

    const hide = () => {
        setShow(false);
    }

    const handleClick = async (id) => {
        try {
            loader.show();
            console.log(orderid);

            const { data: obj } = await axios({
                url: api_url + 'transfer-leads',
                method: 'post',
                responseType: 'json',
                data: {
                    orderid: orderid,
                    admin_id: id
                }
            })

            // console.log(obj);

            if (obj.Success === 'ok') {
                // fetch();
                snackBar.show(obj.message);
                hide();
                window.location.reload(false);
            }
        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }
    }



    return (
        <>

            <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => setShow(true)}>Transfer</Button>

            {show && <ModalComp hide={hide}>

                <div style={{ fontFamily: 'Arial' }}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th colSpan={3}>Transfer</th>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <th>
                                    Transfer
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {admin_id.map((n, i) => {
                                return (
                                    <tr style={{ fontSize: '13px' }}>
                                        <td>{n.name}</td>
                                        <td>
                                            <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(n.id)} >Transfer</Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>


                </div>



            </ModalComp>}

        </>
    )

}

const ModalComp = ({ children, hide }) => {

    return (
        <div>
            <Dialog open
                onClose={() => { hide() }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>

        </div>
    )
}
