import React from "react";
import FormLayoutAdmin from "../../../../../../utils/form-layout-admin";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import { CSVReader } from 'react-papaparse';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { getThemeProps } from "@material-ui/styles";

const _ = require("lodash");

export default function ResponseCSV(props) {

    var arr = [];
    var issue = [];
    // const user = "User's Answer";

    const handleStudentDrop = (data) => {

        var len = data.length;
        // var width = data[0].data.length;

        try {
            let error_roll = [];
            if (data[0].data.length !== 8) {
                alert("Please Upload correct CSV File");
            }
            else {

                if (data[0].data[0] !== 'Question No') {
                    alert("First Column should be of name: Question No in your CSV file")
                    return false;
                }
                if (data[0].data[1] !== 'Username') {
                    alert("Second Column should be of name: Username in your CSV file")
                    return false;
                }
                if (data[0].data[2] !== 'Name') {
                    alert("Third Column should be of name: Name in your CSV file")
                    return false;
                }
                if (data[0].data[3] !== "User's Answer") {
                    alert("Fourth Column should be of name: User's Answer in your CSV file")
                    return false;
                }
                if (data[0].data[4] !== 'Marks Awarded') {
                    alert("Third Column should be of name: Marks Awarded in your CSV file")
                    return false;
                }
                if (data[0].data[5] !== 'Is correct') {
                    alert("Third Column should be of name: Is correct in your CSV file")
                    return false;
                }
                if (data[0].data[6] !== 'Sub Topic Id') {
                    alert("Third Column should be of name: Sub Topic Id in your CSV file")
                    return false;
                }
                if (data[0].data[7] !== 'Section') {
                    alert("Third Column should be of name: Section in your CSV file")
                    return false;
                }
                else {
                    let a, c = 2, i, j, array = [], b, tmp, t_array = [];


                    for (i = 1; i < data.length - 1; i++) {
                        b = data[i].data[1]
                        tmp = data[i].data[0];
                        array.push({ roll_no: b });
                        t_array.push({ quesno: tmp });
                    }

                    let tmp_array = _.uniqBy(array, 'roll_no');
                    let ques_array = _.uniqBy(t_array, 'quesno');


                    let count = 0;
                    for (j = 0; j < tmp_array.length; j++) {
                        for (i = 1; i < data.length - 1; i++) {
                            if (tmp_array[j].roll_no == data[i].data[1]) {
                                count++;
                            }
                        }
                        if (count !== ques_array.length) {
                            error_roll.push({ roll_no: tmp_array[j].roll_no });
                        }
                        count = 0;
                    }

                    for (i = 1; i < len - 1; i++) {
                        // console.log(data[i].data);
                        arr.push({ QuestionNo: data[i].data[0], RollNo: data[i].data[1], Name: data[i].data[2], Response: data[i].data[3], MarksAwarded: data[i].data[4], IsCorrect: data[i].data[5], SubTopicId: data[i].data[6], Subject: data[i].data[7] });
                    }
                    for (a = 0; a < arr.length; a++) {
                        if (arr[a].QuestionNo < 1 || arr[a].QuestionNo > 200 || arr[a].QuestionNo.match(/^[a-zA-Z]+$/)) {
                            issue.push({ row: c, column: 1 });
                        }
                        if (arr[a].RollNo.length !== 8 || arr[a].RollNo.match(/^[a-zA-Z]+$/)) {
                            issue.push({ row: c, column: 2 })
                        }
                        if (arr[a].Name.match(/^[0-9]+$/)) {
                            issue.push({ row: c, column: 3 });
                        }
                        if (arr[a].Subject.match(/^[0-9]+$/)) {
                            issue.push({ row: c, column: 8 });
                        }
                        c++;
                    }
                    console.log(arr);
                }
            }
            props.handleMasterStudentDrop({ id: props.id, width: data[0].data.length, responseissue: issue, responsedata: arr, error: error_roll });
            // arrays = [];
        }
        catch (e) {
            alert("Error: " + e);
        }
    }

    return (
        <>
            <CSVReader onDrop={handleStudentDrop}>
                <span>Drop Response Schema</span>
            </CSVReader>
        </>
    )
}