import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MyCircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  circularprogress: {
    color: theme.palette.primary.color
  }
});

function CircularIndeterminate(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <br />
      <br />
      <br />
      <br />

      <center>
        <MyCircularProgress className={classes.circularprogress} size={25} />
      </center>
    </React.Fragment>
  );
}

CircularIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Spinner = withStyles(styles)(CircularIndeterminate);
