import { Container, TextField, Button } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { api_url } from '../reusable-calling-code-project/config';
import styles from '../reusable-calling-code-project/table.module.css';

const PTMAttendance = () => {

    const loader = useContext(LoaderContext);

    const [roll, setRoll] = useState('');
    const [show, setShow] = useState(false);
    const [token, setToken] = useState('');
    const [batch, setBatch] = useState('')

    const handleClick = async () => {

        try {

            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/attendance-data-calling/ptm-attendance',
                method: 'post',
                responseType: 'json',
                data: {
                    roll: roll,
                },
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setShow(true)
                setToken(obj.token);
                setBatch(obj.batch);
            }

            if (obj.success === 'false') {
                alert('Roll No.is Incorrect');
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }

    }

    return (
        <>

            <Container>
                <FormLayoutAdmin title="PTM Attendance">
                    <TextField
                        type="tel"
                        label="Roll Number"
                        value={roll}
                        placeholder="Enter Roll No."
                        onChange={(e) => setRoll(e.target.value)}
                    />

                    <br />
                    <br />

                    <Button fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={handleClick}><b>Submit</b></Button>
                </FormLayoutAdmin>
            </Container>

            <br />
            <br />

            {show && <Report token={token} batch={batch} loader={loader} setShow={setShow} />}

        </>
    )
}

export default PTMAttendance


const Report = ({ token, batch, loader, setShow }) => {


    const handleClick = async () => {

        try {

            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/attendance-data-calling/ptm-mark-attendance',
                method: 'post',
                responseType: 'json',
                data: {
                    token: token,
                    batch: batch,
                }
            })

            console.log(obj);

            if (obj.success === 'ok') {
                alert("Attendance Marked Successfully");
                setShow(false);
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }
    }

    return (
        <>


            <table className={styles.table}>
                <thead>
                    <tr>
                        <th colSpan={3}>PTM Mark Attendance</th>
                    </tr>
                    <tr>
                        <th>Roll No.</th>
                        <th>Batch</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{token}</td>
                        <td>{batch}</td>
                        <td>
                            <Button fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={handleClick}>Mark Attendance</Button>
                        </td>
                    </tr>
                </tbody>
            </table>


        </>
    )
}
