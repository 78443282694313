import React, { useContext, useState } from "react";
import axios from 'axios';
import { Container, TextField, Grid, Button } from "@material-ui/core";
import { courseware_upload_url } from "../../../../../config";
import FormLayoutcourseware from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_batch_notification_mutation_gql } from "../../../gql/mutation/create-batch-notification-mutation";
import SelectNotification from "../../components/select-notification";
import SelectSessionBatch from "../../components/select-multiple-batch-sessionwise";

export default () => {
  const [create_batch_notification_mutation] = useMutation(
    create_batch_notification_mutation_gql
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_batch_notification_mutation={create_batch_notification_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_batch_notification_mutation }) => {
  const [values, setValues] = useState({
    notification_id: "",
    batch_id:[]
  });
  const handleInputChange = (e) => {
     handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { notification_id, batch_id } = values;

      loader.show();

      let {
        data: {
          create_batch_notification : { success },
        },
      } = await create_batch_notification_mutation({
        variables: {
            notification_id,
            batch_id
        },
      });

      if (success) snackBar.show("batch notification created successfully");
      //resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutcourseware title="Create Batch Courseware">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <SelectNotification
                label="Notifications"
                name="notification_id"
                value={values.notification_id}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
            <SelectSessionBatch
              label="Select Batch"
              onChange={handleInputChange}
              name="batch_id"
              value={values.batch_id}
            />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutcourseware>
      </Container>
    </form>
  );
};
