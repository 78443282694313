import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutclassroom_test_type from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_classroom_test_batch } from "../../../gql/mutation/create-classroom_test_batch-mutation";
import Typography from "@material-ui/core/Typography";
import SelectSessionCoursePhaseBatchTestType from "../../components/select-session-course-phase-batch-classroomtest-type";
import SelectClassroomTest from "../../components/select-classroom-test";

export default () => {
  const [create_classroom_test_batch_mutation] = useMutation(
    create_classroom_test_batch
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
    create_classroom_test_batch_mutation={
      create_classroom_test_batch_mutation
      }
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_classroom_test_batch_mutation }) => {
  const [values, setValues] = useState({
    classroom_test_id: "",
    subject_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { batch_id , classroom_test_id } = values;

      loader.show();

      let {
        data: {
          create_classroom_test_batch: { success },
        },
      } = await create_classroom_test_batch_mutation({
        variables: { classroom_test_id, batch_id },
      });

      if (success) snackBar.show("classroom_test_batch created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutclassroom_test_type title="Create Classroom Test Batch">
          <Grid spacing={3} container>
            <SelectSessionCoursePhaseBatchTestType
              name="classroom_test_type_id"
              onChange={handleInputChange}
            />
            <Grid item xs={12}>
              <SelectClassroomTest
                classroom_test_type_id={values.classroom_test_type_id}
                label="Test"
                name="classroom_test_id"
                value={values.classroom_test_id}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutclassroom_test_type>
      </Container>
    </form>
  );
};
