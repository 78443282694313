import React, { useState, useContext } from 'react'
import readXlsxFile from 'read-excel-file'
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { Container, Grid, TextField, Button } from '@material-ui/core';
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
const XLSX = require("xlsx");

const CreateICard = () => {

    const loader = useContext(LoaderContext);

    const [rows, setRows] = useState([]);
    const [roll, setRoll] = useState();
    const [show, setShow] = useState(false);

    // const api_endpoint = 'http://localhost:8060/upload-photo';
    const api_endpoint = 'https://api-dot-reformexam.el.r.ap\pspot.com/upload-photo';

    const showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            console.log(text)
        };

        // console.log(e);

        readXlsxFile(e.target.files[0], { dateFormat: 'yyyy-mm-dd' }).then((row) => {
            // setRows(row);
            const a = row[0];
            if (a[0] != 'Roll No')
                alert('Heading of first column should be Roll No');
            else {
                setRows(row);
                let i, str = '';
                for (i = 1; i < row.length; i++) {
                    str += `${row[i]},`
                }

                str = str.slice(0, -1);

                console.log(str);

                setRoll(str);

                setShow(true);
            }
        })
    }

    const handleSubmit = async () => {

        window.open(`${api_endpoint}/full-fee-i-card?roll=${roll}&type=paid`, "_blank");

    }

    const handleClick = async () => {

        window.open(`${api_endpoint}/full-fee-i-card?roll=${roll}&type=partial`, "_blank");

    }

    const handleCreatedExcel = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_endpoint + '/download-icard-excel',
                method: 'post',
                responseType: 'json',
                data: {
                    roll: roll,
                }
            })

            if (obj.success === 'ok') {

                let result = obj.array, result1 = obj.array2;

                const worksheet = XLSX.utils.json_to_sheet(result);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
                XLSX.writeFile(workbook, `Created I-Card List.xlsx`);
                alert('Download Successfully')

                const worksheet1 = XLSX.utils.json_to_sheet(result1);
                const workbook1 = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook1, worksheet1, "MySheet");
                XLSX.writeFile(workbook1, `Remaining I-Card List.xlsx`);
                alert('Download Successfully')

            }
        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }
    }



    return (
        <>

            <Grid container scpacing={5}>

                <Grid item xs={6}>
                    <Container>
                        <FormLayoutAdmin title={"Create I-Card"}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <input type="file" id="contained-button-file" style={{ display: 'none' }} onChange={(e) => showFile(e)} />
                                    <label htmlFor="contained-button-file">
                                        <Button variant="outined"
                                            startIcon={<CloudUploadIcon />}
                                            component="span">
                                            Upload
                                        </Button>
                                    </label>
                                    {show && <label>Excel Uploaded</label>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullwidth style={{ border: '1px solid purple', borderStyle: 'dashed' }} onClick={handleSubmit}>Create I-Card (Full Fee Submitted)</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullwidth style={{ border: '1px solid purple', borderStyle: 'dashed' }} onClick={handleClick}>Create I-Card (Partial Fee Submitted)</Button>
                                </Grid>
                            </Grid>
                        </FormLayoutAdmin>
                    </Container>
                </Grid>

                <Grid item xs={6}>
                    <Container>
                        <FormLayoutAdmin title={"Download I Card Excel"}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Button fullwidth style={{ border: '1px solid purple', borderStyle: 'dashed' }} onClick={handleCreatedExcel}>Download Excels (Two excels will be downloaded)</Button>
                                </Grid>
                            </Grid>
                        </FormLayoutAdmin>
                    </Container>
                </Grid>
            </Grid>

        </>
    )
}

export default CreateICard
