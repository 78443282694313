import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import LiveClassroom from "../live-classroom";
import DigitalClassroom from "../digital-classroom";
import Courseware from "../courseware";
import LatestNews from "./latest-news";
import ClassroomTestSyllabus from "../classroom-test-syllabus";
import AllTestResult from "../classroom-test-result/all-test-result";
export default () => (
  <>
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item lg={4} md={12} xl={9} xs={12}>
          <LiveClassroom is_home_component />
        </Grid>
        <Grid item lg={8} md={8} xl={8} xs={12}>
          <AllTestResult is_home_component />
        </Grid>
        {/* <Grid item lg={4} md={6} xl={3} xs={12}>
          <DigitalClassroom is_home_component />
        </Grid> */}
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Courseware is_home_component />
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <LatestNews is_home_component />
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <ClassroomTestSyllabus is_home_component />
        </Grid>
      </Grid>
    </Container>
  </>
);
