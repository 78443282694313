import React from 'react';

import Btn from '@material-ui/core/Button';



export const Button = ({ disabled, endIcon, startIcon, variant = "contained", color = 'primary', title, type, fullWidth = true, onClick, style }) => {


  return (

    <Btn variant={variant} disabled={disabled} color={color} type={type || 'button'} fullWidth={fullWidth} onClick={onClick}

      startIcon={startIcon} endIcon={endIcon} style={{ ...style }}
    >
      {title}
    </Btn>

  );
}