import React from 'react'
import FormLayoutAdmin from "../../../../../../utils/form-layout-admin";
import { Container, TextField, Grid, Button, MenuItem, Select, InputLabel, Paper } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import { CSVReader } from 'react-papaparse';
import axios from 'axios'
import { LoaderContext } from "../../../../../../context/LoaderContext";
import styles from '../../reusable-calling-code-project/table.module.css';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import csvDownload from 'json-to-csv-export';

const EnquiryDataMIS = () => {

    const loader = useContext(LoaderContext);

    const [day, setDay] = useState();
    const [rnet, setRnet] = useState([]);
    const [admission, setAdmission] = useState([]);
    const [enquiry, setEnquiry] = useState([]);
    const [show, setShow] = useState(false);


    const handleClick = async () => {
        try {

            loader.show();

            if (day.length > 0) {
                const { data: obj } = await axios({
                    url: 'https://api-dot-reformexam.el.r.appspot.com/mis/enquiry-data-mis',
                    method: 'post',
                    responseType: 'json',
                    data: {
                        day: day,
                    },
                })

                console.log(obj);

                if (obj.success === 'ok') {
                    setRnet(obj.rnet);
                    setAdmission(obj.admission);
                    setEnquiry(obj.enquiry);
                    setShow(true);
                }
            }
            else {
                alert("Please Select Day");
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }
    }

    const handleDownloadRnet = () => {
        csvDownload(rnet, 'rnet-data.csv')
    }

    const handleDownloadAdmission = () => {
        csvDownload(admission, 'admission-data.csv')
    }
    const handleDownloadEnquiry = () => {
        csvDownload(enquiry, 'enquiry-data.csv')
    }

    return (
        <>
            <Container>
                <FormLayoutAdmin title={"Date-Wise MIS"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Select
                                value={day}
                                onChange={(e) => setDay(e.target.value)}
                                style={{ minWidth: '150px' }}
                                label={"Select Day"}
                            >
                                <em>Select</em>
                                <MenuItem value="0">Today</MenuItem>
                                <MenuItem value="1">Yesterday</MenuItem>
                                <MenuItem value="2">Day Before Yesterday</MenuItem>
                                <MenuItem value="3">2-Days Before Yesterday</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullwidth onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
                        </Grid>
                    </Grid>
                </FormLayoutAdmin>
            </Container>

            <br />

            <br />

            <br />

            {show && <Container>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={9}>RNET Data
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ArrowDownwardIcon cursor={"pointer"} onClick={handleDownloadRnet} /></th>

                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Form No.</th>
                                        <th>Scan Date</th>
                                        <th>Name</th>
                                        <th>Class</th>
                                        <th>Mobile1</th>
                                        <th>Mobile2</th>
                                        <th>Mobile3</th>
                                        <th>Test Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rnet.map((ele, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.fno}</td>
                                                <td>{ele.REGDATE}</td>
                                                <td>{ele.name}</td>
                                                <td>{ele.SCLASS}</td>
                                                <td>{ele.MOBILE1}</td>
                                                <td>{ele.MOBILE2}</td>
                                                <td>{ele.MOBILE3}</td>
                                                <td>{ele.Test}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={9}>Admission Data
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ArrowDownwardIcon cursor={"pointer"} onClick={handleDownloadAdmission} /></th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Form No.</th>
                                        <th>Form Purchased Date</th>
                                        <th>Admission Date</th>
                                        <th>Name</th>
                                        <th>Mobile1</th>
                                        <th>Mobile2</th>
                                        <th>Mobile3</th>
                                        <th>Class</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {admission.map((ele, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.fno}</td>
                                                <td>{ele.Form}</td>
                                                <td>{ele.Admission}</td>
                                                <td>{ele.name}</td>
                                                <td>{ele.mobile1}</td>
                                                <td>{ele.mobile2}</td>
                                                <td>{ele.mobile3}</td>
                                                <td>{ele.classs}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={13}>Enquiry Data
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ArrowDownwardIcon cursor={"pointer"} onClick={handleDownloadEnquiry} /></th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Reg. Id.</th>
                                        <th>Form No.</th>
                                        <th>Name</th>
                                        <th>Scan Date</th>
                                        <th>Mobile1</th>
                                        <th>Mobile2</th>
                                        <th>Mobile3</th>
                                        <th>Class</th>
                                        <th>Test Date</th>
                                        <th>Form Fee Paid</th>
                                        <th>Source</th>
                                        <th>Admin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {enquiry.map((ele, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.ORDERID}</td>
                                                <td>{ele.fno}</td>
                                                <td>{ele.name}</td>
                                                <td>{ele.REGDATE}</td>
                                                <td>{ele.MOBILE1}</td>
                                                <td>{ele.MOBILE2}</td>
                                                <td>{ele.MOBILE3}</td>
                                                <td>{ele.class}</td>
                                                <td>{ele.Test}</td>
                                                <td>{ele.Form}</td>
                                                <td>{ele.source}</td>
                                                <td>{ele.Admin}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>}
        </>
    )
}

export default EnquiryDataMIS
