import React, { useState, useEffect, useContext } from 'react';
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { Select } from "../../../../../../utils/select";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { api_url, rest_headers } from '../../../../../student/app/srceens/digital-classroom/config';
import { Container, TextField, Grid, Button, Paper } from "@material-ui/core";
import styles from '../../reusable-calling-code-project/table.module.css';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
const axios = require('axios');


const ShowUploadedVideo = () => {

    const loader = useContext(LoaderContext);


    const [res, setRes] = useState([]);
    const [video_lecture_topic_id, setId] = useState('');
    const [lecture_id, setLectureId] = useState([]);
    const [list, setList] = useState([]);


    useEffect(async () => {

        try {

            loader.show();

            const { data } = await axios({
                url: api_url + '/video-lecture-topic-list',
                method: 'post',
                responseType: 'json',
                data: {
                    division: null,
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            setRes(data.list)
        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }

    }, []);

    const handleChange = (e) => {

        const val = e.target.value;

        setId(val);

        refresh(val);

    }

    const refresh = async (val) => {

        try {

            loader.show();

            const { data } = await axios({
                url: api_url + '/video-lecture-topic-lecture-list',
                data: {
                    video_lecture_topic_id: val,
                },
                method: 'post',
                responseType: 'json',
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })


            setList(data.array)

        } catch (e) {

            alert(e.message)
        } finally {
            loader.hide();
        }

    }





    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormLayouttopic title="Share Video to Student">
                        <Grid spacing={3} container>
                            <Grid item xs={12}>
                                {res && <Select
                                    label={"Select Topic"}
                                    value={video_lecture_topic_id}
                                    name={'video_lecture_topic_id'}
                                    onChange={handleChange}
                                    array={res}
                                />}
                            </Grid>
                        </Grid>
                    </FormLayouttopic>
                </Grid>
                <Grid item xs={12}>

                    {list.length > 0 && <Grid spacing={3} container>

                        <Grid item xs={12}>

                            <Paper component={Paper} style={{ padding: '16px' }}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>Uploaded Videos</th>
                                        </tr>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Lecture</th>
                                            <th>View Lecture</th>
                                            <th>Delete Lecture</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map((ele, i) => (
                                            <>

                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{ele.value}</td>
                                                    <td><View title={ele.value} vimeo_link={ele.link} topic={ele.topic} /></td>
                                                    <td><Delete video_lecture_topic_id={video_lecture_topic_id} refresh={refresh} loader={loader} keys={ele.key} /></td>
                                                </tr>

                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </Paper>

                        </Grid>

                    </Grid>}
                </Grid>
            </Grid>

        </Container>
    )
}

export default ShowUploadedVideo;


const Delete = ({ keys, loader, refresh, video_lecture_topic_id }) => {

    const handleClick = async () => {
        try {
            loader.show()

            console.log(video_lecture_topic_id)

            const { data: obj } = await axios({
                url: api_url + '/video-lecture-delete-lecture',
                method: 'post',
                responseType: 'json',
                data: {
                    keys: keys
                }
            })

            if (obj.success === 'ok') {
                alert("Lecture Deleted Successfully");
            }

            refresh(video_lecture_topic_id);

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }
    }

    return (
        <Button style={{ border: '1px solid dashed', backgroundColor: '#eee' }} onClick={handleClick}>Delete</Button>
    )

}



const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const View = ({ title, topic, vimeo_link }) => {

    const [show, setShow] = useState(false);

    const onClose = () => {
        setShow(false);
    }

    return (
        <>
            <Button style={{ border: '1px solid dashed', backgroundColor: '#eee' }} onClick={(e) => setShow(true)}>View</Button>

            {
                show && <FullScreenDialog title={title} topic={topic} vimeo_link={vimeo_link} onClose={onClose} />
            }
        </>
    )
}


const FullScreenDialog = ({ title, topic, vimeo_link, onClose }) => {
    const classes = useStyles();

    return (
        <div>

            <Dialog fullScreen open onClose={onClose}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>


                    </Toolbar>
                </AppBar>
                <br />
                <center><div style={{ width: '100%', maxWidth: 400 }}>
                    <div dangerouslySetInnerHTML={{ __html: `<div style="padding:75% 0 0 0;position:relative;"><iframe src="${vimeo_link}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&portrait=0&byline=0&title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="sample-mp4-file.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>` }}></div>
                </div>
                    <br />
                    <Typography variant="h3">{topic}</Typography>
                    <br /><Typography variant="h4">{title}</Typography>
                </center>

            </Dialog>
        </div>
    );
}
