import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../reusable-calling-code-project/config'
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import Profile from '../reusable-calling-code-project/reuable-calling-code/profile'
import moment from 'moment';
const axios = require('axios');
const _ = require("lodash");

export default (props) => {
    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);
    return (
        <Comp
            snackBar={snackBar}
            loader={loader}
            {...props}
        />
    );
};
const Comp = ({ loader, snackBar, ...props }) => {

    const [show, setShow] = useState(false);
    const [sorted, setSorted] = useState(null);
    const [asorted, setASorted] = useState(null);
    const [displayed, setDisplayed] = useState('ASC');
    const [display, setDisplay] = useState('ASC');
    const [ubatch, setBatch] = useState([]);
    const [course_Title, setCourseTitle] = useState([]);
    const [res, setRes] = useState([
        /*{
            "id": 12,

            "mobile": "8118846657",
            "mobile2": "9999999999",

            "name": "Sakshi bhartia",
            "roll_no": "2211111",
            "classs": "8",
            "last_remark": "10000068 payment done ",
            "last_callback": '2022-03-29T16:19',
            "created_at": "11-Oct, 2021 16:18"


        }, {
            "id": 13,

            "mobile": "811846657",
            "mobile2": "9991199999",

            "name": "Ajar hartia",
            "roll_no": "2211123",
            "classs": "9",
            "last_remark": "10000068 payment done ",
            "last_callback": '2022-03-27T16:19',
            "created_at": "11-Oct, 2021 16:18"


        }*/
    ]);
    const refresh = () => {
        fetch()
    }

    useEffect(async () => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        fetch();
    }, [])

    const fetch = async () => {
        try {

            loader.show();
            const { data } = await axios({
                url: api_url + '/fee-data-calling/calling-data',
                method: 'post',
                responseType: 'json',
            })

            console.log(data);

            var i, len = data.arr.length;
            for (i = 0; i < len; i++) {
                if (data.arr[i].last_category == null) {
                    data.arr[i].last_category = 'null';
                }
            }

            let a = [], b = [];
            for (i = 0; i < len; i++) {
                a.push({ batch: data.arr[i].batch });
                b.push({ coursetitle: data.arr[i].coursetitle });
            }
            console.log(a);
            let batch = _.uniqBy(a, 'batch');
            setBatch(batch);

            let coursetitle = _.uniqBy(b, 'coursetitle');
            setCourseTitle(coursetitle);

            setRes(data.arr)




        } catch (e) {

            snackBar.show(e);
        } finally {
            loader.hide();
        }
    }

    const handleSort = () => {
        const tmp = res;
        if (sorted === null) {
            const value = [...tmp].sort((a, b) => new Date(a.date) - new Date(b.date));
            setRes(value);
            setDisplay('ASC');
            setSorted('asc');
        }
        if (sorted == 'asc') {
            const value = [...tmp].sort((a, b) => new Date(b.date) - new Date(a.date));
            setRes(value);
            setDisplay('DESC')
            setSorted(null);
        }
    }

    const handleSorted = () => {
        const tmp = res;
        if (asorted === null) {
            const value = [...tmp].sort((a, b) => a.less_amount - b.less_amount);
            setRes(value);
            setDisplayed('ASC')
            setASorted('asc');
        }
        if (asorted == 'asc') {
            const value = [...tmp].sort((a, b) => b.less_amount - a.less_amount);
            setRes(value);
            setDisplayed('DESC')
            setASorted(null);
        }
    }

    return (
        <div>

            {res && <Profile courset={course_Title} ubatch={ubatch} handleSorted={handleSorted} displayed={displayed} display={display} project='project3' handleSort={handleSort} array={res} refresh={refresh} snackBar={snackBar} loader={loader} {...props} />}

        </div>
    )
}