import { gql } from '@apollo/client';

export default gql`
{
    quiz_list {
      id
      date
      quiz{
        id
        name
      }
    }
  }  
`;