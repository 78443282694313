import React, { useEffect, useState, useContext } from 'react'
import { LoaderContext } from "../../../../../context/LoaderContext";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import axios from 'axios';
import styles from '../reusable-calling-code-project/table.module.css';
import { Container, Paper, Grid, MenuItem, TextField, Typography, Button, Dialog, DialogContent } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { handle_error } from '../reusable-calling-code-project/handle_error'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


// const api_url = 'http://localhost:8060/vijeta-calling/'
const api_url = 'https://api-dot-reformexam.el.r.appspot.com/vijeta-calling/'


const VijetaCalling = () => {

    const loader = useContext(LoaderContext);
    const snackBar = useContext(SnackBarContext);

    const [array, setArray] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {

        fetch();

    }, [])

    const fetch = async () => {
        try {

            loader.show();

            const { data: obj } = await axios({
                url: api_url + 'calling-data',
                method: 'post',
                responseType: 'json',
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            // console.log(obj);

            if (obj.success === 'ok') {
                setArray(obj.array);
                setShow(true);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }
    }


    return (
        <>

            <Container>
                {show && <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper component={Paper}>
                            <table className={styles.table}>

                                <thead>
                                    <tr>
                                        <th colSpan={11}>{(array[0].classs == 12)
                                            ? <>Vijeta Data</>
                                            : <>Vikaas Data</>}</th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Roll No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Batch</th>
                                        <th>Class</th>
                                        <th>Scholarship</th>
                                        <th>
                                            {(array[0].classs == 12)
                                                ? <>Fee (till 23th Jan)</>
                                                : <>Fee (till 31st Jan)</>}
                                        </th>
                                        <th>{(array[0].classs == 12)
                                            ? <>Fee (till 31st Jan)</>
                                            : <>Fee (till 31st March)</>}</th>
                                        <th>{(array[0].classs == 12)
                                            ? <>Fee (after 31st Jan)</>
                                            : <>Fee (after 31st March)</>}</th>
                                        <th>Conversation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {array.map((ele, i) => (
                                        <>

                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.roll_no}</td>
                                                <td>{ele.student}</td>
                                                <td>
                                                    <b>Mobile1</b><CopyIcon mobile={ele.mobile1} snackBar={snackBar} /><br /><br />
                                                    <b>Mobile2</b><CopyIcon mobile={ele.mobile2} snackBar={snackBar} />
                                                </td>
                                                <td>{ele.batch}</td>
                                                <td>{ele.classs}</td>
                                                <td>{ele.scholarship}%</td>
                                                <td>{ele.fee1}/-</td>
                                                <td>{ele.fee2}/-</td>
                                                <td>{ele.fee3}/-</td>
                                                <td>
                                                    <ConversationComp loader={loader} last_remark={ele.last_remark} last_category={ele.last_category} dates={ele.dates} orderid={ele.orderid} snackBar={snackBar} />
                                                </td>
                                            </tr>

                                        </>
                                    ))}
                                </tbody>

                            </table>
                        </Paper>
                    </Grid>
                </Grid>}
            </Container>

        </>
    )
}

export default VijetaCalling

const CopyIcon = ({ mobile, snackBar }) => {
    const mask = (mobile) => {
        try {
            return mobile.replace(/\d(?=\d{4})/g, "*");
        } catch (e) {

        }
    }
    return (
        <>
            {mask(mobile)}&nbsp;&nbsp;
            <FileCopyIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" onClick={() => {
                navigator.clipboard.writeText(mobile);
                snackBar.show('Copied!');
            }} />
        </>
    )
}

const ConversationComp = ({ last_remark, last_category, dates, loader, snackBar, orderid }) => {

    const [category, setCategory] = useState(last_category)
    const [text, setText] = useState(last_remark);

    const classes = useStyles();

    useEffect(() => {
        setCategory(last_category);
        setText(last_remark)
    }, [orderid])


    const handleClick = async () => {
        try {
            loader.show();

            alert("Are you sure to proceed")

            const { data: obj } = await axios({
                url: api_url + 'create-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    orderid: orderid,
                    text: text,
                    category: category
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            if (obj.Success === 'ok') {
                snackBar.show(obj.message);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }
        finally {
            loader.hide()
        }
    }


    return (
        <>


            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Category"
                >
                    <MenuItem value="">
                        <em>Select Category</em>
                    </MenuItem>
                    <MenuItem value="Admission Done">Admission Done</MenuItem>
                    <MenuItem value="Already Enrolled">Already Enrolled</MenuItem>
                    <MenuItem value="Disqualified">Disqualified</MenuItem>
                    <MenuItem value="Follow Up">Follow Up</MenuItem>
                    <MenuItem value="Form Filled">Form Filled</MenuItem>
                    <MenuItem value="Financial Issue">Financial Issue</MenuItem>
                    <MenuItem value="Interested">Interested</MenuItem>
                    <MenuItem value="Joined other Institute">Joined other Institute</MenuItem>
                    <MenuItem value="Not Able To Connect">Not Able To Connect</MenuItem>
                    <MenuItem value="Will Visit Campus">Will Visit Campus</MenuItem>
                    <MenuItem value="Will Apply Online">Will Apply Online</MenuItem>
                    <MenuItem value="Will Deposit Course Fee">Will Deposit Course Fee</MenuItem>
                </Select>

                <br />

                <TextField
                    // label={"Remark"}
                    placeholder="Enter Remark"
                    type="text"
                    variant="outlined"
                    // fullWidth
                    // style={{ width: '50%' }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />

                <br />
                <Button onClick={handleClick} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Submit</Button>
                <br />
                <ViewComp orderid={orderid} loader={loader} snackBar={snackBar} />
                <br />
                <Typography variant='h6'>Date:-&nbsp;&nbsp;{moment(dates).format('Do MMMM YYYY')} </Typography>

            </FormControl>




        </>
    )

}

const ViewComp = ({ orderid, loader, snackBar }) => {

    const [array, setArray] = useState([]);
    const hide = () => {
        setArray([]);
    }

    const handleClick = async (orderid) => {
        try {
            loader.show();

            const { data } = await axios({
                url: api_url + 'view-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    orderid: orderid
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            console.log(data);
            setArray(data.array)


        } catch (e) {
            snackBar.show(handle_error(e));
        } finally {
            loader.hide();
        }

    }


    return (<>

        <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(orderid)}>View</Button>

        {array && array.length > 0 && <ModalComp hide={hide}>
            <div style={{ fontFamily: 'Arial' }}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={3}>Conversation</th>
                        </tr>
                        <tr>
                            <th>Remark</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {array.map((n, i) => {
                            return (
                                <tr style={{ fontSize: '13px' }}>
                                    <td>{n.text}</td>
                                    <td>{n.date}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>


            </div>


        </ModalComp>

        }

    </>)
}

const ModalComp = ({ children, hide }) => {

    return (
        <div>
            <Dialog open
                onClose={() => { hide() }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>

        </div>
    )
}
