import React,{useState,useEffect,useContext} from 'react';
 import {api_url,rest_headers} from '../config'
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { Select } from "../../../../../../utils/select";
import { Select as SelectMultiple } from "../../../../../../utils/select/select-multiple";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
import moment from 'moment';
 import styles from '../../call-center/table.module.css'; 
const axios = require('axios');


export default (props) => {
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
		  {...props}
    />
  );
};
const Comp = ({ loader, snackBar,...props })=>{
	 
	 const [result,setResult]=useState(null)
  useEffect(async()=>{
	   document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
  fetch1();  
  },[])
  
  const fetch1=async()=>{
	  try {
 
   loader.show(); 
   const  {data}=await axios({
        url: api_url+'/view-mis-batch-wise-count',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	    
	 
 setResult(data.data)
  

      
    
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 
   if(!result)
   return <></>
 
	return(
	 <Container>
	 <FormLayouttopic title="Batch wise count">
           
		   
	<Result1 {...result}/>
	  
	</FormLayouttopic>
	</Container>
 
	)
}
 
 
 
 
 
 const Result1=({result1})=>{
 
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Count</th></tr>
          <tr>
            <th>ct</th>
      
			<th  >Batch</th>
			<th  >Session</th>
			
            
          </tr>
        </thead>
		  <tbody>
  
  {
	  result1.map(({ct,batch,session})=>{
		  
		  return(
		 <tr>
		   <td>{ct}</td> <td>{batch}</td> <td>{session}</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 
 
 
  