import moment from 'moment';
import { useEffect, useState } from 'react'
import { api_url, rest_headers } from '../config'
import { handle_error } from '../handle_error'
import Modal from '../modal'
import SendIcon from '@material-ui/icons/Send';
import confirm_click from './confirm_click'
import VisibilityIcon from '@material-ui/icons/Visibility';
import ViewStudentAbsent from '../../view-student-attendance';
const axios = require('axios');




export default function ButtonComp({ roll_no, loader, snackBar, project }) {

    const [show, setShow] = useState(false);

    const hide = () => {
        setShow(false);
    }

    const handleClick = () => {
        setShow(true);
    }
    return (
        <>
            <button onClick={handleClick}>View</button>

            {show && <Modal hide={hide}>

                <ViewStudentAbsent roll_no={roll_no} hide={hide} setShow={setShow} />

            </Modal>

            }
        </>
    )
}