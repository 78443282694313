import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Button, Paper } from "@material-ui/core";
import styles from '../reusable-calling-code-project/table.module.css';
// import XLSX from 'xlsx';
import { LoaderContext } from "../../../../../context/LoaderContext";
const XLSX = require("xlsx");

export default function DataCalling() {
    const loader = useContext(LoaderContext);
    const [result, setResult] = useState([]);
    const [date, setDate] = useState()
    const api_url = 'https://api-dot-reformexam.el.r.appspot.com/attendance-data-calling';
    useEffect(() => {
        fetch();
    }, [])
    const fetch = async () => {
        try {
            loader.show();
            const { data: obj } = await axios({
                // url: 'http://localhost:8060/attendance-data-calling/absent-data',
                url: api_url + '/absent-data',
                method: 'post',
                responseType: 'json'

            })
            setResult(obj.data)
            // console.log(obj.data)
            // console.log(obj.today)
            setDate(obj.today)
        }
        catch (e) {
            alert(e.message)
        }
        finally {
            loader.hide()
        }
    }
    const handleExport = () => {

        // let d = '2023-07-24';

        const worksheet = XLSX.utils.json_to_sheet(result);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
        XLSX.writeFile(workbook, `absent-data-${date}.xlsx`);
        alert('Download Successfully')
    }
    return (
        <>
            <Button type="submit" variant="contained" color="primary" onClick={handleExport}>Download Excel</Button>
            <br></br>
            <br></br>
            <div >
                <Paper component={Paper} style={{ padding: '16px', marginLeft: '20%', marginRight: '20%' }} >

                    <br></br>
                    <table className={styles.table} style={{ fontSize: '11px' }}>
                        <thead>
                            <tr>
                                <th colSpan={8}>Absentees

                                </th>
                            </tr>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>RollNo</th>
                                <th>Batch</th>
                                <th>Mobile1</th>
                                <th>Mobile2</th>
                                <th>Mobile3</th>
                                <th>Last Punch Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result.map((ele, i) => {
                                return (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.Name}</td>
                                        <td>{ele.RollNo}</td>
                                        <td>{ele.batch}</td>
                                        <td>{ele.mobile1}</td>
                                        <td>{ele.mobile2}</td>
                                        <td>{ele.mobile3}</td>
                                        <td>{ele.date}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Paper>
            </div>
        </>
    )
}