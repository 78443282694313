import { gql } from '@apollo/client';


const quiz_gql_string = ` quiz(id: $id,preview:$preview) {
  id
  date
  total_time
  start_time
  end_time
    quiz{
      id
      name
    }
    quiz_question{
      id
      question{
        id
        type
        content{
          id
          language
          content
        }
        subtopic{
          id
          name
          topic{
            id
            name
            childsubject{
              id
              name
              subject{
                id
                name
              }
            }
          }
        }
        subquestion{
          id
          type
          content {
            id
            language
            content
            solution_content
          }
          option {
            id
            is_correct
            content {
              id
              language
              content
            }
          }
        }
      }
      quiz_subquestion {
        id
        subquestion {
          id
          type
          content {
            id
            language
            content
            solution_content
          }
          option {
            id
            is_correct
            content {
              id
              language
              content
            }
          }
        }
      }
    }
}`


const preview_quiz_gql_string = `
  attempt(id: $id) {
    id 
    attempt_quiz_question {
      id
      quiz_question {
        id
      }
      flag
      visited
      answered
      attempt_quiz_subquestion {
        quiz_subquestion{
          id
        }
        attempt_quiz_subquestion_option {
          id
          option {
            id
          }
        }
      }
    }
  }


`



export const quiz_gql = gql`
query quiz($id: Int!,$preview:Boolean) {
 ${quiz_gql_string}
}
  
`
export const preview_quiz_gql = gql`
query quiz($id: Int!,$preview:Boolean) {
  ${quiz_gql_string}
  ${preview_quiz_gql_string}
 }
`



export const submit_gql = gql`
mutation quiz_submit($quiz: String!) {
  quiz_submit(quiz: $quiz) {
    success
  }
}`