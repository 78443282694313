import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange,state_city_list,state }) => {
   let i=0,len=state_city_list.length,city_list=[],tmp_city;
   for(i=0;i<len;i++){
	   if(state_city_list[i].state===state){
		   tmp_city=state_city_list[i].city;
		   city_list.push({key:tmp_city,value:tmp_city})
	   }
   }
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={city_list}
    />
  );
};
