import * as React from 'react';
// import Link from '@mui/material/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from './title';
import { Typography } from '@material-ui/core';

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles({
    container: {
        marginTop: 15,
        width: '100%'
    },
    table: {
        width: '100%'
    },
    title: {
        margin: 20
    }
});

export default function ResponseTable(props) {
    const rows = props.data;
    const subject = props.subject;
    const classes = useStyles();
    console.log(rows);
    return (
        <>
            <TableContainer component={Paper}>
                <div style={{ borderBottom: '1px solid #d6d6d6', height: "auto" }}>
                    <Title>Topic Wise Analysis</Title>
                </div>
                <br />
                {subject.map((ele, index) => (
                    <>
                        <div style={{ paddingLeft: '10px' }}>
                            <Typography component="h2" variant="h5"><b>{ele.name}</b></Typography>
                        </div>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell style={{ textAlign: 'center' }}>Sr. No.</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Subject</TableCell> */}
                                    <TableCell style={{ textAlign: 'left' }}><b>Topic</b></TableCell>
                                    <TableCell style={{ textAlign: 'center' }}><b>Correct</b></TableCell>
                                    <TableCell style={{ textAlign: 'center' }}><b>Incorrect</b></TableCell>
                                    <TableCell style={{ textAlign: 'center' }}><b>Not Attempted</b></TableCell>
                                    <TableCell style={{ textAlign: 'center' }}><b>Total</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    (row.name === ele.name)
                                        ? <TableRow key={row.id}>
                                            {/* <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>{row.subject}</TableCell> */}
                                            <TableCell style={{ textAlign: 'left' }}>{row.topic}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.correct_count}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.incorrect_count}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.not_attempted_count}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.total_count}</TableCell>
                                        </TableRow>
                                        : <></>
                                ))}
                            </TableBody>

                        </Table>
                        <br /><br />
                    </>
                ))}
            </TableContainer>
        </>
    );
}