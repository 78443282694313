import React from 'react';
import ShareVideo from '../share-video';

const SHareVideoJunior = () => {
    return (
        <div>
            <ShareVideo division="Junior" />
        </div>
    )
}

export default SHareVideoJunior
