import {gql} from '@apollo/client';

export const notification_gql = gql`
  {
    batch_notification_list{
      created_at,  
      notification {
          title
          description
          type
          url
        }
      }
  }`