import React, { useEffect, useState, useContext } from 'react'
import { LoaderContext } from "../../../../../context/LoaderContext";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import axios from 'axios';
import styles from '../reusable-calling-code-project/table.module.css';
import { Container, Paper, Grid, MenuItem, TextField, Typography, Button, Dialog, DialogContent } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { handle_error } from '../reusable-calling-code-project/handle_error'

// const api_url = 'http://localhost:8060/review-conversation/'
const api_url = 'https://api-dot-reformexam.el.r.appspot.com/review-conversation/'


const ReviewConversation = () => {

    const loader = useContext(LoaderContext);
    const snackBar = useContext(SnackBarContext);

    const [res, setRes] = useState([]);
    const [title, setTitle] = useState('');
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);



    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={4}>Review Conversation Type</th>
                                </tr>
                                <tr>
                                    <th>Callback</th>
                                    <th>Admission Fee Not Paid</th>
                                    <th>Online Enquiry</th>
                                    <th>Walkin Enquiry</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <ComponentComp setShow={setShow1} setRes={setRes} setTitle={setTitle} loader={loader} snackBar={snackBar} type={"Callback"} />
                                    </td>
                                    <td>
                                        <ComponentComp setShow={setShow2} setRes={setRes} setTitle={setTitle} loader={loader} snackBar={snackBar} type={"Admission"} />
                                    </td>
                                    <td>
                                        <ComponentComp setShow={setShow3} setRes={setRes} setTitle={setTitle} loader={loader} snackBar={snackBar} type={"Online"} />
                                    </td>
                                    <td>
                                        <ComponentComp setShow={setShow4} setRes={setRes} setTitle={setTitle} loader={loader} snackBar={snackBar} type={"Walkin"} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        {show1 && <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={12}>{title}</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Form No.</th>
                                    <th>Mobile</th>
                                    <th>Class</th>
                                    <th>Fee</th>
                                    <th>Scholarship</th>
                                    <th>Reg. Date</th>
                                    <th>Test Date</th>
                                    <th>Last Conversation</th>
                                    <th>Conversation</th>
                                    <th>Lead Assigned</th>
                                </tr>
                            </thead>
                            <tbody>
                                {res.map((ele, i) => (
                                    <>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.fno}</td>
                                            <td>
                                                <b>Mobile1:- </b><CopyIcon mobile={ele.mobile1} snackBar={snackBar} /><br /><br />
                                                {ele.mobile2 && <><b>Mobile2:- </b><CopyIcon mobile={ele.mobile2} snackBar={snackBar} /><br /><br /></>}
                                                <b>Mobile3:- </b><CopyIcon mobile={ele.mobile3} snackBar={snackBar} />
                                            </td>
                                            <td>{ele.classs}</td>
                                            <td>{ele.fee}</td>
                                            <td>{ele.schper}</td>
                                            <td>{ele.regdate}</td>
                                            <td>{ele.testdate}</td>
                                            <td>{ele.last_category}<br /><br />{ele.dates}</td>
                                            <td>
                                                <ConversationComp loader={loader} snackBar={snackBar} orderid={ele.orderid} />
                                            </td>
                                            <td>{ele.admin}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>}
                        {show2 && <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={12}>{title}</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Form No.</th>
                                    <th>Mobile</th>
                                    <th>Class</th>
                                    <th>Fee</th>
                                    <th>Scholarship</th>
                                    <th>Reg. Date</th>
                                    <th>Test Date</th>
                                    <th>Last Conversation</th>
                                    <th>Conversation</th>
                                    <th>Lead Assigned</th>
                                </tr>
                            </thead>
                            <tbody>
                                {res.map((ele, i) => (
                                    <>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.fno}</td>
                                            <td>
                                                <b>Mobile1:- </b><CopyIcon mobile={ele.mobile1} snackBar={snackBar} /><br /><br />
                                                {ele.mobile2 && <><b>Mobile2:- </b><CopyIcon mobile={ele.mobile2} snackBar={snackBar} /><br /><br /></>}
                                                <b>Mobile3:- </b><CopyIcon mobile={ele.mobile3} snackBar={snackBar} />
                                            </td>
                                            <td>{ele.classs}</td>
                                            <td>{ele.fee}</td>
                                            <td>{ele.schper}</td>
                                            <td>{ele.regdate}</td>
                                            <td>{ele.testdate}</td>
                                            <td>{ele.last_remark}<br /><br />{ele.last_date}</td>
                                            <td>
                                                <ConversationComp loader={loader} snackBar={snackBar} orderid={ele.orderid} />
                                            </td>
                                            <td>{ele.admin}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>}
                        {show3 && <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={12}>{title}</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Form No.</th>
                                    <th>Mobile</th>
                                    <th>Class</th>
                                    <th>Fee</th>
                                    <th>Scholarship</th>
                                    <th>Reg. Date</th>
                                    <th>Test Date</th>
                                    <th>Last Conversation</th>
                                    <th>Conversation</th>
                                    <th>Lead Assigned</th>
                                </tr>
                            </thead>
                            <tbody>
                                {res.map((ele, i) => (
                                    <>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.fno}</td>
                                            <td>
                                                <b>Mobile1:- </b><CopyIcon mobile={ele.mobile1} snackBar={snackBar} /><br /><br />
                                                {ele.mobile2 && <><b>Mobile2:- </b><CopyIcon mobile={ele.mobile2} snackBar={snackBar} /><br /><br /></>}
                                                <b>Mobile3:- </b><CopyIcon mobile={ele.mobile3} snackBar={snackBar} />
                                            </td>
                                            <td>{ele.classs}</td>
                                            <td>{ele.fee}</td>
                                            <td>{ele.schper}</td>
                                            <td>{ele.regdate}</td>
                                            <td>{ele.testdate}</td>
                                            <td>{ele.last_remark}<br /><br />{ele.last_date}</td>
                                            <td>
                                                <ConversationComp loader={loader} snackBar={snackBar} orderid={ele.orderid} />
                                            </td>
                                            <td>{ele.admin}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>}
                        {show4 && <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={12}>{title}</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Form No.</th>
                                    <th>Mobile</th>
                                    <th>Class</th>
                                    <th>Fee</th>
                                    <th>Scholarship</th>
                                    <th>Reg. Date</th>
                                    <th>Test Date</th>
                                    <th>Last Conversation</th>
                                    <th>Conversation</th>
                                    <th>Lead Assigned</th>
                                </tr>
                            </thead>
                            <tbody>
                                {res.map((ele, i) => (
                                    <>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.fno}</td>
                                            <td>
                                                <b>Mobile1:- </b><CopyIcon mobile={ele.mobile1} snackBar={snackBar} /><br /><br />
                                                {ele.mobile2 && <><b>Mobile2:- </b><CopyIcon mobile={ele.mobile2} snackBar={snackBar} /><br /><br /></>}
                                                <b>Mobile3:- </b><CopyIcon mobile={ele.mobile3} snackBar={snackBar} />
                                            </td>
                                            <td>{ele.classs}</td>
                                            <td>{ele.fee}</td>
                                            <td>{ele.schper}</td>
                                            <td>{ele.regdate}</td>
                                            <td>{ele.testdate}</td>
                                            <td>{ele.last_remark}<br /><br />{ele.last_date}</td>
                                            <td>
                                                <ConversationComp loader={loader} snackBar={snackBar} orderid={ele.orderid} />
                                            </td>
                                            <td>{ele.admin}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>}
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default ReviewConversation;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ConversationComp = ({ loader, snackBar, orderid }) => {

    const [category, setCategory] = useState('')
    const [text, setText] = useState('');

    const classes = useStyles();


    const handleClick = async () => {
        try {
            loader.show();

            // alert("Are you sure to proceed")

            const { data: obj } = await axios({
                url: api_url + 'create-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    orderid: orderid,
                    text: text,
                    category: category
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            if (obj.Success === 'ok') {
                snackBar.show(obj.message);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }
        finally {
            loader.hide()
        }
    }


    return (
        <>


            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Category"
                >
                    <MenuItem value="">
                        <em>Select Category</em>
                    </MenuItem>
                    <MenuItem value="Admission Done">Admission Done</MenuItem>
                    <MenuItem value="Already Enrolled">Already Enrolled</MenuItem>
                    <MenuItem value="Disqualified">Disqualified</MenuItem>
                    <MenuItem value="Follow Up">Follow Up</MenuItem>
                    <MenuItem value="Form Filled">Form Filled</MenuItem>
                    <MenuItem value="Financial Issue">Financial Issue</MenuItem>
                    <MenuItem value="Interested">Interested</MenuItem>
                    <MenuItem value="Joined other Institute">Joined other Institute</MenuItem>
                    <MenuItem value="Not Able To Connect">Not Able To Connect</MenuItem>
                    <MenuItem value="Will Visit Campus">Will Visit Campus</MenuItem>
                    <MenuItem value="Will Apply Online">Will Apply Online</MenuItem>
                    <MenuItem value="Will Deposit Course Fee">Will Deposit Course Fee</MenuItem>
                </Select>

                <br />

                <TextField
                    // label={"Remark"}
                    placeholder="Enter Remark"
                    type="text"
                    variant="outlined"
                    // fullWidth
                    // style={{ width: '50%' }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />

                <br />
                <Button onClick={handleClick} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Submit</Button>
                <br />
                <ViewComp orderid={orderid} loader={loader} snackBar={snackBar} />


            </FormControl>




        </>
    )

}

const ViewComp = ({ orderid, loader, snackBar }) => {

    const [array, setArray] = useState([]);
    const hide = () => {
        setArray([]);
    }

    const handleClick = async (orderid) => {
        try {
            loader.show();

            const { data } = await axios({
                url: api_url + 'view-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    orderid: orderid
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            console.log(data);
            setArray(data.array)


        } catch (e) {
            snackBar.show(handle_error(e));
        } finally {
            loader.hide();
        }

    }


    return (<>

        <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(orderid)}>View</Button>

        {array && array.length > 0 && <ModalComp hide={hide}>
            <div style={{ fontFamily: 'Arial' }}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={3}>Conversation</th>
                        </tr>
                        <tr>
                            <th>Remark</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {array.map((n, i) => {
                            return (
                                <tr style={{ fontSize: '13px' }}>
                                    <td>{n.text}</td>
                                    <td>{n.date}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>


            </div>


        </ModalComp>

        }

    </>)
}

const ModalComp = ({ children, hide }) => {

    return (
        <div>
            <Dialog open
                onClose={() => { hide() }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>

        </div>
    )
}

const CopyIcon = ({ mobile, snackBar }) => {
    const mask = (mobile) => {
        try {
            return mobile.replace(/\d(?=\d{4})/g, "*");
        } catch (e) {

        }
    }
    return (
        <>
            {mask(mobile)}&nbsp;&nbsp;
            <FileCopyIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" onClick={() => {
                navigator.clipboard.writeText(mobile);
                snackBar.show('Copied!');
            }} />
        </>
    )
}

const ComponentComp = ({ setShow, setRes, setTitle, loader, snackBar, type }) => {

    const handleClick = async () => {

        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + 'calling-data',
                method: 'post',
                responseType: 'json',
                data: {
                    type: type
                }
            })

            if (obj.success === 'ok') {
                setRes(obj.res);
                if (type === 'Callback') {
                    setTitle("Website Callback Data");
                    setShow(true)
                }
                if (type === 'Admission') {
                    setTitle("Admission Not Taken Data")
                    setShow(true)
                }
                if (type === 'Online') {
                    setTitle("Online Enquiry Data")
                    setShow(true)
                }
                if (type === 'Walkin') {
                    setTitle("All Walkin Data")
                    setShow(true)
                }

            }
        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }

    }

    return (
        <>
            <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleClick}>Click</Button>
        </>
    )
}
