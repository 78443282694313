import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { student_logout_gql } from "../../gql/student_logout_gql";
import { LoaderContext } from "../../../../context/LoaderContext";
export default () => {
  const [student_logout_mutation] = useMutation(student_logout_gql);
  const loader = useContext(LoaderContext);
  let navigate = useNavigate();
  return (
    <Comp
      navigate={navigate}
      student_logout_mutation={student_logout_mutation}
      loader={loader}
    />
  );
};
class Comp extends React.Component {
  async componentDidMount() {
    const { student_logout_mutation, navigate, loader } = this.props;

    try {
      loader.show();
      let {
        data: {
          student_logout: { success },
        },
      } = await student_logout_mutation();

      if (success) {
        localStorage.removeItem("admin_token");
        navigate("/admin");
      }
    } catch (e) {
      alert(e);
    } finally {
      loader.hide();
    }
  }
  render() {
    return <span>Logging out</span>;
  }
}
