import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
 
 
const Comp = ({ setShow }) => {
 
  
  return (
    <Button type="submit" variant="outlined" color="secondary" onClick={()=>setShow(true)}>
                Create New Contact
              </Button>
       
		  
		   
  );
};

export default Comp



