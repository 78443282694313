import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Paper } from '../../../paper'
import Grid from '@material-ui/core/Grid';
import { theme } from '../../../theme'
import { is_answered } from '../../../functions'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography';
import SendIcon from '../../../../assets/send-icon.png'
import CloseIcon from '../../../../assets/close-icon.png'
import { Button } from '../../../button'
 import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
 
 const color1 = 'teal'//theme.palette.secondary.main
const color2 = '#ed5f5f'//theme.palette.primary.main
const color3 = '#5960b7'//theme.extra.color3
const color4 = 'white'//theme.extra.color4
 
const common_style={fontSize: 14, cursor: "pointer", width: 30, height: 30,}
const useClasses = makeStyles(theme => ({
    style1: {
        //boxShadow: theme.shadows[3],
		...common_style,
		backgroundColor:color1
    },
	style1_shadow: {
        boxShadow: theme.shadows[3],
		...common_style,
		backgroundColor:color1
    },
	style2: {
       // boxShadow: theme.shadows[3],
		...common_style,
		backgroundColor:color2
    },
	style2_shadow: {
         boxShadow: theme.shadows[3],
		...common_style,
		backgroundColor:color2
    },
	style3: {
        //boxShadow: theme.shadows[3],
		...common_style,
		backgroundColor:color3
    },
	style3_shadow: {
         boxShadow: theme.shadows[3],
		...common_style,
		backgroundColor:color3
    },
	style4: {
        //boxShadow: theme.shadows[3],
		...common_style,
		backgroundColor:color4,
		border:'solid 1px #00000040',
		color:'#999999'
    },
	style4_shadow: {
        boxShadow: theme.shadows[3],
		...common_style,
		backgroundColor:color4
    },
}))


export default withWidth()(({ width,quiz_question, goToQues, current_question_index }) => {
    let bgcolor;
    let status = {}
    let answer_count=0, unanswer_count=0, marked_count=0, notvisited_count = 0
    console.log(quiz_question)
    for(let i in quiz_question){
        if(quiz_question[i].answered)
        answer_count++
        if(!quiz_question[i].visited)
        notvisited_count++
        if(!quiz_question[i].answered)
        unanswer_count++
        if(quiz_question[i].flag)
        marked_count++
    }
    console.log(notvisited_count)
 const classes = useClasses()
    return (
        <div style={{ marginTop: 20,paddingLeft:10,paddingRight:10 }}>
		
            {
				!isWidthUp('md', width) && 
			<React.Fragment>
			
                <img src={CloseIcon} style={{ width: 24,height:24 }}  />
				<Button
                    variant="contained"
                    fullWidth={false}
                    //onClick={onSubmit}
                    color="secondary"
                    title="SUBMIT"
					style={{float:'right'}}
					// endIcon={<img src={SendIcon} style={{ width: 24,height:24 }} />}
                     
                />
      
	   
            <br /> <br />
			</React.Fragment>
}
            
			<Paper style={{  margin:7,paddingTop:7,paddingBottom:7,
			maxHeight: '50vh',
                
                overflowY: 'auto'
			}} elevation={4}>
			<Typography variant="subtitle2" gutterBottom style={{marginLeft:10,marginBottom:10}}>
                Question Pallete
      </Typography>
                <Grid container spacing={1} style={{width:'100%'}}>
				
                    {quiz_question.map((n, i) => {
let tmp_style;
                        if (!n.visited){
							if(i===current_question_index)
                             tmp_style=classes.style4_shadow//color4;
							else 
							tmp_style=classes.style4;
								
						}
                        else if (n.flag)
						{
							if(i===current_question_index)
                             tmp_style=classes.style3_shadow//color3;
							else 
							tmp_style=classes.style3;
						}
                        else {
                            const answered = is_answered({ subquestion: n.quiz_subquestion })
                            if (answered)
							{
								if(i===current_question_index)
									tmp_style=classes.style1_shadow//color1;
							else 
									tmp_style=classes.style1;
								
							}
                            else{
                                
								
								if(i===current_question_index)
										tmp_style=classes.style2_shadow//color2;
							else 
									tmp_style=classes.style2;
								
							}
                        }
					 
						 


                        return (
                            <Grid item xs={3}>

                                <center>
                                    <Avatar className={tmp_style}   onClick={() => goToQues(i)} >{i + 1}</Avatar>
                                </center>
                            </Grid>

                        )
                    })}
                </Grid>
				</Paper>
             
            <br />   

<Paper style={{ margin:7 ,
			 
                padding:10
                 
			}} elevation={4}>
            <Typography variant="subtitle2" gutterBottom>
                Question Status
      </Typography>
            <br />

            <table style={{ width: '100%' }}>
                <tr><td>
                    <Avatar   className={classes.style1}    >{answer_count}</Avatar>
                </td><td>

                        <Typography variant="body" gutterBottom>
                            Answered
      </Typography>
                    </td>
                    <td>

                        <Avatar className={classes.style2}    >{unanswer_count}</Avatar>

                    </td><td>
                        <Typography variant="body" gutterBottom>
                            Un Answered
</Typography>

                    </td>
                </tr><tr>
                    <td style={{ paddingTop: 10 }}>
                        <Avatar  className={classes.style3}   >{marked_count}</Avatar>

                    </td>  <td style={{ paddingTop: 10 }}>
                        <Typography variant="body" gutterBottom>
                            Marked
</Typography>
                    </td>
                    <td style={{ paddingTop: 10 }}>

                        <Avatar  className={classes.style4}     >{notvisited_count}</Avatar>
                    </td>  <td style={{ paddingTop: 10 }}>

                        <Typography variant="body" gutterBottom>
                            Not Visited
</Typography>
                    </td>
                </tr>
            </table>
</Paper>


        </div>

    )
})
