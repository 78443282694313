import React from "react";
import { Select } from "../../../../utils/select";
import { classroom_test_subject_topic_list_query_gql } from "../../gql/query/classroom-test-subject-topic-list-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({
  classroom_test_subject_id,
  course_id,
  label,
  value,
  name,
  onChange,
}) => {
  console.log(course_id)
  if (!classroom_test_subject_id) return <span></span>;
  const { loading, error, data } = useQuery(
    classroom_test_subject_topic_list_query_gql,
    {
      variables: {
        classroom_test_subject_id,
        course_id,
      },
    }
  );
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  
  const { classroom_test_subject_topic_list } = data;
  console.log(classroom_test_subject_topic_list)
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={classroom_test_subject_topic_list}
    />
  );
};
