import { gql } from "@apollo/client";

export const division_list_query_gql = gql`
  {
    division_list {
      key: id
      value: name
    }
  }
`;
