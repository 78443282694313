import React from 'react';
import { useState } from 'react';
import { Button } from '@material-ui/core';

export default function DownloadTestComp(props) {

    const handleUpdate = () => {
        props.handleTestDownload({ id: props.id, test: props.test, index: props.index });

    }

    return (
        <>
            <Button onClick={handleUpdate} style={{ border: '1px solid purple', borderStyle: 'dashed', color: '#b11116' }}>Download Test CSV</Button>
        </>
    )
}