import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../../../../../student/app/srceens/digital-classroom/config'
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { Select } from "../../../../../../utils/select";
import { Select as SelectMultiple } from "../../../../../../utils/select/select-multiple";
import { Container, TextField, Grid, Button } from "@material-ui/core";

import ShareToBatch from './share-to-batch'
import ShareToStudent from './share-to-student'

export default ({ division }) => {
  const [mode, setMode] = useState('share_to_student');
  const year = 2023
  return (
    <>
      <button onClick={() => setMode('share_to_batch 2022')}>Share to Batch 2022</button>
      <br></br>
      <br></br>
      <button onClick={() => setMode('share_to_batch 2023')}>Share to Batch 2023</button><br></br>
      <br></br>
      <button onClick={() => setMode('share_to_batch 2024')}>Share to Batch 2024</button>
      <br />	<br />
      <button onClick={() => setMode('share_to_student')}>Share to Student</button>
      {mode === 'share_to_batch 2022' && <ShareToBatch division={division} year={2022} />}
      {mode === 'share_to_batch 2023' && <ShareToBatch division={division} year={2023} />}
      {mode === 'share_to_batch 2024' && <ShareToBatch division={division} year={2024} />}
      {mode === 'share_to_student' && <ShareToStudent division={division} />}
    </>
  );
};
