import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button, Divider } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { create_session_mutation_gql } from "../../../gql/mutation/create-session-mutation";
import Typography from "@material-ui/core/Typography";
import './table.css'

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Card,
  CardContent,
  CardHeader
} from "@material-ui/core"
const axios = require('axios');

export default () => {
  const [create_session_mutation] = useMutation(create_session_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_session_mutation={create_session_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_session_mutation }) => {
  const [values, setValues] = useState({
    roll_no: "" 
  });
    const [profile, setProfile] = useState(null);
    const [productset, setProductSet] = useState([]);
    const [product, setProduct] = useState(null);

    const [assignproductset, setAssignProductSet] = useState([]);
    const [assignproduct, setAssignProduct] = useState(null);

    const [checked, setChecked] = useState([]);
    const [checkedAll, setCheckedAll] = useState([]);

  const handleInputChange = (e) => {
    e.preventDefault();

    setProfile(null)
    setProductSet([]);
    setProduct(null);
    setAssignProductSet([]);
    setAssignProduct(null);
    setChecked([]);
    setCheckedAll([]);

    setValues({roll_no: e.target.value})
    let roll_no =  e.target.value  
if(roll_no.length!=8)
{
snackBar.show('Enter Roll No of 8 digits');
return false;
}
checkProducts(roll_no)
  };

  // Product List
  const onSelectProduct = (e,type,set) => {
    
    let value = e.target.value ; 
    if(type==="all"){
      let arr = []
      if(checkedAll.includes(value)){
        setCheckedAll(checkedAll.filter(c => c !== value))
        const intersection = product[value].filter(element => !checked.includes(element.id));
        for(let i=0;i<product[value].length;i++){
          const index = checked.indexOf(product[value][i].id);
          if (index > -1) {
          checked.splice(index, 1);
          }
        }
      }
      else {
        setCheckedAll([...checkedAll, value])
        for(let i=0;i<product[value].length;i++){
          const index = checked.indexOf(product[value][i].id);
          if (index === -1) {
            checked.push(product[value][i].id)
          }
        }
      }
      
         
    }
    else {
      if(checkedAll.includes(set))
      setCheckedAll(checkedAll.filter(c => c !== set))
      
      value = parseInt(value,10)
      setChecked(checked.includes(value) ? checked.filter(c => c !== value) : [...checked, value])
      
    }
    
  }


  const checkProducts = async (roll_no) => {
    try {
 
      loader.show();
      axios({
           url: 'https://reliablekota.com/liveclasses/other-apps/distribution/admin/get-student-details.php',
           method: 'post',
       responseType: 'json',
          data: 'auth_token=##reliabletoken##&roll_no='+roll_no,
         })
         .then(function (response) {
          console.log(response)
          if(response.data.error) 
          return snackBar.show(response.data.message); 
          
    let result = response.data.message.result2
    setProfile( {profile:response.data.message.stu_data[0]})
    setProduct(result)   
    setProductSet(Object.keys(result))
   
    let assignProduct = response.data.message.result1

    setAssignProduct(assignProduct)
    setAssignProductSet(Object.keys(assignProduct))
    

        })
        .catch(function (error) {
          console.log(error)
          snackBar.show(error);
          return false;
        });  
   
        // resetForm({ values, setValues });
       } catch (e) {
        
         snackBar.show(e);
       } finally {
         loader.hide();
       }
       
  }

  const submitProducts = () => {
    if(checked.length<1)
    return snackBar.show("Please Select Product");
    try {
      loader.show();
      axios({
           url: 'https://reliablekota.com/liveclasses/other-apps/distribution/admin/assign-product.php',
           method: 'post',
       responseType: 'json',
          data: 'auth_token=##reliabletoken##&roll_no='+profile.profile.fno+'&action=add&id='+checked,
         })
         .then(function (response) {
          if(response.data.error) 
          return snackBar.show(response.data.message); 

          snackBar.show("Assigned Successfully");
          checkProducts(values.roll_no)
          setChecked([])
          
        })
        .catch(function (error) {
          snackBar.show(error);
          return false;
        }); 
         
    } 
       catch (e) {
          snackBar.show(e);
       } finally {
         loader.hide();
       }
  }

  const submitAssignProducts = async (e) => {
    e.preventDefault();
    submitProducts()
  }
 

 console.log(productset)
 console.log(checked)
  return (
    <div>
      <br />
      <Container>
      <Container style={{ maxWidth: 600 }}>
      <Card lg={6}><br />
        <center>
        <img src="https://reliablekota.com/images/logo.png" /><br />
        <Divider /><br />
        <Typography variant="h2">Material Distribution</Typography>
        </center>
        <br />
       <CardContent>
       <center><table><tr><td><TextField
                autoFocus
                label="Enter Roll No"
                variant="outlined"
                name="roll_no"
                value={values.roll_no}
                onChange={handleInputChange}
              /></td>
           </tr></table></center>
            
		  <br /><br />
		  
		  
		    
		  {
			  
			  profile 
		 
		  
		
		  &&
		  <TableContainer  >
      <Table   aria-label="simple table">
        <TableHead>
          <TableRow>
            
            <TableCell><strong>Form No</strong></TableCell>
            <TableCell><strong>Name</strong></TableCell>
            <TableCell><strong>Fee Deposited</strong></TableCell>
            <TableCell><strong>Course Name</strong></TableCell>
			<TableCell><strong>Medium</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow >
              
              <TableCell>{profile.profile.fno} </TableCell>
              <TableCell>{profile.profile.name} </TableCell>
              <TableCell>{profile.profile.fee } Rs.</TableCell>
              <TableCell>{profile.profile.coursetitle} </TableCell>
			   <TableCell>{profile.profile.medium} </TableCell>
            </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>
	  }
		
    </CardContent>
      </Card>
    </Container><br />
        
        
         
      </Container>
    <br /><br />
    
{
  product &&  

  <>
  <table style={{width:'100%'}} >
    <tr><td style={{width:'5%'}}></td>
    <td style={{width:'40%',verticalAlign:'top'}}>
  <Left checked={checked} productset={productset} checkedAll={checkedAll} product={product} onSelectProduct={onSelectProduct}/>
    </td>
    <td style={{width:'15%'}}><Button variant="contained" color="primary" onClick={submitAssignProducts}>Assign Products</Button></td>
    <td style={{width:'40%',verticalAlign:'top'}}>  
  <Right assignproduct={assignproduct} assignproductset={assignproductset} />
    </td>
    </tr>
  </table>
  </>
}
<br /><br />
</div>

  )
}




const Left=({productset,checkedAll,product,onSelectProduct,checked})=>{
  return(
<table style={{width:'100%'}}>
 <tr><th colspan="2"><Typography variant="h3" gutterBottom>Product List</Typography></th></tr>
  
  { productset.map((item,key)=> {
    return <><tr>
    <td >

      <table>
<tr><td style={{verticalAlign:'top'}}>
    <FormControlLabel
        control={<Checkbox checked={checkedAll.includes(item)} value={item} onChange={(e)=>onSelectProduct(e,"all",item)} name={item} />}
        label={item}
      />
    </td>
    <td  >
<div style={{maxHeight:'300px', maxWidth: '450px', overflowY:'auto'}}>
   
<>
<table className="mytable">
{ product[item].map((item1,key1) => { 
      return <tr><td><FormControlLabel
        control={<Checkbox checked={checked.includes(parseInt(item1.id,10))} value={item1.id} onChange={(e)=>onSelectProduct(e,"one",item)} name={item1.id} />}
        label={item1.product_name}
      /></td></tr>})} 

</table>
</>


</div>
    </td>
    </tr>
    
    
    </table>
    </td>
    
  </tr>
 <Divider />
      </>
  })
  }

</table>
  )
}




const Right=({ assignproductset, assignproduct })=>{
  return(
<table style={{width:'100%'}}>
<tr><th colspan="2"><Typography variant="h3" gutterBottom>Assigned Products</Typography></th></tr>
  
{ 
  assignproductset.map((item,key)=> {
   return <><tr>
   <td >

     <table>
<tr><td style={{verticalAlign:'top'}}>
<FormControlLabel
        control={<></>}
        label={item} /></td>
   <td  >
<div style={{maxHeight:'300px', maxWidth: '450px', overflowY:'auto'}}>
<>
<table className="mytable">
{ assignproduct[item].map((item1,key1) => { 
     return <tr><td><FormControlLabel
     control={<></>}
     label={item1.product_name} /></td></tr>})} 

</table>
</>


</div>
   </td>
   </tr>
   
   
   </table>
   </td>
   
 </tr>
 <Divider />
     </>
 })
 }

</table>
  )
}