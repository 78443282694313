import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button, Divider, Paper } from "@material-ui/core";
import FormLayoutliveclass from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
    handleFormChange,
    resetForm,
    error_beautify,
} from "../../../../../utils/functions";
import { create_liveclass_mutation_gql } from "../../../gql/mutation/create-liveclass-mutation";
import Typography from "@material-ui/core/Typography";
import SelectSessionBatch from "../../components/select-multiple-batch-sessionwise";
import SelectClassDuration from "../../components/select-class-duration";
import SelectTimeSlot from "../../components/select-time-slot";

import SelectAllChildSubject from "../../components/select-childsubject-all";
import SelectZoomAccount from "../../components/select-zoom-account";
import SelectFaculty from "../../components/select-faculty";
import axios from 'axios';
import moment from 'moment';

const CreateLiveClass = ({ is_jr = false }) => {

    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);

    const [date, setDate] = useState('');
    const [start_time, setStartTime] = useState('');
    const [duration, setDuration] = useState('');
    const [title, setTitle] = useState('');
    const [zoom_email, setZoomEmail] = useState('');
    const [batch_id, setBatch] = useState([]);
    const [childsubject_id, setChildsubjectId] = useState('');
    const [faculty_id, setFacultyId] = useState('');




    const handleClick = async () => {
        try {
            loader.show()

            if (date.length < 1) {
                snackBar.show("Select Date");
                return false;
            }
            if (start_time.length < 1) {
                snackBar.show("Select start_time");
                return false;
            }
            if (duration.length < 1) {
                snackBar.show("Select duration");
                return false;
            }
            if (title.length < 1) {
                snackBar.show("Select title");
                return false;
            }
            if (zoom_email.length < 1) {
                snackBar.show("Select Zoom Email");
                return false;
            }
            if (batch_id.length < 1) {
                snackBar.show("Select Batch");
                return false;
            }
            if (childsubject_id.length < 1) {
                snackBar.show("Select Child Subject");
                return false;
            }
            if (faculty_id.length < 1) {
                snackBar.show("Select Faculty");
                return false;
            }

            const calculated_end_time = moment(start_time, "HH:mm:ss")
                .add(duration, "minutes")
                .format("HH:mm:ss");

            console.log(calculated_end_time);

            const { data: obj } = await axios({
                // url: 'http://localhost:8060/zoom-testing/zoom-create-meeting',
                url: 'https://api-dot-reformexam.el.r.appspot.com/zoom-testing/zoom-create-meeting',
                method: 'post',
                responseType: 'json',
                data: {
                    date: date,
                    start_time: start_time,
                    duration: duration,
                    title: title,
                    zoom_email: zoom_email,
                    batch_id: batch_id,
                    childsubject_id: childsubject_id,
                    faculty_id: faculty_id,
                    end_time: calculated_end_time,
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            if (obj.success === 'ok') {
                snackBar.show("Live Class Created Successfully")
            }


        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide()
        }
    }

    const handleToken = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                // url: 'http://localhost:8060/zoom-testing/zoom-create-token',
                url: 'https://api-dot-reformexam.el.r.appspot.com/zoom-testing/zoom-create-token',
                method: 'post',
                responseType: 'json',
            })

            console.log(obj);

            if (obj.success === 'ok') {
                snackBar.show("Token Generated Successfully");
            }
        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }
    }


    return (

        <Container>
            <FormLayoutliveclass title="Create Live Class">

                <Grid container spacing={3}>
                    <Grid iem xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <Button onClick={handleToken} variant="contained" color="primary">
                                Generate Token
                            </Button>
                        </Paper>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="date"
                            variant="outlined"
                            name="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectTimeSlot
                            name="start_time"
                            onChange={(e) => setStartTime(e.target.value)}
                            label="Select Time Slot"
                            value={start_time}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectClassDuration
                            name="duration"
                            onChange={(e) => setDuration(e.target.value)}
                            label="Select Class Duration"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Enter Title"
                            variant="outlined"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <SelectZoomAccount
                            name="zoom_email"
                            onChange={(e) => setZoomEmail(e.target.value)}
                            label="Select Zoom Account"
                            is_jr={is_jr}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <SelectSessionBatch
                            label="Select Batch"
                            onChange={(e) => setBatch(e.target.value)}
                            name="batch_id"
                            value={batch_id}
                            is_jr={is_jr}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectAllChildSubject
                            label="Select ChildSubject"
                            onChange={(e) => setChildsubjectId(e.target.value)}
                            name="childsubject_id"
                            value={childsubject_id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectFaculty
                            label="Faculty"
                            name="faculty_id"
                            value={faculty_id}
                            onChange={(e) => setFacultyId(e.target.value)}
                            is_jr={is_jr}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={handleClick} variant="contained" color="primary">
                            CREATE
                        </Button>
                    </Grid>
                </Grid>

            </FormLayoutliveclass>
        </Container>

    )
}

export default CreateLiveClass

