import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { CSVReader } from 'react-papaparse';
import { Container, TextField, Grid, Button, MenuItem, Select, InputLabel, Paper } from "@material-ui/core";
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import { api_url } from "./config";
import { url } from "./config"
const Saper = () => {

    const loader = useContext(LoaderContext);

    const [test, setTest] = useState('');
    const [date, setDate] = useState('');
    const [type, setType] = useState('');
    const [pattern, setPattern] = useState('');
    const [id, setId] = useState();
    const [file, setFile] = useState([]);


    const handleCsvDrop = async (data) => {
        try {
            loader.show();

            console.log(data);

            if (test.length == 0) {
                alert("Enter Test Name");
                return false;
            }
            if (date.length == 0) {
                alert("Enter Test Date");
                return false;
            }
            if (type.length == 0) {
                alert("Enter Test Type");
                return false;
            }
            if (pattern.length == 0) {
                alert("Enter Test Pattern");
                return false;
            }

            if (data[0].data[0] !== 'roll_no') {
                alert("1st Column Should be roll_no")
                return false;
            }
            if (data[0].data[1] !== 'name') {
                alert("2nd Column Should be name")
                return false;
            }
            if (data[0].data[2] !== 'class') {
                alert("3rd Column Should be class")
                return false;
            }
            if (data[0].data[3] !== 'batch') {
                alert("4th Column Should be batch")
                return false;
            }
            if (data[0].data[4] !== 'physics') {
                alert("5th Column Should be physics")
                return false;
            }
            if (data[0].data[5] !== 'physics_mm') {
                alert("6th Column Should be physics_mm")
                return false;
            }
            if (data[0].data[6] !== 'chemistry') {
                alert("7th Column Should be chemistry")
                return false;
            }
            if (data[0].data[7] !== 'chemistry_mm') {
                alert("8th Column Should be chemistry_mm")
                return false;
            }
            if (data[0].data[8] !== 'biology') {
                alert("9th Column Should be biology")
                return false;
            }
            if (data[0].data[9] !== 'biology_mm') {
                alert("10th Column Should be biology_mm")
                return false;
            }
            if (data[0].data[10] !== 'maths') {
                alert("11th Column Should be maths")
                return false;
            }
            if (data[0].data[11] !== 'maths_mm') {
                alert("12th Column Should be maths_mm")
                return false;
            }
            if (data[0].data[12] !== 'english') {
                alert("13th Column Should be english")
                return false;
            }
            if (data[0].data[13] !== 'english_mm') {
                alert("14th Column Should be english_mm")
                return false;
            }
            if (data[0].data[14] !== 'social_science') {
                alert("15th Column Should be social_science")
                return false;
            }
            if (data[0].data[15] !== 'social_science_mm') {
                alert("16th Column Should be social_science_mm")
                return false;
            }
            if (data[0].data[16] !== 'mental_ability') {
                alert("17th Column Should be mental_ability")
                return false;
            }
            if (data[0].data[17] !== 'mental_ability_mm') {
                alert("18th Column Should be mental_ability_mm")
                return false;
            }
            if (data[0].data[18] !== 'hindi') {
                alert("18th Column Should be hindi")
                return false;
            }
            if (data[0].data[19] !== 'hindi_mm') {
                alert("18th Column Should be hindi_mm")
                return false;
            }
            if (data[0].data[20] !== 'sanskrit') {
                alert("18th Column Should be sanskrit")
                return false;
            }
            if (data[0].data[21] !== 'sanskrit_mm') {
                alert("18th Column Should be sanskrit_mm")
                return false;
            }
            if (data[0].data[22] !== 'computer') {
                alert("18th Column Should be computer")
                return false;
            }
            if (data[0].data[23] !== 'computer_mm') {
                alert("18th Column Should be computer_mm")
                return false;
            }
            if (data[0].data[24] !== 'total') {
                alert("19th Column Should be TOTAL")
                return false;
            }
            if (data[0].data[25] !== 'max_marks') {
                alert("20th Column Should be max_marks")
                return false;
            }
            if (data[0].data[26] !== 'percentage') {
                alert("21st Column Should be percentage")
                return false;
            }
            if (data[0].data[27] !== 'mode') {
                alert("22nd Column Should be mode")
                return false;
            }




            let arr = [], len = data.length, wid = data[0].length, i, j, sum, add;


            for (i = 0; i < len - 1; i++) {

                if (i === 0) {
                    continue;
                }

                if (data[i].data[0].length != 8) {
                    alert(`Rollno Incorrect at 1st Column and ${i + 1}th Row`);
                    return false;
                }

                if (data[i].data[0].match(/^[a-zA-Z]+$/)) {
                    alert(`Rollno Incorrect at 1st Column and ${i + 1}th Row`);
                    return false;
                }

                if (parseInt(data[i].data[4]) > parseInt(data[i].data[5])) {
                    alert(`Physics Obtained Marks is greater than Physics Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[6]) > parseInt(data[i].data[7])) {
                    alert(`Chemistry Obtained Marks is greater than Chemistry Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[8]) > parseInt(data[i].data[9])) {
                    alert(`Biology Obtained Marks is greater than Biology Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[10]) > parseInt(data[i].data[11])) {
                    alert(`Maths Obtained Marks is greater than Maths Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[12]) > parseInt(data[i].data[13])) {
                    alert(`English Obtained Marks is greater than English Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[14]) > parseInt(data[i].data[15])) {
                    alert(`Social Science Obtained Marks is greater than Social Science Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[16]) > parseInt(data[i].data[17])) {
                    alert(`Mental Ability Obtained Marks is greater than Mental Ability Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[18]) > parseInt(data[i].data[19])) {
                    alert(`Hindi Obtained Marks is greater than Hindi Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[20]) > parseInt(data[i].data[21])) {
                    alert(`Sanskrit Obtained Marks is greater than Sanskrit Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[22]) > parseInt(data[i].data[23])) {
                    alert(`Computer Obtained Marks is greater than Computer Max Marks at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[24]) > parseInt(data[i].data[25])) {
                    alert(`Total Obtained Marks is greater than Total Max Marks at Row: ${i + 1}`);
                    return false;
                }

                if (parseInt(data[i].data[4]) <= parseInt(-data[i].data[5])) {
                    alert(`Limit for Negative Marks Obtained in Physics is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[6]) <= parseInt(-data[i].data[7])) {
                    alert(`Limit for Negative Marks Obtained in Chemistry is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[8]) < parseInt(-data[i].data[9])) {
                    alert(`Limit for Negative Marks Obtained in Biology is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[10]) < parseInt(-data[i].data[11])) {
                    alert(`Limit for Negative Marks Obtained in Maths is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[12]) < parseInt(-data[i].data[13])) {
                    alert(`Limit for Negative Marks Obtained in English is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[14]) < parseInt(-data[i].data[15])) {
                    alert(`Limit for Negative Marks Obtained in Social Science is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[16]) < parseInt(-data[i].data[17])) {
                    alert(`Limit for Negative Marks Obtained in Mental Ability is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[18]) < parseInt(-data[i].data[19])) {
                    alert(`Limit for Negative Marks Obtained in Hindi is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[20]) < parseInt(-data[i].data[21])) {
                    alert(`Limit for Negative Marks Obtained in Sanskrit is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[22]) < parseInt(-data[i].data[23])) {
                    alert(`Limit for Negative Marks Obtained in Computer is Wrong at Row: ${i + 1}`);
                    return false;
                }
                if (parseInt(data[i].data[24]) < parseInt(-data[i].data[25])) {
                    alert(`Limit for Negative Marks Obtained in Total is Wrong at Row: ${i + 1}`);
                    return false;
                }

                if (parseInt(data[i].data[4]) == NaN) {
                    alert(`Physics Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[5]) == NaN) {
                    alert(`Physics Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[6]) == NaN) {
                    alert(`Chemistry Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[7]) == NaN) {
                    alert(`Chemistry Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[8]) == NaN) {
                    alert(`Biology Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[9]) == NaN) {
                    alert(`Biology Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[10]) == NaN) {
                    alert(`Maths Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[11]) == NaN) {
                    alert(`Maths Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[12]) == NaN) {
                    alert(`English Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[13]) == NaN) {
                    alert(`English Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[14]) == NaN) {
                    alert(`Social Science Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[15]) == NaN) {
                    alert(`Social Science Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[16]) == NaN) {
                    alert(`Mental Ability Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[17]) == NaN) {
                    alert(`Mental Ability Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[18]) == NaN) {
                    alert(`Hindi Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[19]) == NaN) {
                    alert(`Hindi Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[20]) == NaN) {
                    alert(`Sanskrit Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[21]) == NaN) {
                    alert(`Sanskrit Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[22]) == NaN) {
                    alert(`Computer Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[23]) == NaN) {
                    alert(`Computer Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[24]) == NaN) {
                    alert(`Total Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }
                if (parseInt(data[i].data[25]) == NaN) {
                    alert(`Total Max Marks should be in interger at Row: ${i + 1}`)
                    return false;
                }

                let a = `${data[i].data[0]}`;

                a = a.substring(0, 2);

                if (a == 21 || a == 22 || a == 20 || a == 23) {

                    sum = 0;
                    add = 0;

                    sum = interger_convert(data[i].data[4]) + interger_convert(data[i].data[6]) + interger_convert(data[i].data[8]) + interger_convert(data[i].data[10]) + interger_convert(data[i].data[12]) + interger_convert(data[i].data[14]) + interger_convert(data[i].data[16]) + interger_convert(data[i].data[18]) + interger_convert(data[i].data[20]) + interger_convert(data[i].data[22])

                    console.log(sum)

                    if (sum != interger_convert(data[i].data[24])) {
                        alert(`Total Marks is not Equal to Sum of all marks in Row: ${i + 1}`)
                        return false;
                    }


                    add = interger_convert(data[i].data[5]) + interger_convert(data[i].data[7]) + interger_convert(data[i].data[9]) + interger_convert(data[i].data[11]) + interger_convert(data[i].data[13]) + interger_convert(data[i].data[15]) + interger_convert(data[i].data[17]) + interger_convert(data[i].data[19]) + interger_convert(data[i].data[21]) + interger_convert(data[i].data[23])

                    console.log(add)

                    if (add != interger_convert(data[i].data[25])) {
                        alert(`Total Max Marks is not Equal to Sum of all Max marks in Row: ${i + 1}`)
                        return false;
                    }

                }

                else {
                    alert(`Roll No should start with 20 or 21 or 22 or 23 at Row ${i + 1}`);
                    return false;
                }


            }


            for (i = 0; i < len - 1; i++) {
                if (i == 0) {
                    continue;
                }
                arr.push({ roll_no: data[i].data[0], name: data[i].data[1], class: data[i].data[2], batch: data[i].data[3], physics: data[i].data[4], physics_mm: data[i].data[5], chemistry: data[i].data[6], chemistry_mm: data[i].data[7], biology: data[i].data[8], biology_mm: data[i].data[9], maths: data[i].data[10], maths_mm: data[i].data[11], english: data[i].data[12], english_mm: data[i].data[13], social_science: data[i].data[14], sst_mm: data[i].data[15], mental_ability: data[i].data[16], mental_ability_mm: data[i].data[17], hindi: data[i].data[18], hindi_mm: data[i].data[19], sanskrit: data[i].data[20], sanskrit_mm: data[i].data[21], computer: data[i].data[22], computer_mm: data[i].data[23], total: data[i].data[24], max_marks: data[i].data[25], percentage: data[i].data[26], mode: data[i].data[27] })
            }

            let result = arr.map(({ roll_no }) => roll_no);

            // console.log(result);

            const occurrences = result.reduce(function (acc, curr) {
                return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
            }, {});



            // console.log(occurrences);

            // const obj = occurrences;

            // Convert `obj` to a key/value array
            // `[['name', 'Luke Skywalker'], ['title', 'Jedi Knight'], ...]`
            const asArray = Object.entries(occurrences);

            const filtered = asArray.filter(([key, value]) => value > 1);

            // console.log(filtered)

            // Convert the key/value array back to an object:
            // `{ name: 'Luke Skywalker', title: 'Jedi Knight' }`
            const justStrings = Object.fromEntries(filtered);

            // console.log(justStrings);

            if (Object.keys(justStrings).length != 0) {
                alert(`Duplicate Roll Numbers \n ${JSON.stringify(justStrings)}`);
                return false;
            }

            setFile(arr);
            alert("File Uploaded Successfully");



            // console.log(arr);

            // const { data: obj } = await axios({
            //     url: api_url + 'raw-data',
            //     method: 'post',
            //     responseType: 'json',
            //     data: {
            //         test_name: test,
            //         test_date: date,
            //         test_type: type,
            //         test_pattern: pattern,
            //         file: arr,
            //     },
            //     headers: {
            //         APIKEY: '##reliable_rest_api_token##',
            //         ADMINTOKEN: localStorage.getItem('admin_token')
            //     }
            // })

            // console.log(obj);

            // if (obj.success == 'ok') {
            //     setId(obj.test_id);
            //     alert("File Uploaded Successfully");
            // }

            // if (obj.success == 'false') {

            //     alert(obj.message);

            // }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    const handleClick = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: url + '/calculation',
                method: 'post',
                responseType: 'json',
                data: {
                    test_name: test,
                    test_date: date,
                    test_type: type,
                    test_pattern: pattern,

                    file: file
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            console.log(obj);

            if (obj.success == 'ok') {
                alert("Analysis Completed");
                window.location.reload(false);
            }

            if (obj.success == 'false') {
                alert(obj.message);
            }

        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }


    // console.log(session);
    return (
        <div>

            <form>

                <Container>

                    <FormLayoutAdmin title="Create Test">

                        <TextField
                            type="text"
                            label="Test Name"
                            value={test}
                            placeholder="Enter Test Name"
                            onChange={(e) => setTest(e.target.value)}
                        />

                        <br />
                        <br />

                        <TextField
                            style={{ minWidth: '200px' }}
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />

                        <br />
                        <br />
                        <InputLabel>Select Test Type</InputLabel>
                        <Select value={type} style={{ minWidth: '200px' }} onChange={(e) => setType(e.target.value)}>
                            <ev>Select Type</ev>
                            <MenuItem value="Class 6th to 8th Objective">Class 6th to 8th Objective</MenuItem>
                            <MenuItem value="Class 6th to 8th Subjective">Class 6th to 8th Subjective</MenuItem>
                            <MenuItem value="Class 9th to 10th Subjective">Class 9th to 10th Subjective</MenuItem>
                            <MenuItem value="Class 9th to 10th Objective">Class 9th to 10th Objective</MenuItem>
                            <MenuItem value="MPT/MCT">MPT / MCT</MenuItem>
                            <MenuItem value="APT/ACT">APT / ACT</MenuItem>
                        </Select>

                        <br />
                        <br />
                        <InputLabel>Select Test Pattern</InputLabel>
                        <Select value={pattern} style={{ minWidth: '200px' }} onChange={(e) => setPattern(e.target.value)}>
                            <ev>Select Pattern</ev>
                            <MenuItem value="Junior Division">Junior Division</MenuItem>
                            <MenuItem value="Junior Division English">Junior Division Without English</MenuItem>
                            <MenuItem value="MPT">MPT</MenuItem>
                            <MenuItem value="MCT">MCT</MenuItem>
                            <MenuItem value="APT">APT</MenuItem>
                            <MenuItem value="ACT">ACT</MenuItem>
                        </Select>

                        <br />
                        <br />

                        <CSVReader onDrop={handleCsvDrop}>
                            <span>Drop Paper</span>
                        </CSVReader>

                        <br />
                        <br />

                        <Button fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={handleClick}><b>Submit</b></Button>

                    </FormLayoutAdmin>

                </Container>

            </form>

        </div>
    )
}


const interger_convert = (a) => {

    if (a == '') {
        a = 0;
    }
    else {
        a = parseFloat(a);
    }

    return a;

}

export default Saper
