import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange }) => {
  const list = [
    { key: "DPP", value: "DPP" },
    { key: "SHEET", value: "SHEET" },
    { key: "VIDEO", value: "VIDEO" },
  ];

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={list}
    />
  );
};
