import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SelectClassroomTestSubject from "./select-classroom-test-subject";
import { handleFormChange } from "../../../../utils/functions";
import SelectSessionCoursePhaseBatchTest from "./select-session-course-phase-batch-classroomtest";
export default ({ onChange, name }) => {
  const [values, setValues] = useState({
    classroom_test_subject_id: "",
    [name]: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
    // if (e.target.name === name) {
    onChange(e);
    //}
  };

  return (
    <>
      <SelectSessionCoursePhaseBatchTest
        name="classroom_test_batch_id"
        onChange={handleInputChange}
      />

      <Grid item xs={12}>
        <SelectClassroomTestSubject
          classroom_test_batch_id={values.classroom_test_batch_id}
          label="Subject"
          name={name}
          value={values[name]}
          onChange={handleInputChange}
        />
      </Grid>
    </>
  );
};
