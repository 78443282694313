import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../reusable-calling-code-project/config'
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import Profile from '../reusable-calling-code-project/reuable-calling-code/profile'
const axios = require('axios');

export default (props) => {
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
      {...props}
    />
  );
};
const Comp = ({ loader, snackBar, ...props }) => {

  const [show, setShow] = useState(false);
  const [res, setRes] = useState([
    /*{
      "id": 12,

      "mobile": "8118846657",
      "mobile2": "9999999999",

      "name": "Sakshi bhartia",
      "fno": "2211111",
      "classs": "8",
      "last_remark": "10000068 payment done ",
      "last_callback": '2022-03-29T16:19',
      "created_at": "11-Oct, 2021 16:18"


    }, {
      "id": 13,

      "mobile": "811846657",
      "mobile2": "9991199999",

      "name": "Ajar hartia",
      "fno": "2211123",
      "classs": "9",
      "last_remark": "10000068 payment done ",
      "last_callback": '2022-03-27T16:19',
      "created_at": "11-Oct, 2021 16:18"


    }*/
  ]);
  const refresh = () => {
    fetch()
  }

  useEffect(async () => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    fetch();
  }, [])

  const fetch = async () => {
    try {

      loader.show();
      const { data } = await axios({
        url: api_url + '/jee-main-data-calling/calling-data',
        method: 'post',
        responseType: 'json',
        ...rest_headers
      })
      var i, len = data.arr.length;
      for (i = 0; i < len; i++) {
        if (data.arr[i].last_category == null) {
          data.arr[i].last_category = 'null';
        }
      }
      // console.log(data.arr[80].last_category);

      setRes(data.arr)



    } catch (e) {

      snackBar.show(e);
    } finally {
      loader.hide();
    }
  }


  return (
    <div>

      {res && <Profile project='project1' array={res} refresh={refresh} snackBar={snackBar} loader={loader} {...props} />}

    </div>
  )
}