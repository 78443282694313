import React, { useEffect } from "react";
import Shimmer from "react-shimmer-effect";
import { Link as RouterLink } from "react-router-dom";
import injectSheet from "react-jss";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { live_class_schedule_gql } from "../../../gql/live_class_schedule_gql";
import { viewer_gql } from "../../../gql/viewer";
import { student_live_class_mark_attendance_gql } from "../../../gql/student_live_class_mark_attendance_gql";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import JoinMeetingButton from "./join-meeting-button";
import Divider from "@material-ui/core/Divider";
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import {error_beautify} from '../../../../../utils/functions';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";

import ClassList from "./class-list";

var exec = require("child_process").execFile;
const common = {
  height: "8px",
  borderRadius: "8px",
};
const StyleSheet = {
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  item: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  circle: {
    height: 64,
    width: 64,
    borderRadius: "50%",
  },
  line1: {
    ...common,
    width: 200,
  },
  line2: {
    ...common,
    width: 140,
  },
  header: {
    ...common,
    width: 96,
  },
  divider: {
    height: 20,
  },
  divider2: {
    height: 10,
  },
  left: {
    borderRadius: 64,
  },
};

export default ({ is_home_component }) => {
  const [mark_attendance_mutation] = useMutation(
    student_live_class_mark_attendance_gql
  );
  let navigate = useNavigate();

  return (
    <StyledApp
      mark_attendance_mutation={mark_attendance_mutation}
      is_home_component={is_home_component}
      exec={exec}
      navigate={navigate}
    />
  );
};

const App = ({
  classes,
  is_home_component,
  mark_attendance_mutation,
  exec,
  navigate
}) => {

  useEffect(() => {
    const intervalId = setInterval(()=> {
      refetch1();
      refetch2();
    }, 60 * 1000 * 5)

    return () => {
      clearInterval(intervalId);

    }
  },[navigate])

  const QueryMultiple = () => {
    const res1 = useQuery(live_class_schedule_gql);
    const res2 = useQuery(viewer_gql);
    return [res1, res2];
  };
  const [
    { loading: loading1, error: error1, refetch: refetch1, data: data1 },
    { loading: loading2, error: error2, refetch: refetch2, data: data2 },
  ] = QueryMultiple();

  if (loading1 || loading2)
    return (
      <>
        <ShimmerHeader />
        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />
        {!is_home_component && (
          <div style={{ marginTop: 40 }}>
            <ShimmerHeader />
            <ShimmerRow classes={classes} />

            <ShimmerRow classes={classes} />

            <ShimmerRow classes={classes} />
          </div>
        )}
      </>
    );
  if (error1){
    if(error_beautify(error1)  === "Response not successful: Received status code 500"){
      localStorage.removeItem("student_token");
      navigate("/student");  
    }
    return <GqlErrorComp error={error1} />;
  }
  if (error2){
    if(error_beautify(error2)  === "Response not successful: Received status code 500"){
      localStorage.removeItem("student_token");
      navigate("/student");  
    }
    return <GqlErrorComp error={error2} />;
  } 
    

  const r = data1.live_class;

  let user_data = {};
  user_data = data2.viewer;

  return (
    <Comp
      r={r}
      mark_attendance_mutation={mark_attendance_mutation}
      user_data={user_data}
      is_home_component={is_home_component}
      exec={exec}
    />
  );
};

class Comp extends React.Component {
  render() {
    const {
      r,
      user_data,
      is_home_component,
      mark_attendance_mutation,
      exec
    } = this.props;

    const props = {
      mark_attendance_mutation: mark_attendance_mutation,
      is_home_component: is_home_component,
      user_data: user_data,
      exec:exec
    };
    return (
      <Card>
        <CardContent>
          <Typography variant="h4">Live Classroom</Typography>
          <Render {...props} r={r} />
        </CardContent>
       
      </Card>
    );
  }
}

const Render = ({
  r,
  is_home_component,
  user_data,
  mark_attendance_mutation,
  exec
}) => {
  
  return (
    <>
      { r.map((item,key)=> item.info.length > 0 ? <>
             
             <ListSubheader>{item.date}</ListSubheader>

               { item.info.map((t, i) => {
        return (
          <JoinMeetingButton
          r={t}
          user_data={user_data}
          key={i}
          mark_attendance_mutation={mark_attendance_mutation}
          exec={exec}
          />
        );
      })}
      </> : null ) }
    </>
  );
};


const ShimmerHeader = () => {
  return (
    <Shimmer>
      <div
        style={{ marginTop: 30, width: 100, borderRadius: 64, height: 10 }}
      ></div>
    </Shimmer>
  );
};
const ShimmerRow = ({ classes }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
      <Shimmer>
        <div
          style={{
            marginTop: 30,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            width: 24,
            height: 16,
          }}
        ></div>
      </Shimmer>

      <div style={{ justifyContent: "space-between", marginLeft: 30 }}>
        <div
          style={{
            marginTop: 10,
            width: 200,
            borderRadius: 64,
          }}
        >
          <Shimmer>
            <div className={classes.line1}></div>
          </Shimmer>
        </div>

        <div
          style={{
            marginTop: 10,
            width: 140,
            borderRadius: 64,
            height: 10,
          }}
        >
          <Shimmer>
            <div className={classes.line2}></div>
          </Shimmer>
        </div>
      </div>
    </div>
  );
};

const StyledApp = injectSheet(StyleSheet)(App);
