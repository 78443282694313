import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { Container, TextField, Grid, Button, MenuItem, Select, InputLabel, Paper } from "@material-ui/core";
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { api_url, rest_headers, url } from '../saper/config';

const ButtonComp = ({ test_id, loader, year }) => {


    const handleClick = async () => {
        console.log(test_id);

        try {
            loader.show();

            console.log(test_id)

            const { data: obj } = await axios({
                url: api_url + 'delete-test',
                method: 'post',
                responseType: 'json',
                data: {
                    test_id: test_id,
                    year: year
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            if (obj.success == 'ok') {
                alert("Test Deleted SuccessFully");
                window.location.reload(false);
            }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide()

        }


    }


    return (
        <>
            <Button style={{ border: '1px solid purple', borderStyle: 'dashed' }} onClick={handleClick}>Delete Test</Button>

        </>
    )
}

export default ButtonComp
