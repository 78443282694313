import {useState} from 'react'
import {api_url,rest_headers} from '../../config'
import {handle_error} from '../../handle_error'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import confirm_click from './confirm_click'
const axios = require('axios');


export default({id, snackBar, loader,refresh})=>{
	const [val,setVal]=useState('');
	const click=async (id)=>{		   
  try {
   loader.show();
   const  {data}=await axios({
        url: api_url+'/delete-lead',
        method: 'post',
		responseType: 'json',
		data:{
			id:id,
			reason:val
		},
        ...rest_headers
      }) 
	  alert(data.message);
	  refresh()
 
  } catch (e) { 
       snackBar.show(handle_error(e));
    } finally {
      loader.hide();
    }
 
 
 
	}
	
	const onChange=(e)=>{
		setVal(e.target.value);
	}
	
	return(
	<>
	  <input  placeholder="Enter Reason"    value={val} onChange={onChange}  style={{height:30, }}/>&nbsp;&nbsp;
	 <CloseIcon style={{fontSize:16,color:"red",fontWeight:900,cursor:'pointer'}} onClick={()=>{
		
		confirm_click({click,id})
		
		}}/>
	 
	 
	</>
	)
}