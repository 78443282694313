import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { api_url } from '../reusable-calling-code-project/config';
import { rest_headers } from '../reusable-calling-code-project/config';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { Grid, Paper } from '@material-ui/core';
import styles from '../reusable-calling-code-project/table.module.css';

const ViewLeadDetails = () => {

    const [array, setArray] = useState([]);
    const [res, setRes] = useState([]);
    const [show, setShow] = useState(false);

    const loader = useContext(LoaderContext);

    useEffect(async () => {

        try {

            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/lead-data-calling/view-lead-details',
                method: 'post',
                responseType: 'json',
                ...rest_headers,
            })

            // console.log(obj);

            if (obj.Success === 'ok') {
                setArray(obj.list);
                setRes(obj.arr)
                setShow(true);
            }


        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }

    }, [])

    return (

        <Grid container spacing={2}>
            {show && <><Grid item xs={12}>
                <Paper component={Paper} style={{ padding: '16px' }}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th colSpan={4}>Total RNET and Admissions &nbsp;&nbsp; <b>Count:-</b>&nbsp;{res.length}</th>
                            </tr>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Form No.</th>
                                <th>Name</th>
                                <th>Fee Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {res.map((ele, i) => (
                                <tr style={{ backgroundColor: getColor({ fee: ele.fee }) }}>
                                    <td>{i + 1}</td>
                                    <td>{ele.fno}</td>
                                    <td>{ele.name}</td>
                                    <td>{ele.fee}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Paper>
            </Grid>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={5}>Total Leads Generated &nbsp;&nbsp; <b>Count:-</b>&nbsp;{array.length}</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Reg. Id</th>
                                    <th>Name</th>
                                    <th>Class</th>
                                    <th>Mobile</th>
                                </tr>
                            </thead>
                            <tbody>
                                {array.map((ele, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.orderid}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.class}</td>
                                        <td>{ele.mobile}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid> </>}
        </Grid>
    )
}

export default ViewLeadDetails;


const TrComp = ({ group_no, group }) => {

    // console.log(group_no);

    return (
        <>


            {group.map((n, i) => (
                <>

                    {
                        (i < 1)
                            ? <>

                                <tr>
                                    <td rowSpan={group.length} style={{ fontSize: '12px', backgroundColor: getColor(group) }}>
                                        <b>{group_no}</b>
                                    </td>
                                    <Comp1 group={group} />
                                    {/* <Comp2 group={group} /> */}
                                </tr>

                            </>
                            : <>

                                <tr>
                                    <Comp3 n={n} />
                                </tr>

                            </>
                    }


                </>
            ))}


        </>
    )

}

const Comp1 = ({ group }) => {

    return (
        <>

            <td style={{ fontSize: '12px', height: '10px' }}>{group[0].fno}</td>
            <td style={{ fontSize: '12px', height: '10px' }}>{group[0].name}</td>
            <td style={{ fontSize: '12px', height: '10px' }}>{group[0].fee}</td>

        </>
    )

}

const Comp2 = ({ group }) => {

    let len = group.length - 1;

    return (
        <>

            <td style={{ fontSize: '12px', height: '10px' }}>{group[len].fno}</td>
            <td style={{ fontSize: '12px', height: '10px' }}>{group[len].name}</td>
            <td style={{ fontSize: '12px', height: '10px' }}>{group[len].fee}</td>

        </>
    )

}

const Comp3 = ({ n }) => {

    return (
        <>

            <td style={{ fontSize: '12px', height: '10px' }}>{n.fno}</td>
            <td style={{ fontSize: '12px', height: '10px' }}>{n.name}</td>
            <td style={{ fontSize: '12px', height: '10px' }}>{n.fee}</td>

        </>
    )

}

const getColor = ({ fee }) => {

    // console.log(group)

    if (fee === "Y") {
        return '#E9FCE9'
    }

    else {
        return '#FFFFFF'
    }

    // let i, tmp, l_len = group.length;

    // for (i = 0; i < l_len; i++) {
    //     tmp = group[i].fee;
    //     if (tmp == 'Y') {
    //         return '#90EE90';
    //     }
    // }

}