import React, { Fragment } from "react";

import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";

import { theme } from "../../../../../utils/theme";

import LoginForm from "./login-form";

const roundness = 30;

export default (props) => {
   const snackBar = React.useContext(SnackBarContext);
  const loader = React.useContext(LoaderContext);
  return (
    <Comp snackBar={snackBar} loader={loader} {...props}   />
  );
};

class Comp extends React.Component {
  render() { 
    const { snackBar, loader,force } = this.props;
    const props = {
      roundness: roundness,

      theme: theme,
      loader: loader,
      snackBar: snackBar,
	  force:force
    };

    return <LoginForm {...props} />;
  }
}
