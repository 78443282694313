import React from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { api_endpoint } from "../config";
import { isObject } from 'lodash';
import { LoaderContext } from "../../../../../../context/LoaderContext";


const UploadPdf = ({ id }) => {

    const loader = useContext(LoaderContext);

    const handleUpload = async (event) => {
        try {
            const { name } = event.target.files[0];

            if (name.indexOf('pdf') < 0) {

                alert('please choose pdf file')
                return
            }
            loader.show();
            const data = new FormData();
            data.append('file', event.target.files[0]);
            console.log(data);
            const { data: obj } = await axios.post(`${api_endpoint}/uploadFileAPI`, data)

            if (obj.success != 'ok') {
                alert(obj.message);
            }

            //=======================================

            const { data: data2 } = await axios({
                url: api_endpoint + '/data-collection-1',
                method: 'post',
                responseType: 'json',
                data: {
                    url: obj.url,
                    test: id,
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            if (data2.success == 'ok') {
                alert(data2.message);
                window.location.reload(false);
            } else {
                alert(data2.message);
            }

            //=======================================

        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }


    return (
        <div style={{ maxWidth: 400, margin: '0 auto' }}>

            <input type="file" id="contained-button-file" style={{ display: 'none' }} onChange={handleUpload} />
            <label htmlFor="contained-button-file">
                <Button variant="outined"
                    startIcon={<CloudUploadIcon />}
                    component="span">
                    Upload
                </Button>
            </label>



        </div>
    )
}

export default UploadPdf
