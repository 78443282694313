import { gql } from "@apollo/client";
import classroom_test_inner_common_gql from "./classroom_test_inner_common_gql";

export const classroom_test_result_individual_gql = gql`
  query classroom_test_result_individual($classroom_test_id: Int!) {
    classroom_test_result_individual(classroom_test_id: $classroom_test_id) {
     ${classroom_test_inner_common_gql}
    }
  }
`;
