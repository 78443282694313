import React, { useState, useEffect } from 'react'
import { Select } from "../../../../../utils/select";
import confirm_click from '../reusable-calling-code-project/reuable-calling-code/confirm_click';
import Modal from '../reusable-calling-code-project/modal';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import styles from '../reusable-calling-code-project/table.module.css';
import { handle_error } from '../reusable-calling-code-project/handle_error';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import moment from 'moment';
const axios = require('axios');

const Scholarship = ({ loader, token, snackBar, scholarship, hide, demos, refresh }) => {

    const [sch, setSch] = useState('');
    const [demo, setDemo] = useState('')

    const click = async () => {

        try {

            loader.show();

            if (demo.length == 0) {
                alert('Choose Demo Field');
                return
            }
            if (sch.length == 0) {
                alert('Choose Scholarship')
                return
            }

            const { data: obj } = await axios({
                url: api_url + '/calling-panel/update-scholarship',
                method: 'post',
                responseType: 'json',
                data: {
                    token: token,
                    scholarship: sch,
                    demo, demo
                },
                ...rest_headers,
            })

            console.log(obj);

            if (obj.success === 'ok') {
                alert('Scholarship Updated Successful');
                // refresh()
                hide();
            }

        }

        catch (e) {
            alert(e.message)
        }

        finally {
            loader.hide()
        }

    }

    return (
        <>
            <Grid xs={12}>
                <Select
                    label={"Demo Class Required"}
                    value={demo}
                    onChange={(e) => setDemo(e.target.value)}
                    variant="outlined"
                    name={'demo'}
                    array={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" }
                    ]}
                />
            </Grid>
            <br />
            <Grid xs={12}>
                <TextField
                    // label={"Remark"}
                    placeholder="Enter Scholarship"
                    type="text"
                    fullWidth
                    value={sch}
                    onChange={(e) => setSch(e.target.value)}
                />
            </Grid>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: '10px' }}>
                <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={() => {

                    confirm_click({ click, token })
                }}>Submit</Button>&nbsp;&nbsp;
            </div>
            <br /><br />
            <Typography variant='h5'>Scholarship:&nbsp;&nbsp;{scholarship} </Typography>
            <br />
            <Typography variant='h5'>Demo Class:&nbsp;&nbsp;{demos} </Typography>
        </>
    )
}

export default Scholarship
