import { gql } from "@apollo/client";

export default gql`
  mutation student_login(
    $username: String!
    $password: String!
    $info: LoginInfo!
  ) {
    student_login(username: $username, password: $password, info: $info) {
      token
    }
  }
`;
