import { Table, TableContainer, useMediaQuery, Card, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper';
import React from 'react';
import styles from '../reusable-calling-code-project/table.module.css';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Conversation from './conversation';
import Callback from './callback';
import Scholarship from './scholarship';
import ModalComp from './modal-comp';
import CardComp from './card-comp';

const Profile = ({ array, loader, snackBar, refresh }) => {

    // if (array.length === 0)
    //     return <></>

    // console.log(array);

    const isDesktop = useMediaQuery('(min-width: 960px)');


    return (
        <>


            {
                (isDesktop)
                    ? <>
                        <TableContainer component={Paper}>
                            <div style={{ overflowX: 'auto' }}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th colSpan={14}>Calling Panel for Lucky Draw</th>
                                        </tr>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Reg. No.</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Class</th>
                                            <th>Gender</th>
                                            <th>Coaching</th>
                                            <th>Email</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Conversation</th>
                                            <th>Callback</th>
                                            <th>Scholarship</th>
                                            <th>Admin</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {array.map((ele, i) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{ele.token_number}</td>
                                                        <td>{ele.name}</td>
                                                        <td>


                                                            {
                                                                (ele.mobile === null)
                                                                    ? <></>
                                                                    : <>
                                                                        <CopyIcon snackBar={snackBar} mobile={ele.mobile} /><br />
                                                                    </>
                                                            }
                                                            {
                                                                (ele.whatsapp === null)
                                                                    ? <></>
                                                                    : <>
                                                                        <br />
                                                                        <CopyIcon snackBar={snackBar} mobile={ele.whatsapp} />
                                                                    </>
                                                            }


                                                        </td>
                                                        <td>{ele.classs}</td>
                                                        <td>{ele.gender}</td>
                                                        <td>{ele.coaching}</td>
                                                        <td>{ele.email}</td>
                                                        <td>{ele.city}</td>
                                                        <td>{ele.state}</td>
                                                        <td>
                                                            <ModalComp refresh={refresh} last_remark={ele.last_remark} last_category={ele.last_category} last_time={ele.last_time} token={ele.token_number} loader={loader} snackBar={snackBar} component={'conversation'} />
                                                            <br />
                                                            <Typography style={{ fontSize: '12px' }} variant='h5'><b>Category:-</b>&nbsp;&nbsp;{ele.last_category}</Typography>
                                                        </td>
                                                        <td>
                                                            <ModalComp last_callback={ele.last_callback} token={ele.token_number} loader={loader} snackBar={snackBar} component={'callback'} />
                                                        </td>
                                                        <td>
                                                            <ModalComp refresh={refresh} demos={ele.demo} scholarship={ele.scholarship} token={ele.token_number} loader={loader} snackBar={snackBar} component={'scholarship'} />
                                                            <br />
                                                            <Typography style={{ fontSize: '12px' }} variant='h5'><b>Demo:-</b>&nbsp;&nbsp;{ele.demo}</Typography>
                                                        </td>
                                                        <td>{ele.admin_name}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </TableContainer>
                    </>
                    : <>

                        <CardComp array={array} loader={loader} refresh={refresh} snackBar={snackBar} />

                    </>
            }
        </>
    )
}

const CopyIcon = ({ mobile, snackBar }) => {
    const mask = (mobile) => {
        try {

            return mobile.replace(/\d(?=\d{4})/g, "*");
        } catch (e) {

        }
    }

    return (
        <>
            {mask(mobile)}&nbsp;&nbsp;
            <FileCopyIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" onClick={() => {
                navigator.clipboard.writeText(mobile);
                snackBar.show('Copied!');
            }} />
        </>
    )
}

export default Profile
