import React, { Component } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
 

export const InputRich = ({ onChange, value, image_upload_url }) => (
  <CKEditor
    editor={CKEditor}
    data={value || ""}
    onInit={(editor) => {
      // You can store the "editor" and use when it is needed.
      // console.log('Editor is ready to use!', editor);
    }}
    config={{
      simpleUpload: {
        // Upload the images to the server using the CKFinder QuickUpload command.
        uploadUrl: image_upload_url,
      },
    }}
    onChange={(event, editor) => {
      const data = editor.getData();
      //console.log({ event, editor, data });
      onChange({ target: { value: data } });
    }}
    onBlur={(event, editor) => {
      // console.log('Blur.', editor);
    }}
    onFocus={(event, editor) => {
      //  console.log('Focus.', editor);
    }}
  />
);
