import React, { useContext, useState,useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange, 
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
 import { Select } from "../../../../../../utils/select";
import Typography from "@material-ui/core/Typography";
 import {api_url,rest_headers} from '../../../../../student/app/srceens/digital-classroom/config'
import {handle_error} from '../../call-center/handle_error'
import RaiseTicket from '../../../../../student/app/srceens/support-ticket'
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,

 
} from "@material-ui/core";
import {CardComp} from '../../../../../student/app/srceens/support-ticket'
 import styles from '../../call-center/table.module.css'; 
const axios = require('axios');

export default () => {
	  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
 
    return (
    <Comp
     snackBar={snackBar}
      loader={loader}
    />
  );
}; 
const Comp = ({snackBar,loader}) => {
   const [pending,setPending]=useState(true);
   
    const [res, setRes] = useState([]);
	 useEffect(()=>{
		 window.document.addEventListener("keydown", function (event) {
    if (event.ctrlKey) {
        event.preventDefault();
    }   
});

window.document.addEventListener('contextmenu', event => event.preventDefault());
		fetch() 
	 },[])
	  const fetch=async()=>{
	  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/fee-collection',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##'
}
      }) 
	    
	 console.log(data.list)
 
 setRes( data.list)
 
      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  } 
	
   
   
  return (
    
      <Container>
        
          <Grid spacing={3} container>
		     <Grid item>
		
            
			{res && res.length>0 && <Result res={res}/>}
           

            </Grid>
          </Grid>
        
      </Container>
    
  );
};

 
 
 
  
 const Result=({res})=>{
 console.log(res)
	 return(
	 <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="8" className={styles.typogrpahy_header} > Fee Status</th></tr>
          <tr>
		  <th>Roll No.</th>
        
            <th>Name</th>
			<th>Course</th>
		
			<th>mobile1</th>
		     <th>mobile2</th>
			 <th>Fee Dep Date</th>
			   
			
			 <th>Amount Deposited</th>
			 <th>Less Amount</th>
			 
          </tr>
        </thead>
		  <tbody>
  
  {
	  res.map(({fno,name,coursetitle,mobile1,mobile2,depdate,amount_deposited_total,less_amount})=>{
		  
		  return( 
		 <tr>
		  <td>{fno}</td> <td>{name}</td> <td>{coursetitle}</td>   
		 <td>{mobile1}</td> <td>{mobile2}</td>   <td>{depdate}</td> 
			<td>{amount_deposited_total}</td>   <td>{less_amount}</td>   
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
	 )
 }
 