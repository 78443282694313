import { Collapse, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from '../reusable-calling-code-project/table.module.css';
import ModalComp from './modal-comp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CallIcon from '@material-ui/icons/Call';


const CardComp = ({ array, loader, snackBar, refresh }) => {



    return (
        <>

            {array.map((ele, i) => {

                return (

                    <>

                        <Paper style={{ backgroundColor: 'aliceblue', padding: '16px' }}>

                            <Typography variant='h5'><b>Name:</b>&nbsp;&nbsp;{ele.name}</Typography>
                            <br />
                            <Typography variant='h5'><b>Last Remark</b>&nbsp;&nbsp;{ele.last_category}</Typography>

                            <CollapseComp refresh={refresh} name={ele.name} token={ele.token_number} city={ele.city} state={ele.state} coaching={ele.coaching} gender={ele.gender} classs={ele.classs} demos={ele.demos} email={ele.email} mobile={ele.mobile} whatsapp={ele.whatsapp} last_remark={ele.last_remark} last_category={ele.last_category} last_time={ele.last_time} loader={loader} snackBar={snackBar} last_callback={ele.last_callback} scholarship={ele.scholarship} admin_name={ele.admin_name} />

                        </Paper>
                        <br /><br />

                    </>

                )

            })}

        </>
    )
}

export default CardComp


const CollapseComp = ({ name, admin_name, token, city, state, coaching, email, gender, classs, mobile, whatsapp, last_remark, last_time, last_callback, last_category, scholarship, demos, loader, refresh, snackBar }) => {

    const [expanded, setExpanded] = useState(false);

    return (
        <>

            <ExpandMoreIcon onClick={() => setExpanded(!expanded)} />
            <Collapse in={expanded} style={{ backgroundColor: 'seashell', width: '100%' }}>

                <table className={styles.table}>

                    <thead>
                        <tr>
                            <th colSpan={2}>Lucky Draw Calling</th>
                        </tr>
                        <tr>
                            <th>Head</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Token</td>
                            <td>{token}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{name}</td>
                        </tr>
                        <tr>
                            <td>Mobile</td>
                            <td>
                                {
                                    (mobile === null)
                                        ? <></>
                                        : <>

                                            <CopyIcon snackBar={snackBar} mobile={mobile} />
                                            <br />

                                        </>
                                }
                                {
                                    (whatsapp === null)
                                        ? <></>
                                        : <>
                                            <CopyIcon snackBar={snackBar} mobile={whatsapp} />


                                        </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Class</td>
                            <td>{classs}</td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>{gender}</td>
                        </tr>
                        <tr>
                            <td>Coaching</td>
                            <td>{coaching}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{email}</td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>{city}</td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>{state}</td>
                        </tr>
                        <tr>
                            <td>Conversation</td>
                            <td><ModalComp refresh={refresh} last_remark={last_remark} last_category={last_category} last_time={last_time} token={token} loader={loader} snackBar={snackBar} component={'conversation'} /></td>
                        </tr>
                        <tr>
                            <td>Callback</td>
                            <td><ModalComp refresh={refresh} last_callback={last_callback} token={token} loader={loader} snackBar={snackBar} component={'callback'} /></td>
                        </tr>
                        <tr>
                            <td>Scholarship</td>
                            <td><ModalComp refresh={refresh} demos={demos} scholarship={scholarship} token={token} loader={loader} snackBar={snackBar} component={'scholarship'} /></td>
                        </tr>
                        <tr>
                            <td>Admin Name</td>
                            <td>{admin_name}</td>
                        </tr>
                    </tbody>

                </table>

            </Collapse>

        </>
    )
}

const CopyIcon = ({ mobile, snackBar }) => {
    const mask = (mobile) => {
        try {
            return mobile.replace(/\d(?=\d{4})/g, "*");
        } catch (e) {

        }
    }
    return (
        <>
            {mask(mobile)}&nbsp;&nbsp;
            <FileCopyIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" onClick={() => {
                navigator.clipboard.writeText(mobile);
                snackBar.show('Copied!');
            }} />
            <a href={'tel:' + mobile} target='blank'>
                <CallIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" />
            </a>
        </>
    )
}