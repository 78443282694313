import { gql } from "@apollo/client";

export const childsubject_list_query_gql = gql`
  query childsubject_list($subject_id: Int!) {
    childsubject_list(subject_id: $subject_id) {
      key: id
      value: name
    }
  }
`;
