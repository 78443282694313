import { Button, MenuItem, Grid } from "@material-ui/core";
import { InputLabel, Select, Paper } from "@material-ui/core";
import axios from "axios";
import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { LoaderContext } from "../../../../../context/LoaderContext";
import styles from '../reusable-calling-code-project/table.module.css';
import { api_url } from "./config";
export default function SMS() {
  const loader = useContext(LoaderContext);
  const [value, setValue] = useState([]);
  const [campaign, setCampaign] = useState();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    fetch();
  }, [])
  const fetch = async () => {
    try {
      const { data: obj } = await axios({
        url: api_url + "/campaign-details",
        method: "post",
        responseType: "json",
      })
      setValue(obj.result);
      console.log(obj.result);

    }
    catch (e) {
      alert(e.message);
    }
  }
  const handleClick = async (e) => {

    try {
      loader.show();
      const val = e.target.value;
      setCampaign(e.target.value);

      console.log(e.target.value);
      const { data: obj } = await axios({
        url: api_url + '/sms-details',
        method: 'post',
        responseType: 'json',
        data: {
          campaign: val
        }

      })

      console.log(obj);
      setData(obj.test);
      setShow(true);
    }
    catch (e) {
      alert(e.message);
    }
    finally {
      loader.hide();
    }
  }
  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"

      >

        <Paper style={{ padding: '10px', backgroundColor: 'white', width: "200px", }}>
          <InputLabel>Select Campaign</InputLabel>
          <Select value={campaign} onChange={handleClick} label="Select" fullWidth>

            {value.map((ele) => {
              return (

                <MenuItem value={ele.id} >{ele.name}</MenuItem>

              )
            })}
          </Select>

        </Paper>
      </Grid>
      <br></br>
      <br></br>
      {show &&
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"

        >

          <Paper style={{ padding: '10px', backgroundColor: 'white', width: "100vh" }}>
            <table className={styles.table} style={{ fontSize: '13px' }}>
              <thead>
                <th>Roll No</th>
                <th>Mobile</th>
              </thead>
              <tbody>
                {data.map((ele) => {
                  return (
                    <tr>
                      <td>{ele.roll_no}</td>
                      <td>{ele.mobile}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Paper>
        </Grid>
      }
    </React.Fragment>
  )
}