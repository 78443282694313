import React,{useState,useEffect,useContext} from 'react';
import {api_url,rest_headers} from '../config'
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { Select } from "../../../../../../utils/select";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
const axios = require('axios');


export default (props) => {
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
		  {...props}
    />
  );
};
const Comp = ({ loader, snackBar,...props })=>{
	 
	const [show,setShow]=useState(false);
    const [res, setRes] = useState(null);
	const [school_id,setSchool]=useState('');
	const [formno,setFormNo]=useState('');
	const  refresh=()=>{
		fetch()
	}
   
  useEffect(async()=>{
	   document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
  fetch();
  },[])
  
  const fetch=async()=>{
	  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/school-list',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	    
	 
 
 setRes( data.array)

      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      
if(!school_id)
{
	alert('Please select school');
	return;
} 
if(!formno)
{
	alert('Please enter form no');
	return;
}
if(!(formno>=10300001 && formno<=10400000))
{
	alert('Please enter valid form no');
	return;
}

      loader.show();
const  {data}=await axios({
        url: api_url+'/create-form',
        method: 'post',
		responseType: 'json',
		data:{
			school_id:school_id,
			formno:formno
			 
		},
        headers: {
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	  alert(data.message);
       
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  
	
	return(
	<form onSubmit={handleSubmit}>
	 <Container>
	 <FormLayouttopic title="Bulk Entry">
          <Grid spacing={3} container>
		    <Grid item xs={12}>
	{res && <SchoolList array={res} school_id={school_id} setSchool={setSchool} refresh={refresh} snackBar={snackBar} loader={loader} {...props} />}
	</Grid>
	<Grid item xs={12}>
		
		<TextField
                label="Enter Form No."
                variant="outlined"
                name="name"
                value={formno}
                onChange={(e)=>setFormNo(e.target.value)}
              />
	</Grid>
	 <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                SUBMIT
              </Button>
            </Grid>
	</Grid>
	</FormLayouttopic>
	</Container>
	</form>
	)
}

const SchoolList=({array,school_id,setSchool})=>{
	return(
	<Select
      label={"Select School"}
      value={school_id}
      name={'school'}
      onChange={(e)=>{setSchool(e.target.value)}}
      array={array}
    />
	)
}