import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({ title, children }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Container style={{ maxWidth: 600 }}>
      <Card className={classes.root} lg={6}>
        <CardHeader title={title}></CardHeader>
        <Divider />
        <CardContent>{children}</CardContent>
      </Card>
    </Container>
  );
}
