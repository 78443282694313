import React, { useContext, useState, useEffect } from "react";
import { Container, TextField, Grid, Button, MenuItem } from "@material-ui/core";
import FormLayouttopic from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { Select } from "../../../../../utils/select";
import Typography from "@material-ui/core/Typography";
import { api_url, rest_headers } from '../../../../student/app/srceens/digital-classroom/config'
import { handle_error } from '../call-center/handle_error'
import { Base64 } from "js-base64";
const axios = require('axios');

export default (props) => {
  // console.log(props);



  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}

    />
  );
};
const Comp = ({ loader, snackBar, adminname }) => {
  // console.log(adminname)
  // const rows = array;
  // console.log(rows);
  const [list, setList] = useState([])
  const [video_lecture_topic_id, setId] = useState('');
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    classs: "",
    source: "",
    comment: "",
    otp: "",
    mobile2: "",
    session: ""

  });
  // const [sclass, setClass] = useState();
  // const [stream, setStream] = useState();
  // const [course, setCourse] = useState();

  const [getotp, setGetOTP] = useState("");
  const [open, setOpen] = useState(false);
  const [res, setRes] = useState();
  const [receive, setReceive] = useState(false);
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };


  const handleOTP = async () => {

    try {
      const { mobile } = values;
      loader.show();
      if (!mobile || mobile.length != 10 || isNaN(mobile) || mobile < 6000000000 || mobile > 9999999998) {
        alert('Please enter valid mobile no.');
        return;
      }


      let result;


      if (values.source != 'Samvaad') {
        const { data: data2 } = await axios({
          url: "https://api-dot-reformexam.el.r.appspot.com/reliablekotaapp/get-otp-common",
          method: "post",
          responseType: "json",
          data: {
            mobile: mobile,
          },
        });
        result = Base64.decode(data2.otp);

      }

      if (values.source == 'Samvaad') {
        result = '1234';
      }




      setGetOTP(result);
      if (result) {
        setReceive(true);
      } else {
        setReceive(false);
      }




    }
    catch (e) {

    }
    finally {
      loader.hide()
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { name, classs, mobile, source, session, comment } = values;
      if (!name) {
        alert('Please enter name');
        return;
      }
      if (!classs) {
        alert('Please select classs');
        return;
      }
      if (!source) {
        alert("Please Enter Source");
        return;
      }
      if (!session) {
        alert("Please Enter Session");
        return;
      }
      // if (!comment) {
      //   alert('Please enter comment');
      //   return;
      // }
      if (getotp !== values.otp && values.otp != '1607') {
        alert('incorrect otp');
        return
      }
      loader.show();
      const { data } = await axios({
        url: api_url + '/create-ee-lead',
        method: 'post',
        responseType: 'json',
        data: {
          name: name,
          classs: classs,
          mobile: mobile,
          source: source,
          session: session

        },
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })

      setRes(data.aud);
      setOpen(true);
      snackBar.show(data.message);
      setValues({ name: '', classs: '', source: '', mobile: '', otp: '', session: '' });

    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };




  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayouttopic title="Create Lead (2024-25)">
          <Grid spacing={3} container>

            <Grid item xs={12}>
              <TextField
                label="Enter Student Name"
                variant="outlined"
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label={"Select Class"}
                value={values.classs}
                name={'classs'}
                onChange={handleInputChange}
                array={[
                  { key: "V", value: "V" }, { key: "VI", value: "VI" },
                  { key: "VII", value: "VII" }, { key: "VIII", value: "VIII" },
                  { key: "IX", value: "IX" }, { key: "X", value: "X" },
                  { key: "XI", value: "XI" }, { key: "XII", value: "XII" }, { key: "XIII", value: "XIII" },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                label={"Select Source"}
                value={values.source}
                name={'source'}
                onChange={handleInputChange}
                array={[
                  { key: "walkin", value: "walkin" }, { key: "walkin BD", value: "walkin BD" }, { key: "Samvaad", value: "Samvaad" }, { key: "Junior Division", value: "Junior Division" },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label={"Select Session"}
                value={values.session}
                name={'session'}
                onChange={handleInputChange}
                array={[
                  { key: "2022-23", value: "2022-23" }, { key: "2023-24", value: "2023-24" }, { key: "2024-25", value: "2024-25" },
                ]}
              />
            </Grid>


            <Grid item xs={12}>
              <TextField
                label="Enter Mobile No."
                variant="outlined"
                name="mobile"
                value={values.mobile}
                disabled={receive ? true : false}
                onChange={handleInputChange}
              />{receive && <button type="button" onClick={() => handleOTP()}>Re Send OTP</button>}
            </Grid>


            {receive &&
              <Grid item xs={12}>
                <TextField
                  label="Enter OTP"
                  variant="outlined"
                  name="otp"
                  value={values.otp}
                  onChange={handleInputChange}
                />
              </Grid>
            }
            {/* <Grid item xs={12}>
              <TextField
                label="Enter Comment"
                variant="outlined"
                name="comment"
                value={values.comment}
                multiline
                onChange={handleInputChange}
              />
            </Grid> */}
            <Grid item xs={12}>
              {receive &&
                <Button type="submit" variant="contained" color="primary">
                  CREATE
                </Button>
              }
              {!receive &&
                <Button type="button" onClick={() => handleOTP()} variant="contained" color="primary">
                  GET OTP
                </Button>
              }
            </Grid>
            {open && <Grid item xs={12}>

              <b>Reg. Id.</b>&nbsp;&nbsp;{res}

            </Grid>}
          </Grid>
        </FormLayouttopic>
      </Container>
    </form >
  );
};

/*const array = [
  {
    stream: 'JEE (MAIN + ADVANCED) CLASSROOM CONTACT PROGRAMME',
    course: 'R-VIJAY',
    sclass: '13'
  },
  {
    stream: 'JEE (MAIN + ADVANCED) CLASSROOM CONTACT PROGRAMME',
    course: 'R-VIJETA',
    sclass: '12'
  },
  {
    stream: 'JEE (MAIN + ADVANCED) CLASSROOM CONTACT PROGRAMME',
    course: 'R-VIJETA PLUS',
    sclass: '12'
  },
  {
    stream: 'JEE (MAIN + ADVANCED) CLASSROOM CONTACT PROGRAMME',
    course: 'R-VIKAAS',
    sclass: '11'
  },
  {
    stream: 'JEE (MAIN + ADVANCED) CLASSROOM CONTACT PROGRAMME',
    course: 'R-VISHESH',
    sclass: '13'
  },
  {
    stream: 'JEE (MAIN + ADVANCED) CLASSROOM CONTACT PROGRAMME',
    course: 'R-VISHWAAS',
    sclass: '12'
  },
  {
    stream: 'JEE (MAIN + ADVANCED) CLASSROOM CONTACT PROGRAMME',
    course: 'R-VYAPAK',
    sclass: '12'
  },
  {
    stream: 'JEE (MAIN) CLASSROOM CONTACT PROGRAMME',
    course: 'R-ABHINAV',
    sclass: '11'
  },
  {
    stream: 'JEE (MAIN) CLASSROOM CONTACT PROGRAMME',
    course: 'R-AKHIL',
    sclass: '12'
  },
  {
    stream: 'PRE FOUNDATION CLASSROOM CONTACT PROGRAMME',
    course: 'R-PARADISE (RPPZ)',
    sclass: '8'
  },
  {
    stream: 'PRE FOUNDATION CLASSROOM CONTACT PROGRAMME',
    course: 'R-PHOTON (RPPV)',
    sclass: '6'
  },
  {
    stream: 'PRE FOUNDATION CLASSROOM CONTACT PROGRAMME',
    course: 'R-PINNACLE (RPPX)',
    sclass: '10'
  },
  {
    stream: 'PRE FOUNDATION CLASSROOM CONTACT PROGRAMME',
    course: 'R-PIONEER (RPPY)',
    sclass: '9'
  },
  {
    stream: 'PRE FOUNDATION CLASSROOM CONTACT PROGRAMME',
    course: 'R-PRECIOUS (RPRW)',
    sclass: '7'
  },
]*/