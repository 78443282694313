import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import VideoCam from "@material-ui/icons/Videocam";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import theme from "../../../../../theme";
import LiveIcon from "../../../../../assets/live.png";
import zoom_handler from "./zoom-handler";
import { Loader } from "../../../../../utils/loader";
import { ZoomContext } from "../../../../../context/ZoomContext";

export default (props) => {
  const { registerProcess, clearProcessList } = React.useContext(ZoomContext);

  return (
    <Comp
      {...props}
      registerProcess={registerProcess}
      clearProcessList={clearProcessList}
    />
  );
};
class Comp extends React.Component {
  state = {
    loading: false,
  };
  setLoader = (loading) => {
    this.setState({ loading });
  };
  join = async () => {
    let {
      user_data,
      r,
      registerProcess,
      clearProcessList,
      mark_attendance_mutation,
      exec,
    } = this.props;
    const { setLoader } = this;
    console.log(r["disabled"]);
    if (r["disabled"]) return false;

    try {
      zoom_handler({
        user_data,
		r,
        meeting_info: {
          meeting_no: r["meeting_no"],
          meeting_pwd: r["meeting_pwd"],
        },

        setLoader,
        registerProcess,
        clearProcessList,
        mark_attendance_mutation,
        meeting_id: r["id"],
        exec,
      });
    } catch (e) {
      alert(e.message);
    }
  };
  render() {
    const { r } = this.props;
    let style = { marginRight:0 , color: r['disabled'] === false ? '#ff0000' : '#777777' };
    return (
      <>
        <ListItem button onClick={this.join}>
          <ListItemIcon style={style}>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={r["subject"].name}
            secondary={r["start_time"]}
          />
        </ListItem>
        {this.state.loading && <Loader />}
      </>
    );
  }
}
