import { Button, Select, Paper, MenuItem, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import Modal from '../reusable-calling-code-project/modal';
import Callback from '../reusable-calling-code-project/reuable-calling-code/callback';
import Conversation from '../reusable-calling-code-project/reuable-calling-code/conversation';
import moment from 'moment';

const ModalComp = ({ component, handleCallbackFilter, handleClass, handleCategoryFilter, title, handleFees, handleTestDate, tested, handleAdmin, adminf, handleSource, handleUtmSource, sourcef, utmf, isDesktop, last_callback, last_remark, last_category, last_time, loader, project, snackBar, roll_no }) => {

    const [show, setShow] = useState(false);
    const [utm_filter, setUtmFilter] = useState();
    const [source_filter, setSourceFilter] = useState();
    const [admin_filter, setAdminFilter] = useState();
    const [fee_filter, setFeeFilter] = useState();
    const [test_filter, setTestFilter] = useState();
    const [category_filter, setCategoryFilter] = useState();
    const [followup_filter, setFollowUpFilter] = useState();
    const [class_filter, setClassFilter] = useState();

    const handleClick = () => {
        setShow(!show);
    }


    const hide = () => {
        setShow(!show);
    }

    const handleF = (e) => {
        setUtmFilter(e.target.value);
        handleUtmSource({ filter: e.target.value, hide: hide });
    }

    const handleS = (e) => {
        setSourceFilter(e.target.value);
        handleSource({ filter: e.target.value, hide: hide })
    }

    const handleA = (e) => {
        setAdminFilter(e.target.value);
        handleAdmin({ filter: e.target.value, hide: hide })
    }

    const handleFee = (e) => {
        setFeeFilter(e.target.value);
        handleFees({ filter: e.target.value, hide: hide })
    }

    const handleTestF = (e) => {
        setTestFilter(e.target.value);
        handleTestDate({ filter: e.target.value, hide: hide })
    }

    const handleCategoryF = (e) => {
        setCategoryFilter(e.target.value);
        handleCategoryFilter({ filter: e.target.value, hide: hide });
    }

    const handleFollowF = (e) => {
        setFollowUpFilter(e.target.value);
        handleCallbackFilter({ filter: e.target.value, hide: hide })
    }

    const handleClassF = (e) => {
        setClassFilter(e.target.value);
        handleClass({ filter: e.target.value, hide: hide })
    }

    return (
        <div>

            <Button style={{ border: '1px solid #000' }} onClick={handleClick}>{title}</Button>

            {show && <Modal hide={hide}>
                {
                    (component === 'conversation')
                        ? <>
                            <Conversation hide={hide} isDesktop={isDesktop} last_remark={last_remark} last_category={last_category} last_time={last_time} project={project} loader={loader} snackbar={snackBar} roll_no={roll_no} />
                        </>
                        : <></>
                }
                {
                    (component === 'callback')
                        ? <>
                            <Callback hide={hide} isDesktop={isDesktop} project={project} loader={loader} snackbar={snackBar} roll_no={roll_no} last_callback={last_callback} />
                        </>
                        : <></>
                }
                {
                    (component === 'utm_source')
                        ? <>
                            <Paper style={{ padding: '16px', minWidth: '200px' }} component={Paper}>
                                <InputLabel>Select UTM Source</InputLabel>
                                <Select value={utm_filter} onChange={handleF}
                                    fullWidth
                                >
                                    <MenuItem value="null">Select Utm Source</MenuItem>
                                    {utmf.map((ele, i) => (

                                        <MenuItem value={ele.utm}>{ele.utm}</MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                        </>
                        : <></>
                }
                {
                    (component === 'source')
                        ? <>
                            <Paper style={{ padding: '16px', minWidth: '200px' }} component={Paper}>
                                <InputLabel>Select Source</InputLabel>
                                <Select value={source_filter} onChange={handleS}
                                    fullWidth
                                >
                                    <MenuItem value="null">Select Source</MenuItem>
                                    {sourcef.map((ele, i) => (

                                        <MenuItem value={ele.source}>{ele.source}</MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                        </>
                        : <></>
                }
                {
                    (component === 'admin')
                        ? <>
                            <Paper style={{ padding: '16px', minWidth: '200px' }} component={Paper}>
                                <InputLabel>Select Admin</InputLabel>
                                <Select value={admin_filter} onChange={handleA}
                                    fullWidth
                                >
                                    <MenuItem value="123">Select Admin</MenuItem>
                                    <MenuItem value="null">Unassigned Leads</MenuItem>
                                    {adminf.map((ele, i) => (

                                        <MenuItem value={ele.admin_name}>{ele.admin_name}</MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                        </>
                        : <></>
                }
                {
                    (component === 'fees')
                        ? <>
                            <Paper style={{ padding: '16px', minWidth: '200px' }} component={Paper}>
                                <InputLabel>Select Fees</InputLabel>
                                <Select value={fee_filter} onChange={handleFee}
                                    fullWidth
                                >
                                    <MenuItem value="null">Select Fees</MenuItem>
                                    <MenuItem value="Y">Y</MenuItem>
                                    <MenuItem value="N">N</MenuItem>
                                </Select>
                            </Paper>
                        </>
                        : <></>
                }
                {
                    (component === 'testDate')
                        ? <>
                            <Paper style={{ padding: '16px', minWidth: '200px' }} component={Paper}>
                                <InputLabel>Select RNeT Date</InputLabel>
                                <Select value={test_filter} onChange={handleTestF}
                                    fullWidth
                                >
                                    <MenuItem value="null">Select Fees</MenuItem>
                                    {tested.map((ele, i) => (

                                        <MenuItem value={ele.test}>{ele.test}</MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                        </>
                        : <></>
                }
                {
                    (component === 'category')
                        ? <>
                            <Paper style={{ padding: '16px', minWidth: '200px' }} component={Paper}>
                                <InputLabel>Category Filter</InputLabel>
                                <Select value={category_filter} onChange={handleCategoryF}
                                    fullWidth
                                >
                                    <MenuItem value="null">Select Category</MenuItem>
                                    <MenuItem value="Admission Done">Admission Done</MenuItem>
                                    <MenuItem value="Advanced Counselling">Advanced Counselling</MenuItem>
                                    <MenuItem value="Already Enrolled">Already Enrolled</MenuItem>
                                    <MenuItem value="Disqualified">Disqualified</MenuItem>
                                    <MenuItem value="Follow Up">Follow Up</MenuItem>
                                    <MenuItem value="Form Filled">Form Filled</MenuItem>
                                    <MenuItem value="Financial Issue">Financial Issue</MenuItem>
                                    <MenuItem value="Interested">Interested</MenuItem>
                                    <MenuItem value="Joined other Institute">Joined other Institute</MenuItem>
                                    <MenuItem value="Not Able To Connect">Not Able To Connect</MenuItem>
                                    <MenuItem value="Will Visit Campus">Will Visit Campus</MenuItem>
                                    <MenuItem value="Will come after JEE MAIN">Will come after JEE MAIN</MenuItem>
                                    <MenuItem value="Wil come after BOARD EXAMS">null</MenuItem>
                                    <MenuItem value="Will Apply Online">Will Apply Online</MenuItem>
                                    <MenuItem value="Will Deposit Course Fee">Will Deposit Course Fee</MenuItem>
                                </Select>
                            </Paper>
                        </>
                        : <></>
                }
                {
                    (component === 'followup')
                        ? <>
                            <Paper style={{ padding: '16px', minWidth: '200px' }} component={Paper}>
                                <InputLabel>Callback Filter</InputLabel>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type="date"
                                    value={followup_filter}
                                    min={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                                    onChange={handleFollowF}
                                />
                            </Paper>
                        </>
                        : <></>
                }
                {
                    (component === 'class')
                        ? <>
                            <Paper style={{ padding: '16px', minWidth: '200px' }} component={Paper}>
                                <InputLabel>Class Filter</InputLabel>
                                <Select value={class_filter} onChange={handleClassF}
                                    fullWidth
                                >
                                    <MenuItem value="null">Select Category</MenuItem>
                                    <MenuItem value="6">Class 6th</MenuItem>
                                    <MenuItem value="7">Class 7th</MenuItem>
                                    <MenuItem value="8">Class 8th</MenuItem>
                                    <MenuItem value="9">Class 9th</MenuItem>
                                    <MenuItem value="10">Class 10th</MenuItem>
                                    <MenuItem value="11">Class 11th</MenuItem>
                                    <MenuItem value="12">Class 12th</MenuItem>
                                    <MenuItem value="13">Class 13th</MenuItem>
                                </Select>
                            </Paper>
                        </>
                        : <></>
                }

            </Modal>
            }

        </div>
    )
}

export default ModalComp;
