import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import Profile from '../reusable-calling-code-project/reuable-calling-code/profile';
import styles from '../reusable-calling-code-project/table.module.css'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ViewStudentAbsent from '../view-student-attendance';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { handle_error } from '../reusable-calling-code-project/handle_error'
// import { Select } from "../../../../../utils/select";
import { Button, Container, Dialog, DialogContent, Grid, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
const axios = require('axios');
const _ = require("lodash");

export default (props) => {
    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);
    return (
        <Comp
            snackBar={snackBar}
            loader={loader}
            {...props}
        />
    );
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Comp = ({ loader, snackBar, ...props }) => {

    const [view, setView] = useState(false);
    const [titles, setTitles] = useState('');
    const [type, setType] = useState('');
    const [load, setLoad] = useState(false);
    const [data, setData] = useState(false);
    const [sorted, setSorted] = useState(null);
    // const [ubatch, setBatch] = useState([]);
    const [course_Title, setCourseTitle] = useState([]);
    const [batch, setBatch] = useState('');
    const [batcharr, setBatchArr] = useState([]);
    const [arr, setArr] = useState([]);
    const [res, setRes] = useState([
        /*{
            "roll_no": 12,

            "mobile": "8118846657",
            "mobile2": "9999999999",

            "name": "Sakshi bhartia",
            "fno": "2211111",
            "classs": "8",
            "last_remark": "10000068 payment done ",
            "last_callback": '2022-03-29T16:19',
            "created_at": "11-Oct, 2021 16:18"


        }, {
            "roll_no": 13,

            "mobile": "811846657",
            "mobile2": "9991199999",

            "name": "Ajar hartia",
            "fno": "2211123",
            "classs": "9",
            "last_remark": "10000068 payment done ",
            "last_callback": '2022-03-27T16:19',
            "created_at": "11-Oct, 2021 16:18"


        }*/
    ]);
    // const refresh = () => {
    //     fetch()
    // }

    // useEffect(async () => {
    //     document.addEventListener('contextmenu', (e) => {
    //         e.preventDefault();
    //     });
    //     fetch();
    // }, [])


    const classes = useStyles();

    const handleDescSort = () => {

        let tmp = res;


        let value = _.orderBy(res, 'dates', 'desc');
        // console.log(value);
        setRes(value);

    }

    const handleAscSort = () => {
        let tmp = res;

        let value = _.orderBy(res, 'dates', 'asc');
        // console.log(value);
        setRes(value);
    }

    const handleChange = async (e) => {
        const val = e.target.value;
        console.log(val);

        let i, tmp = [];

        for (i = 0; i < arr.length; i++) {

            if (arr[i].batch == val) {
                tmp.push(arr[i]);
            }
        }

        setRes(tmp);

    }


    return (
        <>

            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={4}>Select the day of Attendance</th>
                                    </tr>
                                    <tr>
                                        <th>Regularly Absent</th>
                                        <th>Today's Absent</th>
                                        <th>Yesterday's Absent</th>
                                        <th>Last Week Absent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><ButtonComp title={'Regularly Absent'} setTitles={setTitles} type={100} loader={loader} snackBar={snackBar} setView={setView} setRes={setRes} setArr={setArr} setBatchArr={setBatchArr} /></td>
                                        <td><ButtonComp title={'Todays Absent'} type={0} setTitles={setTitles} loader={loader} snackBar={snackBar} setView={setView} setRes={setRes} setArr={setArr} setBatchArr={setBatchArr} /></td>
                                        <td><ButtonComp title={'Yesterdays Absent'} type={1} setTitles={setTitles} loader={loader} snackBar={snackBar} setView={setView} setRes={setRes} setArr={setArr} setBatchArr={setBatchArr} /></td>
                                        <td><ButtonComp title={'Last Week Absent'} type={7} setTitles={setTitles} loader={loader} snackBar={snackBar} setView={setView} setRes={setRes} setArr={setArr} setBatchArr={setBatchArr} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                    {view &&
                        <>

                            <Grid item xs={12}>
                                <Paper component={Paper} style={{ padding: '16px' }}>
                                    <table className={styles.table}>
                                        <thead>
                                            <tr>
                                                <th colSpan={3} style={{ border: 'none' }}>
                                                    <Button onClick={handleAscSort} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Asc. Order</Button>
                                                </th>
                                                <th style={{ border: 'none' }} colSpan={4}>Absentees Data ({titles})
                                                    <br /><br />
                                                    <b>Total Count:-</b>&nbsp;{res.length}
                                                </th>
                                                <th colSpan={1} style={{ border: 'none' }}>
                                                    <Button onClick={handleDescSort} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Desc. Order</Button>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Roll No.</th>
                                                <th>Mobile No.</th>
                                                <th>Name</th>
                                                <th>Batch
                                                    <br />
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Batch</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={batch}
                                                            onChange={handleChange}
                                                            label="Batch"
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select Batch</em>
                                                            </MenuItem>
                                                            {batcharr.map((ele, i) => (

                                                                <MenuItem key={ele.batch} value={ele.batch}>{ele.batch}</MenuItem>

                                                            ))}
                                                            {/* <MenuItem value="BiometricCard Punch Issue">Biometric/Card Punch Issue</MenuItem>
                                                            <MenuItem value="Card Lost">Card Lost</MenuItem>
                                                            <MenuItem value="Medical Issue">Medical Issue</MenuItem>
                                                            <MenuItem value="Personal Issue">Personal Issue</MenuItem>
                                                            <MenuItem value="Shift To Online Mode">Shift To Online Mode</MenuItem>
                                                            <MenuItem value="Batch Change">Batch Change</MenuItem>
                                                            <MenuItem value="Due To School Examination">Due To School Examination</MenuItem>
                                                            <MenuItem value="Followup">Followup</MenuItem>
                                                            <MenuItem value="Not Able TO Connect">Not Able TO Connect</MenuItem>
                                                            <MenuItem value="Left Reliable">Left Reliable</MenuItem> */}
                                                        </Select>
                                                    </FormControl>
                                                </th>
                                                <th>Course Name</th>
                                                <th>View Attendance</th>
                                                <th>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {res.map((ele, i) => (
                                                <>

                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{ele.roll_no}</td>
                                                        <td>
                                                            <b>Mobile1</b><CopyIcon mobile={ele.mobile1} snackBar={snackBar} /><br /><br />
                                                            <b>Mobile2</b><CopyIcon mobile={ele.mobile2} snackBar={snackBar} />
                                                        </td>
                                                        <td>{ele.name}</td>
                                                        <td>{ele.batch}</td>
                                                        <td>{ele.coursetitle}</td>
                                                        <td>
                                                            <ButtonView roll_no={ele.roll_no} />
                                                        </td>
                                                        <td>
                                                            <ConversationComp roll_no={ele.roll_no} loader={loader} snackBar={snackBar} last_category={ele.last_category} last_remark={ele.last_remark} dates={ele.dates} />
                                                        </td>
                                                    </tr>

                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </Paper>
                            </Grid>
                        </>}
                </Grid>
            </Container>

        </>
    )
}

const ButtonComp = ({ title, type, loader, snackBar, setView, setRes, setTitles, setBatchArr, setArr }) => {

    const [show, setShow] = useState(false);

    // setTitles(title);

    const hide = () => {
        setShow(false);
    }

    return (
        <>

            <Button onClick={() => setShow(true)} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>

            {show && <>

                <ModalComp hide={hide} >

                    <CourseComp setArr={setArr} setBatchArr={setBatchArr} title={title} setTitles={setTitles} type={type} loader={loader} snackBar={snackBar} setView={setView} setRes={setRes} hide={hide} />

                </ModalComp>



            </>}

        </>
    )
}

const ButtonView = ({ roll_no }) => {

    const [show, setShow] = useState(false);

    const hide = () => {
        setShow(false);
    }

    return (<>

        <Button onClick={() => setShow(true)} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>View</Button>

        {show && <>

            <ModalComp hide={hide} >

                <ViewStudentAbsent roll_no={roll_no} hide={hide} setShow={setShow} />

            </ModalComp>



        </>}


    </>)
}

const ModalComp = ({ children, hide }) => {

    return (
        <div>
            <Dialog open
                onClose={() => { hide() }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>

        </div>
    )
}

const CourseComp = ({ title, type, loader, snackBar, setView, setRes, hide, setTitles, setBatchArr, setArr }) => {

    const handleClick = async (value) => {
        try {

            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/attendance-data-calling/calling-data',
                method: 'post',
                responseType: 'json',
                data: {

                    day: type,
                    value: value

                }
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setRes(obj.array)
                setArr(obj.array)
                setTitles(title)
                // setBatchArr(obj.batcharr);

                let val = _.uniqBy(obj.array, 'batch');
                setBatchArr(val);

                console.log(val);

                setView(true);
                hide();
            }


        }

        catch (e) {
            alert(e.message)
        }

        finally {
            loader.hide()
        }
    }


    return (
        <>

            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Select Course</th>
                                    </tr>
                                    <tr>
                                        <th>Course Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>All Data</td>
                                        <td>
                                            <Button onClick={() => handleClick('All Data')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-ABHINAV</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-ABHINAV')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-AJAY</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-AJAY')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-AKHIL</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-AKHIL')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-VIJAY</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-VIJAY')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-VIJETA</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-VIJETA')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-VIJETA PLUS</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-VIJETA PLUS')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-VIKAAS</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-VIKAAS')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-VIRAAT</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-VIRAAT')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-VISHESH</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-VISHESH')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R-VISHWAAS</td>
                                        <td>
                                            <Button onClick={() => handleClick('R-VISHWAAS')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Junior Division</td>
                                        <td>
                                            <Button onClick={() => handleClick('Junior Division')} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Click</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        </>
    )
}

const ConversationComp = ({ last_remark, last_category, dates, loader, snackBar, roll_no }) => {

    const [category, setCategory] = useState(last_category)
    const [text, setText] = useState(last_remark);

    const classes = useStyles();

    useEffect(() => {
        setCategory(last_category);
        setText(last_remark)
    }, [roll_no])


    const handleClick = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/attendance-data-calling/create-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    roll_no: roll_no,
                    text: text,
                    category: category
                },
                ...rest_headers
            })

            if (obj.Success === 'ok') {
                snackBar.show(obj.message);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }
        finally {
            loader.hide()
        }
    }


    return (
        <>


            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Category"
                >
                    <MenuItem value="">
                        <em>Select Category</em>
                    </MenuItem>
                    <MenuItem value="Informed">Informed</MenuItem>
                    <MenuItem value="BiometricCard Punch Issue">Biometric/Card Punch Issue</MenuItem>
                    <MenuItem value="Card Lost">Card Lost</MenuItem>
                    <MenuItem value="Medical Issue">Medical Issue</MenuItem>
                    <MenuItem value="Personal Issue">Personal Issue</MenuItem>
                    <MenuItem value="Shift To Online Mode">Shift To Online Mode</MenuItem>
                    <MenuItem value="Batch Change">Batch Change</MenuItem>
                    <MenuItem value="Due To School Examination">Due To School Examination</MenuItem>
                    <MenuItem value="Followup">Followup</MenuItem>
                    <MenuItem value="Not Able TO Connect">Not Able TO Connect</MenuItem>
                    <MenuItem value="Left Reliable">Left Reliable</MenuItem>
                </Select>

                <br />

                <TextField
                    // label={"Remark"}
                    placeholder="Enter Remark"
                    type="text"
                    variant="outlined"
                    // fullWidth
                    // style={{ width: '50%' }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />

                <br />
                <Button onClick={handleClick} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }}>Submit</Button>
                <br />
                <ViewComp roll_no={roll_no} loader={loader} snackBar={snackBar} />
                <br />
                <Typography variant='h6'>Date:-&nbsp;&nbsp;{moment(dates).format('Do MMMM YYYY')} </Typography>

            </FormControl>


            {/* <Container>

            <Paper component={Paper} style={{ padding: '16px' }}>
            <FormControl variant="outlined" className={classes.formControl}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                label="Category"
                            >
                                <MenuItem value="">
                                    <em>Select Category</em>
                                </MenuItem>
                                <MenuItem value="Informed">Informed</MenuItem>
                                <MenuItem value="BiometricCard Punch Issue">Biometric/Card Punch Issue</MenuItem>
                                <MenuItem value="Card Lost">Card Lost</MenuItem>
                                <MenuItem value="Medical Issue">Medical Issue</MenuItem>
                                <MenuItem value="Personal Issue">Personal Issue</MenuItem>
                                <MenuItem value="Shift To Online Mode">Shift To Online Mode</MenuItem>
                                <MenuItem value="Batch Change">Batch Change</MenuItem>
                                <MenuItem value="Due To School Examination">Due To School Examination</MenuItem>
                                <MenuItem value="Followup">Followup</MenuItem>
                                <MenuItem value="Not Able TO Connect">Not Able TO Connect</MenuItem>
                                <MenuItem value="Left Reliable">Left Reliable</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>

                        <TextField
                            // label={"Remark"}
                            placeholder="Enter Remark"
                            type="text"
                            variant="outlined"
                            // fullWidth
                            // style={{ width: '50%' }}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={handleClick} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px', width: '50%' }}>Submit</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={handleClick} style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px', width: '50%' }}>Submit</Button>
                    </Grid>
                </Grid>
            </FormControl>
            </Paper>

            </Container> */}

        </>
    )

}

const ViewComp = ({ roll_no, loader, snackBar }) => {

    const [array, setArray] = useState([]);
    const hide = () => {
        setArray([]);
    }

    const handleClick = async (roll_no) => {
        try {
            loader.show();

            const { data } = await axios({
                url: api_url + '/attendance-data-calling/view-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    roll_no: roll_no
                },
                ...rest_headers
            })
            console.log(data);
            setArray(data.array)


        } catch (e) {
            snackBar.show(handle_error(e));
        } finally {
            loader.hide();
        }

    }


    return (<>

        <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(roll_no)}>View</Button>

        {array && array.length > 0 && <ModalComp hide={hide}>
            <div style={{ fontFamily: 'Arial' }}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={3}>Conversation</th>
                        </tr>
                        <tr>
                            <th>Remark</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {array.map((n, i) => {
                            return (
                                <tr style={{ fontSize: '13px' }}>
                                    <td>{n.text}</td>
                                    <td>{n.date}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>


            </div>


        </ModalComp>

        }

    </>)
}

const CopyIcon = ({ mobile, snackBar }) => {
    const mask = (mobile) => {
        try {
            return mobile.replace(/\d(?=\d{4})/g, "*");
        } catch (e) {

        }
    }
    return (
        <>
            {mask(mobile)}&nbsp;&nbsp;
            <FileCopyIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" onClick={() => {
                navigator.clipboard.writeText(mobile);
                snackBar.show('Copied!');
            }} />
        </>
    )
}