const jwt = require("jsonwebtoken");

export default (app_key,app_secret) => {
  let dt = new Date();

  let data = new Date(dt.getTime() + 60 * 2 * 60000).getTime(); //2 hours

  let iat = Math.floor(Date.now() / 1000);

  let exp = Math.floor(data / 1000);

  let tokenExpCreate = new Date(dt.getTime() + 120 * 2 * 60000).getTime(); //4 hours

  let tokenExp = Math.floor(tokenExpCreate / 1000);
  
  const payloadSDK = {
    appKey: app_key,

    iat: iat,

    exp: exp,

    tokenExp: tokenExp,
  };

  const tokenSDK = jwt.sign(payloadSDK, app_secret);

  return tokenSDK;
};
