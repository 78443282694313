import { InputLabel, Select, TextField, Typography } from "@material-ui/core";
import { Button, MenuItem, Grid } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useState, useContext } from "react";
import { LoaderContext } from "../../../../../context/LoaderContext";
import styles from '../reusable-calling-code-project/table.module.css';
export default function SMSDetail() {
    const loader = useContext(LoaderContext);
    const [rollno, setRollno] = useState('');
    const [value, setValue] = useState([]);
    const [show, setShow] = useState(false);
    const [type, setType] = useState('');


    const handleClick = async () => {
        try {
            loader.show();
            const { data: obj } = await axios({
                url: 'https://api-dot-reformexam.el.r.appspot.com/student-sms-detail/data',
                method: 'post',
                responseType: 'json',
                data: {
                    rollno: rollno,
                    type: type
                }
            })
            console.log(obj);
            setValue(obj.test);
            //   console.log(obj.test);

            setShow(true);

        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"

            >

                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "500px", }}>
                    <Typography>Enter Roll No</Typography>
                    <TextField label="Roll No" value={rollno} onChange={(e) => setRollno(e.target.value)} />
                    <br></br>
                    <br></br>
                    <Typography>Type</Typography>
                    <InputLabel>Select Type</InputLabel>
                    <Select value={type} onChange={(e) => setType(e.target.value)} label='Select' fullWidth>
                        <MenuItem value='Attendance'>Attendance</MenuItem>
                        <MenuItem value='Other'>Other</MenuItem>
                    </Select>
                    <br></br>
                    <br></br>
                    <Button variant="contained" onClick={handleClick}>Submit</Button>

                </Paper>
            </Grid>
            <br></br>
            <br></br>

            {show &&
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"

                >
                    <Paper style={{ padding: '10px', backgroundColor: 'white', width: "100vh", }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <th>S.No</th>
                                <th>Capaign Name</th>
                                <th>Mobile</th>
                                <th>Message</th>
                                <th style={{ width: "100px" }}>Date</th>
                            </thead>
                            <tbody>
                                {value.map((ele, i) => {

                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.c_name}</td>
                                            <td>{ele.mobile}</td>
                                            <td style={{ fontSize: "11px" }}>{ele.sms_language}</td>
                                            <td>{ele.Date}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            }


        </React.Fragment>
    )
}