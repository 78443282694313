import { gql } from "@apollo/client";

export const classroom_test_result_list_gql = gql`
  {
    classroom_test_result_list {
      id
      name
      test_date
      type: classroom_test_type {
        name
      }
    }
  }
`;
