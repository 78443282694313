import { gql } from "@apollo/client";

export const create_batch_courseware_mutation_gql = gql`
  mutation create_batch_courseware(
    $courseware_id: Int!  
    $batch_id: [Int!]
  ) {
    create_batch_courseware(
      courseware_id: $courseware_id
      batch_id: $batch_id
    ) {
      success
    }
  }
`;
