import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import NotFoundView from "../..//main/not-found";
import CheckFee from "./app/srceens/check-fee"
import AuthCheck from "./auth-check";
import Login from "./auth/screens/login";
import { items } from "./app/layout/menu-list";
const routes = [
  {
    path: "admin/checkfee",
    element: <CheckFee /> 
  },
  {
    path: "admin/app",
    element: <AuthCheck mode="app" />,
    children: items,
  },
  {
    path: "admin/auth",
    element: <AuthCheck mode="auth" />,
    children: [
      { path: "login", element: <Login /> },
      { path: "forgot-password", element: <Login /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="login" /> }, 
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "admin",
    children: [{ path: "/", element: <Navigate to="/admin/app/dashboard" /> }],
  },
];

export default routes;
