import React from "react";
import { CSVReader } from 'react-papaparse';


export default function TestCSV(props) {

    var arry = [];
    var issued = [];

    const handleTestDrop = (data) => {




        try {
            let len = data.length;
            console.log(len);
            if (data[0].data.length !== 8) {
                alert("Please Upload correct CSV File");
                return false;
            }
            else {
                if (data[0].data[0] !== 'Question No') {
                    alert("First Row and First Column should be of name: Question No in your CSV file");
                    return false;
                }
                if (data[0].data[1] !== 'Correct Marks') {
                    alert("Second column should be of name: Correct Marks in your CSV file");
                    return false;
                }
                if (data[0].data[2] !== 'Incorrect Marks') {
                    alert("Third column should be of name: Incorrect Marks in your CSV file");
                    return false;
                }
                if (data[0].data[3] !== 'Type') {
                    alert("Fourth column should be of name: Type in your CSV file");
                    return false;
                }
                if (data[0].data[4] !== 'Correct Answer') {
                    alert("Fifth column should be of name: Correct Answer in your CSV file");
                    return false;
                }
                if (data[0].data[5] !== 'Topic') {
                    alert("Sixth column should be of name: Topic in your CSV file");
                    return false;
                }
                if (data[0].data[6] !== 'Sub Topic') {
                    alert("Seventh column should be of name: Sub Topic in your CSV file");
                    return false;
                }
                if (data[0].data[7] !== 'Subject') {
                    alert("Seventh column should be of name: Subject in your CSV file");
                    return false;
                }
                else {
                    var a, c = 2;
                    for (var i = 1; i < len - 1; i++) {
                        // console.log(data[i].data[0]);
                        arry.push({ Question_no: data[i].data[0], Correct_marks: data[i].data[1], Incorrect_marks: data[i].data[2], Type: data[i].data[3], Correct_ans: data[i].data[4], Topic: data[i].data[5], Sub_topic: data[i].data[6], Subject: data[i].data[7] });
                    }
                    // console.log(arry);
                    for (a = 0; a < arry.length; a++) {
                        if (arry[a].Question_no < 1 || arry[a].Question_no > 200 || arry[a].Question_no.match(/^[a-zA-z]+$/)) {
                            issued.push({ row: c, column: 1 });
                        }
                        if (arry[a].Correct_marks < 0 || arry[a].Correct_marks > 10 || arry[a].Correct_marks.match(/^[a-zA-Z]+$/)) {
                            issued.push({ row: c, column: 2 });
                        }
                        if (arry[a].Incorrect_marks > 0 || arry[a].Incorrect_marks < -4 || arry[a].Incorrect_marks.match(/^[a-zA-Z]+$/)) {
                            issued.push({ row: c, column: 3 });
                        }
                        if (arry[a].Topic.match(/^[0-9]+$/)) {
                            issued.push({ row: c, column: 6 });
                        }
                        if (arry[a].Sub_topic.match(/^[0-9]+$/)) {
                            issued.push({ row: c, column: 7 });
                        }
                        c++;
                    }
                }
            }
            props.handleMasterTestDrop({ id: props.id, width: data[0].data.length, testissue: issued, testdata: arry, quesno: data.length });
        }
        catch (e) {
            alert(e.message);
        }
    }

    return (
        <>
            <CSVReader onDrop={handleTestDrop}>
                <span>Drop Test Schema</span>
            </CSVReader>
        </>
    )
}