let isElectron = require("is-electron");

export default (url) => {
  try {
    if (!isElectron()) {
      window.open(url);
      return false;
    } else {
      var shell = require("electron").shell;
      shell.openExternal(url);
    }
  } catch (e) {
    alert(e);
  }
};
