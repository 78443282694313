import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { view_student_query_gql } from "../../../gql/query/view-student-query";
import { change_student_status_mutation_gql } from "../../../gql/mutation/change-student-status-mutation";
import { useQuery, useMutation } from "@apollo/client";
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default ({ roll_no, setViewProfile, viewProfile }) => {
  if (!roll_no) return <span></span>;
  const classes = useStyles();
  const [change_student_status] = useMutation(change_student_status_mutation_gql);
  const snackBar = React.useContext(SnackBarContext);
  const loader = React.useContext(LoaderContext);
  const { loading, error, data, refetch  } = useQuery(view_student_query_gql,{
  fetchPolicy: "no-cache",
  variables: {
    roll_no
  },
});

if (loading) return <span>Loading...</span>;
if (error) return <GqlErrorComp error={error} />;
const { view_student } = data;
console.log(viewProfile)
console.log(view_student)
let n = view_student;

const handleStudentStatus = async (roll_no, deleted) => {
  let status = ""
  if(deleted === "true")
  status = "active"
  else status = "inactive"

  try {
    loader.show();

    let {
      data: {
        change_student_status: { success },
      },
    } = await change_student_status({
      variables: {
        roll_no,
        status
      },
    });
    
    if (success && status === "active") 
    snackBar.show("student activated successfully");

    if (success && status === "inactive") 
    snackBar.show("student deleted successfully");

    refetch();
  } catch (e) {
    snackBar.show(error_beautify(e.message));
  } finally {
    loader.hide();
  }
}

  return (
    <>
    { (n.is_added === "No Record Found" && !viewProfile ) ? 
    <p>No Record Found  <Button variant="contained" color="primary" onClick={() => setViewProfile(true) }>View Profile</Button></p>
    : null }
    { n.is_added === "Yes" ? 
    <List className={classes.root}>
          <ListItem>
            <ListItemText
              primary={<>{n.fname} {n.lname}</>}
              secondary={<>
                Batch - {n.current_batch.name} <br />
                Email - {n.email} <br />
                Mobile - {n.mobile} <br />
                Gender - {n.gender === "M" ? "Male" : n.gender === "F" ? "Female" : "No Record Found"} <br />
                Status - {n.is_deleted === "true" ? "Deleted"  : "Active"} <br /><br />
                <Button variant="contained" color="primary" onClick={() => handleStudentStatus(roll_no, n.is_deleted)}>{n.is_deleted === "true" ? "Active"  : "Delete"}</Button>
                <br /><br />
                <Button variant="contained" color="primary" onClick={() => setViewProfile(true) }>View Profile</Button>
                </>}
            />
          </ListItem></List> : null }
        { viewProfile ?   
        <List className={classes.root}>
          <ListItem>
            <ListItemText
              primary={<>{n.profile.name}</>}
              secondary={<>
                Gender - {n.profile.gender} <br />
                Mobile-1 - {n.profile.mobile1} <br />
                Mobile-2 - {n.profile.mobile2} <br />
                Email - {n.profile.email} <br />
                Course Title - {n.profile.coursetitle} <br />
                Course Code - {n.profile.course} <br />
                Fee Status - {n.profile.fee_status} <br />
                Form Date - {n.profile.fdate} <br />
                Medium - {n.profile.medium}
                </>}
            />
          </ListItem>
          </List> : null }
    </>
  );
}
