import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutclass_topic from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_class_topic_mutation_gql } from "../../../gql/mutation/create-class_topic-mutation";
import Typography from "@material-ui/core/Typography";
import SelectTopic from "../../components/select-topic";
import SelectClass from "../../components/select-class";

export default () => {
  const [create_class_topic_mutation] = useMutation(
    create_class_topic_mutation_gql
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_class_topic_mutation={create_class_topic_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_class_topic_mutation }) => {
  const [values, setValues] = useState({
    class_id: "",
    topic_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { class_id, topic_id } = values;

      loader.show();

      let {
        data: {
          create_class_topic: { success },
        },
      } = await create_class_topic_mutation({
        variables: {
          class_id,
          topic_id,
        },
      });

      if (success) snackBar.show("class_topic created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutclass_topic title="Create Class Topic">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <SelectClass
                label="Class"
                name="class_id"
                value={values.class_id}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectTopic
                class_id={values.class_id}
                label="Topic"
                name="topic_id"
                value={values.topic_id}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutclass_topic>
      </Container>
    </form>
  );
};
