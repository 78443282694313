import { gql } from "@apollo/client";

export const create_childsubject_mutation_gql = gql`
  mutation create_childsubject(
    $name: String!
    $alias: String!
    $description: String
    $subject_id: ID!
  ) {
    create_childsubject(
      name: $name
      alias: $alias
      description: $description
      subject_id: $subject_id
    ) {
      success
    }
  }
`;
