import * as React from 'react';
// import Link from '@mui/material/Link';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            maxWidth: theme.spacing(48),
            height: theme.spacing(16),
            padding: theme.spacing(4),

        },
    },

}));

function preventDefault(event) {
    event.preventDefault();
}

export default function Deposits(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Card style={{ backgroundColor: '#ff43da40', padding: 20 }}>

                <CardContent style={{ padding: 0 }}>

                    <Typography type="subheading">
                        <b>Total Marks:</b>  {props.marks}
                    </Typography>
                    <br />
                    <Typography type="subheading">
                        <b>Percentage:</b>  {props.percentage}%
                    </Typography>
                    <br />

                    <Typography type="subtitle">
                        <b> Average Marks:</b>  {props.data.avg_marks}
                    </Typography>
                    <br />
                    <Typography type="subtitle">
                        <b>Highest Marks:</b>  {props.data.highest_marks}
                    </Typography>
                </CardContent>


            </Card>
            {/* <Typography component="p" variant="h6" style={{ fontSize: '1.1rem' }} gutterBottom>
                <b>Total Marks</b> : {props.marks} <br /><br />
                <b>Percentage</b> : {props.percentage}%  <br /><br />
                <b>Average Marks</b> : {props.data.avg_marks} <br /><br />
                <b>Highest Marks</b> : {props.data.highest_marks}  <br /><br/>
            </Typography> */}


        </React.Fragment >
    );
}