import React from 'react'
import { Paper } from '../paper'
import Iframe from 'react-iframe'
import { Spinner } from '../spinner'

export class VideoViewer extends React.Component {
    state = {
        loading: true
    }
    componentDidMount() {

        const script = document.createElement("script");
        script.src = "https://anurag.cdn.vooplayer.com/assets/vooplayer.js";
        script.async = true;


        document.body.appendChild(script);


    }
    hide = () => {


        this.setState({ loading: false })
    }
    render() {
        const { props: { url, height = 600, padding = 16 }, state: { loading }, hide } = this

        return (

            <Paper style={{ padding: padding }}>
                {
                    loading && <Spinner />
                }
                <Iframe src={url}
                    id="fraDisabled"
                    width={'100%'}
                    height={height}
                    scrolling="no"
                    className="myClassname"
                    display="initial"
                    frameBorder={0}
                    allowFullScreen
                    onLoad={hide}
                    position="relative" />

            </Paper>

        )

    }
}