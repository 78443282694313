import { gql } from "@apollo/client";

export const classroom_test_subject_topic_list_query_gql = gql`
  query classroom_test_subject_topic_list(
    $classroom_test_subject_id: Int!
    $course_id: Int!
  ) {
    classroom_test_subject_topic_list(
      classroom_test_subject_id: $classroom_test_subject_id
      course_id: $course_id
    ) {
      key: id
      value: name
    }
  }
`;
