import React from "react";
import { Select } from "../../select";
export const SelectNoOfQuestion = ({ onChange, value }) => {
  const array = [];
  for (let i = 0; i < 5; i++) {
    array.push({ key: i + 1, value: i + 1 });
  }
  return (
    <React.Fragment>
      {" "}
      <Select
        array={array}
        value={value}
        label="No of Question"
        onChange={onChange}
      />
      <br /> <br />
    </React.Fragment>
  );
};
