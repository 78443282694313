import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { Container, TextField, Grid, Button, MenuItem, Select, InputLabel, Paper } from "@material-ui/core";
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { api_url, rest_headers, url } from '../saper/config';
import styles from '../reusable-calling-code-project/table.module.css';
import ButtonComp from './buttonComp';

const ViewUploadedTest = () => {

    const loader = useContext(LoaderContext);

    const [res, setRes] = useState([]);
    const [test, setTest] = useState([]);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [hide, setHide] = useState(false);
    let year = 2023

    // useEffect(async () => {
    //     try {
    //         loader.show();

    //         const { data: obj } = await axios({
    //             url: api_url + 'view-test',
    //             method: 'post',
    //             responseType: 'json',
    //             headers: {
    //                 APIKEY: '##reliable_rest_api_token##',
    //                 ADMINTOKEN: localStorage.getItem('admin_token')
    //             }
    //         })

    //         if (obj.success == 'ok') {
    //             setRes(obj.array);
    //         }
    //     }
    //     catch (e) {
    //         alert(e.message);
    //     }
    //     finally {
    //         loader.hide();
    //     }
    // }, [])
    const hClick = async () => {
        try {
            loader.show();
            setHide(false)
            const { data: obj } = await axios({
                url: api_url + 'view-test',
                method: 'post',
                responseType: 'json',
                data: {
                    year: 2022
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }

            })
            if (obj.success == 'ok') {
                setRes(obj.array)
                console.log(obj.array);
                setShow(true);

            }
        }
        catch (e) {
            alert(e.message)
        }
        finally {
            loader.hide();
        }
    }
    const handleClick = async () => {
        try {
            loader.show();
            setShow(false);
            const { data: obj } = await axios({
                url: api_url + 'view-test',
                method: 'post',
                responseType: 'json',
                data: {
                    year: year
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            if (obj.success == 'ok') {
                setTest(obj.array);
                console.log(obj.array);
                setHide(true);

            }
        }
        catch (e) {
            alert(e.message)
        }
        finally {
            loader.hide();
        }
    }
    const handleClick2 = async () => {
        try {
            loader.show();
            setShow2(false);
            const { data: obj } = await axios({
                url: api_url + 'view-test',
                method: 'post',
                responseType: 'json',
                data: {
                    year: 2024
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            if (obj.success == 'ok') {
                setTest(obj.array);
                console.log(obj.array);
                setShow2(true);

            }
        }
        catch (e) {
            alert(e.message)
        }
        finally {
            loader.hide();
        }
    }

    return (
        // <div>

        //     <table className={styles.table}>
        //         <thead>
        //             <tr>
        //                 <th colSpan={5}>View Uploaded Test</th>
        //             </tr>
        //             <tr>
        //                 <th>Sr. No.</th>
        //                 <th>Test Name</th>
        //                 <th>Test Date</th>
        //                 <th>Action</th>
        //             </tr>
        //         </thead>
        //         <tbody>

        //             {res.map((ele, i) => (
        //                 <>
        //                     <tr>
        //                         <td>{i + 1}</td>
        //                         <td>{ele.name}</td>
        //                         <td>{ele.date}</td>
        //                         <td><ButtonComp test_id={ele.test_id} loader={loader} /></td>
        //                     </tr>
        //                 </>
        //             ))}

        //         </tbody>
        //     </table>


        // </div>
        <>
            <Grid container spacing={2} >
                <div style={{ padding: '40px 0px', width: '100%', height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>

                    <Grid item >
                        <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff', width: '150px', height: '70px' }} onClick={hClick}>View Test 2022-23</Button>

                    </Grid>
                    <Grid item >
                        <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff', width: '150px', height: '70px' }} onClick={handleClick} >View Test 2023-24</Button>

                    </Grid>
                    <Grid item >
                        <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff', width: '150px', height: '70px' }} onClick={handleClick2} >View Test 2024-25</Button>

                    </Grid>
                </div>
            </Grid>
            {show &&
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={5}>View Uploaded Test</th>
                        </tr>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Test Name</th>
                            <th>Test Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {res.map((ele, i) => (
                            <>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{ele.name}</td>
                                    <td>{ele.date}</td>
                                    <td><ButtonComp test_id={ele.test_id} loader={loader} year={2022} /></td>
                                </tr>
                            </>
                        ))}

                    </tbody>
                </table>
            }
            {hide &&
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={5}>View Uploaded Test</th>
                        </tr>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Test Name</th>
                            <th>Test Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {test.map((ele, i) => (
                            <>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{ele.name}</td>
                                    <td>{ele.date}</td>
                                    <td><ButtonComp test_id={ele.test_id} loader={loader} year={year} /></td>
                                </tr>
                            </>
                        ))}

                    </tbody>
                </table>}
            {show2 &&
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={5}>View Uploaded Test 2024-25</th>
                        </tr>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Test Name</th>
                            <th>Test Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {test.map((ele, i) => (
                            <>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{ele.name}</td>
                                    <td>{ele.date}</td>
                                    <td><ButtonComp test_id={ele.test_id} loader={loader} year={2024} /></td>
                                </tr>
                            </>
                        ))}

                    </tbody>
                </table>}

        </>
    )
}

export default ViewUploadedTest
