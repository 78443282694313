import React from "react";
import { Select } from "../../../../utils/select";
import { classroom_test_list_by_batch_query_gql } from "../../gql/query/classroom_test_list_by_batch_query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({ batch_id, label, value, name, onChange }) => {
  if (!batch_id) return <span></span>;
  const { loading, error, data } = useQuery(
    classroom_test_list_by_batch_query_gql,
    {
      variables: {
        batch_id: batch_id,
      },
    }
  );
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { classroom_test_batch } = data;

  let i = 0,
    new_array = [],
    array = classroom_test_batch;
    
  for (i = 0; i < array.length; i++) {
    new_array.push({ key: array[i].id, value: array[i].classroom_test.name });
  }

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={new_array}
    />
  );
};
