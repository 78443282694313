import React, { useEffect, useContext, useState } from "react";
import { Select } from "../../../../utils/select";
import { batch_list_sessionwise_query_gql } from "../../gql/query/batch-list-sessionwise-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
import axios from 'axios';
import { SnackBarContext } from "../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../context/LoaderContext";
export default ({ label, value, name, onChange }) => {
  // if (!phase_id) return <span></span>;
  //   const { loading, error, data } = useQuery(batch_list_sessionwise_query_gql, {
  //     variables: { },
  //   });
  //   if (loading) return <span>Loading...</span>;
  //   if (error) return <GqlErrorComp error={error} />;
  //   const { batch_list_sessionwise } = data;
  // console.log(data)

  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);

  const [res, setRes] = useState([]);

  useEffect(async () => {
    try {

      loader.show();

      const { data: obj } = await axios({
        url: 'https://api-dot-reformexam.el.r.appspot.com/reliablekotaapp/batch-list',
        method: 'post',
        responseType: 'json',
        data: {
          year: 1234,
        }
      })

      setRes(obj.list);

    }

    catch (e) {
      snackBar.show(e.message);
    }

    finally {
      loader.hide();
    }
  }, [])

  return (
    <Select
      label="Select Batch"
      value={value}
      name={name}
      onChange={onChange}
      array={res}
    />
  );
};
