import React from 'react';
import styles from "../reusable-calling-code-project/table.module.css";

const DailyTransferTableComp = ({ daily, name, title }) => {
    if (daily.length === 0) {
        return <></>
    }

    return (
        <div>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th colSpan={3}>{name}</th>
                    </tr>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Admin Name</th>
                        <th>Leads {title}</th>
                        {/* <th>Touched Leads</th> */}
                        {/* <th>Untouched Leads</th> */}
                        {/* <th>Interested Leads</th> */}
                        {/* <th>Conversion</th> */}
                    </tr>
                </thead>
                <tbody>
                    {daily.map((n, i) => (
                        <tr>
                            <td>{i + 1}</td>
                            <td>{n.admin_name}</td>
                            <td>{n.count}</td>
                            {/* <td>{n.touched_count}</td> */}
                            {/* <td>{n.leads_alloted - n.touched_leads}&nbsp;&nbsp;({n.u_per}%)</td>
                            <td>{n.interested}</td>
                            <td>Coming soon...</td>  */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default DailyTransferTableComp
