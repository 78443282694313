import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutclassroom_test_type from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_classroom_test_subject } from "../../../gql/mutation/create-classroom_test_subject-mutation";
import Typography from "@material-ui/core/Typography";
import SelectSessionCoursePhaseBatchTest from "../../components/select-session-course-phase-batch-classroomtest";
import SelectSubject from "../../components/select-subject";

export default () => {
  const [create_classroom_test_subject_mutation] = useMutation(
    create_classroom_test_subject
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_classroom_test_subject_mutation={
        create_classroom_test_subject_mutation
      }
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_classroom_test_subject_mutation }) => {
  const [values, setValues] = useState({
    classroom_test_batch_id: "",
    subject_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { classroom_test_batch_id, subject_id } = values;

      loader.show();

      let {
        data: {
          create_classroom_test_subject: { success },
        },
      } = await create_classroom_test_subject_mutation({
        variables: { classroom_test_batch_id, subject_id },
      });

      if (success) snackBar.show("classroom_test_subject created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutclassroom_test_type title="Create Classroom Test Subject">
          <Grid spacing={3} container>
            <SelectSessionCoursePhaseBatchTest
              name="classroom_test_batch_id"
              onChange={handleInputChange}
            />
            <Grid item xs={12}>
              <SelectSubject
                label="Subject"
                name="subject_id"
                value={values.subject_id}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutclassroom_test_type>
      </Container>
    </form>
  );
};
