import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import Modal from '../reusable-calling-code-project/modal';
import ButtonComp from './button-comp';
import styles from '../reusable-calling-code-project/table.module.css';
import { Button } from '@material-ui/core';

export default function TransferComp({ roll_no, state, refresh, mobile1, mobile2, loader }) {

    const [open, setOpen] = useState(false);

    const [array, setArray] = useState([]);


    const handleClick = async () => {
        try {
            loader.show();
            const { data: response } = await axios({
                url: api_url + '/lead-data-calling/admin-data',
                method: 'post',
                responseType: 'json',
                ...rest_headers
            })
            if (response.Success === 'ok') {
                setArray(response.arr);
            }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }
    const hide = () => {
        setArray([]);
    }


    return (
        <>

            <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleClick}>Transfer</Button>

            {array && array.length > 0 && <Modal hide={hide}>


                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th >id</th>
                            <th>Name</th>
                            <th >Transfer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {array.map((n, i) => {
                            return (
                                <tr>
                                    <td>{n.id}</td>
                                    <td>{n.name}</td>
                                    <td >
                                        <ButtonComp state={state} refresh={refresh} admin_id={n.id} hide={hide} roll_no={roll_no} mobile1={mobile1} mobile2={mobile2} loader={loader} />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

            </Modal>
            }

        </>
    )

}