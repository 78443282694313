import { gql } from "@apollo/client";

export const classroom_test_list_by_batch_query_gql = gql`
  query classroom_test_batch($batch_id: Int!) {
    classroom_test_batch(batch_id: $batch_id) {
        id
        classroom_test{
          id
          name
        }
    }
  }
`;
