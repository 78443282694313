import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Sales = ({
  className,
  data: { classroom_test_all_test_result: ctr },
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let data = [];
  for (let i = 0; i < ctr.length; i++) {
    data.push({
      name: ctr[i].test_info.name,
      Percentile: ctr[i].total_info.percentile,
    });
  }
  data.push(data[0]);

  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis type="number" domain={[0, 100]} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="Percentile" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

Sales.propTypes = {
  className: PropTypes.string,
};

export default Sales;
