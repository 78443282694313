import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Dialog from './dialog'
 

export default function FolderList(props) {
 
  const [show,setShow]=useState(false);
 
  const onClose=()=>{
	  setShow(false) 
  }
 
  return (
  <>
   
      <ListItem onClick={()=>{
		  setShow(true);
	  }}  style={{cursor:'pointer',paddingTop:0,paddingBottom:0}}>
        <ListItemAvatar>
          <Avatar style={{backgroundColor:'white'}}>
            <PlayCircleOutlineIcon color="primary"  />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={props.title}     />
      </ListItem>
       
      

	{
		show && <Dialog {...props} onClose={onClose} />
	}
	</>
  );
}
