import { gql } from "@apollo/client";

export const create_classroom_test_topic = gql`
  mutation create_classroom_test_topic(
    $classroom_test_subject_id: Int!
    $topic_id: Int!
  ) {
    create_classroom_test_topic(
      classroom_test_subject_id: $classroom_test_subject_id
      topic_id: $topic_id
    ) {
      success
    }
  }
`;
