import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SelectBatch from "./select-batch";
import { handleFormChange } from "../../../../utils/functions";
import SelectSessionCoursePhase from "./select-session-course-phase";
export default ({ onChange, name }) => {
  const [values, setValues] = useState({
    phase_id: "",
    [name]: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
    // if (e.target.name === name) {
    onChange(e);
    // }
  };

  return (
    <>
      <SelectSessionCoursePhase onChange={handleInputChange} />

      <Grid item xs={12}>
        <SelectBatch
          phase_id={values.phase_id}
          label="Current Batch"
          name={name}
          value={values[name]}
          onChange={handleInputChange}
        />
      </Grid>
    </>
  );
};
