import React from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useQuery } from '@apollo/client';
import quiz_gql from './gql'
import { Spinner } from '../../../../../../utils/spinner'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
    const classes = useStyles();
    const { loading, error, data } = useQuery(quiz_gql);


    if (loading) {
        return <Spinner />
    }
    if (error)
        return <span>Error :{error.message}</span>
    const { quiz_list: quiz } = data


    return (
        <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">

                {
                    quiz && quiz.length > 0 && quiz.map((item, index) => {
                        return (
                            <Link to={`${item.id}`} style={{ textDecoration: 'none' }}><ListItem button key={index + 1}>

                                <ListItemText primary={item.quiz.name} secondary={item.date} />
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                            </ListItem>
                            </Link>
                        )
                    })
                }


            </List>

        </div>
    );
}