import "react-perfect-scrollbar/dist/css/styles.css";
import React from "react";
import "./app.css";
import { useRoutes } from "react-router-dom";
import { TableBody, ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import { ApolloProvider } from "@apollo/client";
import client from "./utils/apollo-client";
import theme from ".//theme";
import routes from "./main/routes";
import { SnackBarContext } from "./context/SnackBarContext";
import { LoaderContext } from "./context/LoaderContext";
import { ZoomContext } from "./context/ZoomContext";
import { SnackBar } from "./utils/snackbar";
import { Loader } from "./utils/loader";

export default () => {
  const routing = useRoutes(routes);
  return <App routing={routing} />;
};

class App extends React.Component {
  state = {
    snackbarText: null,
    snackBar: false,
    loader: false,
    action_url: null,
    action_text: null,
    process_list: [],
  };
  showSnackBar = (text) => {
    if (typeof text === "string")
      this.setState({ snackBar: true, snackbarText: text });
    else if (typeof text === "object")
      this.setState({
        snackBar: true,
        snackbarText: text.text,
        action_url: text.action_url,
        action_text: text.action_text,
      });
  };
  hideSnackBar = () => {
    this.setState({ snackBar: false, snackbarText: null });
  };
  showLoader = () => {
    this.setState({ loader: true });
  };
  hideLoader = () => {
    this.setState({ loader: false });
  };
  clearProcessList = () => {
    let i,
      len = this.state.process_list.length;
    for (i = 0; i < len; i++) {
      this.state.process_list[i].kill("SIGINT");
    }
  };
  registerProcess = (process) => {
    var joined = this.state.process_list.concat(process);
    this.setState({ process_list: joined });
  };

  render() {
    const {
      state: { snackBar, snackbarText, action_url, action_text, loader },
      showSnackBar,
      hideSnackBar,
      showLoader,
      hideLoader,
      registerProcess,
      clearProcessList,
    } = this;
    const { routing } = this.props;

    return (
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <SnackBarContext.Provider
            value={{ show: showSnackBar, hide: hideSnackBar }}
          >
            <LoaderContext.Provider
              value={{ show: showLoader, hide: hideLoader }}
            >
              <ZoomContext.Provider
                value={{
                  registerProcess: registerProcess,
                  clearProcessList: clearProcessList,
                }}
              >
                {routing}
              </ZoomContext.Provider>
              {snackBar && (
                <SnackBar
                  text={snackbarText}
                  action_url={action_url}
                  action_text={action_text}
                  hideSnackBar={hideSnackBar}
                />
              )}
              {loader && <Loader />}
            </LoaderContext.Provider>
          </SnackBarContext.Provider>
        </ThemeProvider>
      </ApolloProvider>
    );
  }
}
