import React from 'react';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import { styled, createTheme, ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import WelcomeCard from './welcome-card';
import RankCard from './Rank-card';
import Toolbar from '@material-ui/core/Toolbar';
import Chart from './chart';
import Tables from './table';
import TestCard from './test-card';
import ResponseTable from './response-table';
import BarGraph from './bar-graph';
import TopicTable from './topic-table';
import { api_endpoint } from '../../../../../admin/app/srceens/result-app/config';
import { STUDENT_TOKEN_NAME } from "../../../../../../config"
import { useLocation } from 'react-router-dom';
import { LoaderContext } from "../../../../../../context/LoaderContext";

// const mdTheme = createTheme();

export default function ResultComp(props) {

    const { state } = useLocation();
    // console.log(state);
    const loader = useContext(LoaderContext);
    const admin = props.admin;
    const id = props.id;
    const rollno = props.rollno;

    const [val, setVal] = useState(null);
    const [loading, setLoading] = useState(true)
    const [testname, setTestName] = useState();
    const [response, setResponse] = useState([]);
    const [charts, setChart] = useState([]);
    const [arr, setArr] = useState([]);
    const [testdata, setTestData] = useState(null);
    const [totalCount, setTotalCount] = useState([]);
    const [topicdata, setTopicData] = useState([]);
    const [testarray, setTestArray] = useState([]);
    const [subjectArr, setSubjectArr] = useState([]);

    useEffect(async () => {
        try {
            // var test = localStorage.getItem('Test_name');
            // setTestName(test);
            loader.show();
            const { data: obj } = await axios({
                url: api_endpoint + '/view-analysis',
                method: 'post',
                responseType: 'json',
                data: {
                    test: state.id,
                    admin: admin,
                    rollno: rollno,
                    testid: id
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    STUDENTRELIABLETOKEN: localStorage.getItem(STUDENT_TOKEN_NAME)
                }
            })
            if (obj.Success === 'ok') {
                // console.log(obj.info);
                console.log(obj);
                setVal(obj.info);
                setChart(obj.subject_marks);
                setArr(obj.array);
                setTestData(obj.test_data);
                setResponse(obj.is_cor);
                setTotalCount(obj.total_count);
                setTopicData(obj.topic_data);
                setTestArray(obj.testarray);
                setSubjectArr(obj.subject_name);
            }
            // console.log(testdata);
        }
        catch (e) {
            alert("Error: " + e);
        }
        finally {
            loader.hide();
            setLoading(false);
        }

    }, []);
    if (loading)
        return (<p>Loading...</p>)

    return (
        <div>

            <Grid container spacing={3}>

                <Grid item xs={12} md={4} sm={6}>
                    <WelcomeCard roll={val.roll_no} test={testdata} />
                </Grid>

                <Grid item xs={12} md={4} sm={6}>
                    <RankCard rank={val.overall_rank} percentile={val.overall_percentile} count={totalCount} />
                </Grid>

                <Grid item xs={12} md={4} sm={6}>
                    <TestCard data={testdata} marks={val.overall_marks} percentage={val.overall_percentage} />
                </Grid>

                <div className="pagebreak"></div>

                <Grid item xs={12}>
                    <Tables testarray={testarray} data={arr} />
                </Grid>

                <Grid item xs={12}>
                    <BarGraph data={arr} />
                </Grid>

                <Grid item xs={12}>
                    <TopicTable subject={subjectArr} data={topicdata} />
                </Grid>

                <Grid item xs={12}>
                    <Chart row={arr} />
                </Grid>

                <Grid item xs={12}>
                    <ResponseTable data={response} />
                </Grid>

            </Grid>

        </div>
    );
}

