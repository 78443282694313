import React from "react";
import { Navigate as Redirect, Outlet } from "react-router-dom";
import AuthLayout from "../auth/layout";
import AppLayout from "../app/layout";
export default class Comp extends React.Component {
  state = {
    loading: true,
    login: null,
  };
  componentDidMount() {
    const token = localStorage.getItem("admin_token");

    this.setState({ loading: false });
    if (token) this.setState({ login: true });
    else this.setState({ login: false });
  }
  render() {
    const { loading, login } = this.state;
    const { mode } = this.props;
    if (loading) return <></>;
    if (mode === "auth") {
      if (login) return <Redirect to="/admin/app" />;
      else
        return (
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        );
    } else if (mode === "app") {
      if (!login) return <Redirect to="/admin/auth" />;
      else
        return (
          <AppLayout>
            <Outlet />
          </AppLayout>
        );
    }
  }
}
