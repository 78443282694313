import React from 'react';
import { useState } from 'react';
import { Button } from '@material-ui/core';

export default function UploadTestComp(props) {

    const handleUpdate = () => {
        props.handleTestClick({ id: props.id, test: props.test, index: props.index, listed: props.listed, setVariable: props.setVariable });

    }

    return (
        <>
            <Button onClick={handleUpdate} style={{ border: '1px solid purple', borderStyle: 'dashed', color: '#001A23' }}>Upload</Button>
        </>
    )
}