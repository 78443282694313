import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange }) => {
  const list = [{ key: "90", value: "90 Min" }, { key: "40", value: "40 Min" }];

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={list}
    />
  );
};
