import React from 'react'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default ({ subject_id, subjects, handleSubjectTab }) => {
    const [value, setValue] = React.useState(subject_id);
  console.log(subject_id)
  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleSubjectTab(newValue)
  };
  console.log(value)
    return (
        <Paper square>
        <Tabs
          value={subject_id}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
        { subjects.map((item,key) => <Tab label={item.name} value={item.id} />) }
       
        </Tabs>
      </Paper>
    )
}