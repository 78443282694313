import { gql } from "@apollo/client";

export const classroom_test_subject_list_query_gql = gql`
  query classroom_test_subject_list($classroom_test_batch_id: Int!) {
    classroom_test_subject_list(classroom_test_batch_id: $classroom_test_batch_id) {
      key: id
      subject {
        value: name
      }
    }
  }
`;
