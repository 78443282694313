import React from 'react';
import { Paper } from '../../../paper'
import Typography from '@material-ui/core/Typography';

export default ({ title }) => {
    return (

        <Typography variant="h6" gutterBottom style={{color:'#aaaaaa'}}>
            {title}
        </Typography>



    )
}