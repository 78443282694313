import React from 'react';
import readXlsxFile from 'read-excel-file'
import moment from 'moment'
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { useState, useContext } from 'react';
export default () => {
    const [rows, setRows] = useState([]);
    const loader = useContext(LoaderContext);
    const showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            console.log(text)
        };

        readXlsxFile(e.target.files[0], { dateFormat: 'yyyy-mm-dd' }).then((row) => {
            // console.log(rows);
            setRows(row);
            const a = row[0];
            if (a[0] != 'Agent Name')
                alert('index 0 should be "Agent Name"');
            if (a[1] != 'Agent ID')
                alert('index 1 should be "Agent ID"');
            if (a[2] != 'Campaign Name')
                alert('index 2 should be "Campaign Name"');
            if (a[3] != 'List Name')
                alert('index 3 should be "List Name"');
            if (a[4] != 'Customer Name')
                alert('index 4 should be "Customer Name"');
            if (a[5] != 'Customer Ph No')
                alert('index 5 should be "Customer Ph No"');
            if (a[6] != 'Queue Time')
                alert('index 6 should be "Queue Time"');
            if (a[7] != 'Date Time')
                alert('index 7 should be "Date Time"');
            if (a[8] != 'Call Duration')
                alert('index 8 should be "Call Duration"');
            if (a[9] != 'Call Type')
                alert('index 9 should be "Call Type"');
            if (a[10] != 'Orientation Type')
                alert('index 10 should be "Orientation Type"');
            if (a[11] != 'Call Status')
                alert('index 11 should be "Call Status"');
            if (a[12] != 'Redial Flag')
                alert('index 12 should be "Redial Flag"');
            if (a[13] != 'Monitor File Path')
                alert('index 13 should be "Monitor File Path"');
            if (a[14] != 'Monitor File Name')
                alert('index 14 should be "Monitor File Name"');
            if (a[15] != 'Custom Monitor File Name')
                alert('index 15 should be "Custom Monitor File Name"');
            if (a[16] != 'Cust Disposition')
                alert('index 16 should be "Cust Disposition"');
            if (a[17] != 'Cust Category')
                alert('index 17 should be "Cust Category"');
            if (a[18] != 'Transferred From')
                alert('index 18 should be "Transferred From"');
            if (a[19] != 'Transferred To')
                alert('index 19 should be "Transferred To"');
            if (a[20] != 'Session ID')
                alert('index 20 should be "Session ID"');
            if (a[21] != 'Call Remarks')
                alert('index 21 should be "Call Remarks"');
            if (a[22] != 'Wrapup Time')
                alert('index 22 should be "Wrapup Time"');
            if (a[23] != 'Channel')
                alert('index 23 should be "Channel"');
            if (a[24] != 'Next Call Time')
                alert('index 24 should be "Next Call Time"');
            if (a[25] != 'Skills')
                alert('index 25 should be "Skills"');
            if (a[26] != 'DID Number')
                alert('index 26 should be "DID Number"');
            if (a[27] != 'Phone Type')
                alert('index 27 should be "Phone Type"');
            if (a[28] != 'Wait Time')
                alert('index 28 should be "Wait Time"');
            if (a[29] != 'Hold Time')
                alert('index 29 should be "Hold Time"');
            if (a[30] != 'IVRS Path')
                alert('index 30 should be "IVRS Path"');
            if (a[31] != 'Disconnected By')
                alert('index 31 should be "Disconnected By"');
            if (a[32] != 'Hold Num Times')
                alert('index 32 should be "Hold Num Times"');
            if (a[33] != 'Wrapped By')
                alert('index 33 should be "Wrapped By"');
            if (a[34] != 'Ringing')
                alert('index 34 should be "Ringing"');
            if (a[35] != 'Total Attempt')
                alert('index 35 should be "Total Attempt"');
            if (a[36] != 'Total Phone Attempt')
                alert('index 36 should be "Total Phone Attempt"');
            if (a[37] != 'Dialer Action')
                alert('index 37 should be "Dialer Action"');
            if (a[38] != 'Ring Duration')
                alert('index 38 should be "Ring Duration"');
            if (a[39] != 'Ring Start Time')
                alert('index 39 should be "Ring Start Time"');
            if (a[40] != 'Ring End Time')
                alert('index 40 should be "Ring End Time"');
            if (a[41] != 'Actual Talk Time')
                alert('index 41 should be "Actual Talk Time"');
            if (a[42] != 'Unique ID')
                alert('index 42 should be "Unique ID"');
            if (a[43] != 'Final Call Status')
                alert('index 43 should be "Final Call Status"');
            if (a[44] != 'Caller Id')
                alert('index 44 should be "Caller Id"');
            if (a[45] != 'Failure Reason')
                alert('index 45 should be "Failure Reason"');
            if (a[46] != 'Agent Ring Start')
                alert('index 46 should be "Agent Ring Start"');
            if (a[47] != 'Agent Ring End')
                alert('index 47 should be "Agent Ring End"');
            if (a[48] != 'Agent Ring Duration')
                alert('index 48 should be "Agent Ring Duration"');
            if (a[49] != 'Customer Sentiment')
                alert('index 49 should be "Customer Sentiment"');
            if (a[50] != 'Cust Field1')
                alert('index 50 should be "Cust Field1"');
            if (a[51] != 'Cust Field2')
                alert('index 51 should be "Cust Field2"');
            if (a[52] != 'Cust Field3')
                alert('index 52 should be "Cust Field3"');
            if (a[53] != 'Cust Field4')
                alert('index 53 should be "Cust Field4"');
            if (a[54] != 'Provider Name')
                alert('index 54 should be "Provider Name"');
            else {
                setRows(row);
            }
        })
    }

    const handleClick = async () => {
        try {
            loader.show();
            // console.log(rows);
            let i, j, len = rows.length
            for (i = 0; i < len; i++) {
                rows[i][7] = moment(rows[i][7]).format('YYYY-MM-DD');
                rows[i][8] = moment(rows[i][8]).format('YYYY-MM-DD');
                rows[i][22] = moment(rows[i][22]).format('YYYY-MM-DD');
                rows[i][28] = moment(rows[i][28]).format('YYYY-MM-DD');
                rows[i][29] = moment(rows[i][29]).format('YYYY-MM-DD');
                rows[i][38] = moment(rows[i][38]).format('YYYY-MM-DD');
                rows[i][41] = moment(rows[i][41]).format('YYYY-MM-DD');
                rows[i][46] = moment(rows[i][46]).format('YYYY-MM-DD');
                rows[i][47] = moment(rows[i][47]).format('YYYY-MM-DD');
            }
            const { data: response } = await axios({
                url: 'https://api-dot-reformexam.el.r.appspot.com/c-zentrix/file-upload',
                method: 'post',
                responseType: 'json',
                data: {
                    rows: rows
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            console.log(response);
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }


    return (
        <>
            <input type="file" id="input" onChange={(e) => showFile(e)} />
            <button onClick={handleClick}>Submit</button>
        </>
    )
}