import React from "react";
import { Select } from "../../../../utils/select";
import { batch_list_query_gql } from "../../gql/query/batch-list-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({ phase_id, label, value, name, onChange }) => {
  if (!phase_id) return <span></span>;
  const { loading, error, data } = useQuery(batch_list_query_gql, {
    variables: {
      phase_id: phase_id,
    },
  });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { batch_list } = data;

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={batch_list}
    />
  );
};
