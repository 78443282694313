import React, { useState, useEffect } from 'react'
import { Select } from "../../../../../utils/select";
import confirm_click from '../reusable-calling-code-project/reuable-calling-code/confirm_click';
import Modal from '../reusable-calling-code-project/modal';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import styles from '../reusable-calling-code-project/table.module.css';
import { handle_error } from '../reusable-calling-code-project/handle_error';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
const axios = require('axios');

const Conversation = ({ token, isDesktop, last_time, last_remark, last_category, loader, snackBar, hide, refresh }) => {

    const [category, setCategory] = useState(last_category);
    const [remark, setRemark] = useState(last_remark);


    useEffect(() => {
        setCategory(last_category);
        setRemark(last_remark)
    }, [token])

    const click = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/calling-panel/create-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    token: token,
                    text: remark,
                    category: category,
                },
                ...rest_headers
            })

            if (obj.success == 'ok') {
                alert('Conversation Created Successfully');
                // refresh();
                hide();
            }

            if (!category) {
                alert("Enter Category");
            }
            if (!remark) {
                alert("Enter Conversation Details");
            }


        } catch (e) {
            alert(e.message)
        } finally {
            loader.hide();
        }
    }

    return (
        <>
            <Grid xs={12}>
                <Select
                    label={"Select Category"}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    variant="outlined"
                    name={'category'}
                    array={[
                        { key: "Admission Done", value: "Admission Done" },
                        { key: "Advanced Counselling", value: "Advanced Counselling" },
                        { key: "Admission Done", value: "Admission Done" },
                        { key: "Already Enrolled", value: "Already Enrolled" },
                        { key: "Disqualified", value: "Disqualified" },
                        { key: "Follow Up", value: "Follow Up" },
                        { key: "Form Filled", value: "Form Filled" },
                        { key: "Financial Issue", value: "Financial Issue" },
                        { key: "Interested", value: "Interested" },
                        { key: "Joined other Institute", value: "Joined other Institute" },
                        { key: "Not Able To Connect", value: "Not Able To Connect" },
                        { key: "Will Visit Campus", value: "Will Visit Campus" },
                        { key: "Will come after JEE MAIN", value: "Will come after JEE MAIN" },
                        { key: "Wil come after BOARD EXAMS", value: "Wil come after BOARD EXAMS" },
                        { key: "Will Apply Online", value: "Will Apply Online" },
                        { key: "Will Deposit Course Fee", value: "Will Deposit Course Fee" }
                    ]}
                />
            </Grid>
            <br /><br />
            <Grid xs={12}>
                <TextField
                    // label={"Remark"}
                    placeholder="Enter Remark"
                    type="text"
                    fullWidth
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                />
            </Grid>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: '10px' }}>
                <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={() => {

                    confirm_click({ click, token })
                }}>Submit</Button>&nbsp;&nbsp;
                <View isDesktop={isDesktop} token={token} loader={loader} snackBar={snackBar} />
            </div>
            <br /><br />
            <Typography variant='h5'>Date-Time:&nbsp;&nbsp;{last_time} </Typography>
        </>
    )
}

const View = ({ token, loader, snackBar, isDesktop }) => {
    const [array, setArray] = useState([]);
    const hide = () => {
        setArray([]);
    }
    const handleClick = async (token) => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/calling-panel/view-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    token: token,
                },
            })

            setArray(obj.array)


        } catch (e) {
            alert(e.message);
        } finally {
            loader.hide();
        }

    }

    return (
        <>

            <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(token)}>View</Button>

            {array && array.length > 0 && <Modal hide={hide}>
                <div style={{ fontFamily: 'Arial' }}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th colSpan={3}>Conversation</th>
                            </tr>
                            <tr>
                                <th>Remark</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {array.map((n, i) => {
                                return (
                                    <tr style={{ fontSize: '13px' }}>
                                        <td>{n.text}</td>
                                        <td>{n.date}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>


                </div>


            </Modal>

            }
        </>
    )
}

export default Conversation
