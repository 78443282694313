import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { view_attendence_query_gql } from "../../../gql/query/view-attendence-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../../utils/gql-error-comp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
}));

export default ({ values }) => {
  if (!values.batch_id) return <span></span>;
  const classes = useStyles();
const { batch_id, childsubject_id, date_from, date_to } = values;
const { loading, error, data  } = useQuery(view_attendence_query_gql, {
  fetchPolicy: "no-cache",
  variables: {
    batch_id,
    childsubject_id,
    date_from,
    date_to
  },
});

if (loading) return <span>Loading...</span>;
if (error) return <GqlErrorComp error={error} />;
const { view_attendence } = data;

console.log(view_attendence)
return (<TableContainer component={Paper}>
  <Table className={classes.table} aria-label="simple table">
  <TableHead><TableRow><TableCell>SNo.</TableCell><TableCell>RollNo</TableCell><TableCell>Date</TableCell></TableRow></TableHead>
    <TableBody>
    { view_attendence.map((n,i)=>{
        return <TableRow><TableCell>{i+1}</TableCell><TableCell>{n.roll_no}</TableCell>
        { n.datewise.map((x,j)=> {
          return <TableRow><TableCell>{x.class_date}  --  {x.present === "Y" ? <span style={{color:'green'}}>YES</span> : <span style={{color:'red'}}>NO</span>}</TableCell></TableRow>
        }) }
        </TableRow> 
    })}
  </TableBody>
  </Table>
    </TableContainer>)

//   return (
//     <List className={classes.root}>
//       {view_live_class.map((n, i) => {
//         console.log(n);
//         return (
//           <>
//           <Typography component="h3">{n.date}</Typography>
//           <ListItem key={i}>
//             <ListItemText
//               primary={n.childsubject.name}
//               secondary={<>
//                 {n.start_time} - {n.end_time} <br />
//                 ZoomID - {n.zoom_account.email} <br />
//                 MeetingID - {n.meeting_no} <br />
//                 Password - {n.meeting_pwd} <br />
//                 Faculty - {n.faculty.name} <br />
//                 Status - {n.is_cancelled ? "Cancelled" : "Ok"}
//                 </>}
//             />
//           </ListItem>
//           <br />
//             <Divider />
//             <br />
//             <br />
//           </>
//         );
//       })}
//     </List>
//   );
}
