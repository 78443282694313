import { Alert, AlertTitle } from '@material-ui/lab';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
 import {api_url,rest_headers} from '../../srceens/digital-classroom/config'
 import {STUDENT_TOKEN_NAME} from "../../../../../config"

const axios=require('axios');

	

//import FileBase64 from 'react-file-base64';
 
export default({snackBar,loader})=>{

	  const handleUpload = async(event) => {
		 try{
		  const {name}=event.target.files[0];
	 
		  if(name.indexOf('pdf')<0){
				
			 snackBar.show('please choose pdf file')
			 return
		  }
		  loader.show()
		  const data = new FormData() ;
		    data.append('file', event.target.files[0]);
 const {data:res}= await axios.post(`${api_url}/uploadFileAPI`, data)

 
 if(res.success!='ok'){
	 snackBar.show(res.message)
 }
 
 
 //=======================================
 
 const  {data:data2}=await axios({
        url: api_url+'/data-collection-1',
        method: 'post',
		responseType: 'json',
		data:{
			url:res.url 
			 
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			STUDENTRELIABLETOKEN:localStorage.getItem(STUDENT_TOKEN_NAME)
}
      }) 
	  if(data2.success=='ok')
	  {
		  snackBar.show(data2.message);
	  }else{
		  snackBar.show(data2.message);
	  }
 
 //=======================================
 
 
 
		 }
		 catch(e){
			  
			 snackBar.show(e.message)
		 }
		 finally{
			 loader.hide()
		 }
        
    }
	return(
	<div style={{maxWidth:400,margin:'0 auto'}}>
<Alert severity="error"
  
>
<AlertTitle><strong>Important</strong></AlertTitle>
Upload Covid-Certificate
<>
{/*<FileBase64
id="contained-button-file"
onDone={handleUpload} />*/}
 <input type="file" id="contained-button-file" style={{display:'none'}} onChange={handleUpload} />
      <label htmlFor="contained-button-file">
        <Button variant="outined"
 startIcon={<CloudUploadIcon />}
		   component="span">
          Upload
        </Button>
      </label>
	  </>
</Alert>
 
	     
</div>
)
}