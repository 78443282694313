import { gql } from "@apollo/client";

export const notification_list_query_gql = gql`
  {
    notification_list {
      key: id
      value: title
    }
  }
`;
