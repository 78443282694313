import React,{useState,useEffect,useContext} from 'react';
import SMSSender from "./sms-sender"
 
export default () => {
    return(
	<SMSSender dynamic/>
  );
};
 
   
 