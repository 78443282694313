import React from "react";
import { Select } from "../../../../utils/select";
import { topic_list_query_gql } from "../../gql/query/topic-list-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({
  childsubject_id,
  class_id,
  label,
  value,
  name,
  onChange,
}) => {
  if (!(childsubject_id && class_id)) return <span></span>;
  const { loading, error, data } = useQuery(topic_list_query_gql, {
    variables: {
      childsubject_id,
      class_id,
    },
  });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { topic_list } = data;

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={topic_list}
    />
  );
};
