import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import injectSheet from "react-jss";
import { useQuery, useMutation } from "@apollo/client";
import {notification_gql} from '../../../gql/notification_gql';
//import { batch_courseware_mark_attendance } from "../../../gql/student_courseware_mark_attendance_gql";
import Divider from "@material-ui/core/Divider";
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import {error_beautify} from '../../../../../utils/functions';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";
import theme from "../../../../../theme";

const common = {
  height: "8px",
  borderRadius: "8px",
};
const StyleSheet = {
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  item: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  circle: {
    height: 64,
    width: 64,
    borderRadius: "50%",
  },
  line1: {
    ...common,
    width: 200,
  },
  line2: {
    ...common,
    width: 140,
  },
  header: {
    ...common,
    width: 96,
  },
  divider: {
    height: 20,
  },
  divider2: {
    height: 10,
  },
  left: {
    borderRadius: 64,
  },
};

export default ({ is_home_component }) => {
//   const [mark_attendance_mutation] = useMutation(
//     batch_courseware_mark_attendance
//   );

  return (
    <StyledApp
      //mark_attendance_mutation={mark_attendance_mutation}
      //is_home_component={is_home_component}
    />
  );
};

const App = ({ classes }) => {
    let navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  const res = useQuery(notification_gql);

  const { loading, error, data, refetch } = res;

  if (loading)
    return (
      <>
        <ShimmerHeader />
        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />
      
      </>
    );
    
    if (error){
      if(error_beautify(error) === "Response not successful: Received status code 500"){
        localStorage.removeItem("student_token");
        navigate("/student");  
      }
      return <GqlErrorComp error={error} />;
    }

  const array = data.batch_notification_list;

  let style = {};
style = { color: theme.palette.primary.main };

  return (
    <Card>
    <CardContent>
        <>
      <Typography variant="h3" align='center'>Notifications</Typography><br /><br />
      {
                array.map(({ notification, created_at }, key) => {
                    return (
            <ListItem button onClick={()=> { 
                navigate("details", { state : {title:notification.title , desc: notification.description} }) 
            return;
            }}>
            <ListItemIcon style={style}>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
          primary={notification.title}
          secondary={<>
            {created_at} <br /> {notification.description}  
            </>}
            />           
        </ListItem>
         
                    )
                })
            } </>
    </CardContent>
   
  </Card>
            
        
  );
};



const ShimmerHeader = () => {
  return (
    <Shimmer>
      <div
        style={{ marginTop: 30, width: 100, borderRadius: 64, height: 10 }}
      ></div>
    </Shimmer>
  );
};
const ShimmerRow = ({ classes }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
      <Shimmer>
        <div
          style={{
            marginTop: 30,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            width: 24,
            height: 16,
          }}
        ></div>
      </Shimmer>

      <div style={{ justifyContent: "space-between", marginLeft: 30 }}>
        <div
          style={{
            marginTop: 10,
            width: 200,
            borderRadius: 64,
          }}
        >
          <Shimmer>
            <div className={classes.line1}></div>
          </Shimmer>
        </div>

        <div
          style={{
            marginTop: 10,
            width: 140,
            borderRadius: 64,
            height: 10,
          }}
        >
          <Shimmer>
            <div className={classes.line2}></div>
          </Shimmer>
        </div>
      </div>
    </div>
  );
};

const StyledApp = injectSheet(StyleSheet)(App);
