import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme, makeStyles, colors } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Title from './title';

const useStyles = makeStyles(() => ({
    root: {},
}));

export default function BarGraph(props) {
    const classes = useStyles();
    const theme = useTheme();
    const data = props.data
    return (
        <>
            <Card className={clsx(classes.root)}>
                <div style={{ borderBottom: '1px solid #d6d6d6', height: "auto" }}>
                    <Title>Relative Comparison of Your Score vs. Average Score (in %)</Title>
                </div>
                <Divider />
                <CardContent>
                    <Box height={400} position="relative">
                        <ResponsiveContainer style={{ width: "100%" }}>
                            <BarChart
                                data={data}
                                margin={{ top: 5, left: 0, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip cursor={{ fill: 'transparent' }} />
                                <Legend wrapperStyle={{ fontFamily: "Roboto, sans-serif;" }} />
                                <Bar dataKey="avgscore" fill={"#2196f3"} label={{ position: 'top' }} />
                                <Bar dataKey="yourmarks" fill={"#ffcc80"} label={{ position: 'top' }} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </CardContent>
                <Divider />
            </Card>
        </>
    )
}