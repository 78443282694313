import React,{useState,useEffect,useContext} from 'react';
import {api_url,rest_headers} from '../../config'
import { SnackBarContext } from "../../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../../context/LoaderContext";
import Profile from './profile'
const axios = require('axios');

export default (props) => {
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
		  {...props}
    />
  );
};
const Comp = ({ loader, snackBar,...props })=>{
	 
	const [show,setShow]=useState(false);
    const [res, setRes] = useState(null);
	const  refresh=()=>{
		fetch()
	}
   
  useEffect(async()=>{
	   document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
  fetch();
  },[])
  
  const fetch=async()=>{
	  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/sheet1',
        method: 'post',
		responseType: 'json',
        ...rest_headers
      }) 
	    
	 
 
 setRes( data.array)

      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 
	
	return(
	<div>
	
	{res && <Profile array={res} refresh={refresh} snackBar={snackBar} loader={loader} {...props} />}
	
	</div>
	)
}