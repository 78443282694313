import React from "react";
import { Select } from "../../../../utils/select";
import { classroom_test_subject_list_query_gql } from "../../gql/query/classroom-test-subject-list-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({ classroom_test_batch_id, label, value, name, onChange }) => {
  console.log(classroom_test_batch_id)
  if (!classroom_test_batch_id) return <span></span>;
  const { loading, error, data } = useQuery(
    classroom_test_subject_list_query_gql,
    {
      variables: {
        classroom_test_batch_id: classroom_test_batch_id,
      },
    }
  );
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { classroom_test_subject_list } = data;
console.log(classroom_test_subject_list)
  let i = 0,
    new_array = [],
    array = classroom_test_subject_list;

  for (i = 0; i < array.length; i++) {
    new_array.push({ key: array[i].key, value: array[i].subject.value });
  }

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={new_array}
    />
  );
};
