import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
  Typography,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import PrintIcon from "@material-ui/icons/Print";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PercentileGraph from "./percentile-graph";
import ScoreCardComp from "./scorecard-comp";
import { classroom_test_all_test_result_gql } from "../../../../gql/classroom_test_all_test_result_gql";
import { useQuery } from "@apollo/client";
import external_url_handler from "../../../../../../utils/external_url_handler";
import { graphql_endpoint, STUDENT_TOKEN_NAME } from "../../../../../../config";
const useStyles = makeStyles(() => ({
  root: {},
}));

const Sales = ({ className, is_home_component }) => {
  const classes = useStyles();

  const res = useQuery(classroom_test_all_test_result_gql, {
    variables: {
      limit: is_home_component ? 5 : 2000,
    },
  });

  const { loading, error, data } = res;
  if (loading) return <span>Loading...</span>;
  if (error) return <span>{error.message}</span>;

  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <Divider />
        <CardContent>
          <Typography variant="h4">
            {is_home_component ? "Latest Results" : "Overall Result"}
          </Typography>
          <br />
          <Box height={400} position="relative">
            <PercentileGraph
              data={data}
              is_home_component={is_home_component}
            />
          </Box>
        </CardContent>

        {is_home_component && (
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="secondary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              component={RouterLink}
              to="../classroom-test-result/all"
            >
              View Overall Result
            </Button>
          </Box>
        )}
      </Card>
      {!is_home_component && (
        <Card
          style={{ marginTop: 10 }}
          className={clsx(classes.root, className)}
        >
          <Divider />
          <CardContent>
            <Typography variant="h4">{"Score Card"}</Typography>
            <br />
            <Box height={400} position="relative">
              <ScoreCardComp
                data={data}
                is_home_component={is_home_component}
              />
            </Box>
          </CardContent>
        </Card>
      )}

      {!is_home_component && (
        <Fab
          onClick={() => print_fn()}
          style={{
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
          }}
          color="primary"
          aria-label="like"
        >
          <PrintIcon />
        </Fab>
      )}
    </>
  );
};

Sales.propTypes = {
  className: PropTypes.string,
};

export default Sales;

const print_fn = () => {
  const token = localStorage.getItem(STUDENT_TOKEN_NAME);
  external_url_handler(
    graphql_endpoint.replace(
      "graphql",
      `print-result?token=${encodeURIComponent(token)}`
    )
  );
};
