import React from "react";
import { InputRich } from "../input_rich";
import { SelectNoOfOption } from "./select-no-of-option";
import { SubQuestionType } from "./subquestion-type";

const tdstyle = { verticalAlign: "top", padding: 10 };
export const SubQuestionInput = ({
  handleSubQuestionType,
  subquestion,
  question_type,
  i,
  onChange,
  handleOptionValueChange,
  handleSubQuestionContent,
  image_upload_url,
}) => {
  return (
    <React.Fragment>
      <table border="1" style={{ width: "100%", borderCollapse: "collapse" }}>
        <tr>
          <td style={tdstyle} rowSpan={2}>
            Question {question_type === "single" ? "" : i + 1} Content
          </td>
          <td style={tdstyle}>English</td>
          <td style={tdstyle}>Hindi</td>
        </tr>
        <tr>
          <td style={tdstyle}>
            <InputRich
              value={subquestion.english_content}
              image_upload_url={image_upload_url}
              onChange={(e) =>
                handleSubQuestionContent(i, e.target.value, "en")
              }
            />
          </td>
          <td style={tdstyle}>
            <InputRich
              value={subquestion.hindi_content}
              image_upload_url={image_upload_url}
              onChange={(e) =>
                handleSubQuestionContent(i, e.target.value, "hi")
              }
            />
          </td>
        </tr>
        <tr>
          <td style={tdstyle} colSpan={2}>
            Type
          </td>
          <td style={tdstyle}>
            <SubQuestionType
              onChange={(e) => handleSubQuestionType(i, e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td style={tdstyle} colSpan={2}>
            No of Options
          </td>
          <td style={tdstyle}>
            <SelectNoOfOption
              value={subquestion.option.length}
              onChange={(e) => onChange(i, e.target.value)}
            />
          </td>
        </tr>
        {subquestion.option && (
          <OptionComp
            i={i}
            option={subquestion.option}
            onChange={handleOptionValueChange}
            image_upload_url={image_upload_url}
          />
        )}
      </table>

      <br />
      <br />
    </React.Fragment>
  );
};

const OptionComp = ({ option, i, onChange, image_upload_url }) => {
  return (
    <React.Fragment>
      {option.map((n, index) => {
        return (
          <React.Fragment>
            <tr>
              <td style={tdstyle} rowSpan={2}>
                Option {index + 1}
              </td>
              <td style={tdstyle}>English</td>
              <td style={tdstyle}>Hindi</td>
            </tr>
            <tr>
              <td style={tdstyle}>
                <InputRich
                  value={option.english_content}
                  image_upload_url={image_upload_url}
                  onChange={(e) => onChange(i, index, e.target.value, "en")}
                />
              </td>
              <td style={tdstyle}>
                <InputRich
                  value={option.hindi_content}
                  image_upload_url={image_upload_url}
                  onChange={(e) => onChange(i, index, e.target.value, "hi")}
                />
              </td>
            </tr>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
