import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import Profile from './profile'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
const axios = require('axios');
const _ = require("lodash");

export default (props) => {
    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);
    return (
        <Comp
            snackBar={snackBar}
            loader={loader}
            {...props}
        />
    );
};
const Comp = ({ loader, snackBar, ...props }) => {

    const [res, setRes] = useState([]);

    const refresh = () => {
        fetch();
    }

    useEffect(() => {

        fetch();

    }, [])


    const fetch = async () => {
        try {

            loader.show()

            const { data: obj } = await axios({
                url: api_url + '/calling-panel/calling-data',
                method: 'post',
                responseType: 'json',
                ...rest_headers,
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setRes(obj.arr)
            }

        }

        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide()
        }
    }





    return (
        <div>

            {res && <Profile array={res} refresh={refresh} snackBar={snackBar} loader={loader} />}

        </div>
    )
}