import { ApolloClient, HttpLink, concat, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { graphql_endpoint as uri, STUDENT_TOKEN_NAME } from "../../config.js";

const httpLink = new HttpLink({ uri: uri });

const authMiddleware = setContext(
  (request) =>
    new Promise((success, fail) => {
      let token = localStorage.getItem(STUDENT_TOKEN_NAME);
      if (!token) token = localStorage.getItem("admin_token");
      success({ headers: { authorization: `Bearer ${token}` } });
    })
);

export default new ApolloClient({
  // link: authMiddleware.concat(errorLink).concat(httpLink),
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  },
});
