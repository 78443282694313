import React from "react";
import { useParams, Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { Spinner } from "../../../../../utils/spinner";
import { useQuery } from "@apollo/client";
import { classroom_test_subject_topic_gql } from "../../../gql/classroom_test_subject_topic_gql";
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronRight from "@material-ui/icons/ChevronRight";

import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";

export default () => {
  let { test_id } = useParams();
  const navigate = useNavigate();
  const res = useQuery(classroom_test_subject_topic_gql, {
    variables: {
      classroom_test_id: parseInt(test_id, 10),
    },
  });

  const { loading, error, data, refetch } = res;

  if (loading) return <Spinner />;
  if (error) return <GqlErrorComp error={error} />;
  const list = data.classroom_test_schedule_subject_topic.classroom_test_batch[0].classroom_test_subject;
  return (
    <Card>
      <CardContent>
        <Typography variant="h4">Test Syllabus</Typography>

        {list.length > 0 &&
          list.map((r, i) => {
            return (
              <>
                <List
                  key={i}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      {r.subject.name}
                    </ListSubheader>
                  }
                >
                  {r.classroom_test_topic.map((t, j) => {
                    return (
                      <ListItem key={j}>
                        <ListItemIcon>
                          <ChevronRight />
                        </ListItemIcon>
                        <ListItemText primary={t.topic.name} secondary={""} />
                      </ListItem>
                    );
                  })}
                </List>
                <Divider />
              </>
            );
          })}
      </CardContent>
    </Card>
  );
};
