import React, { useContext, useState,useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutchildsubject from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify, 
} from "../../../../../../utils/functions";
import Typography from "@material-ui/core/Typography";
import SelectState from "../../../components/call-center--select-state"; 
import SelectStream from "../../../components/call-center--select-stream"; 

import SelectCity from "../../../components/call-center--select-city"; 
import SelectClass from "../../../components/call-center--select-class"; 
import SelectCourse from "../../../components/call-center--select-course"; 
import SelectMedium from "../../../components/call-center--select-medium"; 
import SelectInteractionMode from "../../../components/call-center--select-interaction-mode"; 


import {api_url} from '../config.js'

const axios = require('axios');
export default ({mobile,contact_id,done}) => {
   
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
    contact_id={contact_id}
	done={done}
      snackBar={snackBar}
      loader={loader}
	  mobile={mobile}
    />
  );
};
const Comp = ({ loader, snackBar,mobile:initial_mobile,contact_id, done }) => {
 
	let [disabled,setDisabled]=useState(false);
	 
	let [name,setName]=useState('');
	let [mobile,setMobile]=useState(initial_mobile); 
	let [mobile1,setMobile1]=useState('');
 
	let [course,setCourse]=useState('');
	let [medium,setMedium]=useState('');
	let [state,setState]=useState('');
	let [city,setCity]=useState('');
	let [stream,setStream]=useState('');
	let [classs,setClasss]=useState('');
	let [remark,setRemark]=useState('');
	let [interaction_mode,setInteractionMode]=useState('');
 
	useEffect(()=>{
		if(initial_mobile){
			setDisabled(true);
		}
	},[])
   
  const [state_list,setStateList]=useState([]);
  const [state_city_list,setStateCityList]=useState([]);
  
   const [stream_list,setStreamList]=useState([]);
   const [stream_class_list,setStreamClassList]=useState([]);
   const [stream_class_course_list,setStreamClassCourseList]=useState([]);
   

  const handleSubmit = async (e) => {
    e.preventDefault();

       //const all={name,mobile,mobile1,email,course,state,city,stream,classs,course}; 
 
  try {
	  if(!contact_id){
	   if(name=='' || name.length<3)
{
	snackBar.show('Enter valid valid name');
	return false;
}
 if(mobile.length!=10)
{
	snackBar.show('Enter valid Mobile number');
	return false;
}
	  }
   loader.show();
   const  {data}=await axios({
		url:api_url+'/create-lead.php',
        method: 'post',
		responseType: 'json',
       data: `auth_token=##reliabletoken##&name=${name}&mobile=${mobile}&mobile1=${mobile1}&course=${course}&state=${state}&city=${city}&stream=${stream}&classs=${classs}&course=${course}&medium=${medium}&interaction_mode=${interaction_mode}&remark=${remark}&contact_id=${contact_id}&admin_token=${localStorage.getItem('admin_token')}`
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
 
 
snackBar.show(data.message.message);
      
       setName('');	setMobile(''); setMobile1('');   setCourse(''); setState(''); setCity(''); setStream(''); setClasss('');setRemark('');setMedium('');setInteractionMode('');
	  done(true)
    } catch (e) {
		 
      //snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 


 
   useEffect(async() => {
  
   try {
   loader.show();
   const  {data}=await axios({
        url:  api_url+'/get-select-boxes-list.php',
        method: 'post',
		responseType: 'json',
       data: 'auth_token=##reliabletoken##',
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
 
 setStateList( data.message.state_list)
 setStateCityList( data.message.state_city_list)
 setStreamList( data.message.stream_list)
 setStreamClassList( data.message.stream_class_list)
 setStreamClassCourseList( data.message.stream_class_course_list)
 
 

 
    } catch (e) {
		 
    snackBar.show(e);
    } finally {
      loader.hide();
    }
  
 
  
    
  },[]);
  

  
  
  return (
    
      <Container>
	  <br />
        <FormLayoutchildsubject title="Create Lead">
          <Grid spacing={3} container>
			  {!contact_id && <Grid item xs={6}>
              <TextField
                label="Enter Name"
                variant="outlined"
                name="name"
                value={name}
                onChange={(e)=>{setName(e.target.value)}}
              />
			  </Grid>
			  }
			 {!contact_id &&  <Grid item xs={6}>
              <TextField
                label="Enter Mobile No."
                variant="outlined"
                name="mobile"
				 inputProps={{
					maxLength: 10,
				  }}
                value={mobile}
                 onChange={(e)=>{setMobile(e.target.value)}}
				 disabled={disabled}
			 
              />
			 </Grid>
			 }
			 {!contact_id && <Grid item xs={6}>
              <TextField
                label="Enter Mobile No. 1"
                variant="outlined"
                name="mobile1"
				 inputProps={{
					maxLength: 10,
				  }}
                value={mobile1}
                 onChange={(e)=>{setMobile1(e.target.value)}}
			 
              />
            </Grid>
			 }
			 
            

           {!contact_id && <Grid item xs={6}>
              <SelectState
                label="State"
                name="state"
                value={state}
				state_list={state_list}
                 onChange={(e)=>{setCity('');setState(e.target.value)}}
              />
            </Grid>
		   }
			
           {!contact_id && <Grid item xs={6}>
              <SelectCity
                label="City"
                name="city"
                value={city}
				state_city_list={state_city_list}
				state={state}
				onChange={(e)=>{setCity(e.target.value)}}
              />
            </Grid>
		   }
			
			 <Grid item xs={6}>
              <SelectStream
                label="Stream"
                name="stream"
                value={stream}
				stream_list={stream_list}
                onChange={(e)=>{setClasss('');setCourse('');setStream(e.target.value)}}
              />
            </Grid>
			
           <Grid item xs={6}>
              <SelectClass
                label="Class"
                name="classs"
                value={classs}
				stream_class_list={stream_class_list}
				stream={stream}
				onChange={(e)=>{setCourse('');setClasss(e.target.value)}}
              />
            </Grid>
			<Grid item xs={6}>
              <SelectCourse
                label="Course"
                name="course"
                value={course}
				stream_class_course_list={stream_class_course_list}
				stream={stream}
				classs={classs}
				onChange={(e)=>{setCourse(e.target.value)}}
              />
            </Grid>
			<Grid item xs={6}>
              <SelectMedium
                label="Medium"
                name="medium"
                value={medium}
				onChange={(e)=>{setMedium(e.target.value)}}
              />
            </Grid>
			<Grid item xs={6}>
              <SelectInteractionMode
                label="Interaction Mode"
                name="interaction_mode"
                value={interaction_mode}
				onChange={(e)=>{setInteractionMode(e.target.value)}}
              />
            </Grid>
			<Grid item xs={6}>
            <TextField
                label="Enter Remark"
                variant="outlined"
                name="remark"
				 inputProps={{
					maxLength: 200,
				  }}
				  multiline
                value={remark}
                onChange={(e)=>{setRemark(e.target.value)}}
			 
              />
         
            </Grid>
            <Grid item xs={12}>
              <Button type="button" onClick={handleSubmit} variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutchildsubject>
      </Container>
   
  );
};
