import { Container, Paper, Grid, MenuItem, TextField, Typography, Button, Dialog, DialogContent, Snackbar, Select, InputLabel, FormControl } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import styles from '../reusable-calling-code-project/table.module.css';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { handle_error } from '../reusable-calling-code-project/handle_error'


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));



// const api_url = 'http://localhost:8060/vijeta-calling/'
const api_url = 'https://api-dot-reformexam.el.r.appspot.com/vijeta-calling/'

const VijetaCallingReviews = () => {

    const classes = useStyles();

    const loader = useContext(LoaderContext);
    const snackBar = useContext(SnackBarContext);


    const [array, setArray] = useState([]);
    const [res, setRes] = useState([]);
    const [show, setShow] = useState(false);
    const [category, setCategory] = useState();
    const [admin, setAdmin] = useState([]);
    const [value, setValue] = useState();

    useEffect(() => {
        fetch();
    }, [])

    const fetch = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + 'data-reviews',
                method: 'post',
                responseType: 'json'
            })

            if (obj.Success === 'ok') {
                setArray(obj.array);
                setRes(obj.array);
                setAdmin(obj.admin);
                setShow(true);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }
    }

    const handleCategory = async (e) => {
        try {
            loader.show();
            setShow(false);
            let val = e.target.value;
            setCategory(val);

            const { data: obj } = await axios({
                url: api_url + 'data-reviews-filter',
                method: 'post',
                responseType: 'json',
                data: {
                    category: val,
                }

            })

            console.log(obj);

            if (obj.Success === 'ok') {
                setArray(obj.array);
                setRes(obj.array)
                setShow(true);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }
    }

    const handleAdmin = (e) => {
        const val = e.target.value;
        console.log(val);
        setValue(val);
        const tmp = res;

        if (val == '') {
            setArray(tmp);
        }

        else {



            let i, len = tmp.length, temp = [];

            for (i = 0; i < len; i++) {
                if (tmp[i].admin == val) {
                    temp.push(tmp[i]);
                }
            }
            console.log(temp)
            setArray(temp);
        }

    }

    if (array.lenth < 1)
        return <></>

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {show && <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={6}>Vijeta Calling Reviwes &nbsp;&nbsp;&nbsp;<b>Count:-</b>{array.length}</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Class</th>
                                    <th>Scholarship</th>
                                    <th>View Conversation <br />
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={category}
                                                onChange={handleCategory}
                                                label="Category"
                                                style={{ color: '#000' }}
                                            >
                                                <MenuItem value="">
                                                    <em>Select Category</em>
                                                </MenuItem>
                                                <MenuItem value="Admission Done">Admission Done</MenuItem>
                                                <MenuItem value="Already Enrolled">Already Enrolled</MenuItem>
                                                <MenuItem value="Disqualified">Disqualified</MenuItem>
                                                <MenuItem value="Follow Up">Follow Up</MenuItem>
                                                <MenuItem value="Form Filled">Form Filled</MenuItem>
                                                <MenuItem value="Financial Issue">Financial Issue</MenuItem>
                                                <MenuItem value="Interested">Interested</MenuItem>
                                                <MenuItem value="Joined other Institute">Joined other Institute</MenuItem>
                                                <MenuItem value="Not Able To Connect">Not Able To Connect</MenuItem>
                                                <MenuItem value="Will Visit Campus">Will Visit Campus</MenuItem>
                                                <MenuItem value="Will Apply Online">Will Apply Online</MenuItem>
                                                <MenuItem value="Will Deposit Course Fee">Will Deposit Course Fee</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </th>
                                    <th>Admin Name  <br />

                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-outlined-label">Admin</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={value}
                                                onChange={handleAdmin}
                                                label="Admin"
                                                style={{ color: '#000' }}
                                            >
                                                <MenuItem value="">
                                                    <em>Select Admin</em>
                                                </MenuItem>



                                                <MenuItem value='Akansha Sharma'>Akansha Sharma</MenuItem>
                                                <MenuItem value='Bhanu Pratap'>Bhanu Pratap</MenuItem>
                                                <MenuItem value='Khushboo Dubey'>Khushboo Dubey</MenuItem>
                                                <MenuItem value='Rekha Sharma'>Rekha Sharma</MenuItem>


                                            </Select>
                                        </FormControl>

                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {array.map((ele, i) => (

                                    <>

                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.classs}</td>
                                            <td>{ele.scholarship}%</td>
                                            <td>
                                                <ViewComp orderid={ele.orderid} loader={loader} snackBar={snackBar} />
                                                <br /><br />
                                                <Typography variant='h6'><b>Latest Remark:- </b>{ele.last_remark}</Typography>
                                            </td>
                                            <td>{ele.admin}</td>
                                        </tr>

                                    </>

                                ))}
                            </tbody>
                        </table>
                    </Paper>}
                </Grid>
            </Grid>
        </Container>
    )
}

export default VijetaCallingReviews


const ViewComp = ({ orderid, loader, snackBar }) => {

    const [array, setArray] = useState([]);
    const hide = () => {
        setArray([]);
    }

    const handleClick = async (orderid) => {
        try {
            loader.show();

            const { data } = await axios({
                url: api_url + 'view-conversation',
                method: 'post',
                responseType: 'json',
                data: {
                    orderid: orderid
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })
            console.log(data);
            setArray(data.array)

            if (data.array.length < 1) {
                snackBar.show("No Conversation Found");
            }


        } catch (e) {
            snackBar.show(handle_error(e));
        } finally {
            loader.hide();
        }

    }


    return (<>

        <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(orderid)}>All Remarks</Button>

        {array && array.length > 0 && <ModalComp hide={hide}>
            <div style={{ fontFamily: 'Arial' }}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={3}>Conversation</th>
                        </tr>
                        <tr>
                            <th>Remark</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {array.map((n, i) => {
                            return (
                                <tr style={{ fontSize: '13px' }}>
                                    <td>{n.text}</td>
                                    <td>{n.date}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>


            </div>


        </ModalComp>

        }

    </>)
}

const ModalComp = ({ children, hide }) => {

    return (
        <div>
            <Dialog open
                onClose={() => { hide() }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>

        </div>
    )
}