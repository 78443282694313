import React,{useState,useEffect,useContext} from 'react';
 
import {api_url,rest_headers} from '../digital-classroom/config'
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { Select } from "../../../../../utils/select";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../utils/form-layout-admin";
import {STUDENT_TOKEN_NAME} from "../../../../../config"
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
 import List from '@material-ui/core/List';
import PublishIcon from '@material-ui/icons/Publish';
 
 
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,

  Typography,
} from "@material-ui/core";
import _ from 'lodash'
 
import Divider from '@material-ui/core/Divider';
const axios = require('axios');


export default (props) => {
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
		  {...props}
    />
  );
};
const Comp = ({ loader, snackBar,...props })=>{
	 
	const [show,setShow]=useState(false);
    const [res, setRes] = useState([]);
	 useEffect(()=>{
		fetch() 
	 },[])
  
  const fetch=async()=>{
	  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/my-tickets',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			STUDENTRELIABLETOKEN:localStorage.getItem(STUDENT_TOKEN_NAME),
			STUDENTROLLNO:props.roll_no || null
}
      }) 
	    
	 
 
 setRes( data.list)
 
      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  } 
    
 return(
  <div><br/><br/>
 
   <RaiseTicket roll_no={props.roll_no} fetch={fetch} snackBar={snackBar} loader={loader}/><br/><br/>
 <Card><CardContent>
   <Typography variant="h3" align='center'>My Tickets</Typography>
   </CardContent></Card><br/>
  {res && res.length>0 && 
  
  res.map((n,i)=>{
	  return  <><CardComp {...n} snackBar={snackBar} loader={loader}/><br/></>
  })
  }</div>
)   
} 

export const CardComp=({id,rollno,query,status,image_url,category,remark,reply_image_url,dt,dt_closed,snackBar,loader,admin})=>{
	
	const [stuData,setStuData]=useState(null);
	 const fetch=async ()=>{
	  try{
		 
		   loader.show();
		    
const  {data}=await axios({
        url: api_url+'/admin-get-student-details',
        method: 'post',
		responseType: 'json',
		data:{
			roll_no:rollno 
			 
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	  if(data.error){
		   snackBar.show(data.message);
		   return
	  }
	   const data2=data.message.stu_data;
	   let name;
	   setStuData(data2[0]); 
	  
       
    } catch (e) {
      snackBar.show(e.message);
    } finally {
      loader.hide();
    }
		  
		  
  }
 
	
	return(
	<Card >
      <CardContent>
       
     
    
        <Typography variant="h5" component="h2">
        <strong>Ticket ID:</strong> {id}
        </Typography><br/>
		{admin && <><Typography variant="h5" component="h2">
        <strong>Roll No:</strong> {rollno}
        </Typography><br/></>
		}
		 
		{admin && <button onClick={()=>fetch()}>Fetch Student Details</button> }
			{stuData && <p style={{fontFamily:'Arial'}}><br/>
			Name:{stuData.name}<br/>
			Mobile1:{stuData.mobile1}<br/>
			Mobile2:{stuData.mobile2}<br/>
			Course:{stuData.coursetitle}<br/>
			Fee Status: {stuData.fee_status}<br/>
			Session:{stuData.session}<br/>
			Batch:{stuData.batch}<br/>
			
			</p>}
			<br/>
		<Typography variant="h5" component="h2">
        <strong>Category:</strong> {category}
        </Typography><br/>
		<Typography variant="h5" component="h2">
        <strong>Summary:</strong> {query}
        </Typography><br/>
		
		 
	 
		{
			image_url && <>
		<Typography variant="h5" component="h2">
<strong>Image:</strong>  <Button target="_blank" component="a" href={image_url} color="secondary">view image</Button>
        </Typography> <br/></>
		}
		
		<Typography variant="h5" component="h2">
        <strong>Status: </strong> {status}
        </Typography><br/>
		<Typography variant="h5" component="h2">
        <strong>Date:</strong> {dt}
        </Typography><br/>
         
		 
		 {
			status=='Closed' && <> 
		<Typography variant="h5" component="h2">
        <strong>Closed At:</strong> {dt_closed}
        </Typography><br/>
		<Typography variant="h5" component="h2">
        <strong>Answer:</strong> {remark}
        </Typography><br/>
		{
			reply_image_url  && <> 
		<Typography variant="h5" component="h2">
        <strong>Resolution Image:</strong>  <Button target="_blank" component="a" href={reply_image_url} color="secondary">view image</Button>
        </Typography> 
		 
		</>
		}
		</>
		}
		 
		 
		  
		  
		  {admin && status=='Pending' && <CloseForm  id={id} snackBar={snackBar} loader={loader}/>}
		 
         
      </CardContent>
	  </Card>
	)
}



export const CloseForm=({snackBar,loader,id})=>{
	const [image,setImage]=useState('');
	const [imagename,setName]=useState('');
	const [remark,setRemark]=useState(`Dear Student
Greetings from Reliable!!!

#######

Feel free to contact 07442665544 (08 AM to 08 PM)

Team Reliable`)
	 
	const handleClick=async()=>{
		try{
		if(!remark){
snackBar.show('Please enter Remark')		
		return false 
		}
	 
		
		//=======================================
		loader.show();
 const  {data:data2}=await axios({
        url: api_url+'/close-ticket',
        method: 'post',
		responseType: 'json',
		data:{
			image_url:image, 
			remark:remark,
			id:id
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	  if(data2.success=='ok')
	  {
		  snackBar.show(data2.message);
		  setName('')
		  setImage('')
		  setRemark('')
		  
		  setTimeout(()=>{
			  window.location.reload()
		  },3000)
	  }else{
		  snackBar.show(data2.message);
	  }
 
		//=======================================
		
		}
catch(e){
	snackBar.show(e.message)
		 }
		 finally{
			 loader.hide()
		 }
		
	}
	 
	
	  const handleUpload = async(event) => {
		   
		  try{
		  const {name}=event.target.files[0];
	 
		  if(name.indexOf('jpg')<0){
				
			 snackBar.show('please choose jpg file')
			 return false
		  }
		  loader.show()
		  const data = new FormData() ;
		    data.append('file1', event.target.files[0]);
			setName(name);
 const {data:res}= await axios.post(`https://reliablekota.el.r.appspot.com/upload-queries`, data)
 
  setImage(res.url)
  
 
		 }
		 catch(e){
			  
			 snackBar.show(e.message)
		 }
		 finally{
			 loader.hide()
		 }
        
    }
	
	
	return(
	 <div>
    
	<TextField
          id="outlined-multiline-static"
          label="Write your Remark here"
          multiline
          rows={4}
		  fullWidth
		 
		  onChange={(e)=>setRemark(e.target.value)}
          value={remark}
          variant="outlined"
        /><br/>
		<br/>
		<input type="file" id="contained-button-file3" style={{display:'none'}} onChange={handleUpload} accept="image/jpeg" />
      <label htmlFor="contained-button-file3">
        <Button variant="outined"
 startIcon={<PublishIcon />}
		   component="span">
          Upload Screenshot
        </Button>
		{imagename}
      </label>
		
		<br/><br/>
		 <Button onClick={handleClick} variant="contained" color="primary">
  Close
</Button>
		</div>
	)
}





const category_list=[
{key:'Attendance',value:'Attendance'},
{key:'Batch Change',value:'Batch Change'},
{key:'Dress',value:'Dress'},
{key:'Fee',value:'Fee'},
{key:'I-Card',value:'I-Card'},
{key:'Live Classes',value:'Live Classes'},
{key:'Recorded Video Lectures',value:'Recorded Video Lectures'},
{key:'Exam: Result',value:'Exam: Result'},
{key:'Exam: Login',value:'Exam: Login'},
{key:'Study Material/DPP',value:'Study Material/DPP'},
{key:'WhatsApp: number change',value:'Whatsapp: number change'},
{key:'WhatsApp: not added in group',value:'WhatsApp: not added in group'}
]




export const RaiseTicket=({snackBar,loader,fetch,roll_no})=>{
	const [image,setImage]=useState('');
	const [imagename,setName]=useState('');
	const [query,setQuery]=useState('')
	const [category,setCategory]=useState('');
	
	const handleClick=async()=>{
		try{
		if(!category){
snackBar.show('Please choose the category')		
		return false 
		}
		if(!query){
snackBar.show('Enter something in the textbox')		
		return false 
		}
		
		//=======================================
		loader.show();
 const  {data:data2}=await axios({
        url: api_url+'/raise-ticket',
        method: 'post',
		responseType: 'json',
		data:{
			image_url:image, 
			query:query,
			category:category
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			STUDENTRELIABLETOKEN:localStorage.getItem(STUDENT_TOKEN_NAME),
			STUDENTROLLNO:roll_no || null
}
      }) 
	  if(data2.success=='ok')
	  {
		  snackBar.show(data2.message);
		  setName('')
		  setImage('')
		  setQuery('')
		  setCategory('')
		  setTimeout(()=>{
			  fetch()
		  },3000)
	  }else{
		  snackBar.show(data2.message);
	  }
 
		//=======================================
		
		}
catch(e){
	snackBar.show(e.message)
		 }
		 finally{
			 loader.hide()
		 }
		
	}
	 
	
	  const handleUpload = async(event) => {
		   
		  try{
		  const {name}=event.target.files[0];
	 
		  if(name.indexOf('jpg')<0){
				
			 snackBar.show('please choose jpg file')
			 return false
		  }
		  loader.show()
		  const data = new FormData() ;
		    data.append('file1', event.target.files[0]);
			setName(name);
 const {data:res}= await axios.post(`https://reliablekota.el.r.appspot.com/upload-queries`, data)
 
  setImage(res.url)
  
 
		 }
		 catch(e){
			  
			 snackBar.show(e.message)
		 }
		 finally{
			 loader.hide()
		 }
        
    }
	
	
	return(
	<Card>
	<CardContent>
   <Typography gutterBottom variant="h5" component="h2">
            Raise Ticket
          </Typography><br/>
		  <Select
      label={"I have a issue related to"}
      value={category}
      name={'category'}
      onChange={(e)=>setCategory(e.target.value)}
      array={category_list}
	  
    /><br/><br/>
	<TextField
          id="outlined-multiline-static"
          label="Describe your issue here *"
          multiline
          rows={4}
		  fullWidth
		 
		  onChange={(e)=>setQuery(e.target.value)}
          value={query}
          variant="outlined"
        /><br/>
		<br/>
		<input type="file" id="contained-button-file2" style={{display:'none'}} onChange={handleUpload} accept="image/jpeg" />
      <label htmlFor="contained-button-file2">
        <Button variant="outined"
 startIcon={<PublishIcon />}
		   component="span">
          Upload Screenshot
        </Button>
		{imagename}
      </label>
		
		<br/><br/>
		 <Button onClick={handleClick} variant="contained" color="primary">
  SUBMIT
</Button>
		</CardContent>
		</Card>
	)
}