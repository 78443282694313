import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core'

const Saper = () => {
    // useEffect(() => {
    //     let token = localStorage.getItem("rollno");
    //     console.log(token);
    //     window.open(`https://api-dot-reformexam.el.r.appspot.com/saper/get-data?rollno=${token}&type=rollno&mode=print`, '_blank');
    // }, [])

    const handleSession = async () => {
        let token = localStorage.getItem("rollno");
        console.log(token);
        window.open(`https://api-dot-reformexam.el.r.appspot.com/saper/get-data?rollno=${token}&type=rollno&mode=print`, '_blank');
    }

    const handleSessionYear = async () => {
        let token = localStorage.getItem("rollno");
        console.log(token);
        window.open(`https://api-dot-reformexam.el.r.appspot.com/saper-2023/get-data?rollno=${token}&type=rollno&mode=print`, '_blank');
    }
    const handleSessionYear202425 = async () => {
        let token = localStorage.getItem("rollno");
        console.log(token);
        window.open(`https://api-dot-reformexam.el.r.appspot.com/saper-2024/get-data?rollno=${token}&type=rollno&mode=print`, '_blank');
    }

    return (
        <>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleSession}>View Result of Session 2022-23</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleSessionYear}>View Result of Session 2023-24</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleSessionYear202425}>View Result of Session 2024-25</Button>
                </Grid>
            </Grid>

        </>
    )
}

export default Saper
