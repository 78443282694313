import React from "react";
import { SubQuestionInput } from "./subquestion-input";
import { InputRich } from "../input_rich";

const tdstyle = { verticalAlign: "top", padding: 10 };
export const QuestionInput = ({
  question_type,
  handleSubQuestionType,
  question,
  handleQuestionContent,
  handleOptionInput,
  handleOptionValueChange,
  handleSubQuestionContent,
  image_upload_url,
}) => {
  return (
    <React.Fragment>
      {question_type === "multiple" && (
        <QuestionContent
          english_content={question.english_content}
          hindi_content={question.hindi_content}
          onChange={handleQuestionContent}
          image_upload_url={image_upload_url}
        />
      )}

      {question &&
        question.subquestion.map((n, i) => {
          return (
            <SubQuestionInput
              question_type={question_type}
              handleOptionValueChange={handleOptionValueChange}
              onChange={handleOptionInput}
              handleSubQuestionContent={handleSubQuestionContent}
              handleSubQuestionType={handleSubQuestionType}
              subquestion={n}
              i={i}
              image_upload_url={image_upload_url}
            />
          );
        })}
    </React.Fragment>
  );
};

const QuestionContent = ({
  onChange,
  english_content,
  hindi_content,
  image_upload_url,
}) => {
  return (
    <React.Fragment>
      <table style={{ borderCollapse: "collapse", width: "100%" }} border="1">
        <tr>
          <td rowSpan="2" style={tdstyle}>
            Common Question Content
          </td>
          <td style={tdstyle}>English</td>
          <td style={tdstyle}>Hindi</td>
        </tr>
        <tr>
          <td style={tdstyle}>
            <InputRich
              onChange={(e) => onChange(e, "en")}
              value={english_content}
              image_upload_url={image_upload_url}
            />
          </td>
          <td style={tdstyle}>
            <InputRich
              onChange={(e) => onChange(e, "hi")}
              value={hindi_content}
              image_upload_url={image_upload_url}
            />
          </td>
        </tr>
      </table>
      <br />
      <br />
    </React.Fragment>
  );
};
