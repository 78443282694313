import React,{useState} from 'react';
import {Grid,Container} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormLayoutchildsubject from "../../../../../../utils/form-layout-admin";
import ContactTable from './contact-table'
export default({array,lead_id,setShow,done})=>{
 
const [contact_id,setContactId]=useState(0);
 
	return(
	    <Container style={{maxWidth:700}}>
        <>
		{(array && array.length>0)?<ContactTable array={array}/>:<p>No interactions found</p>}

		
		</>
		
			<br/>
		 
	
		</Container>
		
	)
}

 