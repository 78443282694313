import React from 'react'
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import { Container, TextField, Grid, Button, Paper } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import styles from '../reusable-calling-code-project/table.module.css';

const ViewStudentScholarship = () => {

    const loader = useContext(LoaderContext);
    const snackBar = useContext(SnackBarContext);

    const [roll_no, setRollNo] = useState('');
    const [res, setRes] = useState([]);
    const [show, setShow] = useState(false)

    const api = 'https://api-dot-reformexam.el.r.appspot.com/reliablekotaapp/'



    const handleClick = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api + 'view-scholarship',
                method: 'post',
                responseType: 'json',
                data: {
                    roll_no: roll_no,
                }
            })

            if (obj.success === 'ok') {
                setRes(obj.res);
                setShow(true);
            }
            if (obj.success === 'false') {
                snackBar.show("No Scholarship on this Roll No.")
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide();
        }
    }




    return (
        <>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <FormLayoutAdmin title="View Student Scholarship">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    type='text'
                                    label="Roll No"
                                    value={roll_no}
                                    placeholder="Enter Roll No"
                                    onChange={(e) => setRollNo(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullwidth onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
                            </Grid>
                        </Grid>
                    </FormLayoutAdmin>
                </Grid>

                {show && <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={7}>Student Scholarship</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Roll No</th>
                                    <th>Father's Name</th>
                                    <th>Class</th>
                                    <th>Course Code</th>
                                    <th>Scholarship</th>
                                </tr>
                            </thead>
                            <tbody>
                                {res.map((ele, i) => (
                                    <>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.fno}</td>
                                            <td>{ele.fname}</td>
                                            <td>{ele.classs}</td>
                                            <td>{ele.course}</td>
                                            <td>{ele.scholarship}%</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>}

            </Grid>



        </>
    )
}

export default ViewStudentScholarship
