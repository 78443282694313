import { gql } from "@apollo/client";

export const faculty_list_query_gql = gql`
  {
    faculty_list {
      key: id
      value: name
      dept
    }
  }
`;
