import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";
import { useQuery } from "@apollo/client";
import { latest_news_gql } from "../../../../../main/student/gql/latest_news_gql";
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const common = {
  height: "8px",

  borderRadius: "8px",
};
const StyleSheet = {
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  item: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  circle: {
    height: 64,
    width: 64,
    borderRadius: "50%",
  },
  line1: {
    ...common,
    width: 200,
  },
  line2: {
    ...common,
    width: 140,
  },
  header: {
    ...common,
    width: 96,
  },
  divider: {
    height: 20,
  },
  divider2: {
    height: 10,
  },
  left: {
    borderRadius: 64,
  },
};

export default ({ is_home_component }) => {
  return <StyledApp is_home_component={is_home_component} />;
};

const App = ({ classes, is_home_component }) => {
  useEffect(() => {
    refetch();
  }, []);
  const { loading, error, data, refetch } = useQuery(latest_news_gql);

  if (loading)
    return (
      <>
        <ShimmerHeader />
        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />
        {!is_home_component && (
          <div style={{ marginTop: 40 }}>
            <ShimmerHeader />
            <ShimmerRow classes={classes} />

            <ShimmerRow classes={classes} />

            <ShimmerRow classes={classes} />
          </div>
        )}
      </>
    );
  if (error) return <GqlErrorComp error={error} />;

  const r = data.latest_news_list;

  return <Comp r={r} is_home_component={is_home_component} />;
};

class Comp extends React.Component {
  render() {
    const { r, is_home_component } = this.props;
    return (
      <Card>
        <CardContent>
          <Typography variant="h4">Latest@Reliable</Typography>
          <br />
          <Slider r={r} />
        </CardContent>
      </Card>
    );
  }
}

const ShimmerHeader = () => {
  return (
    <Shimmer>
      <div
        style={{ marginTop: 30, width: 100, borderRadius: 64, height: 10 }}
      ></div>
    </Shimmer>
  );
};
const ShimmerRow = ({ classes }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
      <Shimmer>
        <div
          style={{
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            width: 75,
            height: 55,
          }}
        ></div>
      </Shimmer>

      <div style={{ justifyContent: "space-between", marginLeft: 30 }}>
        <div
          style={{
            marginTop: 10,
            width: 200,
            borderRadius: 64,
          }}
        >
          <Shimmer>
            <div className={classes.line1}></div>
          </Shimmer>
        </div>

        <div
          style={{
            marginTop: 10,
            width: 140,
            borderRadius: 64,
            height: 10,
          }}
        >
          <Shimmer>
            <div className={classes.line2}></div>
          </Shimmer>
        </div>
      </div>
    </div>
  );
};
const StyledApp = injectSheet(StyleSheet)(App);

const Slider = ({ r }) => {
  return (
    <Carousel centerMode showArrows={true} showThumbs={false}>
      {r &&
        r.length > 0 &&
        r.map(({ title, image, url }) => {
          return (
            <div>
              <img src={image} />
              <p className="legend">{title}</p>
            </div>
          );
        })}
    </Carousel>
  );
};
