import { gql } from "@apollo/client";

export const live_class_schedule_gql = gql`
  {
    live_class {
      date
      info {
        id
		eid
        subject: childsubject {
          name
        }
        start_time
        end_time
        faculty {
          name
        }
        meeting_no
        meeting_pwd
        disabled
      }
    }
  }
`;
