import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import JoinMeetingButton from "./join-meeting-button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default ({
  r,
  user_data,
  is_home_component,
  mark_attendance_mutation,
  exec,
}) => {
  const classes = useStyles();
  const props = {
    classes,
    mark_attendance_mutation,
    exec,
    user_data,
  };
  return (
    <>
      {is_home_component ? (
        <Render {...props} r={r[0]} />
      ) : (
        r.map((t, i) => {
          return <Render {...props} r={t} />;
        })
      )}
    </>
  );
};

const Render = ({ r, classes, mark_attendance_mutation, exec, user_data }) => {
  if (r.info.length < 0)
	  return(
  <></>
  )
    return (
      <>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {r.date}
            </ListSubheader>
          }
          className={classes.root}
        >
          {r.info &&
            r.info.map((t, i) => {
              return (
                <JoinMeetingButton
                  r={t}
                  user_data={user_data}
                  key={i}
                  mark_attendance_mutation={mark_attendance_mutation}
                  exec={exec}
                />
              );
            })}
        </List>
        <Divider />
      </>
    );
   
};
