import React from 'react';
import CreateVideo from '../create-video/index.js';

const CreateVideoJunior = () => {

    console.log('junior')

    return (
        <div>
            <CreateVideo division="Junior" />
        </div>
    )
}

export default CreateVideoJunior;
