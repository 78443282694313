import React from "react";
import { Select } from "../../select";
export const SelectNoOfOption = ({ onChange, value }) => {
  const array = [];
  for (let i = 0; i < 6; i++) {
    array.push({ key: i + 1, value: i + 1 });
  }
  return (
    <React.Fragment>
      {" "}
      <Select
        value={value}
        array={array}
        label="No of Options"
        onChange={onChange}
      />
      <br /> <br />
    </React.Fragment>
  );
};
