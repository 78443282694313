import React from "react";
import FormLayoutAdmin from "../../../../../../utils/form-layout-admin";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import { CSVReader } from 'react-papaparse';
import axios from 'axios';
import ViewTest from "../view-test";
import { useNavigate } from "react-router-dom";
import { Routes, Route, useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Alert from "@material-ui/core/Alert";
import { api_endpoint } from '../config';
import { LoaderContext } from "../../../../../../context/LoaderContext";
import moment from 'moment';
import GetResult from "../../../../../student/app/srceens/result-portal/result/index.js";

export default function ViewStudentResult() {

    const loader = useContext(LoaderContext);

    const [rollno, setRollNo] = useState();
    const [show, setShow] = useState(false);

    const handleClick = async () => {
        try {
            loader.show();
            setShow(true);
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    return (
        <>
            <FormLayoutAdmin title="View Student Result">
                <TextField
                    type="tel"
                    label="Student Roll Number"
                    value={rollno}
                    placeholder="Enter Student Roll Number"
                    onChange={(e) => setRollNo(e.target.value)}
                />
                <br />
                <br />
                <Button fullwidth onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
            </FormLayoutAdmin>

            {
                (show)
                    ? <GetResult rollno={rollno} admin />
                    : <></>
            }
        </>

    )

}