import React from 'react';
import { useState } from 'react';
import { Button } from '@material-ui/core';

export default function UploadComp(props) {

    const handleUpdate = () => {
        props.handleClick({ id: props.id, test: props.test, index: props.index, list: props.list, listed: props.listed });

    }

    return (
        <>
            <Button onClick={handleUpdate} style={{ border: '1px solid purple', borderStyle: 'dashed', color: '#001A23' }}>Upload</Button>
        </>
    )
}