import React from "react";
import { Select } from "../../../../utils/select";
import { batch_quiz_list_query_gql } from "../../gql/query/batch-quiz-list-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({ batch_id, label, value, name, onChange }) => {
  if (!batch_id) return <span></span>;
  const { loading, error, data } = useQuery(batch_quiz_list_query_gql, {
    variables: {
        batch_id: batch_id,
    },
  });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { batch_quiz_list } = data;
  const arr = [];
  
  for(let i in batch_quiz_list){
    arr.push({ key:batch_quiz_list[i].id, value: batch_quiz_list[i].quiz.name + " - " + batch_quiz_list[i].date  })
  }
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={arr}
    />
  );
};
