import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
// import { makeStyles } from '@material-ui/core/styles';
import Profile from '../lead-data-calling/profile'
import styles from '../reusable-calling-code-project/table.module.css'

import { Button, Card, Grid, ServerStyleSheets, Typography, useMediaQuery, TextField, Paper } from '@material-ui/core';
import { filter } from 'lodash';
const axios = require('axios');
const _ = require("lodash");
export default (props) => {
    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);
    return (
        <Comp
            snackBar={snackBar}
            loader={loader}
            {...props}
        />
    );
};


const Comp = ({ loader, snackBar, ...props }) => {

    // const classes = useStyles();

    const [show, setShow] = useState(false);
    const [res, setRes] = useState(null);
    const [sorted, setSorted] = useState(null);
    const [datesorted, setDateSorted] = useState(null);
    const [display, setDisplay] = useState('ASC');
    const [test, setTest] = useState([]);
    const [init, setInit] = useState(null);
    const [sourcef, setSourceF] = useState([]);
    const [cityf, setCityF] = useState([]);
    const [untouch, setUntouch] = useState();
    const [utmf, setUtm] = useState([]);
    const [touch, setTouched] = useState();
    const [classf, setClassF] = useState([]);
    const [adminf, setAdminF] = useState([]);
    const [source_filter, setSourceFilter] = useState('null');
    const [fee_filter, setFeeFilter] = useState('null');
    const [test_date_filter, setTestDateFilter] = useState('null');
    const [conversation_filter, setConversationFilter] = useState('null');
    const [callback_filter, setCallbackFilter] = useState('null');
    const [city_filter, setCityFilter] = useState('null');
    const [ispaid_filter, setIsPaid] = useState('null');
    const [utm_filter, setUtmFilter] = useState('null');
    const [class_filter, setClassFilter] = useState('null');
    const [mobile_number, setMobileNo] = useState();
    const [form_number, setFormNo] = useState();
    const [state, setState] = useState();
    const [filter_name, setFilterName] = useState();
    const refresh = (filter) => {
        fetch(filter);
    }
    const manager = 'manager';


    const handleSort = () => {
        const tmp = res;
        if (sorted === null) {
            const value = [...tmp].sort((a, b) => b.ct - a.ct);
            setRes(value);
            setDisplay('ASC');
            setSorted('asc');
        }
        if (sorted == 'asc') {
            const value = [...tmp].sort((a, b) => a.ct - b.ct);
            setRes(value);
            setDisplay('DESC')
            setSorted(null);
        }
    }

    const handleDateSort = () => {
        const tmp = res;
        if (datesorted === null) {
            const value = [...tmp].sort((a, b) => new Date(b.td) - new Date(a.td));
            setRes(value);
            setDisplay('ASC');
            setDateSorted('asc');
        }
        if (datesorted == 'asc') {
            const value = [...tmp].sort((a, b) => new Date(a.td) - new Date(b.td));
            setRes(value);
            setDisplay('DESC')
            setDateSorted(null);
        }
    }


    const handleReliableMedia = () => {
        const filter = 'reliable_media';
        setState(filter);
        setFilterName("Reliable Media")
        fetch(filter);
    }


    const handleReliableSamvaad = () => {
        const filter = 'samvaad';
        setState(filter);
        setFilterName("Samvaad")
        fetch(filter);
    }


    const handleMarchRegistration = () => {
        const filter = 'march_null_utm';
        setState(filter);
        setFilterName("March Registration")
        fetch(filter);
    }

    const handleAprilRegistration = () => {
        const filter = 'april_null_utm';
        setState(filter);
        setFilterName(" April Registration")
        fetch(filter);
    }
    const handleMayRegistration = () => {
        const filter = 'may_null_utm';
        setState(filter);
        setFilterName(" May Registration")
        fetch(filter);
    }

    const handleJuneRegistration = () => {
        const filter = 'june_null_utm';
        setState(filter);
        setFilterName("June Registration")
        fetch(filter);
    }

    const handleJulyRegistration = () => {
        const filter = 'july_null_utm';
        setState(filter);
        setFilterName("July Registration")
        fetch(filter);
    }



    const handleAllWalkin = () => {
        const filter = 'walkin';
        setState(filter);
        setFilterName("Complete Walkin")
        fetch(filter);
    }
    const handleWeekWalkin = () => {
        const filter = 'week_walkin';
        setState(filter);
        setFilterName("Week-Wise Walkin")
        fetch(filter);
    }
    const handleYestWalkin = () => {
        const filter = 'yesterday_walkin';
        setState(filter);
        setFilterName("Yesterday Walkin")
        fetch(filter);
    }
    const handleTodayWalkin = () => {
        const filter = 'today_walkin';
        setState(filter);
        setFilterName("Today's Walkin")
        fetch(filter);
    }

    const handleRnet2022 = () => {
        const filter = 'rnet2022_23';
        setState(filter);
        setFilterName("RNet 2022-23")
        fetch(filter);
    }

    // const handlePastDatesRnetDate = () => {
    //     const filter = 'past_dates__rnet_fee_paid';
    //     setState(filter);
    //     setFilterName("Past RNeT Filter")
    //     fetch(filter);
    // }
    const handleYRnetDate = () => {
        const filter = 'ffy_reliable_rnet';
        setState(filter);
        setFilterName("16th June RNeT")
        fetch(filter);
    }
    const handleNRnetDate = () => {
        const filter = 'ffn_reliable_rnet';
        setState(filter);
        setFilterName("Not Filled Form RNeT")
        fetch(filter);
    }

    const handleUCRnetDate = () => {
        const filter = 'uc_reliable_rnet';
        setState(filter);
        setFilterName("23rd June RNeT")
        fetch(filter);
    }



    const handleMarchCallback = () => {
        const filter = 'march_website_callback';
        setState(filter);
        setFilterName("March Callback")
        fetch(filter);
    }

    const handleMayCallback = () => {
        const filter = 'may_website_callback';
        setState(filter);
        setFilterName("May Callback")
        fetch(filter);
    }
    const handleJuneCallback = () => {
        const filter = 'june_website_callback';
        setState(filter);
        setFilterName("June Callback")
        fetch(filter);
    }
    const handleJulyCallback = () => {
        const filter = 'july_website_callback';
        setState(filter);
        setFilterName("July Callback")
        fetch(filter);
    }
    const handleAprilCallback = () => {
        const filter = 'april_website_callback';
        setState(filter);
        setFilterName("April Callback")
        fetch(filter);
    }

    const handleFebMarchInbound = () => {
        const filter = 'feb_march_inbound';
        setState(filter);
        setFilterName("Feb-March Inbound")
        fetch(filter);
    }
    const handleAprilMayInbound = () => {
        const filter = 'april_may_inbound';
        setState(filter);
        setFilterName("April - May Inbound")
        fetch(filter);
    }
    const handleJuneJulyInbound = () => {
        const filter = 'june_july_inbound';
        setState(filter);
        setFilterName("June - July Inbound")
        fetch(filter);
    }


    const handleAllBDWalkin = () => {
        const filter = 'bd_walkin';
        setState(filter);
        setFilterName("Total Walkin BD")
        fetch(filter);
    }
    const handleWeekBDWalkin = () => {
        const filter = 'week_bd_walkin';
        setState(filter);
        setFilterName("Week-Wise Walkin BD")
        fetch(filter);
    }
    const handleYestBDWalkin = () => {
        const filter = 'yesterday_bd_walkin';
        setState(filter);
        setFilterName("Yesterday Walkin BD")
        fetch(filter);
    }
    const handleTodayBDWalkin = () => {
        const filter = 'today_bd_walkin';
        setState(filter);
        setFilterName("Today's Walkin BD")
        fetch(filter);
    }

    const handleSearchMobile = () => {
        if (mobile_number.length != 10) {
            alert('Enter 10 digit mobile number');
        }
        else {
            const filter = 'leadfinder';
            setFilterName("Lead Finder");
            fetch(filter, mobile_number);
        }
    }

    const handleAllFbData = () => {
        const filter = 'fb_leads';
        setState(filter);
        setFilterName("Total FB Leads");
        fetch(filter);
    }

    const handleWeekFbData = () => {
        const filter = 'week_fb_leads';
        setState(filter);
        setFilterName("Week-Wise FB Leads");
        fetch(filter);
    }

    // const handleTodayFbData = () => {
    //     const filter = 'today_fb_leads';
    //     setState(filter);
    //     setFilterName("Today's FB Leads");
    //     fetch(filter);
    // }

    const handleSearchFno = () => {
        if (form_number.length != 8) {
            alert('Enter 8 digit form number');
        }
        else {
            const filter = 'leadformfinder';
            setFilterName("Lead Finder");
            fetch(filter, form_number);
        }
    }

    const handleMstResult = () => {
        const filter = 'mstresult';
        setState(filter);
        setFilterName("MST Data");
        fetch(filter);
    }


    const handleJeeAdvanced1 = () => {
        const filter = 'jeeadvanced1';
        setState(filter);
        setFilterName("Jee Advanced 1st half");
        fetch(filter);
    }
    const handleJeeAdvanced2 = () => {
        const filter = 'jeeadvanced2';
        setState(filter);
        setFilterName("Jee Advanced 2nd Half");
        fetch(filter);
    }



    const fetch = async (filter, mobile_number) => {
        try {
            loader.show();
            const { data } = await axios({
                url: api_url + '/lead-data-calling/calling-data',
                method: 'post',
                responseType: 'json',
                data: {
                    manager: manager,
                    filter: filter,
                    val: mobile_number,
                },
                ...rest_headers
            })
            console.log(data);

            if (data.Success === 'ok') {

                let i, j, l_len, array, tmp, len = data.arr.length, total, untouched = 0;
                let a = [], b, c = [], d = [], e = [], f = [], g = [];


                for (i = 0; i < len; i++) {
                    l_len = data.arr[i].list.length;
                    for (j = 0; j < l_len; j++) {
                        total = data.arr[i].list[j];
                        if (total.SCLASS == 'VI') {
                            data.arr[i].list[j].SCLASS = 6;
                        }
                        if (total.SCLASS == 'VII') {
                            data.arr[i].list[j].SCLASS = 7;
                        }
                        if (total.SCLASS == 'VIII') {
                            data.arr[i].list[j].SCLASS = 8;
                        }
                        if (total.SCLASS == 'IX') {
                            data.arr[i].list[j].SCLASS = 9;
                        }
                        if (total.SCLASS == 'X') {
                            data.arr[i].list[j].SCLASS = 10;
                        }
                        if (total.SCLASS == 'XI') {
                            data.arr[i].list[j].SCLASS = 11;
                        }
                        if (total.SCLASS == 'XII') {
                            data.arr[i].list[j].SCLASS = 12;
                        }
                        if (total.SCLASS == 'XIII' || total.SCLASS == 'XII PASSED' || total.SCLASS == 'XII Passed') {
                            data.arr[i].list[j].SCLASS = 13;
                        }
                    }
                }

                setRes(data.arr);
                setInit(data.arr);

                for (i = 0; i < len; i++) {
                    l_len = data.arr[i].list.length
                    for (j = 0; j < l_len; j++) {
                        b = data.arr[i].list[j];
                        a.push({ test: b[`TEST DATE`] });
                        c.push({ source: b['source'] })
                        d.push({ city: b['CITY'] })
                        e.push({ utm: b['utm_source'] })
                        f.push({ classs: b['SCLASS'] })
                        g.push({ admin_name: b['admin_name'] })
                    }
                }

                let tests = _.uniqBy(a, 'test');
                tests = _.sortBy(tests, 'test');
                let sources = _.uniqBy(c, 'source')
                sources = _.sortBy(sources, 'source');
                let citys = _.uniqBy(d, 'city')
                citys = _.sortBy(citys, 'city');
                let utms = _.uniqBy(e, 'utm')
                utms = _.sortBy(utms, 'utm');
                let classes = _.uniqBy(f, 'classs');
                classes = _.sortBy(classes, 'classs')
                let admins = _.uniqBy(g, 'admin_name');
                admins = _.sortBy(admins, 'admin_name');
                // console.log(tests);
                setTest(tests);
                setSourceF(sources);
                setCityF(citys);
                setUtm(utms);
                setClassF(classes);
                setAdminF(admins);

            }

            if (data.Success === 'false') {
                alert(data.message);
            }



        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    const handleUtmSource = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, j, new_group = [], total;
        len = filter_result.length;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                for (j = 0; j < filter_result[i].list.length; j++) {
                    total = filter_result[i].list[j].utm_source;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
            new_group = []
        }
        if (obj.filter === "null") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }

    const handleSource = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, l_len, j, new_group = [], total;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                l_len = filter_result[i].list.length;
                for (j = 0; j < l_len; j++) {
                    total = filter_result[i].list[j].source;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
        }
        if (obj.filter === "null") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }

    const handleAdmin = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, l_len, j, new_group = [], total, count = 0;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                l_len = filter_result[i].list.length;
                for (j = 0; j < l_len; j++) {
                    total = filter_result[i].list[j].admin_name;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
        }
        if (obj.filter == "null") {
            filter_result = init;
            for (i = 0; i < len; i++) {
                l_len = filter_result[i].list.length;
                for (j = 0; j < l_len; j++) {
                    total = filter_result[i].list[j].admin_name;
                    if (total == null || total == '') {
                        count++;
                    }
                }
                if (count == l_len) {
                    new_group.push(filter_result[i])
                }
                count = 0;
            }
            filter_result = new_group;
        }
        if (obj.filter === "123") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }

    const handleFees = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, l_len, j, new_group = [], total, count = 0;
        if (obj.filter == "Y") {
            for (i = 0; i < len; i++) {
                l_len = filter_result[i].list.length;
                for (j = 0; j < l_len; j++) {
                    total = filter_result[i].list[j].fee;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            filter_result = new_group;
        }
        if (obj.filter == "N") {
            filter_result = init;
            for (i = 0; i < len; i++) {
                l_len = filter_result[i].list.length;
                for (j = 0; j < l_len; j++) {
                    total = filter_result[i].list[j].fee;
                    if (total == null || total == '' || total == 'N') {
                        count++;
                    }
                }
                if (count == l_len) {
                    new_group.push(filter_result[i])
                }
                count = 0;
            }

            filter_result = new_group;
        }
        if (obj.filter === "null") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }

    const handleCategoryFilter = (obj) => {
        let filter_result = init;
        let len = filter_result.length, i, j, new_group = [], total;
        len = filter_result.length;
        if (obj.filter != "null") {
            for (i = 0; i < len; i++) {
                for (j = 0; j < filter_result[i].list.length; j++) {
                    total = filter_result[i].list[j].last_category;
                    if (total == obj.filter) {
                        new_group.push(filter_result[i]);
                        break;
                    }
                }
            }
            if (new_group.length === 0) {
                alert("No Data Found")
            }
            else {
                filter_result = new_group;
                new_group = []
            }
        }
        if (obj.filter === "null") {
            filter_result = init
        }
        setRes(filter_result);
        obj.hide();
    }



    return (
        <div>

            <>



                <div style={{ overflowX: 'auto' }}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {/* <th rowSpan={2}>MST</th>
                                <th colSpan={2}>Jee Advanced</th> */}
                                {/* <th rowSpan={2}>R. Media</th> */}
                                <th rowSpan={2}>Samvaad</th>
                                <th colSpan={4}>Walkin</th>
                                <th colSpan={2}>FB Leads</th>
                                <th colSpan={4}>BD</th>
                                {/* <th rowSpan={2}>Landing Page</th> */}
                                {/* <th rowSpan={2}>RNeT (2022-23)</th> */}
                                <th colSpan={1}>RNet Completed <br />(Form Fee Paid)</th>
                                <th rowSpan={2}>RNet Completed <br />(Form Fee Not paid)</th>
                                <th colSpan={1}>RNet Upcoming</th>
                                <th colSpan={5}>Registration</th>
                                <th colSpan={5}>Web. Callback</th>
                                <th colSpan={3}>R. Inbound</th>
                            </tr>
                            <tr>
                                {/* <th>First half</th>
                                <th>Second half</th> */}
                                <th>All</th>
                                <th>Week</th>
                                <th>Yest.</th>
                                <th>Today</th>
                                <th>All</th>
                                <th>Week</th>
                                {/* <th>Today</th> */}
                                <th>All</th>
                                <th>Week</th>
                                <th>Yest.</th>
                                <th>Today</th>
                                {/* <th>Past RNeT</th> */}
                                <th>16th May</th>
                                <th>23th May</th>
                                {/* <th>Dec.</th> */}
                                {/* <th>Jan.</th>
                                <th>Feb.</th> */}
                                <th>March</th>
                                <th>April</th>
                                <th>May</th>
                                <th>June</th>
                                <th>July</th>
                                {/* <th>Dec.</th> */}
                                {/* <th>Feb.</th>
                                <th>March</th>
                                <th>April</th> */}
                                {/* <th>March - April</th> */}
                                {/* <th>Jan.</th>
                                <th>Feb.</th> */}
                                <th>March</th>
                                <th>April</th>
                                <th>May</th>
                                <th>June</th>
                                <th>July</th>
                                {/* <th>Jan. - Feb.</th>
                                <th>Mar. - Apr.</th>
                                <th>May - June</th>
                                <th>July - Aug.</th>
                                <th>Sept - Oct.</th> */}
                                {/* <th>Dec. - Jan.</th> */}
                                <th>Feb. - March.</th>
                                <th>April - May</th>
                                <th>June - July</th>
                                {/* <th>Jan. - Feb.</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMstResult}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJeeAdvanced1}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJeeAdvanced2}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleReliableMedia}>View</button>
                                </td> */}
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleReliableSamvaad}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAllWalkin}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleWeekWalkin}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleYestWalkin}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleTodayWalkin}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAllFbData}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleWeekFbData}>View</button>
                                </td>
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleTodayFbData}>View</button>
                                </td> */}
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAllBDWalkin}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleWeekBDWalkin}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleYestBDWalkin}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleTodayBDWalkin}>View</button>
                                </td>
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleRnet2022}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleLandingPage}>View</button>
                                </td> 
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handlePastDatesRnetDate}>View</button>
                                </td>*/}

                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleYRnetDate}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleNRnetDate}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleUCRnetDate}>View</button>
                                </td>
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleNovDecRegistration}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleDecRegistration}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJanRegistration}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleFebRegistration}>View</button>
                                </td> */}
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMarchRegistration}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAprilRegistration}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMayRegistration}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJuneRegistration}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJulyRegistration}>View</button>
                                </td>
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleFebRegistration}>View</button>
                                </td>
                                
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAprilRegistration}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMayJuneRegistration}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJulyRegistration}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAugustRegistration}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleSeptRegistration}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleDecCallback}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJanCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleFebCallback}>View</button>
                                </td> */}
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMarchCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAprilCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMayCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJuneCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJulyCallback}>View</button>
                                </td>
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJanFebCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMarAprCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMayJuneCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJulyAugCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAugustCallback}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleSeptOctCallback}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleNovDecInbound}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJanFebInbound}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMarchAprilInbound}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleMayJuneInbound}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJulyInbound}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAugustSeptInbound}>View</button>
                                </td> */}
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleDecJanInbound}>View</button>
                                </td> */}
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleFebMarchInbound}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleAprilMayInbound}>View</button>
                                </td>
                                <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJuneJulyInbound}>View</button>
                                </td>
                                {/* <td>
                                    <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleJanFebInbound}>View</button>
                                </td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Paper style={{ padding: '16px', maxWidth: '200px' }} component={Paper}>
                            <TextField
                                // fullWidth
                                onChange={(e) => setMobileNo(e.target.value)}
                                value={mobile_number}
                                label="Search Mobile"
                            />
                            <br /><br />
                            <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleSearchMobile}>Find Lead</Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper style={{ padding: '16px', maxWidth: '200px' }} component={Paper}>
                            <TextField
                                // fullWidth
                                onChange={(e) => setFormNo(e.target.value)}
                                value={form_number}
                                label="Search Fno"
                            />
                            <br /><br />
                            <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleSearchFno}>Find Lead</Button>
                        </Paper>
                    </Grid>
                </Grid>
                {/* </div> */}

                <br /><br />

            </>

            {res && <Profile handleCategoryFilter={handleCategoryFilter} filter_name={filter_name} handleFees={handleFees} handleAdmin={handleAdmin} adminf={adminf} handleSource={handleSource} handleUtmSource={handleUtmSource} utmf={utmf} classf={classf} handleDateSort={handleDateSort} datesorted={datesorted} touched={touch} untouched={untouch} manager='manager' handleSort={handleSort} cityf={cityf} sourcef={sourcef} tested={test} sorted={sorted} project='project4' array={res} state={state} refresh={refresh} snackBar={snackBar} loader={loader} {...props} />}
        </div >
    )
}


const ButtonComp = (fetch, filter, mobile_number, setState) => {

    const handleClick = () => {

        // const data = filter;
        // setState(filter);
        fetch(filter, mobile_number);

    }


    return (
        <button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD', borderRadius: '5px', padding: '5px' }} onClick={handleClick}>View</button>
    )
}