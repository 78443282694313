import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import NotFoundView from "../../main/not-found";

import AuthCheck from "./auth-check";
import Login from "./auth/screens/login";
import ForgotPassword from "./auth/screens/forgot-password"
import Dashboard from "./app/srceens/dashboard";
import LiveClassroom from "./app/srceens/live-classroom";
import LiveClassroomRedirect from "./app/srceens/live-classroom/redirect";
import Courseware from "./app/srceens/courseware";
import Notification from "./app/srceens/notifications";
import NotificationDetail from "./app/srceens/notifications/notification-detail"
import ClassroomTestSyllabus from "./app/srceens/classroom-test-syllabus";
import DigitalClassroom from "./app/srceens/digital-classroom";
//import DigitalClassroomAction from "./app/srceens/digital-classroom/action";
import ClassroomTestSyllabusSubject from "./app/srceens/classroom-test-syllabus/subject";
import ClassroomTestResult from "./app/srceens/classroom-test-result";
import ClassroomTestResultAll from "./app/srceens/classroom-test-result/all-test-result";
import ClassroomTestResultIndividualTest from "./app/srceens/classroom-test-result/individual-test-result";
import Quiz from "./app/srceens/quiz";
import QuizList from "./app/srceens/quiz/quiz-list";
import PreviewQuiz from "./app/srceens/preview-quiz";
import Profile from "./app/srceens/profile";
import Test from "./app/srceens/test";
import Support from "./app/srceens/support";
import SupportTicket from "./app/srceens/support-ticket"
import Logout from "./app/logout";

//Created By Manan

import Result from "./app/srceens/result-portal/result";
import ResultComp from "./app/srceens/result-portal/result-page";
import StudentPerformanceReport from "./app/srceens/student-performance-report";

//Created By Manan

const routes = [
  {
    path: "student/app",
    element: <AuthCheck mode="app" />,
    children: [
      //{ path: "account",  element: <AccountView /> },
      //{ path: "customers", element: <CustomerListView /> },

      { path: "dashboard", element: <Dashboard /> },

      /*manan*/

      // { path: "result-portal/result", element: <Result /> },
      // { path: "result-portal/result-page", element: <ResultComp /> },
      { path: "student-performance-report", element: <StudentPerformanceReport /> },

      /*manan*/

      { path: "live-classroom", element: <LiveClassroom /> },
      { path: "live-classroom/redirect/:id", element: <LiveClassroomRedirect /> },
      { path: "digital-classroom", element: <DigitalClassroom /> },
      /*{
        path: "digital-classroom-action/:url/:title",
        element: <DigitalClassroomAction />,
      },*/
      { path: "classroom-test-syllabus", element: <ClassroomTestSyllabus /> },
      {
        path: "classroom-test-syllabus-subject/:test_id/",
        element: <ClassroomTestSyllabusSubject />,
      },
      { path: "classroom-test-result", element: <ClassroomTestResult /> },
      {
        path: "classroom-test-result/all",
        element: <ClassroomTestResultAll />,
      },
      {
        path: "classroom-test-result/:test_id/",
        element: <ClassroomTestResultIndividualTest />,
      },

      { path: "quizlist/:id", element: <Quiz /> },
      { path: "quizlist", element: <QuizList /> },
      { path: "preview-quiz/:id", element: <PreviewQuiz /> },
      { path: "courseware", element: <Courseware /> },
      { path: "notification", element: <Notification /> },
      { path: "notification/details", element: <NotificationDetail /> },
      { path: "profile", element: <Profile /> },
      { path: "logout", element: <Logout /> },
      { path: "test", element: <Test /> },
      { path: "support", element: <SupportTicket /> },
      //{ path: "products", element: <ProductListView /> },
      // { path: "settings", element: <SettingsView /> },
      { path: "/", element: <Navigate to="live-classroom" /> },
    ],
  },
  {
    path: "student/auth",
    element: <AuthCheck mode="auth" />,
    children: [
      { path: "login", element: <Login /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "force-logout", element: <ForgotPassword force /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "student",
    children: [{ path: "/", element: <Navigate to="/student/app" /> }],
  },
];

export default routes;
