import React from "react";
import { Divider } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
export default ({
  test_info: {
    name,
    test_date,
    classroom_test_type: { name: test_type },
  },
}) => {
  return (
    <>
      <CardActions>
        <Typography variant="subtitle1" gutterBottom>
          Test Name :
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {name}
        </Typography>
      </CardActions>
      <CardActions>
        <Typography variant="subtitle1" gutterBottom>
          Test Type :
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {test_type}
        </Typography>
      </CardActions>
      <CardActions>
        <Typography variant="subtitle1" gutterBottom>
          Test Date :
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {test_date}
        </Typography>
      </CardActions>
    </>
  );
};
