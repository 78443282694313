import { gql } from "@apollo/client";

export const courseware_schedule_gql = gql`
  {
    batch_courseware_list {
      subject
      info {
        id
        created_at
        courseware {
          id
          title
          type
          type
          pdf_url
          topic {
            name
            childsubject {
              name
              subject {
                name
              }
            }
          }
        }
      }
    }
  }
`;
