import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from '@material-ui/core/Input';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from "@material-ui/core/FormControl";
import SelectComp from "@material-ui/core/Select";
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    width: 222,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export const Select = ({ array, value, name, label, onChange }) => {
  const classes = useStyles();
  let selected = value  
  if (value === "") value = "select";

  const search = (nameKey, myArray) => {
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].key == nameKey) {
            return myArray[i].value;
        }
    }
}

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <SelectComp
        labelId="demo-mutiple-name-label"
        id="demo-mutiple-name"
        multiple
        value={value}
        input={<Input />}
        inputProps={{
          name: name,
        }}
        onChange={onChange}
        renderValue={x => (
          <div className={classes.chips}>
            {x.map(value => <Chip key={value} label={search(value,array)} className={classes.chip} />)}
          </div>
        )}
      >
        <MenuItem disabled value="">
          <em>Select</em>
        </MenuItem>
        {array &&
          array.map((eachOption, i) => {
            return (
              <MenuItem key={eachOption.key} value={eachOption.key}>
              <Checkbox checked={selected.indexOf(eachOption.key) > -1} />
              <ListItemText primary={eachOption.value} />
              </MenuItem>
            );
          })}
      </SelectComp>
    </FormControl>
  );
};
