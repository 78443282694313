import { gql } from "@apollo/client";

export const create_batch_notification_mutation_gql = gql`
  mutation create_batch_notification(
    $notification_id: Int!  
    $batch_id: [Int!]
  ) {
    create_batch_notification(
      notification_id: $notification_id
      batch_id: $batch_id
    ) {
      success
    }
  }
`;
