import React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { attendance_fn } from "./attendance_fn";
import { student_live_class_mark_attendance_gql } from "../../../gql/student_live_class_mark_attendance_gql";

function Comp() {
    const [mark_attendance_mutation] = useMutation(
        student_live_class_mark_attendance_gql
      );
      let { id } = useParams();
      let navigate = useNavigate();

      attendance_fn({
        mark_attendance_mutation,
        status_type: "disconnect",
        ...{ meeting_id: id },
      });

      navigate('/student');

return <div>Redirecting...</div>
}
export default Comp;          