import { TextField, Typography } from "@material-ui/core";
import { Button, MenuItem, Grid } from "@material-ui/core";
import { Paper, InputLabel, Select } from "@material-ui/core";
import { LoaderContext } from "../../../../../context/LoaderContext";
import React, { useState, useContext } from "react";
import axios from "axios";
import { api_url } from "./config";


export default function FeeDetails() {


    const loader = useContext(LoaderContext);

    const [rollno, setRollNo] = useState();
    const [category, setCategory] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('')
    const [manager, setManager] = useState(false);
    const [admin, setAdmin] = useState('')

    const hClick = async () => {
        try {
            loader.show()
            console.log(rollno.length)
            if (rollno.length < 8) {
                alert('Enter Correct Roll No')
            }
            else {
                const { data: obj } = await axios({
                    url: api_url + "/admission-data",
                    method: "post",
                    responseType: "json",
                    data: {
                        rollno: rollno,
                        category: category,
                        amount: amount,
                        admin: 15321,
                    },
                    headers: {
                        APIKEY: '##reliable_rest_api_token##',
                        ADMINTOKEN: localStorage.getItem('admin_token')
                    }
                })

                if (obj.success === 'ok') {
                    alert("Admission punched Successfully")
                }
                if (obj.success === 'false') {
                    alert(obj.message)
                }
            }
        }
        catch (e) {
            alert(e.message)
        }

        finally {
            loader.hide();
        }

    }

    const handleClick = async () => {
        try {

            loader.show()

            if (rollno.length != 8) {
                alert("Enter Roll Number");
                return false;
            }
            if (category.length == 0) {
                alert("Enter Category");
                return false;
            }
            if (amount.length == 0) {
                alert("Enter Amount");
                return false;
            }
            if (admin.length == 0) {
                alert("Enter Admin Id");
                return false;
            }


            const { data: obj } = await axios({
                url: api_url + "/admission-data",
                method: 'post',
                responseType: 'json',
                data: {
                    rollno: rollno,
                    category: category,
                    amount: amount,
                    admin: admin,
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            console.log(obj)

            if (obj.success === 'ok') {
                alert("Admission punched Successfully")
            }

            if (obj.success === 'false') {
                alert(obj.message)
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }
    }




    return (
        <>

            <Button onClick={() => setManager(true)} style={{ backgroundColor: '#AEAEAE', color: '#000' }}>
                Managers
            </Button>
            <br />
            <br />
            <Button onClick={() => setManager(false)} style={{ backgroundColor: '#AEAEAE', color: '#000' }}>
                Admin Counsellors
            </Button>
            <br />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"

            >

                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "400px", height: 'auto', paddingLeft: "20px" }}>
                    {/* <Typography>Enter Roll NO</Typography> */}
                    {!manager && <> <TextField label="Roll No" value={rollno} onChange={(e) => setRollNo(e.target.value)} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <InputLabel>Select Category</InputLabel>
                        <Select value={category} onChange={(e) => setCategory(e.target.value)} label="Select" style={{ width: '190px' }}>
                            <MenuItem value="One Time Payment">One Time Payment</MenuItem>
                            <MenuItem value="Installment">Installment</MenuItem>
                        </Select>
                        <br></br>
                        <br></br>
                        <br></br>
                        <TextField label="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={hClick}>Submit</Button></>}

                    {manager && <>


                        <TextField label="Roll No" value={rollno} onChange={(e) => setRollNo(e.target.value)} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <InputLabel>Select Category</InputLabel>
                        <Select value={category} onChange={(e) => setCategory(e.target.value)} label="Select" style={{ width: '190px' }}>
                            <MenuItem value="One Time Payment">One Time Payment</MenuItem>
                            <MenuItem value="Installment">Installment</MenuItem>
                        </Select>
                        <br></br>
                        <br></br>
                        <br></br>
                        <TextField label="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <TextField label="Enter Admin Id" value={admin} onChange={(e) => setAdmin(e.target.value)} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={handleClick}>Submit</Button>


                    </>}

                </Paper>
            </Grid>
        </>
    )
}