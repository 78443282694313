import { Container } from '@material-ui/core';
import React, { useState, useContext } from 'react'
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import { TextField, Grid, Button, MenuItem, Select, InputLabel, Paper } from "@material-ui/core";
import axios from 'axios';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import styles from '../reusable-calling-code-project/table.module.css';

const AttendanceDataReport = () => {

    const loader = useContext(LoaderContext);

    const [show, setShow] = useState(false);
    const [roll, setRoll] = useState('')
    const [res, setRes] = useState([]);
    const [hide, setHide] = useState(false);
    const [caller, setCaller] = useState([]);

    const handleClick = async () => {

        try {

            loader.show();

            if (roll.length === 0) {
                alert("Enter Roll Number");
                return false;
            }

            const { data: obj } = await axios({
                url: api_url + '/attendance-data-calling/data-report-roll',
                method: 'post',
                responseType: 'json',
                data: {
                    roll: roll,
                },
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setHide(true);
                setRes(obj.array);
            }

            if (obj.success === 'false') {
                alert(obj.message);
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }

    }

    const handleCallerReport = async () => {

        try {

            loader.show();

            setHide(false);

            const { data: obj } = await axios({
                url: api_url + '/attendance-data-calling/data-report-caller',
                method: 'post',
                responseType: 'json',
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setShow(true);
                setCaller(obj.array);
            }

            if (obj.success === 'false') {
                alert(obj.message);
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }

    }


    return (
        <>

            <Container>
                <FormLayoutAdmin title="Attendance SMS">

                    <TextField
                        type="tel"
                        label="Roll Number"
                        value={roll}
                        placeholder="Enter Roll No."
                        onChange={(e) => setRoll(e.target.value)}
                    />

                    <br />
                    <br />

                    <Button fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={handleClick}><b>Submit</b></Button>

                    <br />
                    <br />

                    <Button fullWidth style={{ backgroundColor: '#AEAEAE', color: '#000' }} onClick={handleCallerReport}><b>View Caller-wise Report</b></Button>

                </FormLayoutAdmin>
            </Container>

            <br />
            <br />

            {hide && <Report1 res={res} />}

            <br />
            <br />

            {show && <Report2 caller={caller} />}
        </>
    )
}

export default AttendanceDataReport;


const Report1 = ({ res }) => {

    return (
        <>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th colSpan={5}>Attendance Calling Report</th>
                    </tr>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Roll No.</th>
                        <th>Text</th>
                        <th>Category</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {res.map((ele, i) => (
                        <>

                            <tr>
                                <td>{i + 1}</td>
                                <td>{ele.roll_no}</td>
                                <td>{ele.text}</td>
                                <td>{ele.category}</td>
                                <td>{ele.date}</td>
                            </tr>

                        </>
                    ))}
                </tbody>
            </table>

        </>
    )
}


const Report2 = ({ caller }) => {

    return (
        <>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th colSpan={5}>Attendance Calling Report</th>
                    </tr>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Admin</th>
                        <th>Count</th>
                        <th>Last Call Placed on</th>
                    </tr>
                </thead>
                <tbody>
                    {caller.map((ele, i) => (
                        <>

                            <tr>
                                <td>{i + 1}</td>
                                <td>{ele.admin_name}</td>
                                <td>{ele.count}</td>
                                <td>{ele.last_date}</td>
                            </tr>

                        </>
                    ))}
                </tbody>
            </table>

        </>
    )
}
