import { gql } from "@apollo/client";

export const classroom_test_schedule_gql = gql`
  {
    classroom_test_schedule {
      id
      name
      test_date
      type: classroom_test_type {
        name
      }
      test_batch_id:  classroom_test_batch {
        id
      }
    }
  }
`;
