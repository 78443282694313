import { attendance_fn } from "./attendance_fn";
import jwt_token from "../../../../../utils/jwt-token";
import zoom_handler_web from "./zoom-handler-web";
const sleep = require("await-sleep");
let isElectron = require("is-electron");
export default async ({
  user_data,
  r,
  meeting_info,
  setLoader,
  registerProcess,
  clearProcessList,
  meeting_id,
  mark_attendance_mutation,
  exec,
}) => {
  clearProcessList();
  await sleep(500);
  user_data = user_data;

  try {
    if (!isElectron()) {
     zoom_handler_web({
  user_data,
  r,
  meeting_info,
  setLoader,
  registerProcess,
  clearProcessList,
  meeting_id,
  mark_attendance_mutation,
  exec,
});
      return false;
    }
    setLoader(true);
    await sleep(500);

    const app = require("electron").remote.app;
    const path = require("path");

    const token = jwt_token(user_data.zoom_app_key,user_data.zoom_app_secret);

    let app_path = app.getAppPath();
    app_path = app_path.split("\\");
    app_path.pop();
    app_path = app_path.join("\\");
    let exe_path = null;
    if (process.env.NODE_ENV === "development")
      exe_path = path.resolve(
        app_path +
          "\\electron\\binaries\\" +
          "zoom-electron-exe\\zoomelectronsdk.exe"
      );
    //development
    else
      exe_path = path.resolve(
        app_path + "\\" + "zoom-electron-exe\\zoomelectronsdk.exe"
      ); //production

    const proc = exec(exe_path, [
      ".",
      "https__colon//www.zoom.us",
      token,
      meeting_info["meeting_no"],
      `${user_data.name} ${user_data.roll_no}`,
      meeting_info["meeting_pwd"],
    ]);

    registerProcess(proc);

    proc.stdout.on("data", function (data) {
      let status_type = {};
      let output = data.toString();
      
      if (output.indexOf("meetingstatus") > -1) {
        setLoader(false);
      }
      if (output.indexOf("EnableInviteButtonOnMeetingUI") > -1) {
        status_type = "connect";
        attendance_fn({
          mark_attendance_mutation,
          status_type,
          ...{ meeting_id: meeting_id },
        });
      }
      if (output.indexOf("meetingstatus 4 0") > -1) {
        status_type = "disconnect";
        attendance_fn({
          mark_attendance_mutation,
          status_type,
          ...{ meeting_id: meeting_id },
        });
      }
    });
  } catch (e) {
    alert(e.message);
    setLoader(false);
  }
};
