import React from "react";
import { Paper } from "../../../paper";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { theme } from "../../../theme";
import BookMark from "../../../../assets/bookmark-icon.png";
import BookMark2 from "../../../../assets/bookmark-icon-2.png";

export default withWidth()(
  ({ width, children, question_no, quiz_question: { flag }, onFlagToggle }) => {
    const index = question_no - 1;
    const new_props = {
      index: index,
      children: children,
      question_no: question_no,
      flag: flag,
      onFlagToggle: onFlagToggle,
    };
    if (!isWidthUp("md", width))
      return (
        <Paper style={{ borderRadius: 0 }} elevation={0}>
          <Comp {...new_props} />
        </Paper>
      );
    return (
      <Paper
        style={{
          marginTop: 20,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Comp {...new_props} big />
      </Paper>
    );
  }
);

const Comp = ({ big, index, children, question_no, flag, onFlagToggle }) => {
  let extra_style = {};
  if (big) extra_style = { borderTopLeftRadius: 4, borderTopRightRadius: 4 };
  return (
    <React.Fragment>
      <div
        style={{
          ...extra_style,
          backgroundColor: theme.palette.primary.main,
          color: "white",
          padding: 20,
        }}
      >
        <span>
          <b>Q. {question_no}</b>
        </span>

        <Comp1
          onFlagToggle={onFlagToggle}
          index={index}
          big={big}
          flag={flag}
        />
      </div>
      <div style={{ height: "60vh", overflowY: "auto", padding: 10 }}>
        {children}
      </div>
    </React.Fragment>
  );
};

const Comp1 = ({ onFlagToggle, index, big, flag }) => {
  return (
    <span
      style={{ float: "right", cursor: "pointer" }}
      onClick={() => onFlagToggle(index)}
    >
      <Icon flag={flag} />
      {big && (
        <span>
          &nbsp;&nbsp;
          <Status flag={flag} />
        </span>
      )}
    </span>
  );
};

const Icon = ({ flag }) => {
  const style = { width: 24, height: 24, verticalAlign: "middle" };
  if (flag) return <img src={BookMark2} style={style} />;
  return <img src={BookMark} style={style} />;
};

const Status = ({ flag }) => {
  if (flag) return <span>Marked For Review</span>;
  return <span>Mark For Review</span>;
};
