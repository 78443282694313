import {useState,useEffect} from 'react'
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import {api_url,rest_headers} from '../../srceens/digital-classroom/config'
import {STUDENT_TOKEN_NAME} from "../../../../../config"
 import { Base64 } from "js-base64";
const axios=require('axios');

	const style1={width:150,height:30,borderRadius:5,border:'1px solid #666',
	  padding: '10px',
	  'box-sizing': 'border-box'
	}
	const style2={width:90,height:30,borderRadius:5,border:'1px solid #666',padding: '10px',
	  'box-sizing': 'border-box'}
	const style3={width:90,height:30,borderRadius:5,background:'#66cdaa',border:'none',color:'white'}

//import FileBase64 from 'react-file-base64';
 
export default({snackBar,loader})=>{
	const init_data={student_wa:null,father_wa:null,mother_wa:null}
	const [init,setInit]=useState(init_data);
	const [loading,setLoading]=useState(true);
	const [error,setError]=useState(false);
	
	const [allowed,setAllowed]=useState(false);
useEffect(async()=>{
	
refetch()

	
},[])

const refetch=async()=>{
	
	try{
	 
	  
 const  {data:data2}=await axios({
        url: api_url+'/whatsapp-number-manager',
        method: 'post',
		responseType: 'json',
		data:{
			read:true
		 },
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			STUDENTRELIABLETOKEN:localStorage.getItem(STUDENT_TOKEN_NAME)
}
	  }) 
if(data2.allowed==false)
	setAllowed(false);
else
	setAllowed(true);
	 if(!data2.result[0])
 setInit(init_data);
 else
 setInit(data2.result[0]);
	 
	
	}
	catch(e){ setError(true)}
	finally{setLoading(false)}

}

	 
	return(
	<div style={{maxWidth:400,margin:'0 auto'}}>
		{loading || error?<></>:
		
		(allowed==true?
<Alert severity="error"
  
>
<AlertTitle><strong>Add Whatsapp Numbers</strong></AlertTitle>
<br/>
<> 
 <Input mode="student" refetch={refetch} init={init} snackBar={snackBar} loader={loader}/><br/>
 <Input mode="father" refetch={refetch} init={init}  snackBar={snackBar} loader={loader}/><br/>
 <Input mode="mother"  refetch={refetch} init={init} snackBar={snackBar} loader={loader}/>
   
	  </>
</Alert>:<></>
)
		}
 
	     
</div>
)
}



const Input=({mode,snackBar,loader,init,refetch})=>{
 
const [mobile,setMobile]=useState('');
const [otp,setOTP]=useState('');
const [inputotp,setInputOTP]=useState('');

	let label;
	if(mode=="student")
		label="Student";
	else  
	if(mode=="father")
		label="Father";
	else  
	if(mode=="mother")
		label="Mother";
		
const handleClick1=async()=>{
	try{
	if(!mobile || isNaN(mobile) || mobile.length!=10){
	snackBar.show(`Please enter valid ${mode}'s number`);
	return
	}
	
	if(mode=='student'){
		if(mobile==init['father_wa'])
		{
			snackBar.show('should be different from father\'s mobile');
			return false
		}
		if(mobile==init['mother_wa'])
		{
			snackBar.show('should be different from mother\'s mobile');
			return false
		}
	}
	if(mode=='father'){
		if(mobile==init['student_wa'])
		{
			snackBar.show('should be different from student\'s mobile');
			return false
		}
		if(mobile==init['mother_wa'])
		{
			snackBar.show('should be different from mother\'s mobile');
			return false
		}
	}
	if(mode=='mother'){
		if(mobile==init['student_wa'])
		{
			snackBar.show('should be different from student\'s mobile');
			return false
		}
		if(mobile==init['father_wa'])
		{
			snackBar.show('should be different from father\'s mobile');
			return false
		}
	} 
	loader.show();
	  const { data: data2 } = await axios({
                url: "https://api-dot-reformexam.el.r.appspot.com/reliablekotaapp/get-otp-common-whatsapp",
                method: "post",
                responseType: "json",
                data: {
                    mobile: mobile,
                },
            });
			 
            const result =  Base64.decode(data2.otp);
			setOTP(result);
			
	}
	catch(e){snackBar.show(e.message)}
	finally{loader.hide();}
	 
}
const handleClick2=async()=>{
	try{
	if(inputotp!=otp){
	snackBar.show('otp is wrong.');
	return false;
	}
	 loader.show();
 const  {data:data2}=await axios({
        url: api_url+'/whatsapp-number-manager',
        method: 'post',
		responseType: 'json',
		data:{
			mobile:mobile,
			mode:mode
			 
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			STUDENTRELIABLETOKEN:localStorage.getItem(STUDENT_TOKEN_NAME)
}
	  }) 		 
	setMobile('');
	setInputOTP('');
	setOTP('');
	
	if(data2.success=='ok')
	  {
		  snackBar.show(data2.message);
		  refetch();
	  }else{
		  snackBar.show(data2.message);
	  }
	
	}
	catch(e){snackBar.show(e.message)}
	finally{loader.hide();}
}
	
   
	return(
	
	<table>
 <tr>
 <td colSpan="2">{`${label}'s WhatsApp Number`}</td>
 </tr>
 {init[mode+'_wa']?<><strong style={{color:'#000'}}>{init[mode+'_wa']}</strong><OfflinePinIcon   style={{verticalAlign:'bottom',color:'teal'}}/></>:
 <tr>
 <td>
 
 {!otp && <input type="tel" placeholder={`Enter number`} value={mobile} onChange={(e)=>setMobile(e.target.value)} style={style1}/>
 }
 {otp && <input  type="tel" placeholder={`Enter otp`}  value={inputotp} onChange={(e)=>setInputOTP(e.target.value)} style={style2}/>}
 </td>
 <td>
 {!otp && <input type="button" value="Get OTP" onClick={handleClick1} style={style3}/>}
 {otp && <input type="button" value="Verify OTP" onClick={handleClick2} style={style3}/>}
 
 
 </td>
 
 </tr>
 }
 </table>
	
	)
}