import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from '../table.module.css';
import Delete from './delete'
import Allot from './allot'
import Callback from './callback'
import Conversation from './conversation'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonComp from './button-comp';
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 400,
		background: '#fce4ec'
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},

}));

export default function RecipeReviewCard({ load, courset, displayed, ubatch, sorted, handleSorted, display, array, snackBar, loader, refresh, manager, handleSort, project }) {

	const classes = useStyles();
	const [cb_date, setCBDate] = useState('');
	const [utm_source, setUTMSource] = useState('');
	const [cv_cat, setCvCat] = useState('');
	const [cb_name, setCbName] = useState('');
	const [cb_mobile, setCbMobile] = useState();
	const [course, setCourse] = useState('');
	const [batchs, setBatch] = useState('');

	// var cons = null;

	let navigate = useNavigate();

	let avatar = {};

	if (array.length < 1)
		return (
			<></>
		)
	var i, len = array.length, count = 0, cbcount = 0, coursecount = 0, batchcount = 0;
	for (i = 0; i < len; i++) {
		if (array[i].last_category == cv_cat) {
			count++;
		}
		if (array[i].last_callback == cb_date) {
			cbcount++;
		}
	}
	if (project === 'project3') {
		for (i = 0; i < len; i++) {
			if (array[i].coursetitle == course) {
				coursecount++
			}
			if (array[i].batch == batchs) {
				batchcount++
			}
		}
	}
	if (project === 'project2') {
		for (i = 0; i < len; i++) {
			if (array[i].batch == batchs) {
				batchcount++
			}
			if (array[i].coursetitle == course) {
				coursecount++
			}
		}
	}
	// console.log(len);

	// console.log(array);
	const handleClick = () => {
		handleSort();
		if (sorted == null) {
			document.getElementById('toggle_sort').style.transform = `rotate(0.5turn)`
		}
		if (sorted == 'asc') {
			document.getElementById('toggle_sort').style.transform = `rotate(0)`
		}
	}

	const handleClickSort = () => {
		handleSorted();
		if (displayed == 'ASC') {
			document.getElementById('toggle_sorted').style.transform = `rotate(0.5turn)`
		}
		if (displayed == 'DESC') {
			document.getElementById('toggle_sorted').style.transform = `rotate(0)`
		}
	}
	// console.log(batchs);

	const handleSubmit = (obj) => {
		console.log(obj.roll_no);
		navigate('../view-student-attendance', { state: { rollno: obj.roll_no } });
	}

	return (
		<>
			<TableContainer component={Paper}>
				<div style={{ overflowX: 'auto', maxHeight: '530px' }}>
					<table className={styles.table} style={{ borderCollapse: 'collapse' }}>
						<thead style={{ position: 'sticky', top: 0 }}>
							{
								(project === 'project1')

									? <tr ><th colSpan={manager ? 10 : 9} className={styles.typogrpahy_header} >Not Filled Form <br /><br /> <b>Total Count: {len}</b></th></tr>
									: <>{
										(project === 'project2')
											? <tr ><th colSpan={manager ? 10 : 9} className={styles.typogrpahy_header} >Absentees<br /><br /> <b>Total Count: {len}</b></th></tr>
											: <>
												{
													(project === 'project3')
														? <tr ><th colSpan={manager ? 10 : 11} className={styles.typogrpahy_header} >Not Paid<br /><br /> <b>Total Count: {len}</b></th></tr>
														: <></>
												}
											</>
									}</>

							}

							<tr>
								<th rowspan="2" width="10">Fno.

								</th>
								<th rowspan="2" width="150">Mobile </th>

								{
									(project === 'project1')
										? <th colspan={manager ? 8 : 7}>JEE Registrations</th>
										: <>{
											(project === 'project2')
												? <th colspan={manager ? 8 : 7}>Attendance</th>
												: <>
													{
														(project === 'project3')
															? <th colspan={manager ? 8 : 9}>Registrations</th>
															: <></>
													}
												</>
										}</>
								}


							</tr>
							<tr><th>Name</th>
								{
									(project === 'project2')
										? <>
											<th>Batch
												<br /><b>Count: {batchcount}</b><br />
												<select value={batchs} onChange={(e) => setBatch(e.target.value)}>
													<option value=''>Select Batch</option>
													{ubatch.map((ele, index) => (
														<option value={ele.batch}>{ele.batch}</option>
													))}
												</select><br />
												<button style={{ padding: 4, color: 'red' }} onClick={() => { setBatch('') }}> X </button>
											</th>
											<th>Course Title<br /><b>Count: {coursecount}</b><br />
												<select value={course} onChange={(e) => setCourse(e.target.value)}>
													<option value="">Select Course</option>
													{courset.map((ele, index) => (
														<option value={ele.coursetitle}>{ele.coursetitle}</option>
													))}
												</select>
												<br />
												<button style={{ padding: 4, color: 'red' }} onClick={() => { setCourse('') }}> X </button>
											</th>
											<th>View Attendance</th>
											{

												(load)
													? <></>
													: <>

														<th>Absent Percentage
															<br />
															<ArrowDropDownIcon style={{ height: '40spx', width: '40px' }} id='toggle_sort' onClick={handleClick} />
														</th>
													</>
											}
										</>
										: <></>
								}
								{
									(project === 'project3')
										? <>
											<th>Batch
												<br /><b>Count: {batchcount}</b><br />
												<select value={batchs} onChange={(e) => setBatch(e.target.value)}>
													<option value=''>Select Batch</option>
													{ubatch.map((ele, index) => (
														<option value={ele.batch}>{ele.batch}</option>
													))}
												</select><br />
												<button style={{ padding: 4, color: 'red' }} onClick={() => { setBatch('') }}> X </button>
											</th>
											<th>Deposited Date</th>
											<th>Amount Deposited</th>
											<th>Amount Less <br />
												{
													(project === 'project3')
														? <ArrowDropDownIcon style={{ height: '40px', width: '40px' }} id='toggle_sorted' onClick={handleClickSort} />
														: <></>
												}
											</th>
											<th>Due Date
												{
													(project === 'project3')
														? <ArrowDropDownIcon style={{ height: '40px', width: '40px' }} id='toggle_sort' onClick={handleClick} />
														: <></>
												}
											</th>
											<th>Course Title<br /><b>Count: {coursecount}</b><br />
												<select value={course} onChange={(e) => setCourse(e.target.value)}>
													<option value="">Select Course</option>
													{courset.map((ele, index) => (
														<option value={ele.coursetitle}>{ele.coursetitle}</option>
													))}
												</select>
												<br />
												<button style={{ padding: 4, color: 'red' }} onClick={() => { setCourse('') }}> X </button>
											</th>
										</>
										: <></>
								}
								<th>Conversations <br /><br /><b>Count: {count}</b>
									<br />
									{
										(project === 'project1')
											? <>
												<select value={cv_cat} onChange={(e) => setCvCat(e.target.value)}>
													<option value="null">Select Category</option>
													<option value="Not Able to Connect">Not Able to Connect</option>
													<option value="Not Appearing in JEE Main 2022">Not Appearing in JEE Main 2022</option>
													<option value="Follow up">Follow up</option>
													<option value="Form not filled">Form not filled</option>
													<option value="Dont want to Update">Don't want to Update</option>
													<option value="Informed">Informed</option>
													<option value="Already Updated">Already Updated</option>
												</select>&nbsp;
												<br />

											</>
											: <>
												{
													(project === 'project2')
														? <><select value={cv_cat} onChange={(e) => setCvCat(e.target.value)}>
															<option value="null">Select Category</option>
															<option value="Informed">Informed</option>
															<option value="BiometricCard Punch Issue">Biometric/Card Punch Issue</option>
															<option value="Card Lost">Card Lost</option>
															<option value="Medical Issue">Medical Issue</option>
															<option value="Personal Issue">Personal Issue</option>
															<option value="Shift To Online Mode">Shift To Online Mode</option>
															<option value="Batch Change">Batch Change</option>
															<option value="Due To School Examination">Due To School Examination</option>
															<option value="Followup">Followup</option>
															<option value="Not Able TO Connect">Not Able TO Connect</option>
															<option value="Left Reliable">Left Reliable</option>
														</select> &nbsp;</>
														: <>
															{
																(project === 'project3')
																	? <>
																		<select value={cv_cat} onChange={(e) => setCvCat(e.target.value)}>
																			<option value="null">Select Category</option>
																			<option value="Deposited">Deposited</option>
																			<option value="Informed">Informed</option>
																			<option value="Will Deposit">Will Deposit</option>
																			<option value="In Touch With Senior Person">In Touch With Senior Person</option>
																			<option value="Need Some Time">Need Some Time</option>
																			<option value="Mode Change Online Offline">Mode Change(Online/Offline)</option>
																			<option value="Fee Issue Scholarship Concession">Fee Issue (Scholarship/Concession)</option>
																			<option value="Followup">Followup</option>
																			<option value="Left Reliable">Left Reliable</option>
																			<option value="Not Able To Connect">Not Able To Connect</option>
																		</select> &nbsp;
																	</>
																	: <></>
															}
														</>
												}
											</>
									}
									<button style={{ padding: 4, color: 'red' }} onClick={() => { setCvCat('') }}> X </button></th>
								<th>Callback<br /><br />
									<b>Count: {cbcount}</b><br /><input type="date" value={cb_date} onChange={(e) => setCBDate(e.target.value)} />&nbsp;<button style={{ padding: 4, color: 'red' }} onClick={() => { setCBDate('') }}> X </button>

								</th>{manager && <th>Allot</th>}

								{
									(project == 'project1')
										? <>
											<th>Lead Owner</th>
										</>
										: <></>
								}


							</tr>
						</thead>
						<tbody>
							{
								array.map((n) => {

									if (cb_date && cb_date != left_date(n['last_callback']))
										return <></>
									if (cv_cat && cv_cat != left_cat(n['last_category']))
										return <></>
									if (project === 'project3') {
										if (batchs && batchs != left_batch(n['batch']))
											return <></>
										if (course && course != left_course(n['coursetitle']))
											return <></>

									}
									if (project === 'project2') {
										if (batchs && batchs != left_batch(n['batch']))
											return <></>
										if (course && course != left_course(n['coursetitle']))
											return <></>
									}

									// if (cb_name && cb_name != left_name(n['name']))
									// 	return <></>
									// if (cb_mobile && cb_mobile != left_mobile(n['mobile1']) && cb_mobile != left_mobile(n['mobile2']) && cb_mobile != left_mobile(n['mobile3']))
									// 	return <></>
									// if (cb_mobile && cb_mobile != left_mobile(n['mobile2']))
									// 	return <></>
									// if (cb_mobile && cb_mobile != left_mobile(n['mobile3']))
									// 	return <></>
									return (
										<tr>
											<td>{n.roll_no}</td><td style={{ textAlign: 'left' }}>
												<b>Mobile1:</b><CopyIcon snackBar={snackBar} mobile={n.mobile1} /><br /><br />
												<b>Mobile2:</b><CopyIcon snackBar={snackBar} mobile={n.mobile2} /><br /><br />
												<b>Mobile3:</b><CopyIcon snackBar={snackBar} mobile={n.mobile3} /><br /><br />
												<b>Mobile4:</b><CopyIcon snackBar={snackBar} mobile={n.mobile4} />
											</td>
											<Comp handleSubmit={handleSubmit} load={load} project={project} {...n} refresh={refresh} loader={loader} snackBar={snackBar} manager={manager} />
										</tr>
									)


								})
							}
						</tbody>
					</table>
				</div>
			</TableContainer >

		</>
	)



}

const left_date = (dt) => {
	if (!dt)
		return dt;

	return dt.split("T")[0];
}
const left_cat = (ct) => {
	if (!ct)
		return ct;
	return ct;
}
const left_course = (cr) => {
	if (!cr)
		return cr;
	return cr;
}

const left_batch = (cr) => {
	if (!cr)
		return cr;
	return cr;
}

const all_none = (registrations, cb_date) => {
	let i = 0, len = registrations.length, j = 0;
	for (i = 0; i < len; i++) {
		if (cb_date != left_date(registrations[i]['last_callback']))
			j++;
	}

	if (j === len)
		return true;
	return false

}

const Comp = ({ amount_deposited_total, admin_name, handleSubmit, last_time, load, percentage, batch, depdate, coursetitle, less_amount, date, project, roll_no, name, last_category, classs, created_at, last_callback, last_remark, snackBar, loader, refresh, manager }) => {
	return (
		<>
			<td>{name} </td>
			{
				(project === 'project2')
					? <>
						<td>{batch}</td>
						<td>{coursetitle}</td>
						<td>
							<ButtonComp roll_no={roll_no} handleSubmit={handleSubmit} />
						</td>
						{
							(load)
								? <></>
								: <td>{percentage}%</td>
						}
					</>
					: <></>
			}
			{
				(project === 'project3')
					? <>
						<td>{batch}</td>

						<td>{depdate}</td>
						<td>{amount_deposited_total}</td>
						<td>{less_amount}</td>
						<td>{date}</td>
						<td>{coursetitle}</td>
					</>
					: <></>
			}
			<td><Conversation project={project} last_time={last_time} last_category={last_category} last_remark={last_remark} roll_no={roll_no} snackBar={snackBar} loader={loader} /></td>
			<td><Callback project={project} last_callback={last_callback} roll_no={roll_no} snackBar={snackBar} loader={loader} /></td>
			{
				(project == 'project1')
					? <>
						<td>{admin_name}</td>
					</>
					: <></>
			}
		</>
	)
}

const CopyIcon = ({ mobile, snackBar }) => {
	const mask = (mobile) => {
		try {
			return mobile.replace(/\d(?=\d{4})/g, "*");
		} catch (e) {

		}
	}
	return (
		<>
			{mask(mobile)}&nbsp;&nbsp;
			<FileCopyIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" onClick={() => {
				navigator.clipboard.writeText(mobile);
				snackBar.show('Copied!');
			}} />
		</>
	)
}

