import { gql } from "@apollo/client";

export const create_courseware_mutation_gql = gql`
  mutation create_courseware(
    $title: String!
    $type: String!
    $pdf_url: String!
    $topic_id: Int!
  ) {
    create_courseware(
      title: $title
      type: $type
      pdf_url: $pdf_url
      topic_id: $topic_id
    ) {
      success
    }
  }
`;
