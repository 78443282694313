import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutsession from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {  
  handleFormChange,  
  resetForm, 
  error_beautify,    
} from "../../../../../../utils/functions";
import { create_session_mutation_gql } from "../../../../gql/mutation/create-session-mutation";
import Typography from "@material-ui/core/Typography";
  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Contacts from './contacts'
import CreateContact from '../create-contact'

import {api_url} from '../config.js'
const axios = require('axios');

export default () => {
  const [create_session_mutation] = useMutation(create_session_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_session_mutation={create_session_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_session_mutation }) => {
  const [values, setValues] = useState({
    mobile: "" 
  });
  const [show,setShow]=useState(false);
    const [res, setRes] = useState(null);
  const handleInputChange = (e) => {

    handleFormChange({ e, values, setValues });
		 
  };
 
 
 const contact_done=()=>{
	 
	 setShow(false);
	 handleSubmit();
 }
  const handleSubmit = async () => {
 
 
  
      const { mobile } = values;
 
if(mobile.length!=10)
{
	snackBar.show('Enter valid Mobile number');
	return false;
}


  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/view-lead.php',
        method: 'post',
		responseType: 'json',
       data: 'auth_token=##reliabletoken##&mobile='+mobile,
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
 
 setRes( data.message.res)

      
     // resetForm({ values, setValues });
    } catch (e) {
		 
      //snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 
 console.log(res)
   
  return (
 
      <>
        
          <Grid spacing={3} container>
            <Grid item xs={12}> 
              <TextField
                label="Enter Mobile No." 
                variant="outlined"
                name="mobile"
				 inputProps={{
					maxLength: 10,
				  }}
                value={values.mobile}
                onChange={handleInputChange}
			 
              />
            </Grid>

             
            <Grid item xs={12}>
              <Button type="button" onClick={handleSubmit} variant="contained" color="primary">
                Check
              </Button>
            </Grid>
          </Grid>
		  
		  
		  <br /><br />
		  
		  {res && 
		  <>
		  <Contacts array={res} mobile={values.mobile}  done={contact_done} setShow={setShow}/>
		  
		  
		  {show && (<><br /><CreateContact mobile={values.mobile} done={contact_done} account_id={res[0]['account_id']}/><br /></>)}
		  </>  
		  }
		  
		
         
       
   </>
  );
};





