import { gql } from "@apollo/client";

export const view_attendence_query_gql = gql`
  query view_attendence($batch_id: Int!,$childsubject_id: Int!,$date_from:String!,$date_to:String!) {
    view_attendence(batch_id: $batch_id,childsubject_id:$childsubject_id,date_from:$date_from,date_to:$date_to) {
        id
        roll_no
        datewise {
          class_date
          present
        }
     }
  }
`;
