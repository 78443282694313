import React, { useEffect } from 'react';
import { useState } from 'react';
import UploadComp from './upload-button';
import { Typography } from '@material-ui/core';
import TestCSV from './test-csv';
import ResponseCSV from './response-csv';
import UploadTestComp from './upload-test-button';
import UrlCSVComp from './url-csv';
import UploadUrlComp from './url-button';
import DownloadTestComp from './download-test-button';
import DownloadResponseComp from './download-response-button';
import DownloadUrlComp from './download-url-button';
import AnalysisComp from './analysis-button';
import DeleteButton from './delete-botton';
import UploadPdf from './upload-pdf';
export default function TableComp(props) {

    const [load, setLoad] = useState(false);
    const [variable, setVariable] = useState(false);
    const [testload, setTestLoad] = useState(false);
    const [urlLoad, setUrlLoad] = useState(false);
    const [analysis, setAnalysis] = useState(false);
    const [pdf, setPdf] = useState(false);

    const responseuploaded = props.uploads;
    const testuploaded = props.uploadtest
    const urluploaded = props.uploadurl;
    const analysiscompleted = props.analysis_completed;
    const pdfuploaded = props.pdfupload;
    // const loader = props.loader;

    // if (uploaded === 0) {
    //     setLoad(true);
    // }

    // console.log(props.ques)

    useEffect(() => {
        try {
            // loader.show();
            if (responseuploaded === 0) {
                setLoad(true);
            }
            if (testuploaded === 0) {
                setTestLoad(true);
            }
            if (urluploaded === 0) {
                setUrlLoad(true);
            }
            if (analysiscompleted === 0) {
                setAnalysis(true);
            }
            if (pdfuploaded === 0) {
                setPdf(true);
            }
        }
        catch (e) {
            alert(e.message)
        }
        // finally {
        //     loader.hide();
        // }
    }, []);

    return (
        <>
            {
                (urlLoad)
                    ? <>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                            <DownloadTestComp id={props.id} handleTestDownload={props.handleTestDownload} index={props.index} test={props.test} />
                        </td>
                        <td>
                            <a href={props.url} target='_blank'><Typography>Download Pdf</Typography></a>
                        </td>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                            <DownloadResponseComp id={props.id} handleResponseDownload={props.handleResponseDownload} index={props.index} test={props.test} />
                        </td>
                        <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                            <DownloadUrlComp handleUrlDownload={props.handleUrlDownload} index={props.index} test={props.test} />
                        </td>
                        {
                            (analysis)
                                ? <>
                                    <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                        <Typography>Analysis Completed</Typography>
                                    </td>
                                </>
                                : <>
                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                        <AnalysisComp id={props.id} handleCalculate={props.handleCalculate} index={props.index} test={props.test} />
                                    </td>
                                </>
                        }
                        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                            <DeleteButton id={props.id} handleDelete={props.handleDelete} index={props.index} test={props.test} />
                        </td>
                    </>
                    : <>
                        {
                            (analysis)
                                ? <>
                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                        <DownloadTestComp id={props.id} handleTestDownload={props.handleTestDownload} index={props.index} test={props.test} />
                                    </td>
                                    <td>
                                        <a href={props.url} target='_blank'><Typography>Download Pdf</Typography></a>
                                    </td>
                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                        <DownloadResponseComp id={props.id} handleResponseDownload={props.handleResponseDownload} index={props.index} test={props.test} />
                                    </td>
                                    {
                                        (urlLoad)
                                            ? <>
                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                    <DownloadUrlComp id={props.id} handleUrlDownload={props.handleUrlDownload} index={props.index} test={props.test} />
                                                </td>
                                            </>
                                            : <>
                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                    <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                        <UrlCSVComp id={props.id} handleUrlMaster={props.handleUrlMaster} />
                                                    </td>
                                                    {
                                                        (!props.urlerror)
                                                            ? <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                <UploadUrlComp id={props.id} handleUrlClick={props.handleUrlClick} index={props.index} test={props.test} urlarray={props.urlarray} />
                                                            </td>
                                                            : <></>
                                                    }
                                                </td>
                                            </>
                                    }
                                    <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                        <Typography>Analysis Completed</Typography>
                                    </td>
                                </>
                                : <>
                                    {
                                        (load)
                                            ? <>
                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                    <DownloadTestComp id={props.id} handleTestDownload={props.handleTestDownload} index={props.index} test={props.test} />
                                                </td>
                                                <td>
                                                    <a href={props.url} target='_blank'><Typography>Download Pdf</Typography></a>
                                                </td>
                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                    <DownloadResponseComp id={props.id} handleResponseDownload={props.handleResponseDownload} index={props.index} test={props.test} />
                                                </td>
                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                    <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                        <UrlCSVComp id={props.id} handleUrlMaster={props.handleUrlMaster} />
                                                    </td>
                                                    {
                                                        (!props.urlerror)
                                                            ? <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                <UploadUrlComp id={props.id} handleUrlClick={props.handleUrlClick} index={props.index} test={props.test} urlarray={props.urlarray} />
                                                            </td>
                                                            : <></>
                                                    }
                                                </td>
                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                    <AnalysisComp id={props.id} handleCalculate={props.handleCalculate} index={props.index} test={props.test} />
                                                </td>
                                            </>
                                            : <>
                                                {
                                                    (pdf)
                                                        ? <>
                                                            <><td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                <DownloadTestComp id={props.id} handleTestDownload={props.handleTestDownload} index={props.index} test={props.test} />
                                                            </td>
                                                                <td>
                                                                    <a href={props.url} target='_blank'><Typography>Download Pdf</Typography></a>
                                                                </td>
                                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>

                                                                    <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                        <ResponseCSV ques={props.ques} id={props.id} handleMasterStudentDrop={props.handleMasterStudentDrop} />
                                                                    </td>
                                                                    {
                                                                        (!props.error)
                                                                            ? <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                                <UploadComp ques={props.ques} id={props.id} setLoad={setLoad} width={props.width} index={props.index} handleClick={props.handleClick} test={props.test} list={props.list} />
                                                                            </td>
                                                                            : <></>
                                                                    }
                                                                </td>
                                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Upload Response</Typography></td>
                                                                <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}></td>
                                                            </>
                                                        </>
                                                        : <>{
                                                            (testload)
                                                                ? <><td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                    <DownloadTestComp id={props.id} handleTestDownload={props.handleTestDownload} index={props.index} test={props.test} />
                                                                </td>
                                                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                        <UploadPdf id={props.id} />
                                                                    </td>
                                                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Upload Pdf</Typography></td>
                                                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Upload Response</Typography></td>
                                                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}></td>
                                                                </>
                                                                : <><td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                    <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                        <TestCSV setVariable={setVariable} handleMasterTestDrop={props.handleMasterTestDrop} />
                                                                    </td>
                                                                    {
                                                                        (!props.error)
                                                                            ? <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}>
                                                                                <UploadTestComp id={props.id} handleTestClick={props.handleTestClick} setVariable={setVariable} listed={props.listed} index={props.index} test={props.test} />
                                                                            </td>
                                                                            : <></>
                                                                    }
                                                                </td>
                                                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Upload Test</Typography></td>
                                                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Upload Pdf</Typography></td>
                                                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}><Typography>Upload Response</Typography></td>

                                                                    <td width={"25%"} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px' }}></td>
                                                                </>
                                                        }</>
                                                }
                                            </>
                                    }
                                </>
                        }
                    </>
            }
        </>
    )
}
