import React from "react";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import Typography from "@material-ui/core/Typography";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutstudent from "../../../../../utils/form-layout-admin";
import Divider from "@material-ui/core/Divider";
import Comp from "./comp";
  
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";

export default () => {
  const [values, setValues] = React.useState({
    roll_no: ""
  });
  const [rollno, setRollno] = React.useState("");
  const [viewProfile, setViewProfile] = React.useState(false)
  const snackBar = React.useContext(SnackBarContext);
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { roll_no } = values;
    setRollno(roll_no);
    setViewProfile(false);
  }
  
  return (
    // <>
    //   {view_student.map((n, i) => {
    //     return (
          <>
          <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutstudent title="Active In-active Student">
          <Grid spacing={3} container>
            <Grid item xs={8}>
            <TextField
                label="Enter Roll No"
                variant="outlined"
                name="roll_no"
                fullWidth
                value={values.roll_no}
                onChange={handleInputChange}
              /></Grid>
              <Grid item xs={4}>
              <Button style={{marginTop : '10px'}} type="submit" variant="contained" color="primary">
                Submit
              </Button></Grid>
           <Grid item xs={12}>
            <Comp viewProfile={viewProfile} setViewProfile={setViewProfile} roll_no={rollno} />
            </Grid>
            </Grid>
        </FormLayoutstudent>
      </Container>
    </form>
          </>
        );
      }
//       )}
//      </>
//   );
// };
