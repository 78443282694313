import { Card, Collapse, Paper, Typography } from '@material-ui/core'
import React, { useState } from 'react';
import Conversation from "../reusable-calling-code-project/reuable-calling-code/conversation";
import Callback from '../reusable-calling-code-project/reuable-calling-code/callback';
import TransferComp from './tranfer-comp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CallIcon from '@material-ui/icons/Call';
import styles from "../reusable-calling-code-project/table.module.css";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import ModalComp from './modal-comp';
import TemplateComp from './template-comp';

const CollapseComp = ({ n, utmf, handleClass, handleCategoryFilter, handleCallbackFilter, sourcef, handleSource, handleTestDate, tested, handleUtmSource, i, project, snackBar, loader, isDesktop }) => {
    const [expanded, setExpanded] = useState(false);
    console.log(sourcef)
    console.log(utmf)
    return (
        <>
            <ExpandMoreIcon onClick={() => setExpanded(!expanded)} />
            <Collapse in={expanded} style={{ backgroundColor: 'seashell', width: '100%' }}>
                <table className={styles.table}>
                    <tr>
                        <td>Name</td>
                        <td>{n.name}</td>
                    </tr>
                    <tr>
                        <td>Mobile No.</td>
                        <td>
                            {
                                (n.MOBILE1)
                                    ? <>
                                        <b>Mobile1:</b>
                                        <CopyIcon snackBar={snackBar} mobile={n.MOBILE1} />
                                    </>
                                    : <></>
                            }
                            <br /><br />
                            {
                                (n.MOBILE2)
                                    ? <>
                                        <b>Mobile2:</b>
                                        <CopyIcon snackBar={snackBar} mobile={n.MOBILE2} />
                                    </>
                                    : <></>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>FNo.</td>
                        <td>{n.fno}</td>
                    </tr>
                    <tr>
                        <td>Scholarship</td>
                        <td>{n.schper}</td>
                    </tr>
                    <tr>
                        <td>Class
                            <br />
                            <ModalComp title={'Filter'} handleClass={handleClass} component={'class'} />
                        </td>
                        <td>{n.SCLASS}</td>
                    </tr>
                    <tr>
                        <td>Course</td>
                        <td>{n.coursetitle}</td>
                    </tr>
                    <tr>
                        <td>Fees</td>
                        <td>{n.fee}</td>
                    </tr>
                    <tr>
                        <td>Reg. Date</td>
                        <td>{moment(n.REGDATE).format('DD-MMM,YYYY')}</td>
                    </tr>
                    <tr>
                        <td>Test Date
                            <br />
                            <ModalComp title={'Filter'} handleTestDate={handleTestDate} component={'testDate'} tested={tested} />
                        </td>
                        <td>{moment(n[`TEST DATE`]).format('DD-MMM,YYYY')}</td>
                    </tr>
                    <tr>
                        <td>Dept. Date</td>
                        <td>{moment(n.DEPTDATE).format('DD-MMM,YYYY')}</td>
                    </tr>
                    <tr>
                        <td>
                            Source
                            <br />
                            <ModalComp title={'Filter'} handleSource={handleSource} component={'source'} sourcef={sourcef} />
                        </td>
                        <td>{n.source}</td>
                    </tr>
                    <tr>
                        <td>Utm Source
                            <br />
                            <ModalComp title={'Filter'} handleUtmSource={handleUtmSource} component={'utm_source'} utmf={utmf} />
                        </td>
                        <td>{n.utm_source}</td>
                    </tr>
                    <tr>
                        <td>Conversation
                            <br />
                            <ModalComp handleCategoryFilter={handleCategoryFilter} component={'category'} title={'Filter'} />
                        </td>
                        <td style={{ width: '200px' }}>
                            <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_time={n['last_time']} last_category={n['last_category']} last_remark={n['last_remark']} roll_no={n['ORDERID']} snackBar={snackBar} loader={loader} component={'conversation'} />
                            <br />
                            <Typography variant="h6">Ctaegory:&nbsp;&nbsp;{n['last_category']}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>Callback
                            <br />
                            <ModalComp handleCallbackFilter={handleCallbackFilter} title={"Filter"} component={"followup"} />
                        </td>
                        <td>
                            <ModalComp title={'Click'} isDesktop={isDesktop} project={project} last_callback={n['last_callback']} roll_no={n['ORDERID']} snackBar={snackBar} loader={loader} component={'callback'} />
                        </td>
                    </tr>
                    <tr>
                        <td>Transfer</td>
                        <td>
                            <TransferComp loader={loader} roll_no={n.ORDERID} mobile1={n.MOBILE1} mobile2={n.MOBILE2} />
                        </td>
                    </tr>
                    <tr>
                        <td>Lead Owner</td>
                        <td>
                            {n.admin_name}
                        </td>
                    </tr>
                    {/* <tr>
                        <td>SMS</td>
                        <td>
                            <TemplateComp ORDERID={n.ORDERID} loader={loader} sms={'sms'} mobile1={checkmobile(n.MOBILE1)} mobile2={checkmobile(n.MOBILE2)} mobile3={checkmobile(n.MOBILE3)} />
                        </td>
                    </tr>
                    <tr>
                        <td>WhatsApp</td>
                        <td>
                            <TemplateComp ORDERID={n.ORDERID} loader={loader} sms={'whatsapp'} mobile1={checkmobile(n.MOBILE1)} mobile2={checkmobile(n.MOBILE2)} mobile3={checkmobile(n.MOBILE3)} />
                        </td>
                    </tr> */}
                </table>
            </Collapse>
        </>
    )
}

const CopyIcon = ({ mobile, snackBar }) => {
    const mask = (mobile) => {
        try {
            return mobile.replace(/\d(?=\d{4})/g, "*");
        } catch (e) {

        }
    }
    return (
        <>
            {mask(mobile)}&nbsp;&nbsp;
            <FileCopyIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" onClick={() => {
                navigator.clipboard.writeText(mobile);
                snackBar.show('Copied!');
            }} />
            <a href={'tel:' + mobile} target='blank'>
                <CallIcon style={{ fontSize: 15, cursor: 'pointer' }} color="primary" />
            </a>
        </>
    )
}

const checkmobile = (mobile) => {
    if (mobile === null || mobile === 'null')
        return false;
    return mobile;
}

export default CollapseComp;