import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange }) => {
  const list = [
    { key: "TEXT", value: "TEXT" },
    { key: "PDF", value: "PDF" },
    { key: "IMAGE", value: "IMAGE" },
  ];

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={list}
    />
  );
};
