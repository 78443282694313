import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
 import SelectState from "../../../components/call-center--select-state"; 
import Typography from "@material-ui/core/Typography";
 import {api_url,rest_headers} from '../config'
import {handle_error} from '../../call-center/handle_error'
import { useQuery } from "@apollo/client";
import { viewer_gql } from "../../../../gql/viewer";
import GqlErrorComp from "../../../../../../utils/gql-error-comp";

const axios = require('axios');

export default () => {
	
		const { loading, error, data } = useQuery(viewer_gql, { fetchPolicy: "no-cache" });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
   const {
    viewer: { name },
  } = data;
	
    const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
       snackBar={snackBar}
      loader={loader}
	  adminname={name}
    />
  );
};
const Comp = ({ loader, snackBar, adminname }) => {
  const [values, setValues] = useState({
    name: "",
	state:"",
    
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { name,state } = values;
if(!state)
{
	alert('Please select state');
	return;
}
if(!name)
{
	alert('Please enter school name');
	return;
}
      loader.show();
const  {data}=await axios({
        url: api_url+'/create-school',
        method: 'post',
		responseType: 'json',
		data:{
			name:name,
			state:state
			 
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	  alert(data.message);
       
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  let state_list=[]
  if(adminname=='Sonu Zia')
  {
	  state_list.push({key:'Uttar Pradesh',value:'Uttar Pradesh'});
  }
  else if(adminname=='Harjendar Singh')
  {
	  state_list.push({key:'Bihar',value:'Bihar'});
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayouttopic title="Create School">
          <Grid spacing={3} container>
		    <Grid item xs={12}>
            <SelectState
                label="State"
                name="state"
                value={values.state}
				state_list={state_list}
                 onChange={handleInputChange}
              />
				</Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter School Name"
                variant="outlined"
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </Grid>
          
           

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    </form>
  );
};
