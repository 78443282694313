import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { api_url } from '../config';
import { LoaderContext } from "../../../../../../context/LoaderContext";
import styles from '../../reusable-calling-code-project/table.module.css';
import { Grid, Paper, InputLabel, Select, MenuItem } from '@material-ui/core';
import moment from 'moment'

const DepartmentMIS = () => {

    const loader = useContext(LoaderContext);


    const [show, setShow] = useState(false);
    const [rnet, setRnet] = useState([]);
    const [grp, setGrp] = useState([]);
    const [admission, setAdmission] = useState([]);
    const [admissiondata, setAdmissionData] = useState([]);
    const [form, setForm] = useState([]);
    const [formdata, setFormData] = useState([]);
    const [leads, setLeads] = useState([]);


    useEffect(async () => {

        try {

            loader.show()

            const { data: obj } = await axios({
                url: api_url + '/department-mis',
                method: 'post',
                responseType: 'json',
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            console.log(obj);

            if (obj.success === 'ok') {

                setAdmission(obj.admission);
                setForm(obj.form);
                setLeads(obj.leads);
                setShow(true);
            }
        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }

    }, [])



    return (
        <>

            {show && <Grid container spacing={3}>


                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={6}>Leads Generation MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin Name</th>
                                    <th>Today's Count</th>
                                    <th>Yesterday's Count</th>
                                    <th>Weekly Count</th>
                                    <th>Overall Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leads.map((ele, i) => (

                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.today}</td>
                                        <td>{ele.yesterday}</td>
                                        <td>{ele.weekly}</td>
                                        <td>{ele.total}</td>
                                    </tr>

                                ))}

                            </tbody>
                        </table>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead>
                                <tr >
                                    <th colSpan={6}>Admission Punch Count</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin Name</th>
                                    <th>Today's Count</th>
                                    <th>Yesterday's Count</th>
                                    <th>Weekly Count</th>
                                    <th>Overall Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    admission.map((item, i) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item.admin}</td>
                                                    <td>{item.today}</td>
                                                    <td>{item.yesterday}</td>
                                                    <td>{item.weekly}</td>
                                                    <td>{item.total}</td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead >
                                <tr>
                                    <th colSpan={6}>Form Punch Count</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin Name</th>
                                    <th>Today's Count</th>
                                    <th>Yesterday's Count</th>
                                    <th>Weekly Count</th>
                                    <th>Overall Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    form.map((item, i) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item.admin}</td>
                                                    <td>{item.today}</td>
                                                    <td>{item.yesterday}</td>
                                                    <td>{item.weekly}</td>
                                                    <td>{item.total}</td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </Paper>
                </Grid>

            </Grid>}

        </>
    )
}

export default DepartmentMIS
