import { gql } from "@apollo/client";

export const view_live_class_query_gql = gql`
  query view_live_class($batch_id: Int!) {
    view_live_class(batch_id: $batch_id) {
        date
        start_time
        end_time
        meeting_no
        meeting_pwd
        childsubject {
            name
        }
        faculty {
            name
        }
        zoom_account {
            email
          }
        is_cancelled 
    }
  }
`;
