import React, { useContext, useState, useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
import { Select } from "../../../../../../utils/select";
import Typography from "@material-ui/core/Typography";
import { api_url, rest_headers } from '../../../../../student/app/srceens/digital-classroom/config'
import { handle_error } from '../../call-center/handle_error'
import UploadComp from './upload-comp'
import TopicList from './topic-list'
const axios = require('axios');

export default ({ division }) => {

  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
      division={division}

    />
  );
};
const Comp = ({ loader, snackBar, adminname, division }) => {

  console.log(division);

  const [list, setList] = useState([])
  const [video_lecture_topic_id, setId] = useState('');
  const [values, setValues] = useState({
    title: "",
    url: "",

  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { title, url } = values;
      if (!title) {
        alert('Please enter title');
        return;
      }
      if (!url) {
        alert('Please enter vimeo url');
        return;
      }
      if (url.indexOf('https://player.vimeo.com/video/') < 0) {
        alert('Please enter correct vimeo url');
        return;
      }
      loader.show();
      const { data } = await axios({
        url: api_url + '/create-video',
        method: 'post',
        responseType: 'json',
        data: {
          title: title,
          url: url,
          video_lecture_topic_id: video_lecture_topic_id

        },
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })
      alert(data.message);
      setValues({ url: '', title: '' });

    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };


  useEffect(async () => {

    fetch();
  }, [])

  const fetch = async () => {
    try {

      loader.show();
      const { data } = await axios({
        url: api_url + '/video-lecture-topic-list',
        method: 'post',
        responseType: 'json',
        data: {
          division: division,
        },
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })


      setList(data.list)




    } catch (e) {

      snackBar.show(e);
    } finally {
      loader.hide();
    }
  }


  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayouttopic title="Create Video">
          <Grid spacing={3} container>

            <Grid item xs={12}>
              <Select
                label={"Select Topic"}
                value={video_lecture_topic_id}
                name={'video_lecture_topic_id'}
                onChange={(e) => setId(e.target.value)}
                array={list}
              />
            </Grid>
            <Grid item xs={12}>
              <TopicList video_lecture_topic_id={video_lecture_topic_id} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Video Title"
                variant="outlined"
                name="title"
                value={values.title}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadComp setVideoUrl={(url) => setValues({ ...values, url: url })} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Video Url"
                variant="outlined"
                name="url"
                value={values.url}
                onChange={handleInputChange}
              />
            </Grid>


            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    </form>
  );
};
