import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange }) => {
  const list = [
    { key: "M", value: "Male" },
    { key: "F", value: "Female" },
  ];

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={list}
    />
  );
};
