import React, { useEffect } from "react";
import Shimmer from "react-shimmer-effect";
import { Link as RouterLink } from "react-router-dom";
import injectSheet from "react-jss";
import { useQuery } from "@apollo/client";
import { classroom_test_schedule_gql } from "../../../gql/classroom_test_schedule_gql";

import Divider from "@material-ui/core/Divider";
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";
import ClassList from "./class-list";

const common = {
  height: "8px",
  borderRadius: "8px",
};
const StyleSheet = {
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  item: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  circle: {
    height: 64,
    width: 64,
    borderRadius: "50%",
  },
  line1: {
    ...common,
    width: 200,
  },
  line2: {
    ...common,
    width: 140,
  },
  header: {
    ...common,
    width: 96,
  },
  divider: {
    height: 20,
  },
  divider2: {
    height: 10,
  },
  left: {
    borderRadius: 64,
  },
};

export default ({ is_home_component }) => {
  return <StyledApp is_home_component={is_home_component} />;
};

const App = ({ classes, is_home_component }) => {
  useEffect(() => {
    refetch();
  }, []);

  const res = useQuery(classroom_test_schedule_gql);

  const { loading, error, data, refetch } = res;

  if (loading)
    return (
      <>
        <ShimmerHeader />
        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />

        <ShimmerRow classes={classes} />
        {!is_home_component && (
          <div style={{ marginTop: 40 }}>
            <ShimmerHeader />
            <ShimmerRow classes={classes} />

            <ShimmerRow classes={classes} />

            <ShimmerRow classes={classes} />
          </div>
        )}
      </>
    );
  if (error) return <GqlErrorComp error={error} />;

  const r = data.classroom_test_schedule;
console.log(data)
  return <Comp r={r} is_home_component={is_home_component} />;
};

class Comp extends React.Component {
  render() {
    const {
      r,

      is_home_component,
    } = this.props;
    return (
      <Card>
        <CardContent>
          <Typography variant="h4">Test Syllabus</Typography>
          <ClassList r={r} is_home_component={is_home_component} />
        </CardContent>
        {is_home_component && (
          <CardActions>
            <Button
              size="small"
              color="secondary"
              component={RouterLink}
              to="../classroom-test-syllabus"
              style={{ marginLeft: "auto" }}
            >
              More
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

const ShimmerHeader = () => {
  return (
    <Shimmer>
      <div
        style={{ marginTop: 30, width: 100, borderRadius: 64, height: 10 }}
      ></div>
    </Shimmer>
  );
};
const ShimmerRow = ({ classes }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
      <Shimmer>
        <div
          style={{
            marginTop: 30,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            width: 24,
            height: 16,
          }}
        ></div>
      </Shimmer>

      <div style={{ justifyContent: "space-between", marginLeft: 30 }}>
        <div
          style={{
            marginTop: 10,
            width: 200,
            borderRadius: 64,
          }}
        >
          <Shimmer>
            <div className={classes.line1}></div>
          </Shimmer>
        </div>

        <div
          style={{
            marginTop: 10,
            width: 140,
            borderRadius: 64,
            height: 10,
          }}
        >
          <Shimmer>
            <div className={classes.line2}></div>
          </Shimmer>
        </div>
      </div>
    </div>
  );
};

const StyledApp = injectSheet(StyleSheet)(App);
