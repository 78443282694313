import * as React from 'react';
import { LineChart, Line, XAxis, YAxis, Label, Legend, ResponsiveContainer } from 'recharts';
import Title from './title';
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme, makeStyles, colors } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    root: {},
}));

export default function Chart(props) {
    const theme = useTheme();
    const classes = useStyles();
    console.log(props.row);
    return (
        <React.Fragment>
            <Card className={clsx(classes.root)}>
                <div style={{ borderBottom: '1px solid #d6d6d6', height: "auto" }}>
                    <Title>Relative Comparison of Subject vs. Your Marks</Title>
                </div>
                <Divider />
                <CardContent>
                    <Box height={400} position="relative">
                        <ResponsiveContainer style={{ width: "100%" }}>
                            <LineChart
                                data={props.row}
                                margin={{
                                    top: 16,
                                    right: 16,
                                    bottom: 0,
                                    left: 24,
                                }}
                            >
                                <XAxis
                                    dataKey="name"
                                    stroke={theme.palette.text.secondary}
                                    style={theme.typography.body2}
                                />
                                <YAxis
                                    stroke={theme.palette.text.secondary}
                                    style={theme.typography.body2}
                                >
                                    <Label
                                        angle={270}
                                        position="left"
                                        style={{
                                            textAnchor: 'middle',
                                            fill: theme.palette.text.primary,
                                            ...theme.typography.body1,
                                        }}
                                    >
                                        Your Marks
                                    </Label>
                                </YAxis>
                                <Legend wrapperStyle={{ fontFamily: "Roboto, sans-serif;" }} />
                                <Line
                                    isAnimationActive={false}
                                    type="monotone"
                                    dataKey="yourmarks"
                                    stroke="#ff7300"
                                    dot={false}
                                />
                                <Line
                                    isAnimationActive={false}
                                    type="monotone"
                                    dataKey="avgscore"
                                    stroke="#3CB371"
                                    dot={false}
                                />
                                <Line
                                    isAnimationActive={false}
                                    type="monotone"
                                    dataKey="highscore"
                                    stroke="#b11116"
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </CardContent>
                <Divider />
            </Card>



            {/* <div style={{ borderBottom: '1px solid #d6d6d6' }}>
                <Title>Graph: Subject v/s Your Marks</Title>
            </div>
            <br />
            <ResponsiveContainer>
                <LineChart
                    data={props.row}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis
                        dataKey="name"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.primary,
                                ...theme.typography.body1,
                            }}
                        >
                            Your Marks
                        </Label>
                    </YAxis>
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="subject_marks"
                        stroke={theme.palette.primary.main}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer> */}
        </React.Fragment>
    );
}