import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { api_url } from '../config';
import { LoaderContext } from "../../../../../../context/LoaderContext";
import styles from '../../reusable-calling-code-project/table.module.css';
import { Grid, Paper, InputLabel, Select, MenuItem } from '@material-ui/core';
import moment from 'moment'

const ConversionMis = () => {

    const loader = useContext(LoaderContext);


    const [show, setShow] = useState(false);
    const [rnet, setRnet] = useState([]);
    const [grp, setGrp] = useState([]);
    const [admission, setAdmission] = useState([]);
    const [admissiondata, setAdmissionData] = useState([]);
    const [form, setForm] = useState([]);
    const [formdata, setFormData] = useState([]);
    const [leads, setLeads] = useState([]);
    const [avg, setAvg] = useState([]);


    useEffect(async () => {

        try {

            loader.show()

            const { data: obj } = await axios({
                url: api_url + '/conversion-mis',
                method: 'post',
                responseType: 'json',
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setRnet(obj.rnet);
                setGrp(obj.grp);
                setAdmission(obj.admission);
                // setAdmissionData(obj.admissiondata);
                setForm(obj.form);
                // setFormData(obj.formdata);
                setLeads(obj.leads);
                setAvg(obj.avg);
                setShow(true);
            }
        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }

    }, [])



    return (
        <>

            {show && <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={5}>RNET MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Date</th>
                                    <th>RNET Count</th>
                                    <th>Admission Count from RNET</th>
                                    <th>Conversion Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rnet.map((ele, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.date}</td>
                                        <td>{ele.rnet}</td>
                                        <td>{ele.admission}</td>
                                        <td>{(ele.admission * 100 / ele.rnet).toFixed(2)}%</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={7}>YTD (Admission) MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Class</th>
                                    <th>Session 2020-21</th>
                                    <th>Session 2021-22</th>
                                    <th>Session 2022-23</th>
                                    <th>Session 2023-24</th>
                                    <th>Session 2024-25</th>
                                </tr>
                            </thead>
                            <tbody>
                                {grp.map((ele, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.grp}</td>
                                        <td>{ele.ct4}</td>
                                        <td>{ele.ct3}</td>
                                        <td>{ele.ct}</td>
                                        <td>{ele.ct2}</td>
                                        <td>{ele.ct5}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={7}>Avg. Fees (Session-Wise) MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Class</th>
                                    <th>Session 2020-21</th>
                                    <th>Session 2021-22</th>
                                    <th>Session 2022-23</th>
                                    <th>Session 2023-24</th>
                                    <th>Session 2024-25</th>
                                </tr>
                            </thead>
                            <tbody>
                                {avg.map((ele, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.Class}</td>
                                        <td>{ele.ct}</td>
                                        <td>{ele.ct1}</td>
                                        <td>{ele.ct2}</td>
                                        <td>{ele.ct3}</td>
                                        <td>{ele.ct4}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={7}>Leads Generation MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Source</th>
                                    <th>Today's Count</th>
                                    <th>Yesterday's Count</th>
                                    <th>Weekly Count</th>
                                    <th>Monthly Count</th>
                                    <th>Overall Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leads.map((ele, i) => (

                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.source}</td>
                                        <td>{ele.daily}</td>
                                        <td>{ele.yesterday}</td>
                                        <td>{ele.weekly}</td>
                                        <td>{ele.monthly}</td>
                                        <td>{ele.total}</td>
                                    </tr>

                                ))}

                            </tbody>
                        </table>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead>
                                <tr >
                                    <th colSpan={3}>Admission Punch Count</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    admission.map((item, i) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item.username}</td>
                                                    <td>{item.count}</td>

                                                </tr>
                                            </>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead >
                                <tr>
                                    <th colSpan={4}>Admission Punch Data</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin Name</th>
                                    <th>Form Number</th>
                                    <th>Date</th>


                                </tr>
                            </thead>
                            <tbody>
                                {
                                    admissiondata.map((item, i) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item.username}</td>
                                                    <td>{item.roll_no}</td>
                                                    <td>{moment(item.date).format('Do MMMM YYYY')}</td>


                                                </tr>
                                            </>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </Paper>
                </Grid> */}
                <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table}>
                            <thead >
                                <tr>
                                    <th colSpan={4}>Form Punch Count</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin Name</th>
                                    <th>Count</th>


                                </tr>
                            </thead>
                            <tbody>
                                {
                                    form.map((item, i) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item.username}</td>
                                                    <td>{item.count}</td>

                                                </tr>
                                            </>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </Paper>
                </Grid>

            </Grid>}

        </>
    )
}

export default ConversionMis
