import React from "react";
import { Select } from "../../../../utils/select";
import { phase_list_query_gql } from "../../gql/query/phase-list-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({ course_id, session_id, label, value, name, onChange }) => {
  if (!(course_id && session_id)) return <span></span>;
  const { loading, error, data } = useQuery(phase_list_query_gql, {
    variables: {
      course_id,
      session_id,
    },
  });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { phase_list } = data;

  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={phase_list}
    />
  );
};
