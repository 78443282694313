import moment from 'moment';
import { useState, useEffect } from 'react'
import { api_url, rest_headers } from '../config'
import { handle_error } from '../handle_error'
import Modal from '../modal'
import SendIcon from '@material-ui/icons/Send';
import confirm_click from './confirm_click'
import VisibilityIcon from '@material-ui/icons/Visibility';
import styles from '../table.module.css';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
const axios = require('axios');


export default ({ roll_no, isDesktop, last_callback, snackBar, loader, project, hide }) => {

	const [val, setVal] = useState(last_callback);
	useEffect(() => {
		setVal(last_callback);
	}, [roll_no])
	const click = async (roll_no) => {
		try {
			loader.show();
			if (val) {
				if (project === 'project1') {
					const { data } = await axios({
						url: api_url + '/jee-main-data-calling/create-callback',
						method: 'post',
						responseType: 'json',
						data: {
							roll_no: roll_no,
							next_callback_date: val
						},
						...rest_headers
					})
					alert(data.message);
				}
				if (project === 'project2') {
					const { data } = await axios({
						url: api_url + '/attendance-data-calling/create-callback',
						method: 'post',
						responseType: 'json',
						data: {
							roll_no: roll_no,
							next_callback_date: val
						},
						...rest_headers
					})
					alert(data.message);
				}
				if (project === 'project3') {
					const { data } = await axios({
						url: api_url + '/fee-data-calling/create-callback',
						method: 'post',
						responseType: 'json',
						data: {
							roll_no: roll_no,
							next_callback_date: val
						},
						...rest_headers
					})
					alert(data.message);
				}
				if (project === 'project4') {
					const { data } = await axios({
						url: api_url + '/lead-data-calling/create-callback',
						method: 'post',
						responseType: 'json',
						data: {
							roll_no: roll_no,
							next_callback_date: val
						},
						...rest_headers
					})
					alert(data.message);
					// if (isDesktop) {
					// 	hide();
					// }
					hide();
				}
			}
			if (!val) {
				alert('First Create Followup');
			}

		} catch (e) {
			snackBar.show(handle_error(e));
		} finally {
			loader.hide();
		}



	}

	const onChange = (e) => {
		setVal(e.target.value);

	}

	return (
		<>

			{/* <input type='datetime-local' style={{ height: 30, }} value={val} min={moment(new Date()).format("YYYY-MM-DDTHH:mm")} onChange={onChange} />&nbsp;&nbsp; */}
			<Typography style={{ textAlign: 'center' }} variant="h5">Create Follow Up</Typography>
			<Grid xs={12}>
				<TextField
					fullWidth
					variant='outlined'
					type="datetime-local"
					value={val}
					min={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
					onChange={onChange}
				/>
			</Grid>
			<br />
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
				<br />

				<Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={() => {

					confirm_click({ click, roll_no })
				}}>Submit</Button>&nbsp;&nbsp;

				<View project={project} isDesktop={isDesktop} roll_no={roll_no} loader={loader} snackBar={snackBar} />
			</div>
		</>
	)
}



const View = ({ roll_no, loader, snackBar, isDesktop, project }) => {
	const [array, setArray] = useState([]);
	const hide = () => {
		setArray([]);
	}
	const handleClick = async (roll_no) => {
		try {
			loader.show();
			if (project === 'project1') {
				const { data } = await axios({
					url: api_url + '/jee-main-data-calling/view-callback',
					method: 'post',
					responseType: 'json',
					data: {
						roll_no: roll_no
					},
					...rest_headers
				})
				setArray(data.array)
			}
			if (project === 'project2') {
				const { data } = await axios({
					url: api_url + '/attendance-data-calling/view-callback',
					method: 'post',
					responseType: 'json',
					data: {
						roll_no: roll_no
					},
					...rest_headers
				})
				setArray(data.array)
			}
			if (project === 'project3') {
				const { data } = await axios({
					url: api_url + '/fee-data-calling/view-callback',
					method: 'post',
					responseType: 'json',
					data: {
						roll_no: roll_no
					},
					...rest_headers
				})
				setArray(data.array)
			}
			if (project === 'project4') {
				const { data } = await axios({
					url: api_url + '/lead-data-calling/view-callback',
					method: 'post',
					responseType: 'json',
					data: {
						roll_no: roll_no
					},
					...rest_headers
				})
				setArray(data.array)
			}

		} catch (e) {
			snackBar.show(handle_error(e));
		} finally {
			loader.hide();
		}

	}

	return (
		<>
			{/* {
				(isDesktop)
					? <> */}
			<Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(roll_no)}>View</Button>
			{/* </>
					: <VisibilityIcon color="secondary" style={{ marginLeft: 5, fontSize: 23, fontWeight: 900, cursor: 'pointer' }} onClick={() => handleClick(roll_no)} />
			} */}

			{array && array.length > 0 && <Modal hide={hide}>
				<div style={{ fontFamily: 'Arial' }}>

					<table className={styles.table}>
						<thead>
							<th colSpan={2}>CallBack</th>
						</thead>
						<tbody>
							{array.map((n, i) => {
								return (
									<tr style={{ fontSize: '13px' }}>
										<td>Next Callback Date</td>
										<td>{n.dt}</td>
									</tr>
								)
							})}
						</tbody>
					</table>


				</div>


			</Modal>

			}
		</>
	)
}