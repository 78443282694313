let server_url;
if (process.env.NODE_ENV === "development")
server_url = "https://reliablekota.el.r.appspot.com/graphql";
//server_url = `http://127.0.0.1:8080`;
else server_url = "https://reliablekota.el.r.appspot.com";
export const graphql_endpoint = `${server_url}/graphql`;
export const image_upload_url = `${server_url}/upload?data=test`;
export const courseware_upload_url = `${server_url}/upload-courseware`
//export const zoom_web_app_url=`http://localhost:8081`;
export const zoom_web_app_url=`https://zoomapp-dot-reliablekota.el.r.appspot.com`;
export const STUDENT_TOKEN_NAME = "student_token";
//export const zoomLeaveUrl="http://localhost:3000/student/app/live-classroom/redirect";
export const zoomLeaveUrl="https://reliablekota.web.app/student/app/live-classroom/redirect";
