import React, { useContext,useEffect, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutsession from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {  
  handleFormChange,  
  resetForm, 
  error_beautify,    
} from "../../../../../../utils/functions";
 import Typography from "@material-ui/core/Typography";
  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LeadTable from './lead-table'
import {api_url} from '../config.js'
import CreateLeadButton from "./create-lead-button" 
 import CreateLead from "../create-lead" 
const axios = require('axios');

export default ({contact_id}) => {
 
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
     contact_id={contact_id}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar,contact_id }) => {
  
 const [show,setShow]=useState(false);
    const [res, setRes] = useState(null);
  
  useEffect(async()=>{
	  
 
 
 
 
 
       

   

  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/view-lead.php',
        method: 'post',
		responseType: 'json',
       data: 'auth_token=##reliabletoken##&contact_id='+contact_id,
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
 
 setRes( data.message.res)

      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  },[contact_id])
 
 
   
  return (
 
      <>
        
          
		  
		  {res && (<>
		  <LeadTable array={res} contact_id={contact_id}  />
		  
		  <br /><CreateLeadButton setShow={setShow}/>
		  	<br/>
			{show && <CreateLead contact_id={contact_id}/>}
			</>
		  )
		  
		  }
		    
		  
		
         
       
   </>
  );
};





