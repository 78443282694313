import moment from 'moment';
import { useEffect, useState } from 'react'
import { api_url, rest_headers } from '../config'
import { handle_error } from '../handle_error'
import Modal from '../modal'
import SendIcon from '@material-ui/icons/Send';
import confirm_click from './confirm_click'
import VisibilityIcon from '@material-ui/icons/Visibility';
import styles from '../table.module.css';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import { Select } from "../../../../../../utils/select";

const axios = require('axios');

export default ({ project, isDesktop, roll_no, last_remark, last_time, snackBar, loader, last_category, hide }) => {
	const [val, setVal] = useState(last_remark);
	const [cat, setCat] = useState(last_category);
	useEffect(() => {
		setCat(last_category);
		setVal(last_remark)
	}, [roll_no])
	const click = async () => {
		try {
			loader.show();
			if (cat && roll_no && val) {
				if (project === 'project2') {
					const { data } = await axios({
						url: api_url + '/attendance-data-calling/create-conversation',
						method: 'post',
						responseType: 'json',
						data: {
							roll_no: roll_no,
							text: val,
							category: cat
						},
						...rest_headers
					})
					alert(data.message)
				}
				if (project === 'project1') {
					const { data } = await axios({
						url: api_url + '/jee-main-data-calling/create-conversation',
						method: 'post',
						responseType: 'json',
						data: {
							roll_no: roll_no,
							text: val,
							category: cat
						},
						...rest_headers
					})
					alert(data.message)
				}
				if (project === 'project3') {
					const { data } = await axios({
						url: api_url + '/fee-data-calling/create-conversation',
						method: 'post',
						responseType: 'json',
						data: {
							roll_no: roll_no,
							text: val,
							category: cat
						},
						...rest_headers
					})
					alert(data.message)
				}
				if (project === 'project4') {
					const { data } = await axios({
						url: api_url + '/lead-data-calling/create-conversation',
						method: 'post',
						responseType: 'json',
						data: {
							roll_no: roll_no,
							text: val,
							category: cat
						},
						...rest_headers
					})
					alert(data.message)
					hide();
					// if (isDesktop) {
					// 	hide();
					// }

				}
			}
			if (!cat) {
				alert("Enter Category");
			}
			if (!val) {
				alert("Enter Conversation Details");
			}
		} catch (e) {
			snackBar.show(handle_error(e));
		} finally {
			loader.hide();
		}



	}
	const onChange = (e) => {
		setVal(e.target.value);
		// console.log(project);

	}
	const handleChange = (e) => {
		setCat(e.target.value);
		// console.log(project);
	}

	return (
		<>

			{
				(project === 'project1')
					? <>
						<b>Category:</b> <select onChange={handleChange} value={cat}>
							<option value="">Select Category</option>
							<option value="Not Able to Connect">Not Able to Connect</option>
							<option value="Not Appearing in JEE Main 2022">Not Appearing in JEE Main 2022</option>
							<option value="Follow up">Follow up</option>
							<option value="Form not filled">Form not filled</option>
							<option value="Dont want to Update">Don't want to Update</option>
							<option value="Informed">Informed</option>
							<option value="Already Updated">Already Updated</option>
						</select>
						{/* <Select onChange={handleChange} value={cat}>
							<MenuItem value="">Select Category</MenuItem>
							<MenuItem value="Not Able to Connect">Not Able to Connect</MenuItem>
							<MenuItem value="Not Appearing in JEE Main 2022">Not Appearing in JEE Main 2022</MenuItem>
							<MenuItem value="Follow up">Follow up</MenuItem>
							<MenuItem value="Form not filled">Form not filled</MenuItem>
							<MenuItem value="Dont want to Update">Don't want to Update</MenuItem>
							<MenuItem value="Informed">Informed</MenuItem>
							<MenuItem value="Already Updated">Already Updated</MenuItem>
						</Select> */}
					</>
					: <>
						{
							(project === 'project2')
								? <>
									Category <select onChange={handleChange} value={cat}>
										<option value="">Select Category</option>
										<option value="Informed">Informed</option>
										<option value="BiometricCard Punch Issue">Biometric/Card Punch Issue</option>
										<option value="Card Lost">Card Lost</option>
										<option value="Medical Issue">Medical Issue</option>
										<option value="Personal Issue">Personal Issue</option>
										<option value="Shift To Online Mode">Shift To Online Mode</option>
										<option value="Batch Change">Batch Change</option>
										<option value="Due To School Examination">Due To School Examination</option>
										<option value="Followup">Followup</option>
										<option value="Not Able TO Connect">Not Able TO Connect</option>
										<option value="Left Reliable">Left Reliable</option>
									</select>
								</>
								: <>
									{
										(project === 'project3')
											? <>
												Category <select onChange={handleChange} value={cat}>
													<option value="">Select Category</option>
													<option value="Deposited">Deposited</option>
													<option value="Informed">Informed</option>
													<option value="Will Deposit">Will Deposit</option>
													<option value="In Touch With Senior Person">In Touch With Senior Person</option>
													<option value="Need Some Time">Need Some Time</option>
													<option value="Mode Change Online Offline">Mode Change(Online/Offline)</option>
													<option value="Fee Issue Scholarship Concession">Fee Issue (Scholarship/Concession)</option>
													<option value="Followup">Followup</option>
													<option value="Left Reliable">Left Reliable</option>
													<option value="Not Able To Connect">Not Able To Connect</option>
												</select> &nbsp;
											</>
											: <>
												{
													(project === 'project4')
														? <>
															<Grid xs={12}>
																<Select
																	label={"Select Category"}
																	value={cat}
																	onChange={handleChange}
																	variant="outlined"
																	name={'category'}
																	array={[
																		{ key: "Admission Done", value: "Admission Done" },
																		{ key: "Advanced Counselling", value: "Advanced Counselling" },
																		{ key: "Admission Done", value: "Admission Done" },
																		{ key: "Already Enrolled", value: "Already Enrolled" },
																		{ key: "Disqualified", value: "Disqualified" },
																		{ key: "Follow Up", value: "Follow Up" },
																		{ key: "Form Filled", value: "Form Filled" },
																		{ key: "Financial Issue", value: "Financial Issue" },
																		{ key: "Interested", value: "Interested" },
																		{ key: "Joined other Institute", value: "Joined other Institute" },
																		{ key: "Not Able To Connect", value: "Not Able To Connect" },
																		{ key: "Will Visit Campus", value: "Will Visit Campus" },
																		{ key: "Will come after JEE MAIN", value: "Will come after JEE MAIN" },
																		{ key: "Wil come after BOARD EXAMS", value: "Wil come after BOARD EXAMS" },
																		{ key: "Will Apply Online", value: "Will Apply Online" },
																		{ key: "Will Deposit Course Fee", value: "Will Deposit Course Fee" }
																	]}
																/>
															</Grid>
															<br />
														</>
														: <></>
												}
											</>
									}
								</>
						}
					</>
			}

			{/* <b>Remark </b><input placeholder="Enter Remark" type='text' style={{ height: 30, }} maxLength={255} value={val} onChange={onChange} />&nbsp;&nbsp; */}

			<Grid xs={12}>
				<TextField
					// label={"Remark"}
					placeholder="Enter Remark"
					type="text"
					fullWidth
					value={val}
					onChange={onChange}
				/>
			</Grid>


			<br />
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: '10px' }}>
				{/* {
					(isDesktop)
						? <> */}
				<Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={() => {

					confirm_click({ click, roll_no })
				}}>Submit</Button>&nbsp;&nbsp;

				{/* </>
						: <>
							<SendIcon color="primary" style={{ fontSize: 23, fontWeight: 900, cursor: 'pointer' }} onClick={() => {

								confirm_click({ click, roll_no })

							}} />
						</>
				} */}

				<View project={project} isDesktop={isDesktop} roll_no={roll_no} loader={loader} snackBar={snackBar} />
			</div>
			<br /><br />
			<Typography variant='h5'>Date-Time:&nbsp;&nbsp;{last_time} </Typography>


		</>
	)
}



const View = ({ roll_no, loader, snackBar, isDesktop, project }) => {
	const [array, setArray] = useState([]);
	const hide = () => {
		setArray([]);
	}
	const handleClick = async (roll_no) => {
		try {
			loader.show();
			if (project === 'project1') {
				const { data } = await axios({
					url: api_url + '/jee-main-data-calling/view-conversation',
					method: 'post',
					responseType: 'json',
					data: {
						roll_no: roll_no
					},
					...rest_headers
				})
				console.log(data);
				setArray(data.array)
			}
			if (project === 'project2') {
				const { data } = await axios({
					url: api_url + '/attendance-data-calling/view-conversation',
					method: 'post',
					responseType: 'json',
					data: {
						roll_no: roll_no
					},
					...rest_headers
				})
				console.log(data);
				setArray(data.array)
			}
			if (project === 'project3') {
				const { data } = await axios({
					url: api_url + '/fee-data-calling/view-conversation',
					method: 'post',
					responseType: 'json',
					data: {
						roll_no: roll_no
					},
					...rest_headers
				})
				console.log(data);
				setArray(data.array)
			}
			if (project === 'project4') {
				const { data } = await axios({
					url: api_url + '/lead-data-calling/view-conversation',
					method: 'post',
					responseType: 'json',
					data: {
						roll_no: roll_no
					},
					...rest_headers
				})
				console.log(data);
				setArray(data.array)
			}

		} catch (e) {
			snackBar.show(handle_error(e));
		} finally {
			loader.hide();
		}

	}

	return (
		<>
			{/* {
				(isDesktop)
					? <> */}
			<Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(roll_no)}>View</Button>
			{/* </>

					: <VisibilityIcon color="secondary" style={{ marginLeft: 5, fontSize: 23, fontWeight: 900, cursor: 'pointer' }} onClick={() => handleClick(roll_no)} />

			} */}
			{array && array.length > 0 && <Modal hide={hide}>
				<div style={{ fontFamily: 'Arial' }}>
					<table className={styles.table}>
						<thead>
							<tr>
								<th colSpan={3}>Conversation</th>
							</tr>
							<tr>
								<th>Remark</th>
								<th>Date</th>
								{
									(project === 'project4')
										? <th>Admin</th>
										: <></>
								}

							</tr>
						</thead>
						<tbody>
							{array.map((n, i) => {
								return (
									<tr style={{ fontSize: '13px' }}>
										<td>{n.text}</td>
										<td>{n.date}</td>
										{
											(project === 'project4')
												? <td>{n.admin_name}</td>
												: <></>
										}
									</tr>
								)
							})}
						</tbody>
					</table>


				</div>


			</Modal>

			}
		</>
	)
}