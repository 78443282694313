import * as React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            maxWidth: theme.spacing(48),
            height: theme.spacing(16),
            padding: theme.spacing(4),

        },
    },

}));

export default function Deposits(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Card style={{ backgroundColor: '#d6f5ff', padding: 20 }}>

                <CardContent style={{ padding: 0 }}>

                    <Typography type="subheading">
                        <b>Rank Obtained:</b>  {props.rank}
                    </Typography>
                    <br />
                    <Typography type="subheading">
                        <b>Percentile:</b>  {props.percentile}
                    </Typography>
                    <br />

                    <Typography type="subtitle">
                        <b> Total Correct Ans.:</b>  {props.count[0].total_count}
                    </Typography>
                    <br />
                    <Typography type="subtitle">
                        <b>Total Incorrect Ans.:</b>  {props.count[1].total_count}
                    </Typography>
                </CardContent>


            </Card>
            {/* <Typography component="p" variant="h6" style={{ fontSize: '1.1rem' }} gutterBottom>
                <b>Rank Obtained</b> : {props.rank} <br /><br />
                <b>Percentile</b>: {props.percentile} <br /><br />
                <b>Total Correct Ans.</b> : {props.count[0].total_count} <br /> <br />
                <b>Total Incorrect Ans.</b> : {props.count[1].total_count}  <br /><br />
                

            </Typography> */}

        </React.Fragment>
    );
}