import React from 'react';
import { Button } from '@material-ui/core';

export default function ButtomComp(props) {

    const handleClick = () => {
        props.handleMaster({ index: props.index, name: props.name, date: props.date, test_id: props.test_id });
    }

    return (
        <Button onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>View Analysis</Button>
    )

}