import { Card, Collapse, Paper, Typography, Button } from '@material-ui/core'
import React, { useState } from 'react';
import Conversation from "../reusable-calling-code-project/reuable-calling-code/conversation";
import Callback from '../reusable-calling-code-project/reuable-calling-code/callback';
import TransferComp from './tranfer-comp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CallIcon from '@material-ui/icons/Call';
import styles from "../reusable-calling-code-project/table.module.css";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CollapseComp from './collapse-comp';

const PaperComp = ({ list, handleCategoryFilter, handleClass, handleCallbackFilter, utmf, sourcef, tested, handleTestDate, handleSource, handleUtmSource, project, snackBar, loader, isDesktop, ct }) => {

    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(false);


    const handleShow = () => {
        setHide(false);
        setShow(!show);
    }

    const handleHide = () => {
        setShow(false);
        setHide(!hide);

    }


    return (
        <Paper style={{ backgroundColor: getPaperColor(list), padding: '16px' }}>
            <Typography style={{ textAlign: 'center' }}><b>{list[0].name}</b>&nbsp;&nbsp;&nbsp;<b>Count:</b>&nbsp; {ct}</Typography>
            <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleShow}>Show All</Button>&nbsp;&nbsp;&nbsp;
            <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleHide}>Latest</Button>
            {list.map((n, i) => {
                return (
                    <>
                        {
                            (show)
                                ? <>
                                    <Card style={{ backgroundColor: 'seashell', width: '100%', padding: '5px' }}>
                                        <label><b>Name:</b>&nbsp; {n.name}</label><br />
                                        <label><b>Last Category:</b>&nbsp; {n['last_category']}</label>
                                        <CollapseComp handleClass={handleClass} handleCallbackFilter={handleCallbackFilter} handleCategoryFilter={handleCategoryFilter} tested={tested} handleTestDate={handleTestDate} utmf={utmf} sourcef={sourcef} handleUtmSource={handleUtmSource} handleSource={handleSource} n={n} i={i} project={project} snackBar={snackBar} loader={loader} isDesktop={isDesktop} />
                                    </Card>
                                    <br /><br />
                                </>
                                : <></>
                        }
                        {
                            (hide)
                                ? <>
                                    {
                                        (i == list.length - 1)
                                            ? <>
                                                <Card style={{ backgroundColor: 'seashell', width: '100%', padding: '5px' }}>
                                                    <label><b>Name:</b>&nbsp; {n.name}</label><br />
                                                    <label><b>Last Category:</b>&nbsp; {n['last_category']}</label>
                                                    <CollapseComp handleClass={handleClass} handleCallbackFilter={handleCallbackFilter} handleCategoryFilter={handleCategoryFilter} tested={tested} handleTestDate={handleTestDate} utmf={utmf} sourcef={sourcef} handleUtmSource={handleUtmSource} handleSource={handleSource} n={n} i={i} project={project} snackBar={snackBar} loader={loader} isDesktop={isDesktop} />
                                                </Card>
                                                <br /><br />
                                            </>
                                            : <></>
                                    }
                                </>
                                : <></>
                        }
                    </>
                )
            })}
        </Paper>
    )
}

const getPaperColor = (list) => {

    let color = '#F2FFEF';

    let i, tmp, l_len = list.length;

    for (i = 0; i < l_len; i++) {

        tmp = list[i].fee;

        if (tmp == 'Y') {
            color = '#caf5be';
            break;
        }



    }


    return color;
}


export default PaperComp;


