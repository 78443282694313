import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { LoaderContext } from "../../../../../../context/LoaderContext";
import styles from '../../reusable-calling-code-project/table.module.css';
import { Paper, Typography } from '@material-ui/core';

const CourseWiseCount = () => {


    const loader = useContext(LoaderContext);

    const [res, setRes] = useState([]);

    // const api_url = 'http://localhost:8060/mis/';
    const api_url = 'https://api-dot-reformexam.el.r.appspot.com/mis/';

    useEffect(async () => {
        try {
            loader.show();
            const { data: obj } = await axios({
                url: api_url + 'course-mis-2023',
                method: 'post',
                responseType: 'json',
            });

            console.log(obj);

            if (obj.success === 'ok') {
                setRes(obj.data);
            }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide()
        }
    }, [])


    if (res.length === 0)
        return <></>


    return (
        <>
            <Paper component={Paper} style={{ padding: '16px' }}>
                <table className={styles.table} style={{ fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th colSpan={6}>Course Wise MIS</th>
                        </tr>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Course Code</th>
                            <th>Course Title</th>
                            <th>New Student Count</th>
                            <th>Old Student Count</th>
                            <th>Total Admission</th>
                        </tr>
                    </thead>
                    <tbody>
                        {res.map((ele, i) => (
                            <tr>
                                <td>{i + 1}</td>
                                <td>{ele.course}</td>
                                <td>{ele.coursename}</td>
                                <td>{ele.new_count}</td>
                                <td>{ele.old_count}</td>
                                <td>{ele.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Paper>
        </>
    )
}

export default CourseWiseCount
