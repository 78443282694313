import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange }) => {
	let array=[];
	let arr=['inbound','outbound','walk_in','whatsapp','cc_sms','sms_campaign'];
	for(let i=0;i<arr.length;i++){
		array.push({key:arr[i],value:arr[i]})
	}
	
	 
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={array}
    />
  );
};
