import React, { useState, useContext } from 'react'
import axios from 'axios';
import { api_url } from "./config";
import { TextField, Typography } from "@material-ui/core";
import { Button, MenuItem, Grid } from "@material-ui/core";
import { Paper, InputLabel, Select } from "@material-ui/core";
import { LoaderContext } from "../../../../../context/LoaderContext";

const FormPunch = () => {

    const loader = useContext(LoaderContext);

    const [roll, setRoll] = useState('');
    const [name, setName] = useState('');
    const [classs, setClasss] = useState('');
    const [manager, setManager] = useState(false);
    const [admin, setAdmin] = useState('');

    const hClick = async () => {
        try {
            loader.show();

            if (roll.length != 8) {
                alert('Enter Roll Number Correctly')
                return false;
            }

            if (name.length == 0) {
                alert("Enter Name Correctly");
                return false;
            }

            if (classs.length == 0) {
                alert("Enter Class Correctly");
                return false;
            }

            const { data: obj } = await axios({
                url: api_url + '/form-punch',
                method: 'post',
                responseType: 'json',
                data: {
                    roll: roll,
                    name: name,
                    classs: classs,
                    admin: 15321,
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            console.log(obj);

            if (obj.success === 'ok') {
                alert("Form Punched Successfully");
            }

            if (obj.success === 'false') {
                alert(obj.message)
            }

        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }

    const handleClick = async () => {
        try {

            loader.show()

            if (roll.length != 8) {
                alert("Enter Roll Number");
                return false;
            }
            if (name.length == 0) {
                alert("Enter Category");
                return false;
            }
            if (classs.length == 0) {
                alert("Enter Amount");
                return false;
            }
            if (admin.length == 0) {
                alert("Enter Admin Id");
                return false;
            }


            const { data: obj } = await axios({
                url: api_url + '/form-punch',
                method: 'post',
                responseType: 'json',
                data: {
                    roll: roll,
                    name: name,
                    classs: classs,
                    admin: admin,
                },
                headers: {
                    APIKEY: '##reliable_rest_api_token##',
                    ADMINTOKEN: localStorage.getItem('admin_token')
                }
            })

            console.log(obj)

            if (obj.success === 'ok') {
                alert("Form punched Successfully")
            }

            if (obj.success === 'false') {
                alert(obj.message)
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }
    }

    return (
        <>

            <Button onClick={() => setManager(true)} style={{ backgroundColor: '#AEAEAE', color: '#000' }}>
                Managers
            </Button>
            <br />
            <br />
            <Button onClick={() => setManager(false)} style={{ backgroundColor: '#AEAEAE', color: '#000' }}>
                Admin Counsellors
            </Button>
            <br />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"

            >

                <Paper style={{ padding: '10px', backgroundColor: 'white', width: "400px", height: 'auto', paddingLeft: "20px" }}>
                    {/* <Typography>Enter Roll NO</Typography> */}
                    {!manager && <><TextField label="Roll No" value={roll} onChange={(e) => setRoll(e.target.value)} />
                        <br></br>
                        <br></br>

                        <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <InputLabel>Select Moving Class</InputLabel>
                        <Select value={classs} onChange={(e) => setClasss(e.target.value)} label="Select Moving Class" style={{ width: '190px' }}>
                            <MenuItem value="6">6th</MenuItem>
                            <MenuItem value="7">7th</MenuItem>
                            <MenuItem value="8">8th</MenuItem>
                            <MenuItem value="9">9th</MenuItem>
                            <MenuItem value="10">10th</MenuItem>
                            <MenuItem value="11">11th</MenuItem>
                            <MenuItem value="12">12th</MenuItem>
                            <MenuItem value="13">12th Passed</MenuItem>
                        </Select>
                        <br></br>
                        <br></br>
                        <br></br>
                        <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={hClick}>Submit</Button> </>}

                    {manager && <>

                        <TextField label="Roll No" value={roll} onChange={(e) => setRoll(e.target.value)} />
                        <br></br>
                        <br></br>

                        <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <InputLabel>Select Moving Class</InputLabel>
                        <Select value={classs} onChange={(e) => setClasss(e.target.value)} label="Select Moving Class" style={{ width: '190px' }}>
                            <MenuItem value="6">6th</MenuItem>
                            <MenuItem value="7">7th</MenuItem>
                            <MenuItem value="8">8th</MenuItem>
                            <MenuItem value="9">9th</MenuItem>
                            <MenuItem value="10">10th</MenuItem>
                            <MenuItem value="11">11th</MenuItem>
                            <MenuItem value="12">12th</MenuItem>
                            <MenuItem value="13">12th Passed</MenuItem>
                        </Select>
                        <br></br>
                        <br></br>
                        <br></br>
                        <TextField label="Enter Admin Id" value={admin} onChange={(e) => setAdmin(e.target.value)} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <Button type="submit" fullWidth style={{ backgroundColor: '#F49093', color: '#fff' }} onClick={handleClick}>Submit</Button>


                    </>}
                </Paper>
            </Grid>

        </>
    )
}

export default FormPunch
