import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { api_url } from "../reusable-calling-code-project/config";
import styles from "../reusable-calling-code-project/table.module.css";
import MediaTableComp from './media-table-comp';
import TableComp from './table-comp';
import { LoaderContext } from "../../../../../context/LoaderContext";
import DailyTransferTableComp from './daily-report-table';

const ViewCallingReports = () => {

    const loader = useContext(LoaderContext);

    const [array, setArray] = useState([]);
    const [result, setResult] = useState([]);
    const [dailytransfer, setDailyTransfer] = useState([]);
    const [yesterdaytransfer, setYesterdayTransfer] = useState([]);
    const [backtransfer, setBacktransfer] = useState([]);
    const [dailytouch, setDailyTouched] = useState([]);
    const [yesterdaytouch, setYesterdayTouched] = useState([]);
    const [beforetouch, setBeforeTouched] = useState([]);


    useEffect(async () => {

        try {
            loader.show();
            const { data: obj } = await axios({
                url: api_url + '/view-calling-reports/calling-reports',
                method: 'post',
                responseType: 'json',
            })

            console.log(obj);
            setResult(obj.score_card);
            setDailyTransfer(obj.daily_transfer);
            setYesterdayTransfer(obj.yesterday_transfer);
            setBacktransfer(obj.back_transfer);
            setDailyTouched(obj.daily_touched);
            setBeforeTouched(obj.before_touched);
            setYesterdayTouched(obj.yesterday_touched);
            // setArray(obj.media_count);
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }

    }, [])

    return (
        <div>
            <Paper component={Paper} style={{ padding: '16px' }}>
                <Typography style={{ textAlign: 'center' }} variant="h4">Detailed Leads Records</Typography><br />
                <TableComp result={result} />
            </Paper>
            {/* <br />
            <MediaTableComp mediaarray={array} /> */}
            <br />
            <Paper component={Paper} style={{ padding: '16px' }}>
                <Typography style={{ textAlign: 'center' }} variant="h4">Leads Tranfer Records</Typography>
                <br />
                <DailyTransferTableComp daily={dailytransfer} name={"Today's Transfer Report"} title={"Transfered"} />
                <br />
                <DailyTransferTableComp daily={yesterdaytransfer} name={"Yesterday's Transfer Report"} title={"Transfered"} />
                <br />
                <DailyTransferTableComp daily={backtransfer} name={"Before Yesterday's Transfer Report"} title={"Transfered"} />
            </Paper>
            <br />
            <Paper component={Paper} style={{ padding: '16px' }}>
                <Typography style={{ textAlign: 'center' }} variant="h4">Leads Touched Records</Typography>
                <br />
                <DailyTransferTableComp daily={dailytouch} name={"Leads Tocuhed Today"} title={"Touched"} />
                <br />
                <DailyTransferTableComp daily={yesterdaytouch} name={"Leads Tocuhed Yesterday"} title={"Touched"} />
                <br />
                <DailyTransferTableComp daily={beforetouch} name={"Leads Tocuhed Before Yesterday"} title={"Touched"} />
            </Paper>
        </div>
    )
}

export default ViewCallingReports
