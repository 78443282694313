import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange,stream_list }) => {
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={stream_list}
    />
  );
};
