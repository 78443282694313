import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../config'
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { Select } from "../../../../../../utils/select";
import { Select as SelectMultiple } from "../../../../../../utils/select/select-multiple"
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
import moment from 'moment';
const axios = require('axios');


export default (props) => {

  const year = 2024;
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
      year={year}
      {...props}
    />
  );
};
const Comp = ({ loader, snackBar, year, ...props }) => {

  const [show, setShow] = useState(false);
  const [res, setRes] = useState([]);
  const [batch_list, setBL] = useState([]);

  const [batch_id, setBatchId] = useState('')
  const [dates, setDates] = useState([])

  const refresh = () => {

    fetch2()
  }

  let date_list = []
  const d1 = moment(new Date(), "YYYY-MM-DD").format('YYYY-MM-DD')
  let i = 0;
  let key, value;
  for (i = 0; i < 10; i++) {
    key = moment(new Date(), "YYYY-MM-DD").add((i - 7), 'days').format('YYYY-MM-DD')
    value = moment(new Date(), "YYYY-MM-DD").add((i - 7), 'days').format('YYYY-MM-DD dddd')

    if (value.indexOf('Sunday') < 0)
      date_list.push({ key, value });
  }

  useEffect(async () => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    fetch2();
  }, [])



  const fetch2 = async () => {
    try {

      loader.show();
      const { data } = await axios({
        url: api_url + '/batch-list',
        method: 'post',
        responseType: 'json',
        data: {
          year: year
        },
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })



      setBL(data.list)



    } catch (e) {

      snackBar.show(e.message);
    } finally {
      loader.hide();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      if (!batch_id || batch_id.length < 1) {
        alert('Select Batch');
        return
      }
      if (!dates || dates.length < 0) {
        alert('Select Dates');
        return
      }
      console.log(batch_id)
      console.log(dates)
      loader.show();
      const { data } = await axios({
        url: api_url + '/upload-tt',
        method: 'post',
        responseType: 'json',
        data: {

          batch_id: batch_id,
          dates: dates,


        },
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })
      alert(data.message);
      setBatchId(''); setDates([])

    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };



  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayouttopic title="Create Time Table">
          <Grid spacing={3} container>

            <Grid item xs={12}>
              {batch_list && batch_list.length > 0 && <Select

                label={"Select Batch"}
                value={batch_id}
                name={'batch_id'}
                onChange={(e) => { setBatchId(e.target.value) }}
                array={batch_list}
              />}
            </Grid>
            <Grid item xs={12}>
              <SelectMultiple

                label={"Select Dates"}
                value={dates}
                name={'dates'}
                onChange={(e) => { setDates(e.target.value) }}
                array={date_list}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    </form>
  )
}
