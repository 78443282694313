import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import TestInfo from "./test-info";
import YourScoreVsAvgScore from "./your-score-vs-average-score";
import ScoreCard from "./scorecard";
import SubjectWiseQuestionStatus from "./subject-wise-question-status";
import { useQuery } from "@apollo/client";
import { classroom_test_result_individual_gql } from "../../../../gql/classroom_test_result_individual_gql";

export default () => {
  let { test_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  const res = useQuery(classroom_test_result_individual_gql, {
    variables: {
      classroom_test_id: parseInt(test_id, 10),
    },
  });

  const { loading, error, data, refetch } = res;
  if (loading) return <span>Loading...</span>;
  if (error) { 
    console.log(error)
    return <span>{error.message}</span>; 
  }
 console.log(data)
  return (
    <Comp navigate={navigate} data={data.classroom_test_result_individual} />
  );
};

class Comp extends React.Component {
  state = {
    loading: true,
  };
  hide = () => {
    this.setState({ loading: false });
  };
  render() {
    const { data, navigate } = this.props;

    return (
      <>
        <Card>
          <CardHeader
            avatar={
              <IconButton
                color="primary"
                component="span"
                onClick={() => {
                  navigate("../../classroom-test-result");
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            }
            title={data.test_info.name}
          />
          <Divider />

          <CardContent>
            <TestInfo test_info={data.test_info} />
            <br />
            <ScoreCard data={data} />
            <br />

            <YourScoreVsAvgScore data={data} />

            <br />

            <SubjectWiseQuestionStatus data={data} />
          </CardContent>
        </Card>
      </>
    );
  }
}
