import React, { useContext, useState,useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutchildsubject from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify, 
} from "../../../../../../utils/functions";
import Typography from "@material-ui/core/Typography";
import SelectState from "../../../components/call-center--select-state"; 
import SelectStream from "../../../components/call-center--select-stream"; 

import SelectCity from "../../../components/call-center--select-city"; 
import SelectClass from "../../../components/call-center--select-class"; 
import SelectCourse from "../../../components/call-center--select-course"; 



import {api_url} from '../config.js'

const axios = require('axios');
export default () => {
   
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
    
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar }) => {
	
	 
	let [mobile,setMobile]=useState('');
	let [cb_date,setCBDate]=useState('');
	
	
   
  
  const handleSubmit = async (e) => {
    e.preventDefault();

      
 
  try {
 
   loader.show();
if(mobile.length!=10)
{
	snackBar.show('Enter valid Mobile number');
	return false;
}
if(cb_date.length!=16)
{
	snackBar.show('Enter valid valid date');
	return false;
}

  const  {data}=await axios({
		url:api_url+'/create-callback.php',
        method: 'post',
		responseType: 'json',
       data: `auth_token=##reliabletoken##&mobile=${mobile}&cb_date=${cb_date}&admin_token=${localStorage.getItem('admin_token')}`
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
  
 
 
snackBar.show(data.message.message);
      
 setMobile(''); setCBDate('');
      
     // resetForm({ values, setValues });
    } catch (e) {
		 
      //snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 


  

  
  
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutchildsubject title="Set Call Back">
          <Grid spacing={3} container>
             
			  <Grid item xs={12}>
              <TextField
                label="Enter Mobile No."
                variant="outlined"
                name="mobile"
				 inputProps={{
					maxLength: 10,
				  }}
                value={mobile}
                 onChange={(e)=>{setMobile(e.target.value)}}
			 
              />
            </Grid>
			 <Grid item xs={12}>
               <input style={{minWidth:'220px',height:'50px',border:'#999 solid 1px',borderRadius:'5px'}} type="datetime-local" value={cb_date} name="cb_date"  onChange={(e)=>{setCBDate(e.target.value)}} />
            </Grid>
			 
            

              
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutchildsubject>
      </Container>
    </form>
  );
};
