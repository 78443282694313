import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import external_url_handler from "../external_url_handler";
import Button from "@material-ui/core/Button";
const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
});

class SimpleSnackbar extends React.Component {
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.hideSnackBar();
  };

  render() {
    const { classes, text, action_text, action_url } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open
          autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{text}</span>}
          action={
            <>
              {action_text ? (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => external_url_handler(action_url)}
                >
                  {action_text}
                </Button>
              ) : (
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </>
          }
        />
      </div>
    );
  }
}

SimpleSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const SnackBar = withStyles(styles)(SimpleSnackbar);
