import { gql } from "@apollo/client";

export const topic_list_query_gql = gql`
  query topic_list($childsubject_id: Int!, $class_id: Int!) {
    topic_list(childsubject_id: $childsubject_id, class_id: $class_id) {
      key: id
      value: name
    }
  }
`;
