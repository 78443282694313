import { gql } from "@apollo/client";

export const create_notification_mutation_gql = gql`
  mutation create_notification(
    $title: String!
    $type: String!
    $description: String!
  ) {
    create_notification(
      title: $title
      type: $type
      description: $description
    ) {
      success
    }
  }
`;
