import { Paper } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import styles from '../reusable-calling-code-project/table.module.css';
import { LoaderContext } from "../../../../../context/LoaderContext";
import { api_url } from './config.js';
export default function AttendanceFeeMis(){
    const loader = useContext(LoaderContext);
   const [result,setResult] = useState([]);
//    const [followup,setFollowup] = useState([]);
   const [data,setData] = useState([]);
   const [p_data,setP_data] = useState([]);
   useEffect(async()=>{
    try{
      loader.show();
      const {data:obj} = await axios({
        url: api_url + '/get-data',
        method:"post",
        responseType:"json"
      })
    //   console.log(obj);
      setResult(obj.array);
    //   setFollowup(obj.sql);
      setData(obj.test);
      setP_data(obj.arr);
    
    }
    catch(e){
       alert(e.message)
    }
    finally{
    loader.hide()
    }
   },[])
    return(
        <div>
            <Paper component={Paper} style={{ padding: '16px' }}>
             <table className={styles.table} style={{ fontSize: '13px' }}>
              <thead>
                <tr>
                    <th colSpan={4}>Attendance Calling</th>
                </tr>
                <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Calling Count</th>
                    <th>Date</th>
                </tr>
              </thead>
              <tbody>
              {result.map((ele,i)=>{
                return(
                    <tr>
                        <td>{i+1}</td>
                        <td>{ele.admin}</td>
                        <td>{ele.count}</td>
                        <td>{ele.date}</td>
                    </tr>
                )
              })}
              </tbody>
             </table>
            </Paper>
            <br></br>
            {/* <Paper component={Paper} style={{ padding: '16px' }}>
             <table className={styles.table} style={{ fontSize: '13px' }}>
              <thead>
                <tr>
                    <th colSpan={4}>Attendance Followup</th>
                </tr>
                <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Followup Count</th>
                    <th>Date</th>
                </tr>
              </thead>
              <tbody>
              {followup.map((ele,i)=>{
                return(
                    <tr>
                        <td>{i+1}</td>
                        <td>{ele.admin}</td>
                        <td>{ele.count}</td>
                        <td>{ele.date}</td>
                    </tr>
                )
              })}
              </tbody>
             </table>
            </Paper> */}
            
            <Paper component={Paper} style={{ padding: '16px' }}>
             <table className={styles.table} style={{ fontSize: '13px' }}>
              <thead>
                <tr>
                    <th colSpan={4}>Fee Calling</th>
                </tr>
                <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Calling Count</th>
                    <th>Date</th>
                </tr>
              </thead>
              <tbody>
              {data.map((ele,i)=>{
                return(
                    <tr>
                        <td>{i+1}</td>
                        <td>{ele.admin}</td>
                        <td>{ele.count}</td>
                        <td>{ele.date}</td>
                    </tr>
                )
              })}
              </tbody>
             </table>
            </Paper>
            <br></br>
            <Paper component={Paper} style={{ padding: '16px' }}>
             <table className={styles.table} style={{ fontSize: '13px' }}>
              <thead>
                <tr>
                    <th colSpan={4}>Fee Followup</th>
                </tr>
                <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Followup Count</th>
                    <th>Date</th>
                </tr>
              </thead>
              <tbody>
              {p_data.map((ele,i)=>{
                return(
                    <tr>
                        <td>{i+1}</td>
                        <td>{ele.admin}</td>
                        <td>{ele.count}</td>
                        <td>{ele.date}</td>
                    </tr>
                )
              })}
              </tbody>
             </table>
            </Paper>
           
           
        </div>
    )
}