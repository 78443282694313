import React from "react";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import Typography from "@material-ui/core/Typography";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutstudent from "../../../../../utils/form-layout-admin";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import Comp from "./comp";
import axios from 'axios';

import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";

export default () => {
  const [values, setValues] = React.useState({
    roll_no: ""
  });
  const [rollno, setRollno] = React.useState("");
  const [viewProfile, setViewProfile] = React.useState([])
  const [profile, setProfile] = React.useState([])
  const [show, setShow] = React.useState(false)
  const [hide, setHide] = React.useState(false)
  const [attendance, setAttendance] = React.useState(false)
  const [result, setResult] = React.useState(false)
  const snackBar = React.useContext(SnackBarContext);
  const loader = React.useContext(LoaderContext);
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };
  const handleSubmit = async (e) => {
    try {
      loader.show();

      e.preventDefault();
      const { roll_no } = values;
      setRollno(roll_no)

      const { data: obj } = await axios({
        url: 'https://api-dot-reformexam.el.r.appspot.com/reliablekotaapp/view-student',
        method: 'post',
        responseType: 'json',
        data: {
          roll_no: roll_no,
        }
      })

      if (obj.Success === 'ok') {

        setViewProfile(obj.view);
        setProfile(obj.profile);
        setShow(true);
        setAttendance(true);
        setResult(true);

      }

      if (obj.Success === 'false') {
        snackBar.show(obj.message)
      }

    }

    catch (e) {
      snackBar.show(e.message);
    }

    finally {
      loader.hide();
    }



  }

  return (
    // <>
    //   {view_student.map((n, i) => {
    //     return (
    <>
      <form >
        <Container>
          <FormLayoutstudent title="View Student">
            <Grid spacing={3} container>
              <Grid item xs={8}>
                <TextField
                  label="Enter Roll No"
                  variant="outlined"
                  name="roll_no"
                  fullWidth
                  value={values.roll_no}
                  onChange={handleInputChange}
                /></Grid>
              <Grid item xs={4}>
                <Button style={{ marginTop: '10px' }} onClick={handleSubmit} variant="contained" color="primary">
                  Submit
                </Button></Grid>
              <Grid item xs={12}>
                <Comp viewProfile={viewProfile} profile={profile} show={show} hide={hide} setHide={setHide} />
              </Grid>
              {attendance && <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={() => window.open(`https://api-dot-reformexam.el.r.appspot.com/attendance-data-calling/print-student-attendance?rollno=${rollno}`, '_blank')}>Attendance</Button>
              </Grid>}
              {result && <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={() => window.open(`https://api-dot-reformexam.el.r.appspot.com/saper-2024/get-data?rollno=${rollno}&type=rollno&mode=print`, '_blank')}>Get Result</Button>
              </Grid>}
            </Grid>
          </FormLayoutstudent>
        </Container>
      </form>
    </>
  );
}
//       )}
//      </>
//   );
// };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));


const Comp = ({ viewProfile, profile, show, hide, setHide }) => {

  const classes = useStyles();



  return (
    <>

      {show &&

        <>

          {viewProfile.length < 1 && <> <p>No Record Found</p> <br /><br /><Button variant="contained" color="primary" onClick={() => setHide(true)}>View Profile</Button> </>}

          {viewProfile.length > 0 && <List className={classes.root}>
            <ListItem>
              <ListItemText
                primary={<>{viewProfile[0].student}</>}
                secondary={<>
                  Batch - {viewProfile[0].name} <br />
                  Email - {viewProfile[0].email} <br />
                  Mobile - {viewProfile[0].mobile} <br />
                  Gender - {viewProfile[0].gender} <br />
                  Status - {viewProfile[0].status}
                  <br /><br />
                  <Button variant="contained" color="primary" onClick={() => setHide(true)}>View Profile</Button>
                </>}
              />
            </ListItem></List>}


          {hide && <>


            <List className={classes.root}>
              <ListItem>
                <ListItemText
                  primary={<>{profile[0].name}</>}
                  secondary={<>
                    Gender - {profile[0].gender} <br />
                    Mobile-1 - {profile[0].mobile1} <br />
                    Mobile-2 - {profile[0].mobile2} <br />
                    Email - {profile[0].email} <br />
                    Course Title - {profile[0].coursetitle} <br />
                    Course Code - {profile[0].course} <br />
                    Fee Status - {profile[0].fee} <br />
                    Form Date - {profile[0].fdate} <br />
                    Deposited Fee - {profile[0].amount_deposited_total} <br />
                    Remaining Fee - {profile[0].less_amount} <br />
                    Medium - {profile[0].medium}
                  </>}
                />
              </ListItem>
            </List>


          </>}


        </>


      }



    </>
  )
}