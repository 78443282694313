import { gql } from "@apollo/client";

export const create_phase_mutation_gql = gql`
  mutation create_phase(
    $name: String!
    $alias: String!
    $description: String
    $start_date: String!
    $end_date: String!
    $session_id: ID!
    $course_id: ID!
  ) {
    create_phase(
      name: $name
      alias: $alias
      description: $description
      start_date: $start_date
      end_date: $end_date
      session_id: $session_id
      course_id: $course_id
    ) {
      success
    }
  }
`;
