import { gql } from "@apollo/client";
import classroom_test_inner_common_gql from "./classroom_test_inner_common_gql";

export const classroom_test_all_test_result_gql = gql`
  query classroom_test_all_test_result($limit: Int!) {
    classroom_test_all_test_result(limit: $limit) {
     ${classroom_test_inner_common_gql}
    }
  }
`;
