import { gql } from "@apollo/client";

export const create_course_mutation_gql = gql`
  mutation create_course(
    $name: String!
    $alias: String!
    $description: String
    $division_id: ID!
    $class_id: ID!
  ) {
    create_course(
      name: $name
      alias: $alias
      description: $description
      division_id: $division_id
      class_id: $class_id
    ) {
      success
    }
  }
`;
