import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutliveclass from "../../../../../utils/form-layout-admin";
import SelectSessionBatch from "../../components/select-batch-sessionwise";
import SelectQuiz from "../../components/select-quiz";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_test_analysis_mutation_gql } from "../../../gql/mutation/create-test-analysis-mutation";

export default () => {
  const [create_classroom_test_analysis] = useMutation(
    create_test_analysis_mutation_gql
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_test_analysis_mutation={create_classroom_test_analysis}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_test_analysis_mutation }) => {
  const [values, setValues] = useState({
    batch_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const {
        quiz_batch_id
      } = values;

      loader.show();

      let {
        data: {
          create_liveclass: { success },
        },
      } = await create_test_analysis_mutation({
        variables: {
          classroom_test_batch_id : quiz_batch_id
        },
      });

      if (success) snackBar.show("Analysis created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutliveclass title="Create Test Analysis">
          <Grid spacing={3} container>

            <Grid item xs={12}>
            <SelectSessionBatch
              label="Select Batch"
              onChange={handleInputChange}
              name="batch_id"
              value={values.batch_id}
            />
            </Grid>
            <Grid item xs={12}>
            <SelectQuiz
              label="Select Quiz"
              batch_id={values.batch_id}
              onChange={handleInputChange}
              name="quiz_batch_id"
              value={values.quiz_batch_id}
            />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutliveclass>
      </Container>
    </form>
  );
};
