import React, { useContext, useState,useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange, 
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
 import { Select } from "../../../../../../utils/select";
import Typography from "@material-ui/core/Typography";
 import {api_url,rest_headers} from '../../../../../student/app/srceens/digital-classroom/config'
import {handle_error} from '../../call-center/handle_error'
import RaiseTicket from '../../../../../student/app/srceens/support-ticket'
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,

 
} from "@material-ui/core";
import {CardComp} from '../../../../../student/app/srceens/support-ticket'
const axios = require('axios');

export default () => {
	  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
 
    return (
    <Comp
     snackBar={snackBar}
      loader={loader}
    />
  );
}; 
const Comp = ({snackBar,loader}) => {
   const [pending,setPending]=useState(true);
   
    const [res, setRes] = useState([]);
	 useEffect(()=>{
		fetch() 
	 },[])
	  const fetch=async()=>{
	  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/ticket-list',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##'
}
      }) 
	    
	 
 
 setRes( data.list)
 
      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  } 
	
   
   
  return (
    
      <Container>
        <FormLayouttopic title="Manage Tickets">
          <Grid spacing={3} container>
		     <Grid item>
		
            <Button variant="outlined"  onClick={()=>setPending(true)}>View Pending Tickets</Button>
			&nbsp;&nbsp;<Button variant="outlined"    onClick={()=>setPending(false)}>View Closed Tickets</Button>
           	 <br/> <br/>
           {pending?<Tickets pending res={res} snackBar={snackBar} loader={loader}  />:
		   <Tickets closed res={res} snackBar={snackBar} loader={loader}  />
		   }

            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    
  );
};


const Tickets=({res,pending,closed,snackBar, loader})=>{
	
	return(
	<div>
	
	{res && res.length>0 && 
  
  res.map((n,i)=>{
	  if(pending && n.status=='Pending')
	  return  <><CardComp {...n} admin snackBar={snackBar} loader={loader}  /><br/></>
	  else if(closed && n.status=='Closed')
	  return  <><CardComp {...n} admin snackBar={snackBar} loader={loader}  /><br/></>
  })
  }
	
	</div>
	)
}
 
  