let isElectron = require("is-electron");
let machineIdSync;

if (isElectron()) machineIdSync = require("node-machine-id").machineIdSync;

export default () => {
  if (isElectron()) return machineIdSync({ original: true });
 

 let uid=window.localStorage.getItem("uniqid");
  if(!uid){
	  let juuid=  "id" + Math.random().toString(16).slice(2)
	window.localStorage.setItem("uniqid",juuid);
 uid=juuid
  }
    return uid;
}