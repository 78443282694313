import React from "react";
import { useLazyQuery  } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { get_zoom_start_url_gql } from "../../../gql/query/get-zoom-start-url-query";
import GqlErrorComp from "../../../../../utils/gql-error-comp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function FolderList({ info }) {
  const classes = useStyles();
  const [values,setValues] = React.useState({zoom_meeting_id:"",zoom_email:""})
  const [ getURL, { loading, error, data }] = useLazyQuery(get_zoom_start_url_gql,{
    variables: values,
  });
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  
  if (data && data.get_zoom_start_url) {
    window.open(
      data.get_zoom_start_url.zoom_start_url,
      "_blank"
    )
  }
  const handleZoomUrl = (zoom_meeting_id,zoom_email) => {
    setValues({zoom_meeting_id, zoom_email})
    getURL()
  }

  

  return (
    <List className={classes.root}>
      {info.map((n, i) => {
        console.log(n);
        return (
          <ListItem key={i}>
            <ListItemText
              primary={n.batch.name}
              secondary={<>
                {n.start_time} - {n.end_time} <br />
                ZoomID - {n.zoom_account.email}
                </>}
            />
            <ListItemSecondaryAction>
              <Button
                onClick={() =>
                  handleZoomUrl(n.meeting_no,n.zoom_account.email)
                }
                variant="contained"
                color="primary"
                disabled={n.disabled}
              >
                Start
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}
