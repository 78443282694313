import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from '../table.module.css'; 
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
	background:'#fce4ec'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
   
}));

export default function RecipeReviewCard({array}) {
 
  const classes = useStyles();
 let avatar={};
 
if(array.length<1)
return(
<></>
)

 
return(
<> 
  <TableContainer component={Paper}>
     <table className={styles.table}>
        <thead>
		   <tr ><th  colSpan="6" className={styles.typogrpahy_header} > Leads</th></tr>
          <tr>
            <th>Name</th>
            <th  >Mobile</th>
			<th  >Mobile1</th>
			
            
          </tr>
        </thead>
		  <tbody>
  
  {
	  array.map(({name,mobile,mobile1})=>{
		  
		  return(
		 <tr>
		   <td>{name}</td> <td>{mobile}</td>  <td>{mobile1}</td> 
		  </tr>
		  )
	  })
  }
    </tbody>
  </table>
  </TableContainer >
  
 </>
)
 
  
  
} 