import React from 'react'
import { useState } from 'react';
import Modal from '../reusable-calling-code-project/modal';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SmsIcon from '@material-ui/icons/Sms';
import axios from 'axios';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import { Paper, Select, MenuItem, Button, InputLabel } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import styles from '../reusable-calling-code-project/table.module.css';

const LeadOwnerComp = ({ ORDERID, loader }) => {

    const [show, setShow] = useState(false);
    const [array, setArray] = useState([]);

    const handleClick = async () => {
        try {
            loader.show()

            const { data: obj } = await axios({
                url: api_url + '/lead-data-calling/get-transfer-record',
                method: 'post',
                responseType: 'json',
                data: {
                    ORDERID: ORDERID,
                },
                ...rest_headers,
            })
            if (obj.Success === 'ok') {
                hide();
                setArray(obj.array);
            }
        }
        catch (e) {
            alert(e.message);
        }
        finally {
            loader.hide();
        }
    }


    const hide = () => {
        setShow(!show);
    }



    return (
        <div>

            <Visibility onClick={handleClick} />

            {show && <Modal hide={hide}>
                <Paper style={{ padding: '16px', minWidth: '200px', alignItems: 'center' }} component={Paper}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th colSpan={2}>Transfer Record</th>
                            </tr>
                            <tr>
                                <th>Admin Name</th>
                                <th>Transfer Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {array.map((ele, i) => (
                                <tr>
                                    <td>{ele.name}</td>
                                    <td>{ele.transfer_date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Paper>
            </Modal>}


        </div>
    )
}

export default LeadOwnerComp;
