import { gql } from "@apollo/client";

export const create_topic_mutation_gql = gql`
  mutation create_topic(
    $name: String!
    $alias: String!
    $childsubject_id: Int!
    $class_id: Int!
  ) {
    create_topic(
      name: $name
      alias: $alias
      childsubject_id: $childsubject_id
      class_id: $class_id
    ) {
      success
    }
  }
`;
