import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutAdmin from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_admin_mutation_gql } from "../../../gql/mutation/create-admin-mutation";
import Typography from "@material-ui/core/Typography";
export default () => {
  const [create_admin_mutation] = useMutation(create_admin_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_admin_mutation={create_admin_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_admin_mutation }) => {
  const [values, setValues] = useState({
    name: "",
    username: "",
    password: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { name, username, password } = values;

      loader.show();

      let {
        data: {
          create_admin: { success },
        },
      } = await create_admin_mutation({
        variables: { name, username, password },
      });

      if (success) snackBar.show("admin created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutAdmin title="Create Admin">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                label="Enter Name"
                variant="outlined"
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Username"
                variant="outlined"
                name="username"
                value={values.username}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Password"
                variant="outlined"
                type="password"
                name="password"
                value={values.password}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutAdmin>
      </Container>
    </form>
  );
};
