import { gql } from "@apollo/client";

export const create_liveclass_mutation_gql = gql`
  mutation create_liveclass(
    $batch_id: [Int!]
    $childsubject_id: Int!
    $date: String!
    $start_time: String!
    $duration: String!
    $title: String
    $topic_id: Int
    $faculty_id: Int!
    $zoom_email: String!
  ) {
    create_liveclass(
      batch_id: $batch_id
      childsubject_id: $childsubject_id
      date: $date
      start_time: $start_time
      duration: $duration
      title: $title
      topic_id: $topic_id
      faculty_id: $faculty_id
      zoom_email: $zoom_email
    ) {
      success
    }
  }
`;
