import React, { useContext, useState,useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange, 
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
 import { Select } from "../../../../../../utils/select";
import Typography from "@material-ui/core/Typography";
 import {api_url,rest_headers} from '../../../../../student/app/srceens/digital-classroom/config'
import {handle_error} from '../../call-center/handle_error'
import RaiseTicket from '../../../../../student/app/srceens/support-ticket'
const axios = require('axios');

export default () => {
	 
    const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
       snackBar={snackBar}
      loader={loader}
	  
    />
  );
}; 
const Comp = ({ loader, snackBar, adminname }) => {
    const [roll,setRoll]=useState('')
	const [disabled,setDisabled]=useState(false)
   const [details,setDetails]=useState(null)
   
   const view_details=async()=>{
	   try{
		    
		   loader.show();
const  {data}=await axios({
        url: api_url+'/admin-get-student-details',
        method: 'post',
		responseType: 'json',
		data:{
			roll_no:roll 
			 
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	  if(data.success=='false')
	  {
		  alert(data.message);
	  }
	  else{
		  setDetails(data.message.stu_data[0])
		  setDisabled(true)
	  }
		   
	   }
	   catch(e){
		   snackBar.show(e.message)
	   }
	   finally{
		   loader.hide()
	   }
   }

   
  return (
    
      <Container>
        <FormLayouttopic title="Raise Ticket">
          <Grid spacing={3} container>
		      
            <Grid item xs={12}>
              <TextField
                label="Enter Roll No."
                variant="outlined"
                name="roll"
                value={roll}
				disabled={disabled}
                onChange={(e)=>setRoll(e.target.value)}
              />
            </Grid> 
            
           
           

            <Grid item xs={12}>
			{
			details && <Details roll_no={roll} {...details}/>
			}
			{!details && <Button onClick={view_details} type="button" variant="contained" color="primary">
                View Details
              </Button>
			}
            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    
  );
};


const Details=({name,mobile1,mobile2,coursetitle,fee_status,session,roll_no})=>{
	return(
	<>
	<table border="0" style={{fontFamily:'Arial'}}>
	<tr><td>Name</td><td>:</td><td>{name}</td></tr>
	<tr><td>Mobile1</td><td>:</td><td>{mobile1}</td></tr>
	<tr><td>Mobile2</td><td>:</td><td>{mobile2}</td></tr>
	<tr><td>Course</td><td>:</td><td>{coursetitle}</td></tr>
	<tr><td>Fee Status</td><td>:</td><td>{fee_status}</td></tr>
	<tr><td>Session</td><td>:</td><td>{session}</td></tr>
	
	</table>
	
	<RaiseTicket roll_no={roll_no}/>
	
	</>
	)
}

 