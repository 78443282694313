import moment from 'moment';
import {useState} from 'react'
import {api_url,rest_headers} from '../../config'
import {handle_error} from '../../handle_error'
import Modal from '../../modal'
import SendIcon from '@material-ui/icons/Send';
import confirm_click from './confirm_click'
import VisibilityIcon from '@material-ui/icons/Visibility';
const axios = require('axios');
 

export default({id,last_remark, snackBar, loader})=>{
	const [val,setVal]=useState(last_remark);
	const click=async (id)=>{		   
  try {
   loader.show();
   const  {data}=await axios({
        url: api_url+'/create-conversation',
        method: 'post',
		responseType: 'json',
		data:{
			id:id,
			text:val
		},
        ...rest_headers
      }) 
	  alert(data.message)
 
  } catch (e) { 
       snackBar.show(handle_error(e));
    } finally {
      loader.hide();
    }
 
 
 
	}
	
	const onChange=(e)=>{
		setVal(e.target.value);
		
	}
 
	return(
	<>
	Remark <input placeholder="Enter Remark"  type='text' style={{height:30, }} maxLength={255} value={val}   onChange={onChange}/>&nbsp;&nbsp;
<SendIcon color="primary" style={{fontSize:18, fontWeight:900,cursor:'pointer'}} onClick={()=>{
		
	confirm_click({click,id})
		
		}}/>
 
	<View id={id} loader={loader} snackBar={snackBar} />
	</>
	)
}



const View=({id,loader,snackBar})=>{
	const [array,setArray]=useState([]);
	const hide=()=>{
		setArray([]);
	}
const handleClick=async(id)=>	{
	try {
   loader.show();
   const  {data}=await axios({
        url: api_url+'/view-conversation',
        method: 'post',
		responseType: 'json',
		data:{
			id:id 
		},
        ...rest_headers
      }) 
	  setArray(data.array)
 
  } catch (e) { 
       snackBar.show(handle_error(e));
    } finally {
      loader.hide();
    }
	
}
 
	return (
	<>
	<VisibilityIcon color="secondary" style={{marginLeft:5,fontSize:18,fontWeight:900,cursor:'pointer'}}  onClick={()=>handleClick(id)}/>
	 
		{array && array.length>0 && <Modal hide={hide}> 
		<div style={{fontFamily:'Arial'}}><h2>Callback</h2><br />
			{array.map((n,i)=>{
			return(<p>Remark : {n.text}<br /></p>)
			})}
			</div>
		
	 
		</Modal>
	 
		}
	</>
	)
}