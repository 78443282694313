import React, { useEffect, useState, useContext } from 'react'
import styles from '../../reusable-calling-code-project/table.module.css'
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import axios from 'axios';
import { api_url } from '../../reusable-calling-code-project/config';
import { Paper } from '@material-ui/core';

const CallingPanelReport = () => {

    const snackBar = useContext(SnackBarContext);
    const loader = useContext(LoaderContext);

    const [res, setRes] = useState([]);

    useEffect(async () => {

        try {

            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/calling-panel/calling-panel-report',
                method: 'post',
                responseType: 'json',
            })

            console.log(obj);

            if (obj.success === 'ok') {
                setRes(obj.array);
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }

    }, [])


    if (res.length === 0)
        return <><h5>Loading...</h5></>

    return (
        <>
            <Paper style={{ padding: '16px' }}>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th colSpan={5}>Calling Report</th>
                        </tr>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Admin Name</th>
                            <th>Total Assigned Leads</th>
                            <th>Total Touched Leads</th>
                            <th>Total Untouched Leads</th>
                        </tr>
                    </thead>
                    <tbody>
                        {res.map((ele, i) => {
                            return (
                                <>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.total}</td>
                                        <td>{ele.touched}</td>
                                        <td>{ele.untouched}</td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>

            </Paper>
        </>
    )
}

export default CallingPanelReport
