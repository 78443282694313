import React from "react";
import { Navigate } from "react-router-dom";
import admin_routes from "./admin/routes";
import student_routes from "./student/routes";
import NotFoundView from "./not-found";
const common = [
  {
    path: "/",
    children: [
	 { path: "/student", element: <Navigate to="/student" /> },
      { path: "/admin", element: <Navigate to="/admin" /> },
	   { path: "/", element: <Navigate to="/student" /> },
      { path: "*", element: <NotFoundView /> },
    ],
  },
];
const routes = [...admin_routes, ...student_routes, ...common];
export default routes;
