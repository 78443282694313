import React, { useState, useContext, useEffect } from 'react'
import { LoaderContext } from "../../../../../../context/LoaderContext";
import styles from '../../reusable-calling-code-project/table.module.css';
import { Grid, Paper, InputLabel, Select, FormControl, MenuItem, Container, TextField, Button } from '@material-ui/core';
import axios from 'axios';
import FormLayoutAdmin from "../../../../../../utils/form-layout-admin";
import { makeStyles } from '@material-ui/core/styles';
import { api_url } from '../config';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AgentData = () => {

    const loader = useContext(LoaderContext);

    const classes = useStyles();


    const [agent, setAgent] = useState();
    const [res, setRes] = useState([]);
    const [show, setShow] = useState(false);
    const [arr, setArr] = useState([]);
    const [leads, setLeads] = useState([]);

    useEffect(() => {

        fetch();

    }, [])


    const fetch = async () => {
        try {

            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/agent-list',
                method: 'post',
                responseType: 'json',

            })

            if (obj.success === 'ok') {
                setRes(obj.array);
            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }
    }


    const handleClick = async () => {
        try {
            loader.show();

            const { data: obj } = await axios({
                url: api_url + '/complete-data',
                method: 'post',
                responseType: 'json',
                data: {
                    agent: agent
                }
            })

            if (obj.success === 'ok') {

                setArr(obj.arr);
                setLeads(obj.leads);
                setShow(true);

            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide();
        }
    }


    return (
        <>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Container>
                        <FormLayoutAdmin title={"Course Code Data"}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Select Agent</InputLabel>
                                        <Select
                                            value={agent}
                                            onChange={(e) => setAgent(e.target.value)}
                                            style={{ minWidth: '150px' }}
                                            label="Select Agent"
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                        >
                                            <em>Select Agent</em>
                                            {res.map((ele, i) => {

                                                return (

                                                    <MenuItem value={ele.key}>{ele.value}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullwidth onClick={handleClick} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
                                </Grid>
                            </Grid>
                        </FormLayoutAdmin>
                    </Container>
                </Grid>

                {show && <><Grid item xs={12}>
                    <Container>
                        <Paper component={Paper} style={{ padding: '16px' }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th colSpan={4}>Total RNET and Admissions &nbsp;&nbsp; <b>RNET Count:-</b>&nbsp;{arr.length}</th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Form No.</th>
                                        <th>Name</th>
                                        <th>Fee Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {arr.map((ele, i) => (
                                        <tr style={{ backgroundColor: getColor({ fee: ele.fee }) }}>
                                            <td>{i + 1}</td>
                                            <td>{ele.fno}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.fee}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>
                    </Container>
                </Grid>

                    <Grid item xs={12}>
                        <Container>
                            <Paper component={Paper} style={{ padding: '16px' }}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th colSpan={5}>Total Leads Generated &nbsp;&nbsp; <b>Count:-</b>&nbsp;{leads.length}</th>
                                        </tr>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Reg. Id</th>
                                            <th>Name</th>
                                            <th>Class</th>
                                            <th>Mobile</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leads.map((ele, i) => (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{ele.orderid}</td>
                                                <td>{ele.name}</td>
                                                <td>{ele.class}</td>
                                                <td>{ele.mobile}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Paper>
                        </Container>
                    </Grid>

                </>}

            </Grid>

        </>
    )
}

export default AgentData


const getColor = ({ fee }) => {


    if (fee === "Y") {
        return '#E9FCE9'
    }

    else {
        return '#FFFFFF'
    }

}