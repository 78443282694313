import React, { useEffect } from 'react';

export default function Timer({time , handleCounter}) {

   useEffect(() => {
    var timer = (time * 60);
    var x = setInterval(function() {

        --timer
              var seconds = timer % 60; // Seconds that cannot be written in minutes
              var secondsInMinutes = (timer - seconds) / 60; // Gives the seconds that COULD be given in minutes
              var minutes = secondsInMinutes % 60; // Minutes that cannot be written in hours
              var hours = (secondsInMinutes - minutes) / 60;
              // Now in hours, minutes and seconds, you have the time you need.
              
              if (timer == 0) {
                  clearInterval(x);
                  handleCounter()
              }
          
        // Output the result in an element with id="demo"
        document.getElementById("demo").innerHTML =  hours + "h "
        + minutes + "m " + seconds + "s ";
          
      }, 1000); 

      return () => {
        clearInterval(x);
      }
   },[])

   return (
    <div id="demo"></div>
   )
    

}