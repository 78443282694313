import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutsession from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { 
  handleFormChange,  
  resetForm, 
  error_beautify,    
} from "../../../../../../utils/functions";
import { create_session_mutation_gql } from "../../../../gql/mutation/create-session-mutation";
import Typography from "@material-ui/core/Typography";
  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Profile from './profile'
import {api_url} from '../config.js'
const axios = require('axios');

export default () => {
  const [create_session_mutation] = useMutation(create_session_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_session_mutation={create_session_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_session_mutation }) => {
  const [values, setValues] = useState({
    mobile: "9887093255" 
  });
    const [profile, setProfile] = useState(null);
  const handleInputChange = (e) => {

    handleFormChange({ e, values, setValues });
		 
  };
 
  const handleSubmit = async (type) => {
   
   try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/create-campaign.php',
        method: 'post',
		responseType: 'json',
       data: 'auth_token=##reliabletoken##&type='+type,
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
 
 setProfile( data.message.profile)
console.log(profile);
      
     // resetForm({ values, setValues });
    } catch (e) {
		 
      //snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 
 
  
  return (
     
     <Container>
        <FormLayoutsession title="Create Campaign">
          <Grid spacing={3} container>
		  
              <Grid item xs={12}>
              <Button type="button" style={{width:'300px'}} variant="outlined" color="secondary" onClick={()=>{
				handleSubmit('crm')  
			  }}>
                Leads
              </Button>
            </Grid>
			 <Grid item xs={12}>
              <Button type="button" style={{width:'300px'}} variant="outlined" color="secondary" onClick={()=>{
				handleSubmit('course_fee_not_paid')  
			  }}>
                Course Fee Not Paid
              </Button>
            </Grid>
			 <Grid item xs={12}>
              <Button type="button" style={{width:'300px'}} variant="outlined" color="secondary" onClick={()=>{
				handleSubmit('form_fee_not_paid')  
			  }}>
                Form Fee Not Paid
              </Button>
            </Grid>
			<Grid item xs={12}>
              <Button type="button" style={{width:'300px'}} variant="outlined" color="secondary" onClick={()=>{
				handleSubmit('call_back_at_website')  
			  }}>
                Call Back at Website
              </Button>
            </Grid>
			<Grid item xs={12}>
              <Button type="button" style={{width:'300px'}} variant="outlined" color="secondary" onClick={()=>{
				handleSubmit('front_office')  
			  }}>
                Front Office
              </Button>
            </Grid>
			 
			
          </Grid>
		  
		  
		  <br /><br />
		  
		  {profile && <Profile array={profile}/>}
		    
		  </FormLayoutsession>
		  </Container>
		
         
       
 
  );
};





