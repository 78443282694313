import React from "react";
import { QuestionType } from "./question-type";
import { QuestionInput } from "./question-input";
import { SelectNoOfQuestion } from "./select-no-of-question";
import { image_upload_url } from "../../../config";
//import question from 'utils/quiz/question-area/question';
//import { sub } from 'date-fns';
import PreviewQuiz from "../../quiz";
const _init = {
  english_content: null,
  hindi_content: null,
  subquestion: [{ english_content: null, hindi_content: null, option: [] }],
};

export default class Comp extends React.Component {
  state = {
    question_type: "",
    question: _init,
    str_quiz: null,
    preview:false
  };
  handleQuestionType = (e) => {
    const value = e.target.value;
    const _init = {
      english_content: null,
      hindi_content: null,
      subquestion: [{ english_content: null, hindi_content: null, option: [] }],
    };

    this.setState({ question_type: value, question: _init });
  };
  handleQuestionContent = (e, type) => {
    let { subquestion, ...rest } = this.state.question;
    const content = e.target.value;
    if (type === "en")
      this.setState({
        question: { ...rest, subquestion, english_content: content },
      });
    else if (type === "hi")
      this.setState({
        question: { ...rest, subquestion, hindi_content: content },
      });
  };
  handleSubQuestionContent = (index, value, type) => {
    let { subquestion, ...rest } = this.state.question;
    const len = subquestion.length;
    for (let i = 0; i < len; i++) {
      if (i == index) {
        if (type === "en") subquestion[i].english_content = value;
        else if (type === "hi") subquestion[i].hindi_content = value;
      }
    }

    const question = { ...rest, subquestion: subquestion };
    this.setState({ question: question });
  };

  handleSubQuestionType = (index, value) => {
    let { subquestion, ...rest } = this.state.question;
    const len = subquestion.length;
    for (let i = 0; i < len; i++) {
      if (i == index) {
        subquestion[i].type = value;
      }
    }

    const question = { ...rest, subquestion: subquestion };
    this.setState({ question: question });
  };

  handleOptionInput = (index, value) => {
    let { subquestion, ...rest } = this.state.question;
    const len = subquestion.length;
    for (let i = 0; i < len; i++) {
      if (i == index) {
        subquestion[i].option = [];
        for (let j = 0; j < value; j++) subquestion[i].option.push({});
      }
    }

    const question = { ...rest, subquestion: subquestion };
    this.setState({ question: question });
  };
  handleOptionValueChange = (sub_index, option_index, value, type) => {
    let { subquestion, ...rest } = this.state.question;
    const len = subquestion.length;
    for (let i = 0; i < len; i++) {
      if (i === sub_index) {
        for (let j = 0; j < subquestion[i].option.length; j++) {
          if (j === option_index) {
            if (type === "en") subquestion[i].option[j].english_content = value;
            else if (type === "hi")
              subquestion[i].option[j].hindi_content = value;
          }
        }
      }
    }

    const question = { ...rest, subquestion: subquestion };
    this.setState({ question: question });
  };
  handleNoOfSubQuestion = (e) => {
    let { subquestion, ...rest } = this.state.question;
    subquestion = [];
    const len = e.target.value;
    for (let i = 0; i < len; i++) {
      subquestion.push({ option: [] });
    }
    const question = { ...rest, subquestion: subquestion };
    this.setState({ question: question });
  };
  render() {
    const { question_type, question, str_quiz } = this.state;
    const q = { quiz_question : question.subquestion}
    return (
      <React.Fragment>
        <QuestionType onChange={this.handleQuestionType} />
        <br />
        <br />
        {question_type === "multiple" && (
          <SelectNoOfQuestion
            onChange={this.handleNoOfSubQuestion}
            value={question.subquestion.length}
          />
        )}
        {question_type && (
          <QuestionInput
            question_type={question_type}
            question={question}
            handleOptionInput={this.handleOptionInput}
            handleOptionValueChange={this.handleOptionValueChange}
            handleQuestionContent={this.handleQuestionContent}
            handleSubQuestionContent={this.handleSubQuestionContent}
            handleSubQuestionType={this.handleSubQuestionType}
            image_upload_url={image_upload_url}
          />
        )}<br />
  <button onClick={() => this.setState({preview : true})}>Preview</button><br />
         {/* <PreviewQuiz
          quiz={str_quiz}
          preview={false}
          sendQuizData={() => {}}
          onSubmit={() => {}}
          previewQuestion
        /> */}
       { this.state.preview ? <PreviewQuiz
          quiz={q}
          preview
          sendQuizData={() => {}}
          onSubmit={() => {}}
          previewQuestion
          show_on_small_screen
          attempt={{attempt_quiz_question : false}}
        /> : null }
      </React.Fragment>
    );
  }
}
