import React from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";
import { useQuery } from "@apollo/client";
import { viewer_gql } from "../../../gql/viewer";
import { Link as RouterLink } from "react-router-dom";
import GqlErrorComp from "../../../../../utils/gql-error-comp";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";

const StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  circle: {
    height: 64,
    width: 64,
    borderRadius: "50%",
  },
  line: {
    width: "96px",
    height: "8px",
    marginTop: 8,
    borderRadius: "8px",
  },
};

const App = ({ classes }) => {
  const { loading, error, data } = useQuery(viewer_gql);
  if (loading)
    return (
      <div className={classes.container}>
        <Shimmer>
          <div className={classes.circle} />

          <div className={classes.line} />

          <div className={classes.line} />
        </Shimmer>
      </div>
    );
  if (error) return <GqlErrorComp error={error} />;
  const { viewer } = data;
  return <Comp viewer={viewer} />;
};

class Comp extends React.Component {
  render() {
    const {
      viewer: { name, roll_no, profile_pic, current_batch },
    } = this.props;

    return (
      <>
        <Avatar
          style={{
            width: 64,
            height: 64,
          }}
          //component={RouterLink}
          src={profile_pic || false}
          //  to="/student/app/profile"
        />
        <Typography color="textPrimary" variant="h5">
          {name}
        </Typography>
        {/* <Typography color="textSecondary" variant="body2">
          {roll_no}
        </Typography> */}
        <Typography color="textSecondary" variant="body2">
          Batch - {current_batch.name}
        </Typography>
      </>
    );
  }
}

export default injectSheet(StyleSheet)(App);
