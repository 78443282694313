import React from 'react';
import Linkify from 'react-linkify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography
} from "@material-ui/core";
import { useLocation, useNavigate } from 'react-router-dom';


export default () => {
  const location = useLocation();
  let navigate = useNavigate();
  return (
    <>
    <Card>
    <CardContent>
    <Button
        variant="outlined"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={()=> navigate(-1)}
      >
        Back
      </Button> <br /><br />
      <Typography variant="h4" align='center'>{location.state.title}</Typography><br />
      <Typography variant="h6" style={{padding: 20, paddingTop: 10}}>    
      <Linkify>
      {location.state.desc}
      </Linkify>
      </Typography>
      </CardContent>
      </Card>
    </>
  );
};

