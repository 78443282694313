import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutclassroom_test_type from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_classroom_test_topic } from "../../../gql/mutation/create-classroom_test_topic-mutation";
import Typography from "@material-ui/core/Typography";
import SelectSessionCoursePhaseBatchTestSubject from "../../components/select-session-course-phase-batch-classroomtest-subject";
import SelectSubjectTopic from "../../components/select-subject-topic";

export default () => {
  const [create_classroom_test_topic_mutation] = useMutation(
    create_classroom_test_topic
  );
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_classroom_test_topic_mutation={
        create_classroom_test_topic_mutation
      }
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_classroom_test_topic_mutation }) => {
  const [values, setValues] = useState({
    classroom_test_subject_id: "",
    topic_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { classroom_test_subject_id, topic_id } = values;

      loader.show();

      let {
        data: {
          create_classroom_test_topic: { success },
        },
      } = await create_classroom_test_topic_mutation({
        variables: { classroom_test_subject_id, topic_id },
      });

      if (success) snackBar.show("classroom_test_topic created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutclassroom_test_type title="Create Classroom Test Topic">
          <Grid spacing={3} container>
            <SelectSessionCoursePhaseBatchTestSubject
              name="classroom_test_subject_id"
              onChange={handleInputChange}
            />
            <Grid item xs={12}>
              <SelectSubjectTopic
                classroom_test_subject_id={values.classroom_test_subject_id}
                course_id={values.course_id}
                label="Topic"
                name="topic_id"
                value={values.topic_id}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutclassroom_test_type>
      </Container>
    </form>
  );
};
