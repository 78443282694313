import { gql } from "@apollo/client";

export const change_student_status_mutation_gql = gql`
  mutation change_student_status(
    $roll_no: String!
    $status: String!
  ) {
    change_student_status(
      roll_no: $roll_no
      status: $status
    ) {
      success
    }
  }
`;
