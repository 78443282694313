import { gql } from "@apollo/client";

export const faculty_time_table_query_gql = gql`
  {
    faculty_time_table {
      date
      info {
        faculty {
          name
        }
        disabled
        is_cancelled
        meeting_no
        batch {
          name
        }
        start_time
        end_time
        zoom_account {
          email
        }
      }
    }
  }
`;
