import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { LoaderContext } from "../../../../../context/LoaderContext";
import styles from '../reusable-calling-code-project/table.module.css'
import { api_url } from "./config";


export default function FrontOfficeMis() {

    const loader = useContext(LoaderContext);

    const [array, setArray] = useState([]);
    const [arraytwo, setArraytwo] = useState([]);
    const [arraythree, setArraythree] = useState([]);
    const [arrayfour, setArrayfour] = useState([]);
    const [arrayfive, setArrayFive] = useState([]);

    useEffect(() => {
        fetch();

    }, [])



    const fetch = async () => {
        try {
            loader.show();
            const { data: obj } = await axios({
                url: api_url + "mis",
                method: "post",
                reponseType: 'json'
            })
            setArray(obj.data)
            setArraytwo(obj.datatwo)
            setArraythree(obj.data3);
            setArrayfour(obj.data4);
            setArrayFive(obj.grp);
            // console.log(obj.grp);
            // console.log(obj.data)
            // console.log(obj)
        }
        catch (e) {
            alert(e.message)
        }
        finally {
            loader.hide()
        }
    }



    return (
        <div style={{ width: '100%' }}>
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'

            }}>
                <div style={{ marginTop: "50px", width: '50%' }}>
                    <table className={styles.table}>
                        <thead>
                            <tr >
                                <th colSpan={3}>Admission Punch Count</th>
                            </tr>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Admin</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                array.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.username}</td>
                                                <td>{item.count}</td>

                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>

                <div style={{ marginTop: "50px", width: '50%' }}>
                    <table className={styles.table}>
                        <thead >
                            <tr>
                                <th colSpan={4}>Admission Punch Data</th>
                            </tr>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Admin Name</th>
                                <th>Form Number</th>
                                <th>Date</th>


                            </tr>
                        </thead>
                        <tbody>
                            {
                                arraytwo.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.username}</td>
                                                <td>{item.roll_no}</td>
                                                <td>{item.date}</td>


                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>


                <div style={{ marginTop: "50px", width: '50%' }}>
                    <table className={styles.table}>
                        <thead >
                            <tr>
                                <th colSpan={4}>Form Punch Count</th>
                            </tr>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Admin Name</th>
                                <th>Count</th>


                            </tr>
                        </thead>
                        <tbody>
                            {
                                arraythree.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.username}</td>
                                                <td>{item.count}</td>

                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>

                <div style={{ marginTop: "50px", width: '50%' }}>
                    <table className={styles.table}>
                        <thead >
                            <tr>
                                <th colSpan={4}>Form Punch Data</th>
                            </tr>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Admin Name</th>
                                <th>Form Number</th>
                                <th>Date</th>


                            </tr>
                        </thead>
                        <tbody>
                            {
                                arrayfour.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.username}</td>
                                                <td>{item.rollno}</td>
                                                <td>{item.date}</td>


                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>

                <div style={{ marginTop: "50px", width: '50%' }}>
                    <table className={styles.table}>
                        <thead>
                            <tr >
                                <th colSpan={6}>YTD(Admission) MIS</th>
                            </tr>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Class</th>
                                <th>Session 2020-21</th>
                                <th>Session 2021-22</th>
                                <th>Session 2022-23</th>
                                <th>Session 2023-24</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                arrayfive.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.grp}</td>
                                                <td>{item.ct4}</td>
                                                <td>{item.ct3}</td>
                                                <td>{item.ct}</td>
                                                <td>{item.ct2}</td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>

                <div style={{ padding: '20px 0px' }}></div>
            </div>

        </div>
    );
}
