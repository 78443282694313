import { gql } from "@apollo/client";

export const view_courseware_list_query_gql = gql`
  query view_courseware_list($topic_id: Int!,$type: String) {
    view_courseware_list(topic_id: $topic_id,type:$type) {
      key: id
      value: title
    }
  }
`;
