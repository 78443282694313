import { gql } from "@apollo/client";

export const viewer_gql = gql`
  {
    viewer {
      name: fname
      roll_no
      profile_pic
      zoom_app_key
      zoom_app_secret
      current_batch {
        name
      }
      profile {
        fee
        fno
        name
        coursetitle
        medium
        less_amount
      }
    }
  }
`;
