import React, { useEffect, useState } from 'react'
import styles from './table.module.css';
import axios from 'axios';
import moment from 'moment'
import { Paper, Grid } from '@material-ui/core';
export default function JnvDetails() {
    const [data, setData] = useState([])
    const api_url = 'https://api-dot-reformexam.el.r.appspot.com/';
    useEffect(() => {
        fetch();
    }, [])
    const fetch = async () => {
        try {
            const { data: obj } = await axios({
                url: api_url + '/jnv-live-class/jnv-data',
                method: 'post',
                responseType: 'json'
            })
            if (obj.Success == 'ok') {
                setData(obj.data)
            }
        }
        catch (e) {
            alert(e.message)
        }
    }
    return (
        <div>
            <Grid container spacing={3}>

                <Paper component={Paper} style={{ padding: '16px' }}>

                    <Grid item xs={12}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={14}>JNV Details</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Region</th>
                                    <th>School</th>
                                    <th>Principal Name</th>
                                    <th>Principal Mobile</th>
                                    <th>Principal Email</th>
                                    <th>Coordinator Name</th>
                                    <th>Coordinator Mobile</th>
                                    <th>School Address</th>
                                    <th>School Email</th>
                                    <th>JEE students</th>
                                    <th>NEET students</th>
                                    <th>Services Required</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((ele, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.region}</td>
                                            <td>{ele.schoolname}</td>
                                            <td>{ele.principal_name}</td>
                                            <td>{ele.p_mobile}</td>
                                            <td>{ele.p_email}</td>
                                            <td>{ele.co_name}</td>
                                            <td>{ele.co_mobile}</td>
                                            <td>{ele.school_address}</td>
                                            <td>{ele.school_email}</td>
                                            <td>{ele.jee_students}</td>
                                            <td>{ele.neet_students}</td>
                                            <td>{ele.services}</td>
                                            <td>{moment(ele.date).format('DD/MM/YYYY')}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Grid>
                </Paper>

            </Grid>
        </div>
    )
}
