import { Container, Paper, Grid, MenuItem, TextField, Typography, Button, Dialog, DialogContent, Snackbar, Select, InputLabel, FormControl } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import styles from '../../reusable-calling-code-project/table.module.css';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import FormLayoutAdmin from "../../../../../../utils/form-layout-admin";


// const api_url = 'http://localhost:8060/mis/'
const api_url = 'https://api-dot-reformexam.el.r.appspot.com/mis/'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 180,
    },
}));


const RnetMis = () => {

    const classes = useStyles();

    const loader = useContext(LoaderContext);
    const snackBar = useContext(SnackBarContext);

    const [array, setArray] = useState([]);
    const [show, setShow] = useState(false);
    const [date, setDate] = useState();
    const [enddate, setEndDate] = useState();
    const [classs, setClasss] = useState();

    // useEffect(() => {
    //     fetch();
    // }, [])


    const fetch = async () => {
        try {

            loader.show();

            setShow(false);

            const { data: obj } = await axios({
                url: api_url + 'rnet-mis',
                method: 'post',
                responseType: 'json',
                data: {
                    date: date,
                    enddate: enddate,
                    classs: classs,
                },

            })

            if (obj.success == 'ok') {
                setArray(obj.array);
                setShow(true);
            }

        }

        catch (e) {
            snackBar.show(e.message);
        }

        finally {
            loader.hide()
        }
    }


    return (

        <Container>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <FormLayoutAdmin title="RNet MIS">
                        <Grid container spacing={1}>

                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        id="date"
                                        label="Start Date"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </FormControl>

                            </Grid>

                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        id="date"
                                        label="End Date"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={enddate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </FormControl>

                            </Grid>

                            <Grid item xs={12}>

                                <FormControl variant="outlined" className={classes.formControl}>

                                    <InputLabel id="demo-simple-select-outlined-label">Class</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={classs}
                                        onChange={(e) => setClasss(e.target.value)}
                                        label="Category"
                                    >
                                        <MenuItem value="">
                                            <em>Select Class</em>
                                        </MenuItem>
                                        <MenuItem value="6">6th Class</MenuItem>
                                        <MenuItem value="7">7th Class</MenuItem>
                                        <MenuItem value="8">8th Class</MenuItem>
                                        <MenuItem value="9">9th Class</MenuItem>
                                        <MenuItem value="10">10th Class</MenuItem>
                                        <MenuItem value="11">11th Class</MenuItem>
                                        <MenuItem value="12">12th Class</MenuItem>
                                        <MenuItem value="13">12th Passed</MenuItem>
                                    </Select>

                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Button fullwidth onClick={fetch} style={{ border: '1px solid purple', borderStyle: 'dashed' }}>Submit</Button>
                                </FormControl>

                            </Grid>
                        </Grid>
                    </FormLayoutAdmin>
                </Grid>

                {show && <Grid item xs={12}>

                    <Paper component={Paper} style={{ padding: '16px' }}>

                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={7}>
                                        RNet MIS &nbsp;&nbsp;&nbsp;<b>Count: </b>{array.length}
                                    </th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Form No.</th>
                                    <th>Name</th>
                                    <th>Form Date</th>
                                    <th>Class</th>
                                    <th>RNet Form Fee</th>
                                    <th>Admission Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                {array.map((ele, i) => (
                                    <>

                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele.fno}</td>
                                            <td>{ele.name}</td>
                                            <td>{ele.fdate}</td>
                                            <td>{ele.classs}</td>
                                            <td>{ele.ispaid}</td>
                                            <td>{ele.fee}</td>
                                        </tr>

                                    </>
                                ))}
                            </tbody>
                        </table>

                    </Paper>

                </Grid>}

            </Grid>

        </Container>
    )
}

export default RnetMis
