import React from "react";
import { Select } from "../../../../utils/select";
export default ({ label, value, name, onChange,stream_class_list,stream }) => {
 
   let i=0,len=stream_class_list.length,class_list=[],tmp_class;
   for(i=0;i<len;i++){
	   if(stream_class_list[i].stream===stream){
		   tmp_class=stream_class_list[i].classs;
	 
		   class_list.push({key:tmp_class,value:tmp_class})
	   }
   }
   
  
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={class_list}
    />
  );
};
