import { gql } from "@apollo/client";

export const create_classroom_test_subject = gql`
  mutation create_classroom_test_subject(
    $classroom_test_batch_id: Int!
    $subject_id: Int!
  ) {
    create_classroom_test_subject(
      classroom_test_batch_id: $classroom_test_batch_id
      subject_id: $subject_id
    ) {
      success
    }
  }
`;
