import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
import { create_topic_mutation_gql } from "../../../gql/mutation/create-topic-mutation";
import Typography from "@material-ui/core/Typography";
import SelectSubjectChildsubject from "../../components/select-subject-childsubject";
import SelectClass from "../../components/select-class";
export default () => {
  const [create_topic_mutation] = useMutation(create_topic_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      create_topic_mutation={create_topic_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_topic_mutation }) => {
  const [values, setValues] = useState({
    name: "",
    alias: "",
    childsubject_id: "",
    class_id: "",
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { name, alias, childsubject_id, class_id } = values;

      loader.show();

      let {
        data: {
          create_topic: { success },
        },
      } = await create_topic_mutation({
        variables: { name, alias, childsubject_id, class_id },
      });

      if (success) snackBar.show("topic created successfully");
      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayouttopic title="Create Topic">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Alias"
                variant="outlined"
                name="alias"
                value={values.alias}
                onChange={handleInputChange}
              />
            </Grid>
            <SelectSubjectChildsubject
              onChange={handleInputChange}
              name="childsubject_id"
            />

            <Grid item xs={12}>
              <SelectClass
                label="Class"
                name="class_id"
                value={values.class_id}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    </form>
  );
};
