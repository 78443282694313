import React from 'react';
import QuizTitle from '../quiz-title'
import Drawer from '../drawer'
import { Paper } from '../../../../utils/paper'
import Timer from './timer';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
export default withWidth()(({ width,quiz: { name: title, quiz_question }, goToQues,current_question_index, preview, time, handleCounter }) => {
	  
	
    return (
        <Paper style={{   borderRadius:0 }} >
            <table style={{ width: '100%' }}><tbody><tr style={{ width: '100%' }}><td  > <QuizTitle title={title} /></td>
                <td> { (!isWidthUp('md', width) && !preview && time > 0) ? <div style={{display: "flex", alignItems:'center', justifyContent: 'center'}}><Timer time={time} handleCounter={handleCounter} /></div> : null }</td>
                <td><div style={{ float: 'right' }}>
				
				{
					!isWidthUp('md', width) && 
					<Drawer quiz_question={quiz_question} goToQues={goToQues} current_question_index={current_question_index} />
					
				}
				</div></td></tr>
                </tbody></table>
        </Paper>
    )
})