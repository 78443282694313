import { gql } from "@apollo/client";

export const phase_list_query_gql = gql`
  query phase_list($course_id: Int!) {
    phase_list(course_id: $course_id) {
      key: id
      value: name
    }
  }
`;
