import { Grid, Paper, InputLabel, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { api_url } from '../config';
import { LoaderContext } from "../../../../../../context/LoaderContext";
import styles from '../../reusable-calling-code-project/table.module.css';

const SamvaadCallingMIS = () => {


    const loader = useContext(LoaderContext);

    const [attendance, setAttendance] = useState([]);
    const [fee, setFee] = useState([]);
    const [lead, setLead] = useState([]);
    const [show, setShow] = useState(false);
    const [showAtt, setShowAtt] = useState(false);
    const [showFee, setShowFee] = useState(false);
    const [showLead, setShowLead] = useState(false);
    const [fbleads, setFbLeads] = useState([]);
    const [showfb, setShowFb] = useState(false);
    const [day, setDay] = useState('');


    const handleData = async (e) => {
        try {

            setDay(e.target.value)

            loader.show()

            const { data: obj } = await axios({
                url: api_url + '/samvaad-calling-mis',
                method: 'post',
                responseType: 'json',
                data: {
                    day: e.target.value,
                },
            })

            // console.log(obj.fb.length);

            if (obj.success === 'ok') {
                setShow(true);
                if (obj.attendance.length > 0) {

                    setAttendance(obj.attendance);
                    setShowAtt(true);
                }

                if (obj.fee.length > 0) {
                    setFee(obj.fee);
                    setShowFee(true);
                }

                if (obj.lead.length > 0) {
                    setLead(obj.lead);
                    setShowLead(true);
                }

                if (obj.fb.length > 0) {
                    setFbLeads(obj.fb);
                    setShowFb(true);
                }



            }

        }

        catch (e) {
            alert(e.message);
        }

        finally {
            loader.hide()
        }
    }


    return (
        <>

            <Paper component={Paper} style={{ padding: '16px', justifyContent: 'center' }}>
                <InputLabel>Select Day</InputLabel>
                <Select value={day} style={{ minWidth: '200px' }} onChange={handleData}>
                    <ev>Select Day</ev>
                    <MenuItem value="0">Today</MenuItem>
                    <MenuItem value="1">Yesterday</MenuItem>
                    <MenuItem value="2">2 days Ago</MenuItem>
                    <MenuItem value="3">3 Days Ago</MenuItem>
                    <MenuItem value="4">4 Days Ago</MenuItem>
                    <MenuItem value="5">5 Days Ago</MenuItem>
                    <MenuItem value="6">6 Days Ago</MenuItem>
                    <MenuItem value="100">Overall</MenuItem>
                </Select>
            </Paper>

            <br />
            <br />

            {show && <Grid container spacing={3}>
                {showAtt && <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={3}>Attendance MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin</th>
                                    <th>No. of Calls</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendance.map((ele, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>}
                {showFee && <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={3}>Fee MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin</th>
                                    <th>No. of Calls</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fee.map((ele, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>}
                {showLead && <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={3}>CRM MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin</th>
                                    <th>No. of Calls</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lead.map((ele, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>}
                {showfb && <Grid item xs={12}>
                    <Paper component={Paper} style={{ padding: '16px' }}>
                        <table className={styles.table} style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th colSpan={3}>FB Leads MIS</th>
                                </tr>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Admin</th>
                                    <th>No. of Calls</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fbleads.map((ele, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>}
            </Grid>}

        </>
    )
}

export default SamvaadCallingMIS
