import React, { useState, useEffect, useContext } from 'react';
import { api_url, rest_headers } from '../../../../../student/app/srceens/digital-classroom/config'
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import { Select } from "../../../../../../utils/select";
import { Select as SelectMultiple } from "../../../../../../utils/select/select-multiple";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
import moment from 'moment';
import SharedList from './shared-list'
const axios = require('axios');


export default (props) => {
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
      {...props}
    />
  );
};
const Comp = ({ loader, snackBar, division, ...props }) => {

  const [show, setShow] = useState(false);
  const [res, setRes] = useState([]);
  const [batch_list, setBL] = useState([]);
  const [roll, setRoll] = useState('');
  const [video_lecture_topic_id, setId] = useState('')
  const [batch_id, setBatchId] = useState([])
  const [start_date, setStartDate] = useState('')
  const [end_date, setEndDate] = useState('');
  const [lecture_id, setLectureId] = useState([])

  const refresh = () => {
    fetch1()
    fetch2()
  }

  useEffect(async () => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    fetch1(); fetch2();
  }, [])

  const fetch1 = async () => {
    try {

      loader.show();
      const { data } = await axios({
        url: api_url + '/video-lecture-topic-list',
        method: 'post',
        responseType: 'json',
        data: {
          division: division,
        },
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })



      setRes(data.list)



    } catch (e) {

      snackBar.show(e);
    } finally {
      loader.hide();
    }
  }

  const fetch2 = async () => {
    try {

      loader.show();
      const { data } = await axios({
        url: api_url + '/batch-list',
        method: 'post',
        responseType: 'json',
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })



      setBL(data.list)



    } catch (e) {

      snackBar.show(e);
    } finally {
      loader.hide();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      if (!lecture_id || lecture_id.length < 1) {
        alert('Select Lectures');
        return
      }

      if (!start_date) {
        alert('Select Start Date');
        return
      }
      if (!end_date) {
        alert('Select Days');
        return
      }
      loader.show();
      const json = {
        lecture_id: lecture_id,
        roll_no: roll,
        start_date: start_date,
        end_date: end_date

      }
      console.log(json)
      const { data } = await axios({
        url: api_url + '/share-video-student',
        method: 'post',
        responseType: 'json',
        data: json,
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })
      alert(data.message);

    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };



  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayouttopic title="Share Video to Student">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              {res && <Select
                label={"Select Topic"}
                value={video_lecture_topic_id}
                name={'video_lecture_topic_id'}
                onChange={(e) => { setId(e.target.value) }}
                array={res}
              />}
            </Grid>
            <Grid item xs={12}>
              <LecutureList lecture_id={lecture_id} setLectureId={setLectureId} video_lecture_topic_id={video_lecture_topic_id} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Roll No."
                variant="outlined"
                name="roll"
                value={roll}
                onChange={(e) => setRoll(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              Start Date<br /><input type='date' style={{ height: 30, }} value={start_date} min={moment(new Date()).format("YYYY-MM-DD")} onChange={(e) => setStartDate(e.target.value)} />

            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Days"
                variant="outlined"
                name="days"

                onChange={(e) => {
                  setEndDate(moment(start_date, "YYYY-MM-DD").add(e.target.value, 'days').format('YYYY-MM-DD'))

                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    </form>
  )
}



const LecutureList = ({ video_lecture_topic_id, lecture_id, setLectureId }) => {

  const [list, setList] = useState([]);
  useEffect(async () => {
    if (video_lecture_topic_id)
      fetch();
  }, [video_lecture_topic_id])

  const fetch = async () => {
    try {


      const { data } = await axios({
        url: api_url + '/video-lecture-topic-lecture-list',
        data: {
          video_lecture_topic_id: video_lecture_topic_id
        },
        method: 'post',
        responseType: 'json',
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })


      setList(data.array)

    } catch (e) {

      alert(e)
    } finally {

    }

  }
  return (
    <div>
      <SelectMultiple

        label={"Select Lecture"}
        value={lecture_id}
        name={'lecture_id'}
        onChange={(e) => { setLectureId(e.target.value) }}
        array={list}
      />

    </div>
  )

}