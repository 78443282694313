import React,{useEffect,useState} from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon'
import { SnackBarContext } from "../context/SnackBarContext";
import NotificationsIcon from '@material-ui/icons/Notifications';
import SaveIcon from '@material-ui/icons/Save';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage ,getToken } from "firebase/messaging";
 import {api_url,rest_headers} from '../main/student/app/srceens/digital-classroom/config'
import {STUDENT_TOKEN_NAME} from "../config"
const axios=require('axios');
 const firebaseConfig = {
  apiKey: "AIzaSyCRx3fw15G5GgXIyTXqNuQyxZ5k1x0K5Vc",
  authDomain: "reliablekota.firebaseapp.com",
  databaseURL: "https://reliablekota.firebaseio.com",
  projectId: "reliablekota",
  storageBucket: "reliablekota.appspot.com",
  messagingSenderId: "1060049790283",
  appId: "1:1060049790283:web:a16559f421a3f82a7b34ce",
  measurementId: "G-M12DGPCPCD"
};
const app = initializeApp(firebaseConfig);
 
const messaging = getMessaging();
//==========================================================
	   onMessage(messaging, (payload) => {
		   
        const notificationTitle = payload.data.title;
        const notificationOptions = {
          body: payload.data.body,
          icon: payload.data.icon,
		  largeIcon:payload.data.largeIcon,
		  smallIcon:payload.data.smallIcon,
		  badge:'https://reliablekota.web.app/logo-square-badge.png',
          image: payload.data.image,
		  data: {
      url: payload.data.click_action
    },
	/*
	actions: [
          {action: 'explore', title: 'Explore this new world',
            icon: 'images/checkmark.png'},
          {action: 'close', title: 'Close notification',
            icon: 'images/xmark.png'},
        ]*/
        };

        if (!("Notification" in window)) {
          console.log("This browser does not support system notifications");
        }
        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
          // If it's okay let's create a notification
          /*var notification = new Notification(
            notificationTitle,
            notificationOptions
          );
		  */
		  window.navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
        registration.showNotification(
            notificationTitle,
            notificationOptions
        )
    });
	 
          /*notification.onclick = function(event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open(payload.data.click_action, "_blank");
            notification.close();
          };
		  */
		   
        }
      });
  
	//==========================================================

export default()=>{
  const snackBar = React.useContext(SnackBarContext);
	const [show,setShow]=useState(true);
	const [loading,setLoading]=useState(false)
	
useEffect(()=>{
	if(localStorage.getItem('fcm_token'))
	{
		setShow(false);
	
	
	
	}
	 
},[])
	const ask=()=>{
		 setLoading(true)
		getToken(messaging).then(async(currentToken) => {
			 
  if (currentToken) {
	   try{
		  
	  //======================================================
	  const {data}=await axios({
        url: api_url+'/fcm-registration',
        method: 'post',
		responseType: 'json',
		data:{
			fcm:currentToken 
		},
           headers:{
			APIKEY: '##reliable_rest_api_token##',
			STUDENTRELIABLETOKEN:localStorage.getItem(STUDENT_TOKEN_NAME)
}
      }) 
	  if(data.success=='ok'){
		setShow(false)
		localStorage.setItem('fcm_token',currentToken);
	  snackBar.show('Successfully subscribed for push notifications');
	  }
	   }
	   catch(e){
snackBar.show('some error occured while sending token to the server. please try again.');		   
		   }
	   finally{
		   setLoading(false)
	   }
	  
	  //======================================================
	   
	   
    // Send the token to your server and update the UI if necessary
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});
	}
	let style={backgroundColor:'red',borderRadius:20,color:'white'}
	if(loading)
		style={...style,backgroundColor:'#ddd',color:'#000'}
	 if(show)
	return (
	<>
	  <center><Button
        variant="contained"
        style={style}
         onClick={()=>{
		 ask()
		 }}
        endIcon={!loading && <NotificationsIcon/>}
      >{!loading?
	  'Subscribe for Push Notifications':'Sending token to server...'}
      </Button>
	  </center>
	  <br /><br />
	  </>
	)
	return <></>
}