import React from 'react'


// between question and option
export const Separator1 = () => <React.Fragment>
    <br /><br /><br /></React.Fragment>


// between   options
export const Separator2 = () => <React.Fragment></React.Fragment>



// between paragraph and question
export const Separator3 = () => <React.Fragment>
    <br /><br /><br /><br /></React.Fragment>

// between paragraph's question
export const Separator4 = () => <React.Fragment>
    <br /><br /> <br /> <br /></React.Fragment> 