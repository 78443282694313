import { gql } from "@apollo/client";

export const zoom_account_list_query_gql = gql`
  {
    zoom_account_list {
      key: email
      value: email
      dept
    }
  }
`;
