import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import styles from "./table.module.css";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable({
  data: { classroom_test_all_test_result: ctr },
}) {
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <table id="scorecard_table" className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th} colSpan={12}>
                Score Card
              </th>
            </tr>
            <tr>
              <th className={styles.th} colSpan={3}>
                Test
              </th>
              <th className={styles.th} colSpan={4}>
                Marks
              </th>

              <th className={styles.th} colSpan={3}>
                Percentage
              </th>

              <th className={styles.th} rowSpan={2}>
                Percentile
              </th>

              <th className={styles.th} rowSpan={2}>
                Rank
              </th>
            </tr>
            <tr className={styles.tr}>
              <th className={styles.th}>Date</th>
              <th className={styles.th}>Name</th>
              <th className={styles.th}>Type</th>

              <th className={styles.th}>Maximum</th>
              <th className={styles.th}>Highest</th>

              <th className={styles.th}>Average</th>
              <th className={styles.th}>Obtained</th>

              <th className={styles.th}>Highest</th>
              <th className={styles.th}>Average</th>

              <th className={styles.th}>Obtained</th>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {ctr.map((n, i) => {
              return (
                <tr className={styles.tr}>
                  <td className={styles.td}>{n.test_info.test_date}</td>

                  <td className={styles.td}>{n.test_info.name}</td>
                  <td className={styles.td}>
                    {n.test_info.classroom_test_type.name}
                  </td>
                  <td className={styles.td}>{n.total_info.max_marks}</td>
                  <td className={styles.td}>{n.total_info.highest_marks}</td>
                  <td className={styles.td}>{n.total_info.avg_marks}</td>
                  <td className={styles.td}>{n.total_info.my_marks}</td>
                  <td className={styles.td}>
                    {n.total_info.highest_percentage}
                  </td>

                  <td className={styles.td}>{n.total_info.avg_percentage}</td>

                  <td className={styles.td}>{n.total_info.my_percentage}</td>

                  <td className={styles.td}>{n.total_info.percentile}</td>

                  <td className={styles.td}>{n.total_info.my_rank}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableContainer>
      <br />
    </>
  );
}
