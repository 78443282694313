import React, { memo } from "react";
import { Input } from "../../../../../utils/input";
import { Button } from "../../../../../utils/button";
import { error_beautify } from "../../../../../utils/functions";
import { useMutation } from "@apollo/client";
import login_mutation_gql from "./login-mutation-gql";
import MButton from "@material-ui/core/Button";
import { Navigate } from "react-router-dom";
import get_device_id from "../../../../../utils/unique-device-id";
import get_model_name from "../../../../../utils/model-name";
import external_url_handler from "../../../../../utils/external_url_handler";
import Typography from '@material-ui/core/Typography';
export default ({ _handleSubmit, snackBar, loader, setLogin }) => {
  const [student_login_mutation] = useMutation(login_mutation_gql);

  return (
    <Comp
      student_login_mutation={student_login_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};

class Comp extends React.Component {
  state = {
    username: "",
    password: "",
    login: false,
  };
  _handleChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  _handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const { snackBar, loader, student_login_mutation } = this.props;

    if (username === "") {
      snackBar.show("Enter Roll No");
      return false;
    }

    if (password === "") {
      snackBar.show("Enter Password");
      return false;
    }
    if (password.length < 6 || password.length > 15) {
      snackBar.show("password length must be between 6 to 15");
      return;
    }
    try {
      loader.show();

      const device_id = get_device_id();
      const model_name = get_model_name();
      const info = {
        device_id: device_id,
        model_name: model_name,
        app_version: "0.0.5"
      };
      let {
        data: {
          student_login: { token },
        },
      } = await student_login_mutation({
        variables: { username, password, info },
      });

      localStorage.setItem("student_token", token);
      localStorage.setItem("rollno", username);
      this.setState({ login: true });
    } catch (e) {
      if (e.message.indexOf("Already") > -1) {
        snackBar.show({
          text: error_beautify(e.message),
          action_url: "https://www.reliablekota.com/liveclasses/other-apps/force-logout",
          action_text: "Logout from other devices",
        });
      } else snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };

  render() {
    const { state } = this;
    return (
      <div style={{ width: "100%" }}>
        <form onSubmit={this._handleSubmit} method="post" noValidate>
          <br /> <center><Typography component="h1" variant="h5">
            Sign in
          </Typography></center>
          <br />

          <Input
            type="number"
            label={"Roll No"}
            value={state["username"]}
            maxLength={8}
            onChange={(e) =>
              this._handleChange({ name: "username", value: e.target.value })
            }
          />
          <br /> <br />
          <Input
            type="password"
            label={"Password"}
            value={state["password"]}
            maxLength={15}
            onChange={(e) =>
              this._handleChange({ name: "password", value: e.target.value })
            }
          />
          <br /> <br /> <Button type="submit" title="Login" color="primary" />
          <br /> <br />
          <center><MButton
            onClick={() =>
              external_url_handler(
                "https://www.reliablekota.com/liveclasses/other-apps/forgot-password/"
              )
            }
            color="secondary"
            size='small'
          >
            Generate Password?
          </MButton> <br />
            <MButton
              color="secondary"
              disabled
            >
              OR
            </MButton><br />
            <MButton
              onClick={() =>
                external_url_handler(
                  "https://www.reliablekota.com/liveclasses/other-apps/forgot-password/"
                )
              }
              color="secondary"
              size='small'
            >
              Forgot Password?
            </MButton></center>
        </form>
        {state.login && <Navigate to="/student" />}
      </div>
    );
  }
}
