 import React,{useState,useEffect} from 'react'
import {api_url,rest_headers} from '../../../../../student/app/srceens/digital-classroom/config'
 const axios = require('axios');
 
 export default({video_lecture_topic_id})=>{
	 const [list,setList]=useState([]);
 useEffect(async()=>{
	  if(video_lecture_topic_id)
  fetch();
  },[video_lecture_topic_id])
  
  const fetch=async()=>{
	  try {
 
  
   const  {data}=await axios({
        url: api_url+'/video-lecture-topic-video-list',
		data:{
			video_lecture_topic_id:video_lecture_topic_id
		},
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	    
	 
setList(data.list)
 
    } catch (e) {
		 
     alert(e)
    } finally {
       
    }
	
  }
  return(
 <div style={{marginLeft:10,paddingLeft:10}}><ul>
  {list && list.map((n,i)=>{
	  return(
	  <li>{n.title}</li>
	  )
  })}
  </ul>
  </div>
  )
  
 }