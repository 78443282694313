import React,{useState,useEffect,useContext} from 'react';
import {api_url,rest_headers} from './config'
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import { Select } from "../../../../../utils/select";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../utils/form-layout-admin";
import {STUDENT_TOKEN_NAME} from "../../../../../config"
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
 import List from '@material-ui/core/List';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,

  Typography,
} from "@material-ui/core";
import _ from 'lodash'
import ListComp from './list'
import Divider from '@material-ui/core/Divider';
const axios = require('axios');


export default (props) => {
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      snackBar={snackBar}
      loader={loader}
		  {...props}
    />
  );
};
const Comp = ({ loader, snackBar,...props })=>{
	 
	const [show,setShow]=useState(false);
    const [res, setRes] = useState([]);
	 useEffect(()=>{
		fetch() 
	 },[])
  
  const fetch=async()=>{
	  try {
 
   loader.show();
   const  {data}=await axios({
        url: api_url+'/video-lecture-list',
        method: 'post',
		responseType: 'json',
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			STUDENTRELIABLETOKEN:localStorage.getItem(STUDENT_TOKEN_NAME)	 
}
      }) 
	    
	 
 
 setRes( data.array)

      
   
    } catch (e) {
		 
       snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
    let arr={};
	
 if(res.length>0){
   arr=groupBy(res,'childsubject');
  
 }
 const subject_list=get_subject_list(res);
 let topic_list=[]
 let lecture_list=[]
 return(
 <Tabs>
    <TabList>
	{subject_list && subject_list.length>0 && subject_list.map((n,i)=>{
		return(
		<Tab key={i}>
		<Typography variant="h4">
		{n}
		</Typography>
		</Tab>
		)
	})}
      
      
    </TabList>
	{subject_list && subject_list.length>0 && subject_list.map((n,i)=>{
		topic_list=get_topic_list(res,n);
		return(
		<TabPanel>
		{topic_list && topic_list.length>0 && topic_list.map((t,i)=>{
			lecture_list=get_lecture_list(res,n,t);
			return(
			
			  <List subheader={
        <ListSubheader component="div" id="nested-list-subheader">
		<TopicName topic={t} res={res}/>
        </ListSubheader>
      } style={{width:'100%',backgroundColor:'#fff'}}>
	  
	  
	  
	  {lecture_list && lecture_list.length>0 && lecture_list.map((l,i)=>{
		  return(
		  <ListComp {...l}/>
		  )
	  })}
	  
			  </List>
			)
		})}  
		</TabPanel>
		)
	})}
    
     
  </Tabs>
 )
 
 
	return(
	 <>
	 <Card>
        <CardContent>
          <Typography variant="h4">Digital Classroom</Typography>
           
        </CardContent>
       
      </Card>
	 <br/>
	 {
		arr &&		Object.keys(arr).map((keyName, i)=>{
	  
			  return(
			   <>
			   <br/>  <br/>
			   <Typography variant="h4">{keyName}</Typography>
			   <Divider /><br/>
			  <List style={{width:'100%',maxWidth:400,backgroundColor:'#fff'}}>
				   {
					 arr[keyName].map((m,j)=>{
						 
						   return(
						    
					  
								<ListComp {...m}/>
						  
						  
						   )
					   })  
				   }
				      </List>
			  </>
			  )
						})
			}
		 
	 </>
	)
}
 
 
 var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const get_subject_list=(res)=>{
return 	res.map(item => item.childsubject)
  .filter((value, index, self) => self.indexOf(value) === index).sort()
}
const get_topic_list=(input,subject)=>{
	let i,len=input.length,res=[];
	
	for(i=0;i<len;i++){
		if(input[i]['childsubject']==subject){
			res.push(input[i])
		}
	}
return 	res.map(item => item.topic)
  .filter((value, index, self) => self.indexOf(value) === index).sort()
}

const get_lecture_list=(input,subject,topic)=>{
	let i,len=input.length,res=[];
	
	for(i=0;i<len;i++){
		if(input[i]['childsubject']==subject && input[i]['topic']==topic){
			res.push(input[i])
		}
	}
	 
return 	 _.sortBy(res, 'title')
}



const TopicName=({topic,res})=>{
let i,len=res.length,obj;
for(i=0;i<len;i++){
	 
	if(res[i]['topic']==topic)
	{
		obj=res[i]
		break;
	}
}
 
 const [rem,setRem]=useState('')
 
 useEffect(()=>{
	 

  // Set the date we're counting down to
var countDownDate = new Date(obj.end_date + " 23:59:59").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Display the result in the element with id="demo"
    setRem(days + " days " + hours + " hours "  );

   
}, 1000 );
  
   },[])
  
 
 
	return(<> 
	 <Typography variant="h5" style={{paddingTop:10}}>{topic}</Typography> (will expire in <span style={{color:'red'}}>{rem} </span>) </>
	)
}
