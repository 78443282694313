import React, { useState, useEffect } from 'react'
import { api_url, rest_headers } from '../config'
import { Button } from "@material-ui/core";

import '../../sms-portal/send-sms/table.css'
const axios = require('axios');
export default ({ batch_id, loader, year }) => {
	console.log(year);
	const [list, setList] = useState([]);
	useEffect(async () => {
		if (batch_id)
			fetch();
	}, [batch_id])

	const fetch = async () => {
		try {

			loader.show()
			const { data } = await axios({
				url: api_url + '/batch-attendance-list',
				data: {
					batch_id: batch_id
				},
				method: 'post',
				responseType: 'json',
				headers: {
					APIKEY: '##reliable_rest_api_token##',
					ADMINTOKEN: localStorage.getItem('admin_token')
				}
			})


			setList(data.list)
			console.log(data.list)
		} catch (e) {

			alert(e)
		} finally {
			loader.hide()
		}

	}
	return (
		<div style={{ marginLeft: 10, paddingLeft: 10, fontFamily: 'Arial' }}>
			{list && list.length > 0 &&
				<table id="table">
					<tr><th>Date</th><th>Check</th><th>Attendance Status</th><th>Mobile Status</th><th>SMS Status</th></tr>
					{list.map((n, i) => {
						return (
							<tr><td>{n.date_styled}</td><td><Check fetch={fetch} year={year} loader={loader} {...n} /></td><td><ProcessAttendance year={year} fetch={fetch} loader={loader} {...n} /></td><td><ProcessSMS fetch={fetch} loader={loader} year={year} {...n} /></td>
								<td><SendSMS fetch={fetch} loader={loader} {...n} /></td></tr>
						)
					})
					}
				</table>
			}

		</div>
	)

}


const Check = ({ loader, fetch, attendance_processed, attendance_checked, batch_id, date, absent_count, present_count, year }) => {
	let absent_percent, color;
	const process = async () => {

		try {

			loader.show()
			const { data } = await axios({
				url: api_url + '/check-attendance',
				data: {
					batch_id: batch_id,
					date: date,
					year: year,
				},
				method: 'post',
				responseType: 'json',
				headers: {
					APIKEY: '##reliable_rest_api_token##',
					ADMINTOKEN: localStorage.getItem('admin_token')
				}
			})

			fetch()


		} catch (e) {

			alert(e)
		} finally {
			loader.hide()
		}

	}


	absent_percent = ((absent_count / (absent_count + present_count)) * 100).toFixed(2);
	if (absent_percent > 90)
		color = 'red';
	else
		color = 'white'
	return <>
		{!!attendance_checked && <table>
			<tr><td>Absent:</td><td>{absent_count}</td></tr>
			<tr><td>Present:</td><td>{present_count}</td></tr>
			<tr style={{ backgroundColor: color }}><td>Absent %: </td><td>{absent_percent}</td></tr>
		</table>
		}
		{!attendance_processed && <Button type="button" variant="contained" onClick={() => {
			process()
		}} color="primary">
			Check
		</Button>
		}
	</>


}
const ProcessAttendance = ({ loader, fetch, attendance_processed, attendance_checked, batch_id, date, year }) => {
	const process = async () => {
		try {

			loader.show()
			const { data } = await axios({
				url: api_url + '/process-attendance',
				data: {
					batch_id: batch_id,
					date: date,
					year: year,
				},
				method: 'post',
				responseType: 'json',
				headers: {
					APIKEY: '##reliable_rest_api_token##',
					ADMINTOKEN: localStorage.getItem('admin_token')
				}
			})

			fetch()


		} catch (e) {

			alert(e)
		} finally {
			loader.hide()
		}

	}
	if (attendance_processed)
		return <p>Processed</p>
	if (!attendance_checked)
		return <></>
	return (
		<Button type="button" variant="contained" onClick={() => {
			process()
		}} color="primary">
			Process
		</Button>
	)
}

const ProcessSMS = ({ loader, fetch, attendance_processed, sms_processed, attendance_checked, batch_id, date, year }) => {
	console.log(year);
	const process = async () => {
		try {
			loader.show()

			const { data } = await axios({
				url: api_url + '/process-sms',
				data: {
					batch_id: batch_id,
					date: date,
					year: year
				},
				method: 'post',
				responseType: 'json',
				headers: {
					APIKEY: '##reliable_rest_api_token##',
					ADMINTOKEN: localStorage.getItem('admin_token')
				}
			})

			fetch()


		} catch (e) {

			alert(e)
		} finally {
			loader.hide()
		}

	}
	if (sms_processed)
		return <p>Processed</p>
	if (!attendance_checked)
		return <></>
	if (!attendance_processed)
		return <></>
	return (
		<Button type="button" variant="contained" onClick={() => {
			process()
		}} color="primary">
			Process
		</Button>
	)
}


const SendSMS = ({ loader, fetch, attendance_processed, sms_processed, attendance_checked, sms_sent, batch_id, date }) => {
	const process = async () => {
		try {

			loader.show()
			const { data } = await axios({
				url: api_url + '/send-sms',
				data: {
					batch_id: batch_id,
					date: date,

				},
				method: 'post',
				responseType: 'json',
				headers: {
					APIKEY: '##reliable_rest_api_token##',
					ADMINTOKEN: localStorage.getItem('admin_token')
				}
			})

			fetch()


		} catch (e) {

			alert(e)
		} finally {
			loader.hide()
		}

	}
	if (sms_sent)
		return <>SMS Sent</>
	if (!sms_processed)
		return <></>
	if (!attendance_checked)
		return <></>
	if (!attendance_processed)
		return <></>
	return (
		<Button type="button" variant="contained" onClick={() => {
			process()
		}} color="primary">
			Send
		</Button>
	)
}