import React from 'react';
import styles from "../reusable-calling-code-project/table.module.css";

const TableComp = ({ result }) => {
    return (
        <div>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th colSpan={5}>Calling Reports</th>
                    </tr>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Admin Name</th>
                        <th>Leads Alloted</th>
                        <th>Touched Leads</th>
                        <th>Untouched Leads</th>
						{/* <th>Interested Leads</th>*/}
                        {/* <th>Conversion</th> */}
                    </tr>
                </thead>
                <tbody>
                    {result.map((n, i) => (
                        <tr>
                            <td>{i + 1}</td>
                            <td>{n.name}</td>
                            <td>{n.leads_alloted}</td>
                            <td>{n.touched_leads}&nbsp;&nbsp;({n.t_per}%)</td>
                            <td>{n.leads_alloted - n.touched_leads}&nbsp;&nbsp;({n.u_per}%)</td>
								{/*<td>{n.interested}</td>*/}
                            {/* <td>Coming soon...</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TableComp
