import React, { useEffect } from "react";
import _ from "lodash";
import MathComp from "../../../mathcomp";
import Radio from "../../../input_radio";
import CheckBox from "../../../input_checkbox";
import {
  Separator1,
  Separator2,
  Separator3,
  Separator4,
} from "../../design/separator";
import View from "../../design/view";
import { OptionContainer, Left, Right } from "../../design/option-container";
import SubquestionContainer from "../../design/subquestion-container";
export default ({ question, subquestions, onChange, setVisited, current_question_index, onMount, onUnmount }) => {
  useEffect(() => {
    setVisited(current_question_index);
    onMount(current_question_index)
    return function cleanup() {
      onUnmount(current_question_index)
    };
  }, [current_question_index]);
  
  const { type } = question;
  if (type === "single") {
    const english = extract_english({ subquestion: subquestions[0].subquestion });
    return (
      <React.Fragment>
        <LatexComp content={english.content} />
        <Separator1 />
        <OptionList
          onChange={(e) =>
            onChange({ id: subquestions[0].subquestion.id, value: e.target.value })
          }
          subquestion={subquestions[0].subquestion}
          option={subquestions[0].subquestion.option}
        />
      </React.Fragment>
    );
  }
  let english,
    ques_english = extract_english({ subquestion: question });

  return (
    <React.Fragment>
      <LatexComp content={ques_english.content} />
      <Separator3 />
      {subquestions.map((sub, i) => {
        english = extract_english({ subquestion: sub.subquestion });
        return (
          <React.Fragment key={sub.id}>
            <SubquestionContainer index={i + 1}>
              <LatexComp content={english.content} />

              <Separator1 />
              <OptionList
                onChange={(e) =>
                  onChange({ id: sub.subquestion.id, value: e.target.value })
                }
                subquestion={sub.subquestion}
                option={sub.subquestion.option}
              />
            </SubquestionContainer>
            <Separator4 />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const LatexComp = ({ content }) => {
  return <MathComp html={content} />;
};

const OptionList = ({ subquestion, onChange, option }) => {
  const name = subquestion.id;
 
  return (
    <React.Fragment>
      {option.map((op) => {
        return (
          <React.Fragment key={op.id}>
            <OptionComp
              type={subquestion.type}
              onChange={onChange}
              option={op}
              name={name}
            />
            <Separator2 />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const OptionComp = ({ type, option, name, onChange }) => {
  const english = extract_english({ subquestion: option });

  return (
    <React.Fragment>
      <OptionContainer>
        <Left>
          {type === "mcq" ? (
            <CheckBox
              name={name}
              onChange={onChange}
              initialValue={option.id}
              selected={option.selected}
            />
          ) : (
            <Radio
              name={name}
              onChange={onChange}
              initialValue={option.id}
              selected={option.selected}
            />
          )}
        </Left>
        <Right>
          <LatexComp content={english.content} />
        </Right>
      </OptionContainer>
    </React.Fragment>
  );
};

const extract_english = ({ subquestion }) => {
  const subquestion_content = subquestion.content;
  const english = _.find(subquestion_content, { language: "en" });

  return english;
};
