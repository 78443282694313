import { gql } from "@apollo/client";

export const classroom_test_subject_topic_gql = gql`
  query classroom_test_schedule_subject_topic($classroom_test_id: Int!) {
    classroom_test_schedule_subject_topic(
      classroom_test_id: $classroom_test_id
    ) {
      id
    classroom_test_batch{
      classroom_test_subject {
        subject {
          name
        }
        classroom_test_topic {
          topic {
            name
          }
        }
      }
      }
    }
  }
`;
