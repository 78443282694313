import React from "react";
import { Select } from "../../../../utils/select";
import { faculty_list_query_gql } from "../../gql/query/faculty-list-query";
import { useQuery } from "@apollo/client";
import GqlErrorComp from "../../../../utils/gql-error-comp";
export default ({ label, value, name, onChange, is_jr=false }) => {
  const { loading, error, data } = useQuery(faculty_list_query_gql);
  if (loading) return <span>Loading...</span>;
  if (error) return <GqlErrorComp error={error} />;
  const { faculty_list } = data;

  function checkAge(faculty) {
    return faculty.dept === "JrDivision";
  }

  const arr = faculty_list.filter(checkAge)
  
  return (
    <Select
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      array={is_jr ? arr : faculty_list}
    />
  );
};
