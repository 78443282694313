import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import JoinMeetingButton from "./join-meeting-button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default ({
  r,

  is_home_component,
  mark_attendance_mutation,
}) => {
  const classes = useStyles();
  const props = {
    r,
    is_home_component,
    mark_attendance_mutation,
    classes,
  };
  return (
    <>
      {r.map((t) => {
        return <Render {...props} r={t} />;
      })}
    </>
  );
};

const Render = ({
  r: { subject, info },
  mark_attendance_mutation,
  classes,
  is_home_component,
}) => {
  if (info.length < 1) return <></>;
  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {subject}
          </ListSubheader>
        }
        className={classes.root}
      >
        {info.length > 0 &&
          info.map((t, i) => {
            if (is_home_component && i > 0) return <></>;
            if (t.courseware.topic.childsubject.subject.name === subject)
              return (
                <JoinMeetingButton
                  r={t}
                  key={i}
                  mark_attendance_mutation={mark_attendance_mutation}
                />
              );
          })}
      </List>
    </>
  );
};
