import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import theme from "../../../../../theme";
import VideoViewer from "../../../../../utils/video-modal"

import { Loader } from "../../../../../utils/loader";
import pdf_handler from "./pdf-handler";

export default (props) => {
  return <Comp {...props} />;
};
class Comp extends React.Component {
  state = {
    loading: false,
    toggle: false
  };
  setLoader = (loading) => {
    this.setState({ loading });
  };
  toggleModal= (status) => {
    this.setState({ toggle : status });
  }
  join = async () => {
    let {
      r: org_r,

      mark_attendance_mutation,
    } = this.props;
    const { setLoader, toggleModal } = this;
    const r = org_r.courseware;
    if (r["disabled"]) return false;

    try {
      let {
        data: {
          batch_courseware_mark_attendance: { success },
        },
      } = await mark_attendance_mutation({
        variables: {
          id: org_r["id"],
        },
      });
      if(r['type'] === "VIDEO"){
        toggleModal(true)
      }
      else pdf_handler({ url: r["pdf_url"], title: r["title"] });
    } catch (e) {
      alert(e.message);
    }
  };
  render() {
    const { r: org_r } = this.props;
    const r = org_r.courseware;
    let style = {};
    style = { color: theme.palette.primary.main };

    return (
      <>
        <ListItem button onClick={this.join}>
          <ListItemIcon style={style}>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              r["topic"].name +
              " - " +
              r["topic"]["childsubject"]["subject"].name
            }
            secondary={r["type"] + " - " + org_r["created_at"]}
          />
        </ListItem>
        {this.state.loading && <Loader />}
        {this.state.toggle && <VideoViewer toggleModal={this.toggleModal} url={r['pdf_url']} />}
      </>
    );
  }
}
