import React, { useContext, useState,useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutchildsubject from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify, 
} from "../../../../../../utils/functions";
import Typography from "@material-ui/core/Typography";
import SelectState from "../../../components/call-center--select-state"; 
import SelectStream from "../../../components/call-center--select-stream"; 
import SelectCity from "../../../components/call-center--select-city"; 
import SelectClass from "../../../components/call-center--select-class"; 
import SelectCourse from "../../../components/call-center--select-course"; 
import SelectMedium from "../../../components/call-center--select-medium"; 



import {api_url} from '../config.js'

const axios = require('axios');
export default ({mobile,account_id,done}) => {
 
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
    account_id={account_id}
	done={done}
      snackBar={snackBar}
      loader={loader}
	  mobile={mobile}
    />
  );
};
const Comp = ({ loader, snackBar,mobile:initial_mobile,account_id,done }) => {
	let [disabled,setDisabled]=useState(false);
	 
	let [name,setName]=useState('');
	let [mobile,setMobile]=useState(initial_mobile); 
	let [mobile1,setMobile1]=useState('');
 let [state,setState]=useState('');
	let [city,setCity]=useState('');

 
	useEffect(()=>{
		if(initial_mobile){
			setDisabled(true);
		}
	},[])
	
	  const [state_list,setStateList]=useState([]);
  const [state_city_list,setStateCityList]=useState([]);
	
	   useEffect(async() => {
  
   try {
   loader.show();
   const  {data}=await axios({
        url:  api_url+'/get-select-boxes-list.php',
        method: 'post',
		responseType: 'json',
       data: 'auth_token=##reliabletoken##',
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
 
 setStateList( data.message.state_list)
 setStateCityList( data.message.state_city_list)
 


 
    } catch (e) {
		 
    snackBar.show(e);
    } finally {
      loader.hide();
    }
  
 
  
    
  },[]);
  

   
  
  const handleSubmit = async (e) => {
    e.preventDefault();

     
 
  try {
	   if(name=='' || name.length<3)
{
	snackBar.show('Enter valid valid name');
	return false;
}
 if(mobile.length!=10)
{
	snackBar.show('Enter valid Mobile number');
	return false;
}
   loader.show();
   const  {data}=await axios({
		url:api_url+'/create-contact.php',
        method: 'post',
		responseType: 'json',
       data: `auth_token=##reliabletoken##&name=${name}&mobile=${mobile}&mobile1=${mobile1}&state=${state}&city=${city}&account_id=${account_id}&admin_token=${localStorage.getItem('admin_token')}`
      }) 
	    
	  if(data.error){
		  snackBar.show(data.message);
		  return false;
	  }
 
 
snackBar.show(data.message.message);
      
       setName('');	setMobile('');  setState(''); setCity('');
 done(true)	   
    } catch (e) {
		 
      //snackBar.show(e);
    } finally {
      loader.hide();
    }
  }
 


 
 
  
  
  return (
    
      <Container>
        <FormLayoutchildsubject title="Create Contact">
          <Grid spacing={3} container>
            <Grid item xs={6}>
              <TextField
                label="Enter Name"
                variant="outlined"
                name="name"
                value={name}
                onChange={(e)=>{setName(e.target.value)}}
              />
            </Grid>
			  <Grid item xs={6}>
              <TextField
                label="Enter Mobile No."
                variant="outlined"
                name="mobile"
				 inputProps={{
					maxLength: 10,
				  }}
                value={mobile}
                 onChange={(e)=>{setMobile(e.target.value)}}
				 
			 
              />
            </Grid>
			<Grid item xs={6}>
              <TextField
                label="Enter Mobile No.1"
                variant="outlined"
                name="mobile1"
				 inputProps={{
					maxLength: 10,
				  }}
                value={mobile1}
                 onChange={(e)=>{setMobile1(e.target.value)}}
				 
			 
              />
            </Grid>
			 
			 <Grid item xs={6}>
              <SelectState
                label="State"
                name="state"
                value={state}
				state_list={state_list}
                 onChange={(e)=>{setCity('');setState(e.target.value)}}
              />
            </Grid>
		   
			
           <Grid item xs={6}>
              <SelectCity
                label="City"
                name="city"
                value={city}
				state_city_list={state_city_list}
				state={state}
				onChange={(e)=>{setCity(e.target.value)}}
              />
            </Grid>
		   
			 
			 
                
            <Grid item xs={12}>
              <Button type="button" onClick={handleSubmit} variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutchildsubject>
      </Container>
   
  );
};
