import React from 'react'
export default ({ index, children }) => {
    return (
        <div  >
            <span><b>Q. {index}</b></span><br /><br />
            {children}


        </div >
    )
}