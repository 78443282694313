import {gql} from '@apollo/client';

export const latest_news_gql = gql`
  {
    latest_news_list {
      title
      image
      url
    }
  }
`;
