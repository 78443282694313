import React, { useContext, useState,useEffect } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayouttopic from "../../../../../../utils/form-layout-admin";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../../context/LoaderContext";
import {
  handleFormChange, 
  resetForm,
  error_beautify,
} from "../../../../../../utils/functions";
 import { Select } from "../../../../../../utils/select";
import Typography from "@material-ui/core/Typography";
 import {api_url,rest_headers} from '../../../../../student/app/srceens/digital-classroom/config'
import {handle_error} from '../../call-center/handle_error'
 
const axios = require('axios');

export default () => {
	 
    const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
       snackBar={snackBar}
      loader={loader}
	  
    />
  );
};
const Comp = ({ loader, snackBar, adminname }) => {
    const [roll,setRoll]=useState('')
   const [mobile,setMobile]=useState('')
   

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
 
if(!roll)
{
	alert('Please enter roll no ');
	return;
}
	if(isNaN(roll) || roll.length!=8 || !(roll[0]=='2'))
{
	alert('Please enter correct roll no. ');
	return;
}
if(!mobile)
{
	alert('Please enter mobile ');
	return; 
}
	if(isNaN(mobile) || mobile.length!=10 || !(mobile[0]=='6' || mobile[0]=='7' || mobile[0]=='8' || mobile[0]=='9'))
{
	alert('Please enter correct mobile ');
	return;
}
      loader.show();
const  {data}=await axios({
        url: api_url+'/change-mobile-no',
        method: 'post',
		responseType: 'json',
		data:{
			roll:roll,
			mobile:mobile
			 
		},
        headers:{
			APIKEY: '##reliable_rest_api_token##',
			ADMINTOKEN:localStorage.getItem('admin_token')	 
}
      }) 
	  alert(data.message);
	  if(data.success=='ok'){
		  setRoll('')
		  setMobile('')
	  }
       
    } catch (e) {
		 
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayouttopic title="Change Mobile No.">
          <Grid spacing={3} container>
		      
            <Grid item xs={12}>
              <TextField
                label="Enter Roll No."
                variant="outlined"
                name="roll"
                value={roll}
                onChange={(e)=>setRoll(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Mobile No."
                variant="outlined"
                name="mobile"
                value={mobile}
                onChange={(e)=>setMobile(e.target.value)}
              />
            </Grid>
           
           

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CHANGE
              </Button>
            </Grid>
          </Grid>
        </FormLayouttopic>
      </Container>
    </form>
  );
};


 