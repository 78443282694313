import { gql } from "@apollo/client";

export const batch_quiz_list_query_gql = gql`
  query batch_quiz_list($batch_id:Int!){
    batch_quiz_list(batch_id:$batch_id){
    id
    date
    quiz{
      id
      name
    }
  }
}
`;
