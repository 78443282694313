import { Button, Select, Paper, MenuItem, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import Modal from '../reusable-calling-code-project/modal';
import Callback from './callback';
import Conversation from './conversation';
import moment from 'moment';
import Scholarship from './scholarship';

const ModalComp = ({ token, isDesktop, refresh, demos, scholarship, last_remark, last_category, last_time, loader, snackBar, last_callback, component }) => {

    const [show, setShow] = useState(false);


    const handleClick = () => {
        setShow(!show);
    }


    const hide = () => {
        setShow(!show);
    }



    return (
        <div>

            <Button style={{ border: '1px solid #000' }} onClick={handleClick}>Click</Button>

            {show && <Modal hide={hide}>
                {
                    (component === 'conversation')
                        ? <>
                            <Conversation refresh={refresh} hide={hide} isDesktop={isDesktop} last_remark={last_remark} last_category={last_category} last_time={last_time} loader={loader} snackbar={snackBar} token={token} />
                        </>
                        : <></>
                }
                {
                    (component === 'callback')
                        ? <>
                            <Callback hide={hide} isDesktop={isDesktop} loader={loader} snackbar={snackBar} token={token} last_callback={last_callback} />
                        </>
                        : <></>
                }
                {
                    (component === 'scholarship')
                        ? <>
                            <Scholarship refresh={refresh} demos={demos} scholarship={scholarship} hide={hide} isDesktop={isDesktop} loader={loader} snackBar={snackBar} token={token} />
                        </>
                        : <></>
                }

            </Modal>
            }

        </div>
    )
}

export default ModalComp;
