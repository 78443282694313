import { gql } from "@apollo/client";

export const create_admin_mutation_gql = gql`
  mutation create_admin(
    $username: String!
    $password: String!
    $name: String!
  ) {
    create_admin(username: $username, password: $password, name: $name) {
      success
    }
  }
`;
