import axios from 'axios';
import React from 'react';
import { LoaderContext } from '../../../../../context/LoaderContext';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import { Button } from '@material-ui/core';

export default function ButtonComp({ admin_id, Unchecked, setCheckBoxList, checkboxlist, hide, refresh, loader, state }) {

    const handleClick = async () => {
        try {
            loader.show();
            if (checkboxlist.length == 0) {
                alert('Select Leads for transfer');
            }
            else {
                const { data: response } = await axios({
                    url: api_url + '/lead-data-calling/bulk-transfer',
                    method: 'post',
                    responseType: 'json',
                    data: {
                        id: admin_id,
                        checkboxlist: checkboxlist,
                    },
                    ...rest_headers
                })
                if (response.Success === 'ok') {
                    hide(true);
                    setCheckBoxList([]);
                    checkboxlist = [];
                    Unchecked();
                    refresh(state);
                    alert('Lead Transfered Successfully');
                }
            }
        }
        catch (e) {
            alert(e.message)
        }
        finally {
            loader.hide();
        }
    }

    return (
        <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={handleClick}>Transfer</Button>
    )
}