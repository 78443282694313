import React, { useContext, useState } from "react";
import { Container, TextField, Grid, Button } from "@material-ui/core";
import FormLayoutbatch from "../../../../../utils/form-layout-admin";
// import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { LoaderContext } from "../../../../../context/LoaderContext";
import {
  handleFormChange,
  resetForm,
  error_beautify,
} from "../../../../../utils/functions";
// import { create_batch_mutation_gql } from "../../../gql/mutation/create-batch-mutation";
import Typography from "@material-ui/core/Typography";
import SelectSessionCoursePhase from "../../components/select-session-course-phase";
import axios from "axios";
import { api_url } from "./config";

export default () => {
  // const [create_batch_mutation] = useMutation(create_batch_mutation_gql);
  const snackBar = useContext(SnackBarContext);
  const loader = useContext(LoaderContext);
  return (
    <Comp
      // create_batch_mutation={create_batch_mutation}
      snackBar={snackBar}
      loader={loader}
    />
  );
};
const Comp = ({ loader, snackBar, create_batch_mutation }) => {
  const [values, setValues] = useState({
    name: "",
    description: "",
    alias: "",

    phase_id: "",
    session_id: ""
  });
  const handleInputChange = (e) => {
    handleFormChange({ e, values, setValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { name, description, alias, phase_id, session_id } = values;

      loader.show();

      const { data: obj } = await axios({
        url: api_url + "/batch-data",
        method: "post",
        responseType: 'json',
        data: {
          name: values.name,
          alias: values.alias,
          description: values.description,
          phase_id: values.phase_id,
          session_id: values.session_id
        },
        headers: {
          APIKEY: '##reliable_rest_api_token##',
          ADMINTOKEN: localStorage.getItem('admin_token')
        }
      })
      // console.log(obj);
      if (obj.Success === 'ok') {
        snackBar.show("Batch Created Successfully");
      }

      // let {
      //   data: {
      //     create_batch: { success },
      //   },
      // } = await create_batch_mutation({
      //   variables: {
      //     name,
      //     description,
      //     alias,
      //     phase_id,
      //   },
      // });

      // if (success) snackBar.show("batch created successfully");

      resetForm({ values, setValues });
    } catch (e) {
      snackBar.show(error_beautify(e.message));
    } finally {
      loader.hide();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FormLayoutbatch title="Create Batch">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <TextField
                label="Enter Name"
                variant="outlined"
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Alias"
                variant="outlined"
                name="alias"
                value={values.alias}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter Description"
                variant="outlined"
                name="description"
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>

            <SelectSessionCoursePhase onChange={handleInputChange} />
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                CREATE
              </Button>
            </Grid>
          </Grid>
        </FormLayoutbatch>
      </Container>
    </form>
  );
};
