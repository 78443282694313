import React, { useState, useEffect } from 'react'
import { Select } from "../../../../../utils/select";
import confirm_click from '../reusable-calling-code-project/reuable-calling-code/confirm_click';
import Modal from '../reusable-calling-code-project/modal';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import styles from '../reusable-calling-code-project/table.module.css';
import { handle_error } from '../reusable-calling-code-project/handle_error';
import { api_url, rest_headers } from '../reusable-calling-code-project/config';
import moment from 'moment';
const axios = require('axios');



const Callback = ({ token, isDesktop, last_callback, loader, snackBar, hide }) => {

    const [callback, setCallback] = useState(last_callback);

    useEffect(() => {
        setCallback(last_callback);
    }, [token])

    // console.log(token)

    const click = async () => {
        try {

            loader.show();

            // console.log(abc)

            const { data: obj } = await axios({
                url: api_url + '/calling-panel/create-callback',
                method: 'post',
                responseType: 'json',
                data: {
                    token: token,
                    callback: callback,
                },
                ...rest_headers,
            })

            if (obj.success === 'ok') {
                alert('Follow Up Created Successfully');
                hide();
            }


        } catch (e) {
            alert(e.message)
        } finally {
            loader.hide();
        }
    }

    return (
        <>
            <Typography style={{ textAlign: 'center' }} variant="h5">Create Follow Up</Typography>
            <Grid xs={12}>
                <TextField
                    fullWidth
                    variant='outlined'
                    type="datetime-local"
                    value={callback}
                    min={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                    onChange={(e) => setCallback(e.target.value)}
                />
            </Grid>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <br />

                <Button style={{ border: '1px solid #000', backgroundColor: '#F5DFDD' }} onClick={() => {

                    confirm_click({ click, token })
                }}>Submit</Button>&nbsp;&nbsp;

                <View token={token} loader={loader} snackBar={snackBar} />
            </div>
        </>
    )
}

const View = ({ token, loader, snackBar, isDesktop }) => {
    const [array, setArray] = useState([]);
    const hide = () => {
        setArray([]);
    }
    const handleClick = async (token) => {
        try {
            loader.show();


            const { data: obj } = await axios({
                url: api_url + '/calling-panel/view-callback',
                method: 'post',
                responseType: 'json',
                data: {
                    token: token,
                }
            })

            setArray(obj.array);


        } catch (e) {
            alert(e.message)
        } finally {
            loader.hide();
        }

    }

    return (
        <>
            <Button style={{ border: '1px solid #000', backgroundColor: '#EFC2EF' }} onClick={() => handleClick(token)}>View</Button>



            {array && array.length > 0 && <Modal hide={hide}>
                <div style={{ fontFamily: 'Arial' }}>

                    <table className={styles.table}>
                        <thead>
                            <th colSpan={2}>CallBack</th>
                        </thead>
                        <tbody>
                            {array.map((n, i) => {
                                return (
                                    <tr style={{ fontSize: '13px' }}>
                                        <td>Next Callback Date</td>
                                        <td>{n.dt}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>


                </div>


            </Modal>

            }
        </>
    )
}

export default Callback
