import { createMuiTheme } from "@material-ui/core/styles";
import color from "color";

const primary = color("#b11116").lighten(1).hex();
const accent = color("#7e9b24").darken(0).hex();

const mytheme = createMuiTheme({
  palette: {
    background: {
      default: "#ffffff",
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: accent,
    },
    accent: {
      main: accent,
    },
  },
  status: {
    danger: "red",
  },
});

mytheme.extra = {
  screenBackgroundColor: color(mytheme.palette.accent.main).lighten(1.7).hex(),
  iconColor: mytheme.palette.primary.main,
  activeIconColor: mytheme.palette.accent.main,
  inactiveIconColor: "#999999",
  chatColors: {
    left: color(mytheme.palette.accent.main).lighten(1.5).hex(),
    right: color(mytheme.palette.primary.main).lighten(1.5).hex(),
  },
  color3: "#8E0E59",
  color4: "#dddddd",
};

export const theme = mytheme;
